// Container
@mixin container {
  position: relative;
  // padding-right: 80px;
  // padding-left: 80px;
  padding-right: 5.8565%;
  padding-left: 5.8565%;

  @media #{$pc} {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }

  @media #{$sp} {
    padding-right: 30px;
    padding-left: 30px;
  }
}
