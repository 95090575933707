.item-shop-guide {
  background-color: $main-bg-color;

  .tab-only {
    @media all and (min-width: 600px) and (max-width: 1191px) {
      display: none;
    }
  }

  // 共通スタイル
  h3 {
    border-left: 5px solid $black-color;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.08em;
    padding: 7px 24px 9px;

    @media #{$sp} {
      font-weight: 500;
      padding: 9px 29px 7px 30px;

      position: relative;
    }

    &::after {

      @media #{$sp} {
        content: "";
        display: block;
        background-image: url(/assets/img/item/icon_arrow_bottom.svg);
        width: 29px;
        height: 29px;

        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        transition: background-image .2s;
      }
    }
  }

  h4 {
    background-color: $secondar-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0;
    padding: 6px 12px 8px;
    margin-bottom: 8px;

    @media #{$sp} {
      font-size: 20px;
      font-weight: 500;
      padding: 11px 11px 9px;
      margin-bottom: 2px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.14285714;
    letter-spacing: 0;

    @media #{$pc} {
      padding-right: 8px;
    }

    @media #{$sp} {}
  }

  ul {

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 2.14285714;
      letter-spacing: 0;

      @media #{$pc} {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }

  a {
    text-decoration: underline $black-color;
    text-indent: 0;
  }

  img {
    display: block;
    width: 224px;
    margin-top: 4px;
    margin-bottom: 26px;

    @media #{$sp} {
      margin-top: 5px;
      margin-bottom: 25px;
    }
  }

  &__wrap {

    @media #{$pc} {
      max-width: 1366px;
      margin: 0 auto;
      padding: 0 5.85651537%;
    }
  }

  &__inner {
    background-color: $white-color;

    @media #{$pc} {
      padding: 7.71144279% 8.54063018% 4.97512438% 9.2039801%;

      display: flex;
    }

    @media all and (min-width: 600px) and (max-width: 1191px) {
      padding: 6.94246315% 6.94246315% 5.70613409% 7.70328103%;
    }

    @media all and (min-width: 600px) and (max-width: 756px) {
      display: block;
    }
  }

  &__col {

    flex-basis: 50%;

    & + & {

      @media #{$pc} {
        margin-left: 5.64516129%;
      }

      @media all and (min-width: 600px) and (max-width: 756px) {
        margin-top: 80px;
        margin-left: 0;
      }

      @media #{$sp} {
        padding-bottom: 0;
      }
    }

    &.is-open {

      h3::after {
        @media #{$sp} {
          background-image: url(/assets/img/item/icon_arrow_top.svg);
        }
      }

      .purchase-box__title {
        @media #{$sp} {
          border-bottom: 1px solid $border-gray-color;
        }
      }

      .purchase-box__inner {
        @media #{$sp} {
          display: block;
          padding-bottom: 50px;
        }
      }

      .delivery-box__inner {
        @media #{$sp} {
          display: block;
        }
      }
    }
  }

  // 左カラム
  .purchase-box {

    &__title {
      @media #{$sp} {
        border-top: 1px solid $border-gray-color;
        padding: 20.5px 29px 19.5px 30px;
      }
    }

    &__inner {
      margin-top: 36px;

      @media #{$sp} {
        display: none;
        padding: 0 30px;
        margin-top: 55px;
      }
    }

    &__postage {

      h4 {
        margin-top: 41px;
        margin-bottom: 4px;

        @media #{$sp} {
          margin-top: 57px;
          margin-bottom: 11px;
        }
      }

      ul {

        @media #{$pc} {
          margin-right: -9px;
        }
      }
    }

    &__ban {

      h4 {
        margin-top: 36px;
        margin-bottom: 18px;

        @media #{$sp} {
          margin-top: 46px;
          margin-bottom: 7px;
        }
      }
    }
  }

  // 右カラム
  .delivery-box {

    &__title {

      @media #{$sp} {
        border-top: 1px solid $border-gray-color;
        border-bottom: 1px solid $border-gray-color;
        padding: 22.5px 29px 20.5px 33px;
      }

      h3 {
        padding: 7px 24px 9px 20px;

        @media #{$sp} {
          padding: 9px 24px 7px 25px;
        }
      }
    }

    &__inner {
      margin-top: 36px;

      @media #{$sp} {
        display: none;
        padding: 0 30px 30px;
        margin-top: 55px;
      }
    }

    &__date {

      h4 {
        padding-left: 14px;
        margin-bottom: 3px;
      }

      p {
        line-height: 2.85714286;
      }
    }

    &__time {

      h4 {
        padding-left: 14px;
        margin-top: 42px;
        margin-bottom: 17px;
      }
    }

    &__return {

      h4 {
        padding-left: 14px;
        margin-top: 47px;
        margin-bottom: 15px;
      }
    }
  }

  .attention {
    color: $bronw-color;
  }

  .strong {
    font-weight: bold;
  }
}
