/*
  ----------------
  Top | News
  ----------------
*/
.top-news {
  &__wrap {
    padding: 110px 80px 150px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding: 55px 40px 75px;
    }

    @media #{$sp} {
      padding: 80px 35px 145px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.15em;
    line-height: 1.566;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 0.96;
    }
  }

  &__list {
    margin-top: 55px;

    display: flex;
    flex-wrap: wrap;

    @media #{$sp} {
      margin-top: 35px;
    }
  }

  &__item {
    border-top: 1px solid $light-gray-color;
    flex-basis: 100%;

    &:last-child {
      border-bottom: 1px solid $light-gray-color;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    padding: 18px 0;

    @media #{$sp} {
      display: block;
      padding: 20px 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__date {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    min-width: 86px;

    @media #{$sp} {
      letter-spacing: -0.3px;
    }
  }

  &__tag {
    border: 1px solid $black-color;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    margin-left: 17px;
    text-align: center;
    width: 100px;

    @media #{$sp} {
      font-size: 12px;
      margin-left: 11px;
      padding: 0 14px;
      width: auto;
    }
  }

  &__text {
    font-weight: 400;
    margin-left: 20px;
    padding-right: 15px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media #{$sp} {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 2.307;
      margin-top: 12px;
      margin-left: 0;
      padding-right: 0;

      -webkit-line-clamp: 2;
    }
  }

  &__more {
    margin-top: 65px;
    text-align: center;

    @media #{$sp} {
      margin-top: 38px;
    }
  }

  &__btn {
    @include btnLink(brown);
    padding-top: 9px;
    padding-bottom: 9px;
  }
}