/*
  ----------------
  .account-overview | アカウントトップ
  ----------------
*/
.account-overview {

  .account-menu-list {
    margin-top: 20px;

    li:not(:first-child) {
      margin-top: 20px;
    }

    // アカウントクローズの非表示
    &.account-close {
      display: none;
    }

    a {
      // text-decoration: underline;
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        background: $black-color;
        border-radius: 50%;
        width: 17px;
        height: 17px;

        position: absolute;
        top: 50%;
        left: 0;

        @media #{$pc} {
          transform: translateY(-50%);
        }

        @media #{$sp} {
          width: 16px;
          height: 16px;
          top: 7px;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        border-top: 1px solid $white-color;
        border-right: 1px solid $white-color;
        width: 4px;
        height: 4px;

        position: absolute;
        top: 50%;
        left: 3px;

        @media #{$pc} {
          transform: rotate(45deg) translateY(-50%);
        }

        @media #{$sp} {
          top: 13px;
          left: 4px;
          transform: rotate(45deg);
        }
      }
    }
  }

  .custompropkeys {
    display: none;
  }
}
