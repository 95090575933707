/*
  ----------------
  .contact-form | お問い合わせ・資料請求
  ----------------
*/

.form {
  &-title {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.08em;
    line-height: 2;
    margin-top: 88px;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      line-height: 1.6;
      margin-top: 48px;
    }
  }

  &-select {
    margin: 55px auto 0;
    max-width: 1206px;

    display: flex;
    align-items: flex-start;
    gap: 13px;
    justify-content: space-between;

    @media #{$sp} {
      margin: 65px 15px 0;
    }

    &__item {
      max-width: 593px;
      width: 100%;

      &.is-active {
        max-width: 600px;
      }
    }

    &__link {
      background: $black-color;
      border-top: 2px solid $black-color;
      color: $white-color;
      display: block;
      font-size: 16px;
      height: 50px;
      letter-spacing: 0;
      line-height: 1.5;
      padding-top: 9px;
      text-align: center;

      @media #{$sp} {
        border-right: 1px solid $light-gray-color;
        border-left: 1px solid $light-gray-color;
      }

      .is-active & {
        background: $main-color;
        color: $black-color;
        height: 62px;
      }
    }
  }

  #contact-type {
    option {
      width: 50%;
    }
  }
}

.contact_contact_html,
.form-form-contact_guest,
.form-form-catalog_guest {
  & > h3 {
    display: none;
  }

  .form-note {
    margin-top: 0;
    margin-bottom: 0;

    @media #{$sp} {
      padding: 0;
    }
  }

  .page-title {
    @include pageTitle($white-color, contact);

    @media #{$pc} {
      margin-bottom: 77px;
    }
  }

  .account-login {
    background: #fafafa;
    max-width: 1206px;
    margin: 0 auto;
    padding-top: 40px;

    @media #{$sp} {
      padding: 30px 30px 0;
    }

    & > a {
      @include btn();
      display: inline-block;
      max-width: none;
      width: auto;

      @media #{$sp} {
        font-size: 12px;
        width: 100%;
      }
    }
  }

  .form-body {
    @media #{$pc} {
      margin-bottom: 177px;
      padding-top: 41px;
      padding-bottom: 78px;
    }

    @media #{$sp} {
      // border-top: 1px solid $light-gray-color;
      // margin-top: -1px;
      padding-top: 30px;
      padding-bottom: 154px;
      position: relative;
    }
  }

  .formrow.name-input-row,
  .formrow.formrow-radio {
    border-top: none;
  }

  .btn-submit-signup {
    margin-top: 1rem;
  }

  .formrow-submit {
    margin-top: 60px;

    @media #{$sp} {
      margin-top: 47px;
    }
  }
}

// 注意事項
.form-precautions {
  &__title {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.6;
    text-align: center;

    @media #{$sp} {
      font-size: 20px;
      line-height: 2;
    }
  }

  &__text {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.8462;
    margin-top: 22px;
    text-align: center;

    @media #{$sp} {
      line-height: 1.8462;
      margin-top: 9px;
    }
  }

  &__text-area {
    background: $white-color;
    border: 1px solid $light-gray-color;
    border-radius: 2px;
    box-sizing: border-box;
    color: $black-color;
    display: block;
    font-size: 13px;
    font-weight: 400;
    height: 100vh;
    line-height: 3.0769;
    margin: 45px auto 0;
    max-height: 447px;
    max-width: 1000px;
    overflow-y: scroll;
    padding: 50px;
    width: 100%;

    @media #{$sp} {
      letter-spacing: 0;
      line-height: 1.9231;
      margin-top: 28px;
      max-height: 629px;
      padding: 20px 21px;
    }

    & > a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}