/*
  ----------------
  Privacy policy | 個人情報保護方針
  ----------------
*/
.privacy-policy {

  &__wrap {
    @include container;
    padding-top: 110px;
    padding-bottom: 160px;

    @media #{$sp} {
      padding-top: 61px;
      padding-bottom: 80px;
    }
  }

  &__title {
    border-bottom: 2px solid $black-color;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.08em;
    margin-top: 80px;
    padding-left: 5px;

    @media #{$sp} {
      font-size: 25px;
      margin-top: 40px;
    }
  }

  &__content {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;

    @media #{$sp} {
      padding: 30px 10px 0;
    }
  }

  &__lead {
    line-height: 1.7;
  }

  &__title-sub {
    font-size: 20px;
    margin-top: 40px;

    @media #{$sp} {
      font-size: 18px;
      text-align: center;
    }
  }

  &__text {
    line-height: 1.7;
    margin-top: 10px;
  }

  &__list {
    margin-top: 10px;
    padding-left: 18px;
  }

  &__item {
    line-height: 1.7;
    position: relative;

    &::before {
      content: "・";
      display: block;
      font-size: 14px;

      position: absolute;
      top: 0;
      left: -18px;

      @media #{$sp} {
      }
    }
  }

}