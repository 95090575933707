
/********** Breakpoint設定 **********/
$sp: "all and (max-width : 599px)";
$pc: "all and (min-width: 600px)";

/********** Font-family **********/
$font-family: "Source Han Sans JP",
"源ノ角ゴシック JP",
"源ノ角ゴシック",
"游ゴシック体",
YuGothic,
"游ゴシック",
"Yu Gothic",
'ヒラギノ角ゴ Pro W3',
'Hiragino Kaku Gothic Pro',
'ＭＳ Ｐゴシック',
'MS PGothic',
sans-serif;

/********** カラー **********/

// サイトカラー
$main-color: #fafafa;
$secondar-color: #f2f2f2;

// ベースカラー
$white-color: #fff;
$black-color: #171717;
$gray-color: #9f9f9f;
$bronw-color: #9b0000;
$light-gray-color: #dbdbdb;

// テキスト
$main-text-color: $black-color;
$brown-text-color: #ba0000;
$success-text-color: #0c0;
$alert-text-color: #c77064;
$link-text-color: $black-color;

// フォーム & テーブル
//適宜ページ担当者が設定をしてください
$table-border-color: darkgray;
$form-border-color: lightgray;
$form-bg-color: lightgray;
$form-radio-color: skyblue;

// ボタン
$main-btn-color: darkgray;
$sub-btn-color: darkgray;
$delete-btn-color: #333;
$brown-btn-color: #cc8600;

// 背景色
$main-bg-color: #fafafa;
$tag-bg-color: #9f9f9f;
$title-bg-color: #f2f2f2;

// ボーダー
$border-gray-color: #707070;
