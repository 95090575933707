/*
  ----------------
  Top | Pick up
  ----------------
*/
.user-voice-slide {
  background-color: $white-color;

  @media #{$sp} {
    display: none;
  }

  .top & {
    background-color: $main-color;

    @media #{$sp} {
      display: block;
    }
  }

  // お客さまインタビュー
  .news & {
    @media #{$sp} {
      display: block;
      padding-bottom: 110px;
    }
  }

  &__wrap {
    padding: 0 80px 90px;

    @media #{$sp} {
      padding: 0;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding: 0 40px 50px;
    }

    .top & {
      padding-top: 217px;

      @media all and (max-width : 800px) and (min-width : 600px) {
        padding-top: 100px;
      }

      @media #{$sp} {
        padding-top: 130px;
        padding-bottom: 134px;
      }
    }
  }

  &__title {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.56;
    text-align: center;

    @media #{$sp} {
      font-size: 20px;
      letter-spacing: 0.08em;
      line-height: 1.2;
    }
  }

  &__slider {
    position: relative;
  }

  &__container {
    display: none;
    margin-top: 38px;
    overflow: hidden;
    padding-bottom: 47px;
    position: relative;

    &.swiper-container-initialized {
      display: block;
    }

    @media #{$sp} {
      margin-top: 33px;
      padding-bottom: 46px;
    }
  }

  &__body {

  }

  &__footer {
    margin-top: 40px;
    text-align: center;

    @media #{$sp} {
      margin-top: 38px;
    }
  }

  &__item {
    background-color: $main-color;
    height: 360px;
    padding: 44px;

    .top & {
      background-color: $white-color;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding: 20px;
    }

    @media #{$sp} {
      height: 378px;
      padding: 36px 34px;
    }
  }

  &__text {
    max-height: 100%;
    overflow: hidden;

    @media #{$sp} {
      font-size: 13px;
      letter-spacing: 0.03em;
      line-height: 2.3;
    }
  }

  &__link.is-new-item::before {
    display: block;
    content: "";
    background: url(/assets/img/top/icon_new_badge.svg) 50% / contain no-repeat;

    position: absolute;
    top: -25px;
    right: -15px;
    width: 50px;
    height: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include hoverLink;
    cursor: pointer;
    height: 57px;
    top: 45%;
    width: 22px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      height: 45px;
      width: 15px;
    }

    @media #{$sp} {
      display: none;
    }

    &::after {
      background: url(/assets/img/top/icon_arrow_pickup_prev.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 57px;
      width: 22px;

      position: absolute;
      top: 0;
      left: 0;

      @media all and (max-width : 800px) and (min-width : 600px) {
        height: 45px;
        width: 15px;
      }
    }
  }

  &__link {
    @include btnLink(brown);
  }

  .swiper-button-prev {
    left: -55px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      left: -30px;
    }
  }

  .swiper-button-next {
    right: -55px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      right: -30px;
    }

    &::after {
      background-image: url(/assets/img/top/icon_arrow_pickup_next.svg);
    }
  }

  .swiper-container-horizontal .swiper-pagination-bullet {
    background-color: #f2f2f2;
    border-radius: 25px;
    margin: 0 5px;
    opacity: 1;

    height: 8px;
    width: 8px;

    @media #{$sp} {
    }
  }

  .swiper-container-horizontal .swiper-pagination-bullet-active {
    background-color: $black-color;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0;
  }
}
