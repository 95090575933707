/*
  ---------------
  .item-list | 商品一覧 main~subの親要素 main下の4連アロー
  ---------------
*/
.item-list {
  background-color: $main-bg-color;
  padding: 10.7613469% 0 11.7130307%;
  margin-top: 6.44216691%;

  @media #{$sp} {
    margin-top: 47px;
    padding: 71px 0 84px;
  }

  &__wrap {

    padding: 0 5.85651537%;

    @media #{$sp} {
      padding: 0;
    }
  }

  &__arrow {
    width: 57.22px;
    margin: 8.04311774% auto 0;

    @media all and (min-width: 600px) and (max-width: 850px) {
      margin: 80px auto 0;
    }

    @media #{$sp} {
      margin: 80px auto 0;
    }
  }
}
