/*
  ---------------
  .view-inquiries | お問い合わせ一覧
  ---------------
*/
.view-inquiries {

  .inquiries-list {
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }

    .msg {
      display: block;
      font-size: 20px;
      margin-top: -50px;
      text-align: center;

      @media #{$sp} {
        font-size: 18px;
        margin-top: -25px;
      }
    }
  }

  .panel {
    border: 1px solid $form-border-color;

    & + .panel {
      margin-top: 60px;
    }
  }

  .panel-heading,
  .inq-item,
  .inq-resp,
  .inq-re-entry {
    border-bottom: 1px solid $form-border-color;

    @media #{$pc} {
      display: flex;
    }

    @media all and (min-width: 600px) and (max-width: 850px) {
      display: block;
    }

    @media #{$sp} {}
  }

  .inq-type,
  .inq-item-label,
  .inq-resp-heading {
    background-color: $title-bg-color;
    border-right: 1px solid $form-border-color;
    flex: 0 0 260px;
    text-align: center;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (min-width: 600px) and (max-width: 850px) {
      border-right: 0;
    }

    @media #{$sp} {
      border-right: 0;
    }
  }

  .inq-date,
  .inq-item-values,
  .inq-resp-content {
    display: block;
    padding: 10px;

    @media #{$pc} {
      flex: 0 1 100%;
    }

    @media all and (min-width: 600px) and (max-width: 850px) {
      text-align: center;
    }

    @media #{$sp} {
      text-align: center;
    }
  }

  .inq-resp-heading {
    flex-direction: column;
  }

  .inq-re-entry {

    &:last-child {
      border-bottom: 0;
    }
  }

  #re_entry_form {
    display: block;
    text-align: center;
    padding: 40px;

    @media #{$pc} {
      flex: 0 1 100%;
    }

    @media all and (min-width: 600px) and (max-width: 850px) {
      padding: 20px;
    }

    @media #{$sp} {
      padding: 10px;
    }
  }

  textarea {
    resize: vertical;
    width: 100%;
    min-height: 140px;

    @media #{$sp} {
      width: 100%;
    }
  }

  button.btn-submit {
    margin-top: 40px;

    @media all and (min-width: 600px) and (max-width: 850px) {
      margin-top: 20px;
    }

    @media #{$sp} {
      margin-top: 0;
    }
  }
}
