/*
  ----------------
  .header-user
  Break point 追加: 1125px
  ----------------
*/
.header-user {
  display: flex;

  @media #{$pc} {
    margin-left: 48px;
  }

  @media all and (max-width : 1125px) and (min-width : 600px) {
    margin-left: 5px;
  }

  &__item {
    border-left: 1px solid #d8d8d8;

    @media #{$sp} {
      border-left: 1px solid $white-color;
      width: 60px;

    }
  }

  .sidebar-is-open & .header-user__item svg {
    @media #{$sp} {
      fill: #fff;
    }
  }

  &__item--cart {
    @media #{$pc} {
      background-color: #2b2b2b;
    }

    .header-user__link {
      padding: 0 17px;
      width: auto;

      @media #{$sp} {
        padding: 0 5px 0 0;
      }
    }

    svg {
      @media #{$pc} {
        fill: #fff;
      }
    }
  }

  &__link {
    color: #191919;
    height: 60px;
    letter-spacing: 0;
    position: relative;
    text-align: center;
    transition: background-color 0.2s ease-in-out;
    width: 159px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width : 1125px) and (min-width : 600px) {
      font-size: 12px;
      width: 60px;

      .header-user__item--login & {
        width: 80px;
      }
    }

    @media #{$sp} {
      padding: 0 11px;
      width: 60px;

      &:active {
        color: $white-color;
        background-color: $black-color;
        transition: background-color 0.2s ease-in-out;

        svg {
          fill: #fff;
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $white-color;
        background-color: $black-color;
        transition: background-color 0.2s ease-in-out;
        opacity: 1;
      }
    }
  }

  &__link-badge {
    background-color: $bronw-color;
    border-radius: 25px;
    color: $white-color;
    font-size: 12px;

    height: 18px;
    width: 18px;

    position: absolute;
    top: 15%;
    right: 15%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}