/*
  ----------------
  Button with link styles | 矢印あり
  ----------------
*/

// ---------------------------------------
// Link buton | With arrow

@mixin btnLink($type: none) {
  @include btn;

  // Icon arrow
  &::before {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    display: block;
    content: "";

    position: absolute;
    top: 50%;
    right: 14px;
    width: 7px;
    height: 7px;
    transform: rotate(45deg) translateY(-50%);
  }

  // ---------------------------------------
  // White color

  @if $type==white {
    border: 1px solid $white-color;
    color: $white-color;

    // Icon arrow
    &::before {
      border-color: $white-color;
    }

    @include hoverBtn(white);
  }

  // ---------------------------------------
  // gradient color

  @else if $type==brown {
    @include gradient;

    // Icon arrow
    &::before {
      border-color: #cc8600;
    }

    @include hoverBtn(brown);
  }

  // ---------------------------------------
  // Return button

  @else if $type==return {
    @include gradient(return);

    // Icon arrow
    &::before {
      border-top: none;
      border-right: none;
      border-bottom: 1px solid #cc8600;
      border-left: 1px solid #cc8600;
      left: 14px;
    }
  }
}