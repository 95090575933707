.view-order,
.view-orders,
.order-journal-history,
.order-process-main-payment-status,
.rp-subscriptions {

  .panel-heading {

    > div.code.shipment-code {
      @media all and (min-width: 600px) and (max-width: 850px) {
        border-bottom: 0;
      }

      @media #{$sp} {
        border-bottom: 0;
      }
    }

    > div {
      border-bottom: 1px solid $form-border-color;

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {}

      > div {
        display: block;
        padding: 10px;

        @media #{$pc} {
          flex: 0 1 100%;
        }

        @media all and (min-width: 600px) and (max-width: 850px) {
          text-align: center;
        }

        @media #{$sp} {
          text-align: center;
        }
      }
    }
  }
}
