/*
  ----------------
  Top | Concept
  ----------------
*/
.top-concept {
  background: url(/assets/img/top/bg_concept_dummy.jpg) 50% / cover no-repeat;

  @media #{$pc} {
    background: url(/assets/img/top/bg_concept_dummy.jpg) 50% / cover no-repeat;
    margin-top: 69px;
  }

  &__wrap {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 65px 50px 69px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$sp} {
      padding: 42px 33px 55px;
    }
  }

  &__title-sub {
    @include h2TitleRuby();

    img {
      height: 23.55px;

      @media #{$sp} {
        height: 15.7px;
      }
    }
  }

  &__title {
    @include h2Title();
    color: $white-color;
    line-height: 1;
    margin-top: 18px;

    @media #{$sp} {
      margin-top: 9px;
    }
  }

  &__text {
    color: $white-color;
    letter-spacing: 0.1em;
    line-height: 2.5;
    margin-top: 48px;
    text-align: center;

    @media #{$sp} {
      font-size: 13px;
      letter-spacing: 0.1em;
      line-height: 2.692;
      margin-top: 26px;
      text-align: left;
    }
  }

  &__link {
    @include btnLink(white);
    margin-top: 25px;

    @media #{$sp} {
      margin-top: 30px;
    }
  }
}
