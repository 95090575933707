/*
  ----------------
  .item-list-lead | 商品一覧 FV下リード文
  ----------------
*/
.item-list-lead {
  margin-top: 6.51537335%;

  @media all and (min-width: 600px) and (max-width: 1222px) {
    margin-top: 7.36497545%;
  }

  @media #{$sp} {
    margin-top: 45px;
  }

  &__wrap {

    @media #{$pc} {
      max-width: 1366px;
      margin: 0 auto;
      padding: 0 80px;

      display: flex;
      align-items: center;
    }

    @media all and (min-width: 600px) and (max-width: 1222px) {
      padding: 0 6.54664484%;
    }
  }

  &__header {
    @media #{$sp} {
      padding: 0 33px;
    }
  }

  &__header-wrap {
    border: 1px solid $black-color;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{$pc} {
      width: 320px;
      height: 320px;
    }

    @media screen and (min-width: 787px) and (max-width: 1222px) {
      width: 260px;
      height: 260px;
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
      width: 200px;
      height: 200px;
    }

    @media #{$sp} {
      padding: 26px 0 29px;
      width: 100%;
    }
  }

  &__header-title {
    width: 100%;
    max-width: 233px;
    height: 59px;
    margin: 0 auto;

    @media #{$pc} {
      padding-top: 7px;
    }

    @media screen and (min-width: 787px) and (max-width: 1222px) {
      max-width: 194px;
      height: 49px;
    }

    @media screen and (min-width: 600px) and (max-width: 786px) {
      max-width: 174px;
      height: 40px;
    }

    @media #{$sp} {
      max-width: 194px;
      height: 49px;
    }
  }

  &__header-text {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 2.14285714;
    margin-top: 8px;

    @media all and (min-width: 600px) and (max-width: 1222px) {
      font-size: 12px;
      margin-top: 0;
    }

    @media #{$sp} {
      letter-spacing: 0.08em;
      margin-top: -12px;
    }
  }

  &__body {

    @media #{$pc} {
      margin-left: 6.38474295%;
    }

    @media #{$sp} {
      padding: 0 23px 0 34px;
      margin-top: 23px;
    }

  }

  &__body-title {
    font-size: 25px;
    font-weight: 400;
    line-height: 2.4;
    letter-spacing: 0;

    @media all and (min-width: 787px) and (max-width: 1222px) {
      font-size: 20px;
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
      font-size: 15px;
    }

    @media #{$sp} {
      font-size: 16px;
      line-height: 2.5;
      letter-spacing: 0.08em;
    }

    .tab-only {
      @media screen and (min-width: 600px) and (max-width: 1015px) {
        display: block !important;
      }
    }
  }

  &__body-text {
    font-size: 25px;
    font-weight: 400;
    line-height: 2.4;
    letter-spacing: 0;

    @media all and (min-width: 787px) and (max-width: 1222px) {
      font-size: 20px;
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
      font-size: 15px;
    }

    @media #{$sp} {
      font-size: 16px;
      line-height: 2.8125;
      letter-spacing: 0.08em;
      margin-top: 11px;
    }
  }
}
