/*
  ----------------
  .contact-catalog | お問い合わせ・資料請求
  ----------------
*/
.form-form-contact,
.form-form-catalog,
.form-form-contact_guest,
.form-form-catalog_guest {
  & > h3 {
    display: none;
  }

  .page-title {
    @include pageTitle($white-color, contact);

    @media #{$pc} {
      margin-bottom: 77px;
    }
  }

  .form-note {
    margin: 0;

    @media #{$sp} {
      text-align: center;
    }
  }

  .form-body {
    @media #{$pc} {
      margin-bottom: 177px;
      padding-bottom: 78px;

      .formrow-submit {
        margin-top: 60px;
      }
    }
  }
}

.contact-catalog {
  &__wrap {
    margin: 0 auto;
    max-width: 1206px;
    // padding: 110px 0 101px;

    @media #{$sp} {
      padding: 52px 30px 10px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.08em;
    line-height: 1.5;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      line-height: 1.6;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2.8571;
    margin-top: 43px;
    text-align: center;

    @media #{$sp} {
      margin-top: 36px;
      text-align: left;
    }
  }

  &__select {
    margin: 76px auto 0;
    max-width: 1206px;

    display: grid;
    gap: 18px;
    grid-template-columns: repeat(auto-fit,minmax(594px,1fr));

    @media #{$sp} {
      display: block;
      margin-top: 53px;
    }
  }

  &__select-item {
    border: 1px solid $light-gray-color;
    text-align: center;
    width: 100%;

    @media #{$pc} {
      // max-width: 594px;
      min-height: 287px;
    }

    @media #{$sp} {
      padding: 28px 19px 19px;
    }

    &:last-child {
      padding-top: 12px;

      @media #{$sp} {
        margin-top: 20px;
        padding-top: 27px;
        padding-bottom: 24px;

        .contact-select__title {
          font-weight: 400;
        }
      }
    }
  }

  &__select-title {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.08em;
    line-height: 1.7;
    margin-top: 47px;
    text-align: center;

    @media #{$sp} {
      font-weight: 500;
      line-height: 1.2;
      margin-top: 0;
    }
  }

  &__select-tel {
    margin-top: -15px;

    @media #{$sp} {
      margin-top: 4px;
    }

    & > span {
      font-size: 20px;
      letter-spacing: 0.08em;
      line-height: 1.2;

      @media #{$sp} {
        display: inline-block;
        font-size: 13px;
        line-height: 1.1538;
        transform: translateY(-4px);
      }
    }

    & > a {
      display: inline-block;
      font-size: 50px;
      letter-spacing: 0.08em;
      line-height: 2.0833;
      margin-left: 13px;

      @media #{$sp} {
        font-size: 35px;
        margin-left: 5px;
      }
    }
  }

  &__select-time {
    letter-spacing: 0;
    margin-top: -15px;

    @media #{$sp} {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.8462;
    }
  }

  &__select-text {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1.6667;
    margin-top: 19px;

    @media #{$sp} {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.8462;
      margin-top: 16px;
    }
  }

  &__select-link {
    border-bottom: 1px solid #cc8600;
    border-image: linear-gradient(to right, #cc8600 0%, #ba0000 100%);
    border-image-slice: 1;

    color: $brown-text-color;
    font-size: 20px;
    height: 70px;
    letter-spacing: 0.08em;
    line-height: 1.2;
    margin: 46px auto 0;
    max-width: 460px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$sp} {
      font-size: 18px;
      font-weight: 400;
      height: 84px;
      line-height: 1.3333;
      margin-top: 22px;
    }
  }
}
