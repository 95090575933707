/*
  ----------------
  ご注文ガイド | guide-contents
  ----------------
*/
.guide-contents {
  width: 100%;

  @media #{$pc} {
    margin-left: 80px;
  }

  @media all and (max-width : 960px) and (min-width : 801px) {
    margin-left: 40px;

  }

  @media all and (max-width : 800px) {
    margin-left: 0;
  }

  &__wrap {
    border-top: 1px solid $border-gray-color;
    width: 100%;

    @media #{$pc} {
      max-width: 850px;
    }

    @media all and (max-width : 800px) {
      margin-top: 53px;
      max-width: 100%;
    }

    @media #{$sp} {
      margin-top: 0;
    }
  }

  &__title {
    background-color: $secondar-color;
    border-top: 1px solid $black-color;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2;
    padding: 11px 17px 8px;

    @media #{$sp} {
      font-weight: 500;
      padding: 5px 21px 4px;
    }
  }

  &__title-sub {
    font-size: 18px;
    padding: 11px 17px 0;

    @media #{$sp} {
      font-size: 16px;
      font-weight: 500;
    }
  }

  details {
    border-bottom: 1px dashed $border-gray-color;
    position: relative;
  }

  details[open] {
    .guide-contents__inner {
      animation: fadeIn 0.5s ease-in-out;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }

  summary,
  &__inner-title {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.777;
    position: relative;

    @media #{$sp} {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.714;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    summary:hover &__icon {
      background-color: $black-color;

      &::before {
        color: $white-color;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  summary {
    @include hoverLink;
    padding: 30px 50px 30px 20px;

    @media #{$sp} {
      font-size: 20px;
      line-height: 1.2;
      padding: 20px 50px 25px 20px;
    }
  }

  &__icon {
    border: 1px solid $black-color;
    position: absolute;
    top: 50%;
    right: 20px;
    height: 30px;
    width: 30px;
    transform: translateY(-50%);

    &::before {
      content: "▼";
      display: block;
      font-size: 10px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }
  }

  details[open] &__icon {
    background-color: $black-color;

    &::before {
      content: "▲";
      color: $white-color;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__inner {
    background-color: $main-color;
    margin-bottom: 25px;
    padding: 25px 36px 32px;

    @media #{$sp} {
      margin: 0 0 15px;
      padding: 7px 19px 24px;
    }
  }

  &__inner-title {

  }

  &__inner-title a {
    display: inline-block;
  }

  &__inner-text {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 2.307;
    margin-top: 10px;

    a {
      @include hoverLink;
      text-decoration: underline;
    }

  }

  &__inner-card {
    max-width: 270px;
  }

  &__inner-payment {
    max-width: 245px;
    margin-top: 20px;
    margin-left: 20px;
  }

  &__inner-text+&__inner-title {
    margin-top: 20px;
  }

  &__section {

  }
}