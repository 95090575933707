/*
  ---------------
  .item-list-howto | 商品一覧 お悩み方法ご案内エリア
  ---------------
*/
.item-list-howto {
  background: url(/assets/img/list/img_how_to_use.jpg) center / cover;
  height: calc(100vw * 0.32942899);

  position: relative;

  @media #{$pc} {
    max-height: 450px;
  }

  @media all and (min-width: 787px) and (max-width: 1015px) {
    height: calc(100vw * 0.39408867);
  }

  @media all and (min-width: 600px) and (max-width: 786px) {
    height: calc(100vw * 0.50890585);
  }

  @media #{$sp} {
    background: url(/assets/img/list/img_how_to_use_sp.jpg) center / contain no-repeat;
    padding-top: 108.205128%;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: $black-color;
    opacity: 0.3;

    position: absolute;
    top: 0;
    left: 0;
  }

  &__wrap {
    text-align: center;
    width: 100%;

    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{$sp} {
      top: calc(50% - 5px);
    }
  }

  &__ruby {
    @include h2TitleRuby;

    img {
      height: 24.12px;

      @media #{$sp} {
        height: 16.08px;
      }
    }
  }

  &__title {
    color: $white-color;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.08em;
    margin-top: 13px;

    @media all and (min-width: 787px) and (max-width: 1015px) {
      font-size: 36px;
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
      font-size: 29px;
    }

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      line-height: 1.68;
      letter-spacing: 0.03em;
      margin-top: 15px;
    }
  }

  &__btn {
    margin-top: 4.09956076%;

    @media #{$sp} {
      margin-top: 30px;
    }
  }

  &__btn-link {
    @include btnLink(white);

    font-size: 14px;
    font-weight: 400;
    line-height: 2.14285714;
    padding: 8px 10px 10px 12px;

    @media #{$sp} {
      padding: 8px 10px 10px 16px;
    }
  }
}
