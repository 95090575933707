/*
  ----------------
  .manage-addressbook | アドレス編集（ご登録住所）
  ----------------
*/
.manage-addressbook {
  .btn-group {
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }

    .btn {
      background-color: $white-color;
      border: 1px solid transparent;
      border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
      border-image-slice: 1;
      color: $brown-text-color;
      font-weight: 500;
      line-height: normal;
      padding: 15px 10px 15px 12px;

      &:hover {
        border-radius: 0;
      }
    }
  }

  .panel-list {
    margin-top: 60px;

    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(410px, auto);
    // flex-wrap: wrap;

    @media all and (max-width: 1195px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media all and (max-width: 838px) {
      grid-template-columns: 1fr;
    }

    @media #{$sp} {
      margin-top: 40px;
    }

    li {
      border: 1px solid $form-border-color;
      padding: 20px 20px 30px;

      display: flex;
      flex-direction: column;

      &:first-child {

        .btn-group {
          margin-top: 0;
        }
      }

      .btn-group {
        text-align: center;
        padding: 0;
        margin-top: 40px;
      }
    }

    .btn {
      @include btn(brownBg);
    }
  }

  .panel {
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .panel-body {
    flex-grow: 1;
  }

  .person-contents {
    // border-bottom: 1px dashed $border-gray-color;
    padding-bottom: 20px;

    .kana,
    .name,
    .organization-name,
    .postal-code,
    .address,
    .phone-number {
      // border-bottom: 1px solid $form-border-color;
      // text-align: center;
      // padding-bottom: 10px;

      &:before {
        display: inline-block;
        padding-right: 10px;
      }

      .label {
        display: inline-block;
      }
    }

    // .kana {
    //   &:before {
    //     content: "お名前(フリガナ)";
    //   }
    // }

    // .name {
    //   &:before {
    //     content: "お名前";
    //   }
    // }

    // .organization-name {
    //   &:before {
    //     content: "会社名";
    //   }
    // }

    // .organization-unit-name {
    //   display: none;
    // }

    // .postal-code {
    //   &:before {
    //     content: "郵便番号";
    //   }
    // }

    // .address {
    //   &:before {
    //     content: "住所";
    //   }
    // }

    .phone-number {
      border-bottom: 0;
    }
  }

  .msg-default-addr {
    display: block;
    text-align: center;
    margin-top: 40px;

    &::before {
      content: "✳︎";
      display: inline-block;
    }
  }
}
