/*
  ----------------
  Online Counseling | Guidance
  ----------------
*/
.counseling-guidance {
  &__wrap {
    margin: 0 auto;
    max-width: 1206px;
    padding-top: 91px;

    @media all and (max-width : 900px) {
      padding: 51px 30px 0;
    }
  }

  &__header {
    // padding: 0 50px;
  }

  &__title-sub {
    @include h2TitleRuby();

    img {
      height: 28.56px;

      @media all and (max-width : 900px) {
        height: 52.56px;
      }
    }
  }

  &__title {
    @include h2Title();
    line-height: 1.45;

    @media all and (max-width : 900px) {
      margin-top: 5px;
    }
  }

  &__list {
    margin-top: 70px;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;

    @media all and (max-width : 900px) {
      display: block;
      margin-top: 35px;
    }
  }

  &__item {
    background: $secondar-color;
    border-radius: 5px;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.6667;
    max-width: 307px;
    padding: 11px 0 12px;
    position: relative;
    text-align: center;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width : 900px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.875;
      max-width: 100%!important;
      padding: 11px 0 10px;
    }

    &:nth-child(2) {
      @media #{$pc} {
        max-width: 456px;
      }

      @media all and (max-width : 900px) {
        margin-top: 21px;
        padding: 16px 0 7px;

        &::after {
          transform: scale(-1, 1);
          right: 17px;
          left: auto;
        }
      }
    }

    &:nth-child(3) {
      @media #{$pc} {
        max-width: 350px;
      }

      @media all and (max-width : 900px) {
        margin-top: 22px;
        padding: 22px 0 16px;
      }
    }

    &::after {
      background: url(/assets/img/counseling/arrow_balloon.svg) no-repeat center center;
      content: "";
      display: block;
      height: 22.111px;
      width: 15.691px;

      position: absolute;
      bottom: -17px;
      left: 7px;

      @media all and (max-width : 900px) {
        left: 17px;
      }
    }
  }

  &__text {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 3.0769;
    margin-top: 89px;
    text-align: center;

    @media all and (max-width : 900px) {
      font-size: 14px;
      line-height: 2.1429;
      margin-top: 53px;
      text-align: left;
    }
  }

  &__contact {
    border: 1px solid $light-gray-color;
    margin-top: 79px;
    padding: 39px 50px 39px 62px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width : 900px) {
      display: block;
      margin-top: 35px;
      padding: 24px 19px 18px;
      text-align: center;
    }
  }

  &__contact-title {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.6;

    @media all and (max-width : 900px) {
      font-weight: 500;
      text-align: center;
    }
  }

  &__contact-link {
    @include btn(gradientBg);
    font-size: 20px;
    line-height: 1.2;
    max-width: 460px;
    padding: 22px 10px 22px 12px;

    @media all and (max-width : 900px) {
      font-size: 18px;
      letter-spacing: 0.08em;
      line-height: 1.3333;
      margin-top: 18px;
      max-width: 290px;
      padding: 17px 10px 17px 11px;
      text-align: center;
    }
  }
}