/*
  ----------------
  Action-links-box | Common
  Break point 追加: 960px
  ----------------
*/
.action-links-box {
  background-color: $main-color;

  &__wrap {
    display: flex;
    padding: 57px 71px;

    @media all and (max-width : 960px) and (min-width : 600px) {
      padding: 57px 20px;
    }

    @media #{$sp} {
      display: block;
      padding: 83px 35px 70px;
      margin: 0 auto;
    }

    .freepage & {
      padding: 57px 71px 140px;

      @media all and (max-width : 960px) and (min-width : 600px) {
        padding: 57px 20px 140px;
      }

      @media #{$sp} {
        padding: 64px 35px 124px;
      }
    }
  }


  &__link {
    border: 1px solid $light-gray-color;
    margin: 0 9px;
    padding: 39px 20px 34px;

    display: flex;
    align-items: center;
    flex-basis: calc((100% - 54px) / 3);
    flex-direction: column;

    @media all and (max-width : 960px) and (min-width : 600px) {
      flex-basis: calc((100% - 30px) / 3);
      margin: 0 5px;
      padding: 39px 10px 34px;
    }

    @media #{$sp} {
      margin: 0 0 10px;
      padding: 31px 20px 16px;
      width: 100%;
    }
  }

  &__link {

  }

  &__icon {
    height: 94px;

    @media #{$sp} {
      height: 75px;
      width: 86px;
    }

    .action-links-box__link--faq &{
      padding-top: 3px;
    }

    .action-links-box__link--guide &{
      @media #{$sp} {
        padding: 0 6px;
      }
    }

    .action-links-box__link--online & {
      padding-top: 6px;

      @media #{$sp} {
        padding: 0 11px;
      }
    }
  }

  &__img {

  }

  &__info {
    margin-top: 16px;

    @media #{$sp} {
      margin-top: 26px;
    }
  }

  &__text {
    text-align: center;

    @media all and (max-width : 960px) and (min-width : 600px) {
      font-size: 13px;
      height: 37px;
      line-height: 1.4;
    }

    @media #{$sp} {
      font-size: 13px;
      letter-spacing: 0.03em;
      line-height: 1.384;
    }
  }

  &__title {
    font-size: 25px;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;

    @media all and (max-width : 960px) and (min-width : 600px) {
      font-size: 20px;
      margin-top: 10px;
    }

    @media #{$sp} {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.77;
      margin-top: 5px;
    }
  }
}
