/*
  ----------------
  .form-normal-signin | サインイン
  ----------------
*/
.form-order-checkout-forms {
  // padding: 110px 80px 120px;
  // max-width: 1366px;
  // width: 100%;
  // margin: 0 auto;

  // @media all and (max-width : 850px) {
  //   padding: 110px 30px 120px;
  // }

  // @media #{$sp} {
  //   padding-top: 60px;
  // }

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  h2 {
    display: none;
  }

  .form-note {

    @media #{$sp} {
      padding: 0;
      text-align-last: center;
    }
  }

  .form-body {
    @media #{$pc} {
      padding-bottom: 70px;
    }

    @media #{$sp} {
      margin-right: -30px;
      margin-left: -30px;
    }

    .signout {
      width: 100%;

      & > a {
        margin-top: 40px;

        @include btn();
        background-color: $white-color;
        display: inline-block;
        max-width: none;
        width: auto;
      }
    }

    .formrow-submit {
      margin-top: 40px;
    }
  }

  .reminder {
    margin-top: 40px;
    text-align: center;

    & > a {
      @include btn();
      display: inline-block;
      max-width: none;
      width: auto;
    }
  }

  #signout-at-signin {
    margin-top: 40px;
    text-align: center;

    & > a {
      @include btn();
      display: inline-block;
      max-width: none;
      width: auto;
    }
  }
}

.signin-signup {

  .go-signup-to-order {
    @include btn();
    display: block;
    margin: 60px auto 0;
    max-width: 300px;
  }

  .note {
    margin-top: 20px;
    text-align: center;
  }
}
