.item-trial {
  margin-top: 82px;

  @media #{$sp} {
    margin-top: 42px;
  }

  &__wrap {}

  &__title {
    border-bottom: 2px solid $black-color;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 13px;

    @media #{$sp} {
      border-bottom: 1px solid $black-color;
      font-weight: 500;
      letter-spacing: 0.08em;
      padding-bottom: 10px;
    }
  }

  &__block {
    margin-top: 74px;

    @media #{$sp} {
      margin-top: 27px;
    }
  }

  &__box {

    @media #{$pc} {
      display: flex;
    }

    @media all and (min-width: 600px) and (max-width: 1000px) {
      display: block;
    }
  }

  &__img {

    @media #{$pc} {
      flex: 0 1 100%;
    }
  }

  &__contents {

    @media #{$pc} {
      flex: 0 0 442px;
      margin-left: 6.59204%;
    }

    @media all and (min-width: 600px) and (max-width: 1000px) {
      margin-left: 0;
      margin-top: 25px;
    }

    @media #{$sp} {
      margin-top: 25px;
    }
  }

  &__description {
    border-bottom: 1px dashed $border-gray-color;
    padding-bottom: 35.5px;

    @media #{$pc} {
      margin-top: -7px;
    }

    @media #{$sp} {
      padding-bottom: 19.5px;
    }
  }

  &__description-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.85714286;
    letter-spacing: 0;

    @media #{$pc} {
      margin-right: -4px;
    }

    @media #{$sp} {
      line-height: 2.14285714;
    }
  }

  &__info {
    margin-top: 31.5px;

    @media #{$sp} {
      margin-top: 16.5px;
    }
  }

  &__info-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0;

    @media #{$sp} {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.66666667;
    }
  }

  &__label {
    margin-top: 2px;

    @media #{$sp} {
      margin-top: 3px;
    }
  }

  &__tag {
    background-color: $tag-bg-color;
    border-radius: 50px;
    color: $white-color;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
    letter-spacing: 0;
    padding: 2px 14px 4px 15px;

    @media #{$sp} {
      font-size: 12px;
      line-height: 2;
      padding: 1px 8px 2px;
    }

    & + & {
      padding: 2px 13px 4px 14px;
      margin-left: 6px;

      @media #{$sp} {
        padding: 1px 11px 2px;
        margin-left: 5px;
      }
    }
  }

  &__price {
    font-size: 0;
    margin-top: 6px;

    @media #{$sp} {
      line-height: 1.4875;
      margin-top: 2px;
    }
  }

  &__price-number {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.04em;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0.08em;
    }
  }

  &__price-yen {
    font-size: 14px;
    font-weight: 500;
    line-height: 2.28571429;
    letter-spacing: 0;
    margin-left: 8px;

    @media #{$sp} {
      font-size: 13px;
      line-height: 2.46153846;
      margin-left: 6px;
    }
  }

  &__price-caution {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
    letter-spacing: 0;

    @media #{$pc} {
      margin-left: 3px;
    }

    @media #{$sp} {
      font-size: 12px;
      font-weight: 400;
      line-height: 2;
    }
  }

  &__form {
    text-align: right;
    margin-top: 11px;

    @media #{$sp} {
      margin-top: 15px;
    }
  }

  &__btn {
    @include btn(brownBgCart);
    line-height: 2.14285714;
    padding: 9px 10px 11px;
    max-width: 200px;

    @media #{$sp} {
      max-width: 220px;
    }
  }
}
