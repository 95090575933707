.form-form-counseling,
.form-form-counseling_guest {
  & > h3 {
    display: none;
  }

  .page-title {
    @include pageTitle($black-color, counseling);

    @media #{$sp} {
      padding-top: 44px;
      text-align: center;
    }
  }

  .form-note {
    margin-top: 0;

    @media #{$sp} {
      padding: 0;
    }
  }

  .account-login {
    max-width: 1206px;
    margin: 40px auto 0;

    @media #{$sp} {
      margin-top: 30px;
      padding: 0 30px;
    }

    & > a {
      @include btn();
      display: inline-block;
      max-width: none;
      width: auto;

      @media #{$sp} {
        font-size: 12px;
        width: 100%;
      }
    }
  }

  .formrow-submit {
    margin-top: 60px;

    @media #{$sp} {
      margin-top: 40px;
    }
  }
}