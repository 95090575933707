/*
  ----------------
  Order pages | Import pages
  ----------------
*/
/********** Common | 注文ページ共通 **********/
@import "common";

/********** Deliveryservice | 購入者情報の入力 **********/
@import "orderer/form-orderer";

/********** Orderer | 配送に関するご要望設定 **********/
@import "deliveryservice/form-deliveryservice";

/********** paymentstatus | お支払いの状態 **********/
@import "paymentstatus/order-process-main-payment-status";

/********** Payment | お支払い方法 **********/
@import "payment/form-payment";

/********** Consignee | お届け先の編集 **********/
@import "consignee/form-consignee";

/********** Confirm | 注文内容の確認 **********/
@import "confirm/order-entry";

/********** Complete | 注文処理の完了 **********/
@import "complete/order-process-main-complete";

/********** Points | ポイント利用設定 **********/
@import "points/form-points";

/********** Sender | 送り主の編集 **********/
@import "sender/form-sender";

/********** Sender | 受注エラー **********/
@import "error/order-error";
