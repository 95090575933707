/*
  ----------------
  Base styles
  ----------------
  */
html {
  overflow-x: hidden;
}

body {
  background-color: $white-color;
  // position stiky が効かないためコメントアウト
  // overflow-x: hidden;
  position: relative;

  @media #{$sp} {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
}

a {
  @include hoverLink;
}

figure {
  margin: 0;
}

ol,
ul {
  margin: 0;
  padding-left: 0;
}

button:focus {
  outline: none;
}

@media #{$pc} {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

@media #{$sp} {
  .container {
    padding-left: 5vmin;
    padding-right: 5vmin;
  }
}

/*
  ----------------
  Base font settings
  ----------------
*/

html {
  font-size: 62.5%;
}

body {
  color: $black-color;
  font-size: 14px;
  font-family: $font-family;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2.142;

  @media #{$sp} {
    font-weight: 500;
    letter-spacing: 0.1em;
  }
}

h1 {
  font-size: 40px;
  line-height: 1.75;

  @media #{$sp} {
    font-size: 25px;
    line-height: 1.6;
    letter-spacing: 0.08em;
  }
}

h2 {
  font-size: 30px;
  letter-spacing: 0.15em;
  line-height: .8;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0;
}

li {
  list-style: none;
}
