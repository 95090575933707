/*
  ---------------
  .rp-future-entries | 定期出荷予定(一覧)
  ---------------
*/
.rp-future-entries {

  ol.rp-subscriptions-nav-list {
    margin-top: 30px;
    text-align: center;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);

    @media all and (min-width: 600px) and (max-width: 850px) {
      margin-top: 15px;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    @media #{$sp} {
      margin-top: 15px;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }

    a,
    span {
      border: 1px solid #171717;
      color: #171717;
      display: inline-block;
      letter-spacing: .08em;
      padding: 9px 10px 9px 12px;
      position: relative;
      text-align: center;
      text-decoration: none;
      transition: opacity 0.2s ease-in-out;
      z-index: 0;
      width: 100%;
      padding: 14px 5px;
    }

    li.active {
      span {
        background-color: #171717;
        border: 1px solid #171717;
        color: #fff;
      }
    }
  }

  /*==========================================
    .panel-heading | 商品コード〜お届け予定日
  ==========================================*/
  .rp-orderentry {
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }
  }

  .panel {
    border: 1px solid $form-border-color;
    display: flex;
    flex-direction: column;
  }

  .panel-heading {
    font-weight: normal;
    margin-left: auto;
    margin-right: 0;
    width: 50%;
    order: 2;

    @media #{$sp} {
      width: 100%;
    }

    .code,
    .status,
    .control,
    .date-to-checkout,
    .date-to-ship,
    .date-to-delivery {
      border-bottom: 1px solid $form-border-color;
      padding: 5px;
      text-align: right;

      a {
        text-decoration: underline;
      }
    }

    .control {
      padding: 10px;
    }

    .date-to-delivery {
      border-bottom: none;
    }
  }

  /*==========================================
    .panel-body | 商品明細タイトル〜商品合計
  ==========================================*/
  .panel-body {
    // border: 1px solid $form-border-color;

    .itemdetails-caption {

      h3 {
        background-color: $title-bg-color;
        border-bottom: 1px solid $form-border-color;
        font-weight: 400;
        text-align: center;
        padding: 10px;
      }
    }

    .itemdetails-header {
      display: none;
    }

    .itemdetail-row {
      border-bottom: 1px solid $form-border-color;
      padding: 30px 0;

      @media #{$pc} {
        display: block;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {
        padding: 30px 20px;
      }
    }

    .item {
      text-align: center;
    }

    .goods {
      @media #{$pc} {

        display: flex;
        align-items: center;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {
        display: block;
      }
    }

    .goods-text {
      text-align: left;

      @media #{$pc} {
        flex: 0 1 100%;
        margin-left: 10px;
      }

      @media all and (min-width: 600px) and (max-width: 1047px) {
        margin-left: 0;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
        margin-top: 10px;
        margin-left: 0;
      }

      @media #{$sp} {
        text-align: center;
        margin-top: 10px;
        margin-left: 0;
      }
    }

    .goods-text-heading {

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: left;
        margin: 0 auto;
        max-width: 400px;
      }

      @media #{$sp} {
        text-align: left;
        margin: 0 auto;
        max-width: 400px;
      }

      //商品コード非表示
      .code {
        display: none;
      }
    }

    .rpoption,
    .unit-price {

      @media all and (min-width: 600px) and (max-width: 850px) {
        max-width: 400px;
        margin: 0 auto;
        text-align: left;
      }

      @media #{$sp} {
        max-width: 400px;
        margin: 0 auto;
        text-align: left;
      }
    }

    .purchase-contents {
      color: $bronw-color;

      display: flex;
      justify-content: flex-end;

      @media #{$pc} {
        // min-width: 350px;
        // padding: 0 20px;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 20px;

        justify-content: flex-end;
      }

      @media #{$sp} {
        margin-top: 20px;

        justify-content: flex-end;
      }

      @media all and (max-width: 448px) {
        justify-content: flex-start;
      }

      .quontity {}

      .subtotal {
        margin-left: 20px;

        @media #{$sp} {
          margin-left: 15px;
        }
      }
    }

    .price-total {

      @media #{$sp} {
        text-align: right;
      }

      .row {
        padding: 10px 0;

        display: flex;
        justify-content: flex-end;

        @media #{$sp} {
          padding: 20px;
        }

        @media all and (max-width: 448px) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }

      .qty {
        margin-left: 20px;

        @media #{$sp} {
          margin-left: 10px;
        }

        @media all and (max-width: 448px) {
          margin-right: 10px;
        }
      }

      .subtotal {
        margin-left: 20px;

        @media #{$sp} {
          margin-left: 10px;
        }

        @media all and (max-width: 448px) {
          margin-left: 0;
        }
      }
    }
  }
}
