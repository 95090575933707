/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.7

  ▼hoverBtnBorder：
    Border opacity 0.4
  ----------------
*/

@mixin hoverLink($type: default) {
  @media (hover: hover) and (pointer: fine) {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    @if $type==default {
      cursor: pointer;
    }

    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &::before,
  &::after {
    backface-visibility: hidden;
  }
}

@mixin hoverText {
  @media (hover: hover) and (pointer: fine) {
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $bronw-color;
      opacity: 1;
      transition: color 0.2s ease-in-out;
    }
  }
}