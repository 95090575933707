.item-switch {
  margin-top: 58.5px;

  @media #{$sp} {
    margin-top: 36.5px;
  }

  &__list {
    padding: 0 5.85651537%;

    display: flex;

    @media #{$pc} {
      align-items: center;
    }

    @media #{$sp} {
      padding: 0 30px;
    }
  }

  &__item {
    border-right: 1px solid $black-color;
    padding: 0 10px;
    width: calc(100% / 3);

    &:first-child {
      border-left: 1px solid $black-color;
    }

    @media #{$sp} {

      display: flex;
      justify-content: center;
    }

    .item-wrapper-02 & {
      @media #{$sp} {
        width: calc(100% / 2);
      }
    }
  }

  &__link {
    @include hoverBtn(blackBg);

    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 2.28571429;
    letter-spacing: 0;
    text-align: center;
    padding: 9px 0;

    @media #{$sp} {
      line-height: 1.42857143;
      padding: 5px 0;

      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-wrapper-02 & {
      @media #{$sp} {
        min-height: 50px;
      }
    }
  }
}
