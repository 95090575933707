/*
  ----------------
  コラム一覧 | Heading
  ----------------
*/
.column-heading {
  margin-bottom: 98px;

  @media #{$sp} {
    margin-bottom: 39px;
  }

  &__wrap {
    position: relative;
  }

  &__img {}

  &__box {
    width: 100%;

    position: absolute;
    top: calc(50% + 53px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @media #{$sp} {
      top: calc(50% + 30px);
    }
  }

  &__title {
    color: $white-color;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.08em;
    text-align: center;
    width: 100%;

    @media all and (min-width: 787px) and (max-width: 1015px) {
      font-size: 36px;
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
      font-size: 29px;
    }

    @media #{$sp} {
      font-weight: 500;
      line-height: 2;
    }
  }
}

.news-archive-column_pick_up,
.news-archive-column_less_is_more,
.news-archive-column_main {
  h1 {
    @include pageTitle($white-color, column);
    margin-bottom: 98px;

    @media #{$sp} {
      margin-bottom: 39px;
    }
  }

  .page-breadcrumbs {
    display: none;
  }
}