/*
  ----------------
  .yourcoupons | クーポン一覧
  ----------------
*/
.yourcoupons {

  #page-title {

    .description {
      margin: 100px auto 0;
      width: 100%;
      max-width: 650px;

      @media #{$sp} {
        margin-top: 60px;
      }
    }
  }

  .yourcoupon-list {
    margin-top: 2rem;

    @media #{$sp} {
      display: flex;
      flex-direction: column;
    }

    .page-error-msg {
      border: 1px solid #171717;
      border-radius: 8px;
      margin-right: auto;
      margin-left: auto;
      max-width: 650px;
      padding: 15px;
      text-align: center;
      width: 100%;
    }

    .coupon-props {
      border: 1px solid $table-border-color;
      border-radius: 4px;
    }
  }

  .label {
    text-align: center;
    padding: 4px 8px;

    .code {
      display: inline-block;
      background: linear-gradient(to left, #c10000 0%, #d35900 100%);
      color: $white-color;
      padding: 2px 10px;
      border-radius: 4px;
      font-weight: bold;

      @media #{$sp} {
        display: block;
        margin-bottom: 0.5rem;
      }
    }

    .name {
      font-size: 16px;
      font-weight: bold;

      @media #{$pc} {
        margin-left: 10px;
      }
    }
  }

  .expiry {
    text-align: right;
    font-size: 12px;
    padding: 2px 8px;
    color: $alert-text-color;

    @media #{$sp} {
      text-align: center;
    }

    .description {
      padding: 4px 8px;
      font-size: 12px;
    }
  }

  .coupon {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 650px;

    &:not(:first-child) {
      margin-top: 6rem;
    }

    .coupon-props {
      padding: 5rem 1rem;
    }
  }
}
