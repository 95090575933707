@charset "UTF-8";
/*
  ----------------
  Import files
  ----------------
*/
/********** Breakpoint設定 **********/
/********** Font-family **********/
/********** カラー **********/
/*--------------------------------------------------
Mixin
--------------------------------------------------*/
/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.7

  ▼hoverBtnBorder：
    Border opacity 0.4
  ----------------
*/
/*
  ----------------
  Button hover styles
  ----------------
*/
/*
  ----------------
  Button styles | 矢印なし
  ----------------
*/
/*
  ----------------
  Button with link styles | 矢印あり
  ----------------
*/
/*
  ---------------
  page title | 下層ページタイトル
  ---------------
*/
/*
  ---------------
  common title for h2 and ruby | h2タイトル上ルビ h2タイトル
  ---------------
*/
/*--------------------------------------------------
 Base
--------------------------------------------------*/
* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-size: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  backface-visibility: hidden;
}

ul,
ol,
dl,
dt,
dd {
  padding: 0;
  margin: 0;
}

li,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

[role="button"] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media all and (min-width: 600px) {
  a[href^=tel] {
    pointer-events: none;
  }
}

/*
  ----------------
  Base styles
  ----------------
  */
html {
  overflow-x: hidden;
}

body {
  background-color: #fff;
  position: relative;
}

@media all and (max-width: 599px) {
  body {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

a::before, a::after {
  backface-visibility: hidden;
}

figure {
  margin: 0;
}

ol,
ul {
  margin: 0;
  padding-left: 0;
}

button:focus {
  outline: none;
}

@media all and (min-width: 600px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

@media all and (max-width: 599px) {
  .container {
    padding-left: 5vmin;
    padding-right: 5vmin;
  }
}

/*
  ----------------
  Base font settings
  ----------------
*/
html {
  font-size: 62.5%;
}

body {
  color: #171717;
  font-size: 14px;
  font-family: "Source Han Sans JP", "源ノ角ゴシック JP", "源ノ角ゴシック", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2.142;
}

@media all and (max-width: 599px) {
  body {
    font-weight: 500;
    letter-spacing: 0.1em;
  }
}

h1 {
  font-size: 40px;
  line-height: 1.75;
}

@media all and (max-width: 599px) {
  h1 {
    font-size: 25px;
    line-height: 1.6;
    letter-spacing: 0.08em;
  }
}

h2 {
  font-size: 30px;
  letter-spacing: 0.15em;
  line-height: .8;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0;
}

li {
  list-style: none;
}

/*--------------------------------------------------
 Setting
--------------------------------------------------*/
/*
  ----------------
  ユーティリティー
  ----------------
*/
.container {
  position: relative;
}

@media all and (min-width: 600px) {
  .container {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 80px;
  }
}

@media all and (max-width: 599px) {
  .container {
    padding: 0 35px;
  }
}

/*
  ----------------
  レスポンシブ用のユティリティー
  ----------------
*/
@media all and (min-width: 600px) {
  .sp-only {
    display: none !important;
  }
}

@media all and (max-width: 599px) {
  .pc-only {
    display: none !important;
  }
}

/*--------------------------------------------------
 Modules
--------------------------------------------------*/
/*
  ----------------
  Breadcrumb | パンくず
  ----------------
*/
.breadcrumbs,
.category-breadcrumbs,
.page-breadcrumbs {
  max-width: 1150px;
  margin: 20px auto 0;
}

@media all and (max-width: 599px) {
  .breadcrumbs,
  .category-breadcrumbs,
  .page-breadcrumbs {
    margin: 0;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
}

.breadcrumbs ul,
.category-breadcrumbs ul,
.page-breadcrumbs ul {
  padding-left: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs ul::before,
.category-breadcrumbs ul::before,
.page-breadcrumbs ul::before {
  position: absolute;
  display: block;
  content: "";
  top: 45%;
  left: 0px;
  width: 18px;
  height: 13px;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .breadcrumbs ul::before,
  .category-breadcrumbs ul::before,
  .page-breadcrumbs ul::before {
    top: 42%;
    left: 2px;
  }
}

.breadcrumbs li,
.category-breadcrumbs li,
.page-breadcrumbs li {
  padding-right: 1rem;
  position: relative;
}

.breadcrumbs li:not(:first-child),
.category-breadcrumbs li:not(:first-child),
.page-breadcrumbs li:not(:first-child) {
  margin-left: 0px;
  padding-left: 10px;
}

@media all and (max-width: 599px) {
  .breadcrumbs li:not(:first-child),
  .category-breadcrumbs li:not(:first-child),
  .page-breadcrumbs li:not(:first-child) {
    margin-left: 0;
    padding-left: 14px;
  }
}

.breadcrumbs li:not(:first-child)::before,
.category-breadcrumbs li:not(:first-child)::before,
.page-breadcrumbs li:not(:first-child)::before {
  content: '>';
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
}

@media all and (max-width: 599px) {
  .breadcrumbs li,
  .breadcrumbs li a,
  .category-breadcrumbs li,
  .category-breadcrumbs li a,
  .page-breadcrumbs li,
  .page-breadcrumbs li a {
    font-size: 11px;
  }
}

.breadcrumbs + .breadcrumbs {
  display: none;
}

/*
  ----------------
  Button | ボタンのスタイル
  ----------------
*/
/********** Common | 全ボタン共通 **********/
.btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

/********** Scroll button | TOPへスクロールボタン **********/
.go-top {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  background-color: #2d2d2d;
  display: none;
  padding: 0 15px 10px;
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 95px;
  right: 0;
  z-index: 100;
}

@media (hover: hover) and (pointer: fine) {
  .go-top:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media all and (max-width: 599px) {
  .go-top {
    bottom: 61px;
    transition: none;
  }
}

.go-cart {
  position: fixed;
  bottom: 95px;
  right: 0;
  z-index: 101;
}

@media all and (max-width: 599px) {
  .go-cart {
    bottom: 61px;
    transition: none;
  }
}

/********** Submit btn | 確定ボタン **********/
button.btn-confirm,
button.btn-back,
button.btn-submit {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  padding: 14px 5px;
  margin: 0 8px;
}

@media (hover: hover) and (pointer: fine) {
  button.btn-confirm:hover,
  button.btn-back:hover,
  button.btn-submit:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media all and (max-width: 599px) {
  button.btn-confirm,
  button.btn-back,
  button.btn-submit {
    margin: 0;
    max-width: 100%;
  }
}

button.btn-submit {
  background: linear-gradient(to right, #9B0000 0%, #AD4900 100%);
  border: none;
  color: #fff;
}

@media all and (min-width: 600px) {
  button.btn-submit {
    padding: 15px 5px;
  }
}

@media all and (max-width: 599px) {
  button.btn-submit {
    padding: 15px 5px;
  }
}

/********** Small btn | 小ボタン **********/
.btn-sm {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  padding: 14px 5px;
}

@media (hover: hover) and (pointer: fine) {
  .btn-sm:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

/********** Edit btn | 編集ボタン **********/
.btn-edit {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  padding: 14px 5px;
}

@media (hover: hover) and (pointer: fine) {
  .btn-edit:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

/********** Delete btn | 削除ボタン **********/
.btn-delete {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .btn-delete:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

/********** Inline btn | インラインボタン **********/
/********** Favorite add btn | お気に入り追加ボタン **********/
/********** Favorite remove btn | お気に入り削除ボタン **********/
/********** Group btn | ボタングループ **********/
.btn-group {
  text-align: left;
}

@media all and (max-width: 599px) {
  .btn-group {
    text-align: center;
  }
}

.btn-group > div {
  margin-top: 1rem;
}

/*
  ----------------
   Common
  ----------------
*/
/*
  ----------------
  .header-user
  Break point 追加: 1125px
  ----------------
*/
.header-user {
  display: flex;
}

@media all and (min-width: 600px) {
  .header-user {
    margin-left: 48px;
  }
}

@media all and (max-width: 1125px) and (min-width: 600px) {
  .header-user {
    margin-left: 5px;
  }
}

.header-user__item {
  border-left: 1px solid #d8d8d8;
}

@media all and (max-width: 599px) {
  .header-user__item {
    border-left: 1px solid #fff;
    width: 60px;
  }
}

@media all and (max-width: 599px) {
  .sidebar-is-open .header-user .header-user__item svg {
    fill: #fff;
  }
}

@media all and (min-width: 600px) {
  .header-user__item--cart {
    background-color: #2b2b2b;
  }
}

.header-user__item--cart .header-user__link {
  padding: 0 17px;
  width: auto;
}

@media all and (max-width: 599px) {
  .header-user__item--cart .header-user__link {
    padding: 0 5px 0 0;
  }
}

@media all and (min-width: 600px) {
  .header-user__item--cart svg {
    fill: #fff;
  }
}

.header-user__link {
  color: #191919;
  height: 60px;
  letter-spacing: 0;
  position: relative;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  width: 159px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1125px) and (min-width: 600px) {
  .header-user__link {
    font-size: 12px;
    width: 60px;
  }
  .header-user__item--login .header-user__link {
    width: 80px;
  }
}

@media all and (max-width: 599px) {
  .header-user__link {
    padding: 0 11px;
    width: 60px;
  }
  .header-user__link:active {
    color: #fff;
    background-color: #171717;
    transition: background-color 0.2s ease-in-out;
  }
  .header-user__link:active svg {
    fill: #fff;
  }
}

@media (hover: hover) and (pointer: fine) {
  .header-user__link:hover {
    color: #fff;
    background-color: #171717;
    transition: background-color 0.2s ease-in-out;
    opacity: 1;
  }
}

.header-user__link-badge {
  background-color: #9b0000;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 15%;
  right: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
  ----------------
  .header-links
  Break point 追加: 1125px
  ----------------
*/
.header-links {
  display: flex;
}

.header-links__item + .header-links__item {
  margin-left: 28px;
}

@media all and (max-width: 1125px) {
  .header-links__item + .header-links__item {
    margin-left: 5px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .header-links__link {
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  .header-links__link:hover {
    color: #9b0000;
    opacity: 1;
    transition: color 0.2s ease-in-out;
  }
}

.header-links__link {
  color: #191919;
  font-weight: normal;
  height: 60px;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1125px) and (min-width: 600px) {
  .header-links__link {
    font-size: 11px;
  }
}

/*
  ----------------
  Gnav
  Break point 追加: 800px
  ----------------
*/
.gnav {
  background-color: #2b2b2b;
  position: relative;
}

.gnav__wrap {
  padding: 0 9px 0 0px;
  display: flex;
  justify-content: space-between;
}

.gnav__list {
  display: flex;
}

.gnav__item {
  width: 90px;
}

.gnav__item--product {
  margin-left: 10px;
}

.gnav__item--concept {
  margin-left: 40px;
}

.gnav__item--column {
  margin-left: 20px;
}

.gnav__item--voice {
  margin-left: 10px;
}

.gnav .gnav__item--product .gnav__link::after {
  display: block;
  content: "";
  background: url(/assets/img/nav/icon_arrow.svg) 50%/contain no-repeat;
  color: #fff;
  position: absolute;
  top: 51%;
  right: -7px;
  width: 9px;
  height: 7px;
  transform: translateY(-50%);
}

.gnav__link {
  color: #fff;
  font-size: 16px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gnav__link:hover {
  opacity: 1;
}

.gnav__link::before {
  display: block;
  content: "";
  background-color: #fff;
  transition: opacity .2s ease-in-out;
  opacity: 0;
  position: absolute;
  bottom: 1px;
  left: 0px;
  width: 90px;
  height: 2px;
}

.gnav__link:hover::before {
  left: 0px;
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.gnav__online {
  display: flex;
  align-items: center;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .gnav__online {
    justify-content: flex-end;
  }
}

.gnav__online-link {
  border: 1px solid #fff;
  color: #fff;
  line-height: 1.714;
  letter-spacing: 0;
  padding: 4px 21px 4px 55px;
  position: relative;
  transition: background-color .2s ease-in-out;
}

.gnav__online-link::before {
  display: block;
  content: "";
  background: url(/assets/img/nav/icon_gnav_online.svg) 50%/contain no-repeat;
  position: absolute;
  top: 50%;
  left: 22px;
  width: 20px;
  height: 22px;
  transform: translateY(-50%);
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .gnav__online-link {
    padding: 5px 7px 0;
  }
  .gnav__online-link::before {
    display: none;
  }
}

@media (hover: hover) and (pointer: fine) {
  .gnav__online-link:hover {
    background-color: #fff;
    color: #171717;
    opacity: 1;
    transition: background-color .2s ease-in-out;
  }
  .gnav__online-link:hover::before {
    background: url(/assets/img/nav/icon_gnav_online_black.svg) 50%/contain no-repeat;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .gnav__online-text {
    display: none;
  }
}

.gnav__online-img {
  display: none;
}

.gnav__online-img:hover svg g {
  fill: #171717;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .gnav__online-img {
    display: block;
  }
}

/*
  ----------------
  Gnav product
  Break point 追加: 800px
  ----------------
*/
.gnav-product {
  background-color: rgba(43, 43, 43, 0.7);
  display: flex;
  padding: 28px 32px 24px;
  width: 100%;
  position: absolute;
  top: 49px;
  left: 0;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  visibility: hidden;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .gnav-product {
    flex-wrap: wrap;
  }
}

.gnav-product__item {
  max-width: 153px;
}

.gnav-product__item + .gnav-product__item {
  margin-left: 22px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .gnav-product__item + .gnav-product__item {
    margin: 10px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .gnav-product__item {
    margin: 10px;
  }
}

.gnav-product__item figure {
  background-color: #fff;
  position: relative;
}

.gnav-product__item figure::before {
  border: 3px solid #fff;
  display: block;
  content: "";
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  transform: translate(-50%, -50%);
}

.gnav-product__img {
  width: 153px;
  height: 80px;
  object-fit: cover;
}

@media (hover: hover) and (pointer: fine) {
  .gnav-product__link:hover {
    opacity: 1;
  }
  .gnav-product__link:hover figure::before {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  .gnav-product__link:hover .gnav-product__name {
    color: #9f9f9f;
  }
}

.gnav-product__name {
  color: #fff;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.538;
  margin-top: 9px;
}

.gnav__item--product:hover .gnav-product {
  opacity: 1;
  transition: opacity .2s ease-in-out;
  visibility: visible;
}

/*
  ----------------
  SP menu
  ----------------
*/
.sp-menu {
  border-left: 1px solid #fff;
}

.sp-menu__button {
  padding: 15px 13px 0 16px;
  position: relative;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.sp-menu__icon {
  height: 16px;
  width: 30px;
  position: relative;
}

.sp-menu__bar {
  background-color: #000;
  display: block;
  transition: transform .2s ease-in-out, background-color .2s ease-in-out;
  height: 1px;
  width: 30px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sp-menu__bar--top, .sp-menu__bar--bottom {
  transform: translate(-50%, -50%) rotate(0);
}

.sp-menu__bar--top {
  top: 1px;
  transform-origin: top left;
}

.sp-menu__bar--middle {
  top: 50%;
}

.sp-menu__bar--bottom {
  bottom: 0;
  transform-origin: bottom left;
}

.sp-menu__icon-text {
  width: 100%;
}

.sidebar-is-open .sp-menu {
  background-color: transparent;
  transition: background-color .2s ease-in-out;
}

.sidebar-is-open .sp-menu__bar--top, .sidebar-is-open .sp-menu__bar--bottom {
  background-color: #fff;
  transition: transform .2s ease-in-out, background-color .2s ease-in-out;
}

.sidebar-is-open .sp-menu__bar--top {
  transform: translate(-50%, -50%) rotate(28deg);
  transform-origin: top left;
}

.sidebar-is-open .sp-menu__bar--middle {
  display: none;
}

.sidebar-is-open .sp-menu__bar--bottom {
  transform: translate(-50%, -50%) rotate(-28deg);
  transform-origin: bottom left;
}

.sidebar-is-open .sp-menu .sp-menu__icon-text {
  fill: #fff;
}

/*
  ----------------
  Header common
  Break point 追加: 1125px
  ----------------
*/
.header-main {
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color .2s ease-in-out;
  width: 100%;
  z-index: 110;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.header-main .contents-wrapper {
  width: 100%;
}

.sidebar-is-open .header-main {
  background-color: #171717;
  transition: background-color .2s ease-in-out;
}

.header-main__main {
  height: 60px;
  padding-left: 21px;
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 1125px) and (min-width: 600px) {
  .header-main__main {
    padding-left: 10px;
  }
}

@media all and (max-width: 599px) {
  .header-main__main {
    border-bottom: 1px solid #fff;
  }
}

.header-main__logo-link {
  display: flex;
  align-items: center;
}

@media all and (max-width: 1125px) and (min-width: 600px) {
  .header-main__logo-link {
    max-width: 100px;
  }
}

@media all and (max-width: 599px) {
  .header-main__logo-link {
    max-width: 151px;
  }
}

@media all and (max-width: 1125px) and (min-width: 600px) {
  .header-main__logo {
    display: flex;
  }
}

.sidebar-is-open .header-main__logo-parts {
  fill: #fff;
}

.header-main__box {
  display: flex;
}

/*
  ----------------
  Footer common
  ----------------
*/
.footer__wrap {
  background-color: #171717;
  padding: 77px 0 0;
}

@media all and (max-width: 599px) {
  .footer__wrap {
    padding: 93px 0 0;
  }
}

.footer__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__body .common-menu {
  overflow-y: hidden;
}

.footer__lead {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.09em;
  line-height: 1.333;
  margin-top: 32px;
}

@media all and (max-width: 599px) {
  .footer__lead {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 1.8;
    text-align: center;
  }
}

.footer__info {
  background-color: #272727;
  padding: 24px 0 32px;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 599px) {
  .footer__info {
    padding-top: 55px;
  }
}

.footer__info-list {
  display: flex;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .footer__info-list {
    flex-direction: column;
    align-items: center;
  }
}

.footer__info-item {
  margin: 0 16px;
  position: relative;
}

@media all and (min-width: 600px) {
  .footer__info-item:not(:first-child)::before {
    display: block;
    color: #fff;
    content: "|";
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
}

.footer__info-link {
  color: #fff;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .footer__info-link {
    font-size: 16px;
    line-height: 2.5;
  }
}

.footer__copy {
  color: #fff;
  font-size: 12px;
  line-height: 2.5;
  letter-spacing: 0;
  text-align: center;
}

@media all and (max-width: 599px) {
  .footer__copy {
    margin-top: 20px;
  }
}

/*
  ----------------
  nav menu | Common
  ----------------
*/
.common-menu {
  background-color: #171717;
  color: #fff;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  transition: right .2s ease-in-out;
}

.header-main .common-menu {
  position: fixed;
  top: 60px;
  right: -105vw;
  height: calc(100vh - 60px);
  width: calc(100vw + 4px);
}

.sidebar-is-open .common-menu {
  right: -2px;
}

.common-menu__wrap {
  padding: 40px 36px;
}

.footer .common-menu__wrap {
  padding: 50px 24px 44px;
}

@media all and (max-width: 599px) {
  .footer .common-menu__wrap {
    padding-bottom: 73px;
  }
}

@media all and (min-width: 600px) {
  .common-menu__wrap {
    padding: 50px 40px 44px;
  }
}

@media all and (max-width: 930px) and (min-width: 600px) {
  .common-menu__wrap {
    padding: 50px 24px 44px;
  }
}

@media all and (min-width: 600px) {
  .common-menu__main {
    max-width: 878px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

@media all and (min-width: 600px) {
  .common-menu__list {
    width: calc((100% - 132px) / 3);
  }
  .common-menu__list:not(:first-child) {
    margin-left: 24px;
  }
}

@media all and (max-width: 930px) and (min-width: 600px) {
  .common-menu__list {
    width: calc((100% - 48px) / 3);
  }
}

@media all and (max-width: 599px) {
  .footer .common-menu__list--last {
    border-bottom: 1px solid #707070;
  }
}

@media all and (min-width: 600px) {
  .common-menu__item:first-child .common-menu__link,
  .common-menu__item:first-child .common-menu__title {
    border-top: none;
  }
}

.common-menu__link {
  font-size: 16px;
  line-height: 1.875;
  letter-spacing: 0;
  padding: 12px 0 10px;
  width: 100%;
}

@media all and (max-width: 599px) {
  .common-menu__link {
    border-top: 1px solid #707070;
  }
  .footer .common-menu__link {
    padding: 12px 0 13px;
  }
}

@media all and (min-width: 600px) {
  .common-menu__link {
    border-bottom: 1px solid #707070;
    font-size: 14px;
    font-weight: 400;
    line-height: 2.142;
    padding: 7px 0 10px;
  }
  .common-menu__list--last .common-menu__link {
    padding: 10px 0;
  }
}

.common-menu__title {
  font-size: 16px;
  line-height: 1.875;
  letter-spacing: 0;
  padding: 12px 30px 10px 0;
  position: relative;
}

@media all and (max-width: 599px) {
  .common-menu__title {
    border-top: 1px solid #707070;
    cursor: pointer;
  }
  .common-menu__title::before, .common-menu__title::after {
    background-color: #fff;
    content: "";
    display: block;
    position: absolute;
    transition: transform .2s ease-in-out;
    height: 2px;
    width: 20px;
    top: 53%;
    right: 15px;
    transform: translateY(-50%) rotate(0);
  }
  .common-menu__title::before {
    transform: translateY(-50%) rotate(90deg);
  }
  .common-menu__title.accordion-is-open {
    border-bottom: 1px solid #707070;
  }
  .common-menu__title.accordion-is-open::before {
    transform: translateY(-50%) rotate(0);
  }
}

@media all and (min-width: 600px) {
  .common-menu__title {
    border-bottom: 1px solid #707070;
    font-size: 14px;
    font-weight: 400;
    line-height: 2.142;
    padding: 9px 30px 8px 0;
  }
}

.common-menu__inner-list {
  padding: 10px 0 15px;
}

@media all and (max-width: 599px) {
  .common-menu__inner-list {
    display: none;
  }
  .footer .common-menu__inner-list {
    padding: 10px 0 27px;
  }
}

@media all and (min-width: 600px) {
  .common-menu__inner-list.common-menu__inner-list--column {
    padding: 10px 0 5px;
  }
}

.common-menu__inner-item {
  position: relative;
}

.common-menu__inner-item::before {
  display: block;
  content: "・";
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.common-menu__item--product .common-menu__inner-item::before {
  content: "-";
}

@media all and (max-width: 930px) and (min-width: 600px) {
  .common-menu__item--product .common-menu__inner-item {
    margin-top: 10px;
  }
  .common-menu__item--product .common-menu__inner-item::before {
    top: 13px;
  }
}

.common-menu__inner-link {
  font-size: 13px;
  line-height: 2.615;
  letter-spacing: 0;
  padding-left: 14px;
}

.footer .common-menu__inner-link {
  line-height: 2.3;
}

@media all and (min-width: 600px) {
  .common-menu__inner-link {
    font-weight: 400;
    line-height: 2.3;
  }
}

.common-menu__item--product .common-menu__inner-link {
  padding-left: 8px;
}

@media all and (min-width: 600px) {
  .common-menu__item--product .common-menu__inner-link {
    line-height: 1.4;
  }
}

/*
  ---------------
  .js-item-list-fv-slide | 商品一覧 FVスライド
  ---------------
*/
.item-list-fv .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 14px;
}

@media all and (max-width: 599px) {
  .item-list-fv .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 11px;
  }
}

.item-list-fv .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0 0 8px;
}

@media all and (max-width: 599px) {
  .item-list-fv .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 0 0 7px;
  }
}

.item-list-fv .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:first-child {
  margin-left: 0;
}

.item-list-fv .swiper-pagination-bullet {
  background: #fff;
  border-radius: 0;
  opacity: 1;
  width: 80px;
  height: 5px;
}

@media all and (max-width: 599px) {
  .item-list-fv .swiper-pagination-bullet {
    width: 53px;
    height: 5px;
  }
}

.item-list-fv .swiper-pagination-bullet-active {
  background: #171717;
}

/*
  ----------------
  Action-links-box | Common
  Break point 追加: 960px
  ----------------
*/
.action-links-box {
  background-color: #fafafa;
}

.action-links-box__wrap {
  display: flex;
  padding: 57px 71px;
}

@media all and (max-width: 960px) and (min-width: 600px) {
  .action-links-box__wrap {
    padding: 57px 20px;
  }
}

@media all and (max-width: 599px) {
  .action-links-box__wrap {
    display: block;
    padding: 83px 35px 70px;
    margin: 0 auto;
  }
}

.freepage .action-links-box__wrap {
  padding: 57px 71px 140px;
}

@media all and (max-width: 960px) and (min-width: 600px) {
  .freepage .action-links-box__wrap {
    padding: 57px 20px 140px;
  }
}

@media all and (max-width: 599px) {
  .freepage .action-links-box__wrap {
    padding: 64px 35px 124px;
  }
}

.action-links-box__link {
  border: 1px solid #dbdbdb;
  margin: 0 9px;
  padding: 39px 20px 34px;
  display: flex;
  align-items: center;
  flex-basis: calc((100% - 54px) / 3);
  flex-direction: column;
}

@media all and (max-width: 960px) and (min-width: 600px) {
  .action-links-box__link {
    flex-basis: calc((100% - 30px) / 3);
    margin: 0 5px;
    padding: 39px 10px 34px;
  }
}

@media all and (max-width: 599px) {
  .action-links-box__link {
    margin: 0 0 10px;
    padding: 31px 20px 16px;
    width: 100%;
  }
}

.action-links-box__icon {
  height: 94px;
}

@media all and (max-width: 599px) {
  .action-links-box__icon {
    height: 75px;
    width: 86px;
  }
}

.action-links-box__link--faq .action-links-box__icon {
  padding-top: 3px;
}

@media all and (max-width: 599px) {
  .action-links-box__link--guide .action-links-box__icon {
    padding: 0 6px;
  }
}

.action-links-box__link--online .action-links-box__icon {
  padding-top: 6px;
}

@media all and (max-width: 599px) {
  .action-links-box__link--online .action-links-box__icon {
    padding: 0 11px;
  }
}

.action-links-box__info {
  margin-top: 16px;
}

@media all and (max-width: 599px) {
  .action-links-box__info {
    margin-top: 26px;
  }
}

.action-links-box__text {
  text-align: center;
}

@media all and (max-width: 960px) and (min-width: 600px) {
  .action-links-box__text {
    font-size: 13px;
    height: 37px;
    line-height: 1.4;
  }
}

@media all and (max-width: 599px) {
  .action-links-box__text {
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 1.384;
  }
}

.action-links-box__title {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}

@media all and (max-width: 960px) and (min-width: 600px) {
  .action-links-box__title {
    font-size: 20px;
    margin-top: 10px;
  }
}

@media all and (max-width: 599px) {
  .action-links-box__title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.77;
    margin-top: 5px;
  }
}

/*
  ----------------
  Form & Table | フォーム & テーブル
  ----------------
*/
body.signup h1 {
  color: #fff;
  background: url(/assets/img/signup/bg_page_title.jpg) no-repeat center center;
  background-size: cover;
  font-size: 40px;
  height: 448px;
  letter-spacing: 0.08em;
  line-height: 1.75;
  padding-top: 105px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 98px;
}

@media all and (max-width: 599px) {
  body.signup h1 {
    background: url(/assets/img/signup/bg_page_title_sp.jpg) no-repeat center center;
    background-size: cover;
    font-size: 25px;
    height: 400px;
    letter-spacing: 0.08em;
    line-height: 1.6;
    padding-top: 58px;
  }
}

@media all and (max-width: 599px) {
  body.signup h1 {
    margin-bottom: 39px;
  }
}

.form-note,
.submit-note {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.8571;
  margin: 43px 0;
  text-align: center;
}

@media all and (max-width: 599px) {
  .form-note,
  .submit-note {
    margin: 30px 0;
    padding: 0 30px;
  }
}

.form-body {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  background: #fafafa;
  padding: 70px 8.5406% 130px;
}

@media all and (min-width: 600px) {
  .form-body {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .form-body {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) {
  .form-body {
    max-width: 1206px;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .form-body {
    padding: 50px 30px 70px;
  }
}

@media all and (max-width: 599px) {
  .form-body {
    padding: 50px 30px 70px;
  }
}

@media all and (min-width: 600px) and (max-width: 794px) {
  .form-rps-consignee .form-body,
  .form-edit-account .form-body,
  .form-edit-address .form-body {
    padding: 50px 30px 70px;
  }
}

.form-body .signup-form__title {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.6;
  text-align: center;
}

.form-body .signup-form__note,
.form-body .signup-form .note-signu {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.8462;
  margin-top: 22px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .form-body .signup-form__note,
  .form-body .signup-form .note-signu {
    font-size: 14px;
    line-height: 2.1429;
    padding: 0;
    text-align-last: left;
  }
}

.form-body .signup-form__terms {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  box-sizing: border-box;
  color: #171717;
  display: block;
  font-size: 13px;
  font-weight: 400;
  height: 100vh;
  line-height: 1.8462;
  margin: 45px auto 0;
  max-height: 553px;
  max-width: 1000px;
  overflow-y: scroll;
  padding: 50px;
  width: 100%;
}

@media all and (max-width: 599px) {
  .form-body .signup-form__terms {
    font-size: 14px;
    line-height: 2.1429;
    margin: 45px 0 0;
    max-height: 814px;
    padding: 10px;
  }
}

.form-body .signup-form .btn-submit {
  margin-top: 60px;
}

@media all and (max-width: 599px) {
  .form-body .signup-form .btn-submit {
    margin-top: 47px;
  }
}

fieldset {
  border: none;
  margin: 60px 0 0;
  padding: 60px 0 0;
  position: relative;
}

fieldset::before {
  background-color: #dbdbdb;
  content: "";
  display: block;
  height: 1px;
  margin: 0 0 0 -103px;
  width: calc(100% + 206px);
  position: absolute;
  top: 0;
  left: 0;
}

@media all and (max-width: 599px) {
  fieldset {
    margin: 55px 0 0;
    padding: 55px 0 0;
  }
}

fieldset.new-password::before {
  top: -40px;
}

fieldset.new-password legend {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.6;
  text-align: center;
  transform: translateY(50px);
}

@media all and (min-width: 600px) {
  .formrow {
    display: flex !important;
    margin-top: 30px;
  }
}

@media all and (max-width: 599px) {
  .formrow {
    display: block;
    font-size: 15px;
  }
}

.formrow:not(:first-of-type), .formrow.formrow-checkbox {
  border-top: none;
}

@media all and (min-width: 600px) and (max-width: 794px) {
  .form-rps-consignee .formrow,
  .form-edit-account .formrow,
  .form-edit-address .formrow,
  .manage-paymentmethod .formrow {
    display: block;
  }
}

.input-label {
  display: flex;
  align-items: baseline;
}

@media all and (min-width: 600px) {
  .input-label {
    flex-basis: 33.665%;
  }
}

@media all and (max-width: 599px) {
  .input-label {
    margin-top: 12px;
    align-items: center;
  }
}

.form-edit-account .input-label .input-may-required,
.form-edit-address .input-label .input-may-required {
  border: 1px solid #9b0000;
  border-radius: 10px;
  color: #9b0000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08em;
  margin-left: 1rem;
  height: 21px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .form-edit-account .input-label .input-may-required,
  .form-edit-address .input-label .input-may-required {
    height: 20px;
  }
}

.input-divider-heading {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 40px;
}

@media all and (max-width: 599px) {
  .input-divider-heading {
    font-weight: 500;
    margin-top: 36px;
  }
}

.input-name {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .input-name {
    font-size: 14px;
    font-weight: 500;
  }
}

.input-required {
  border: 1px solid #9b0000;
  border-radius: 10px;
  color: #9b0000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08em;
  margin-left: 1rem;
  height: 21px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .input-required {
    height: 20px;
  }
}

.input-control {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

@media all and (max-width: 599px) {
  .input-control {
    margin-top: 13px;
  }
}

@media all and (min-width: 600px) and (max-width: 794px) {
  .form-rps-consignee .input-control,
  .form-edit-account .input-control,
  .form-edit-address .input-control,
  .manage-paymentmethod .input-control {
    margin-top: 13px;
  }
}

.input-control-group .input-group {
  width: auto;
}

.input-group {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

@media all and (min-width: 600px) {
  .input-group + .input-group {
    margin-left: 28px;
  }
}

@media all and (max-width: 599px) {
  .input-group + .input-group {
    margin-left: 12px;
  }
}

.manage-paymentmethod .input-group {
  flex-wrap: nowrap;
}

.input-group input,
.input-group select {
  -webkit-appearance: none;
  appearance: none;
}

.input-group input[type=text],
.input-group input[type=email],
.input-group input[type=tel],
.input-group input[type=number],
.input-group input[type=password],
.input-group input[type=date],
.input-group select,
.input-group textarea {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  font-size: 16px;
  letter-spacing: 0;
  padding: 1.5rem 2rem;
  resize: vertical;
  width: 100%;
}

@media all and (max-width: 599px) {
  .input-group input[type=text],
  .input-group input[type=email],
  .input-group input[type=tel],
  .input-group input[type=number],
  .input-group input[type=password],
  .input-group input[type=date],
  .input-group select,
  .input-group textarea {
    font-size: 14px;
    font-weight: 500;
  }
}

.input-group input[type=text]::placeholder,
.input-group input[type=email]::placeholder,
.input-group input[type=tel]::placeholder,
.input-group input[type=number]::placeholder,
.input-group input[type=password]::placeholder,
.input-group input[type=date]::placeholder,
.input-group select::placeholder,
.input-group textarea::placeholder {
  color: #9f9f9f;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .input-group input[type=text]::placeholder,
  .input-group input[type=email]::placeholder,
  .input-group input[type=tel]::placeholder,
  .input-group input[type=number]::placeholder,
  .input-group input[type=password]::placeholder,
  .input-group input[type=date]::placeholder,
  .input-group select::placeholder,
  .input-group textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
  }
}

.input-group .postalCode,
.input-group .pref,
.input-group .job {
  max-width: 227px;
}

.input-group select {
  background-image: url(/assets/img/signup/icon_arrow_select.svg);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 29px 29px;
  -webkit-appearance: none;
  appearance: none;
}

.input-group .date {
  position: relative;
  width: 31.8182%;
  -webkit-appearance: none;
  appearance: none;
}

.manage-paymentmethod .input-group .date {
  max-width: 100%;
}

@media all and (max-width: 599px) {
  .manage-paymentmethod .input-group .date {
    width: 100%;
  }
}

.input-group .date.date-yyyy, .input-group .date.date-mm, .input-group .date.date-dd {
  background-image: url(/assets/img/signup/text_year_select.svg);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 16px 24px;
}

.manage-paymentmethod .input-group .date.date-yyyy {
  margin-left: 15px;
}

.input-group .date.date-mm {
  background-image: url(/assets/img/signup/text_month_select.svg);
  margin-left: auto;
}

@media all and (max-width: 599px) {
  .input-group .date.date-mm {
    margin-left: 2.1%;
  }
}

.manage-paymentmethod .input-group .date.date-mm {
  margin-left: 0;
}

.input-group .date.date-dd {
  background-image: url(/assets/img/signup/text_day_select.svg);
  margin-left: auto;
}

@media all and (max-width: 599px) {
  .input-group .date.date-dd {
    margin-left: 2.4242%;
  }
}

.input-group .date-delim {
  display: none;
}

.input-group textarea {
  height: 196px;
}

@media all and (max-width: 599px) {
  .input-group textarea {
    max-width: none;
  }
}

.input-group label {
  font-size: 16px;
  letter-spacing: 0;
  padding-left: 45px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

@media all and (max-width: 599px) {
  .input-group label {
    font-size: 14px;
    font-weight: 400;
  }
}

.input-group label + label {
  margin-top: 15px;
}

.input-group label input[type=checkbox] {
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 0;
}

.input-group label input[type=checkbox]::before, .input-group label input[type=checkbox]::after {
  border-radius: 4px;
  box-sizing: border-box;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.input-group label input[type=checkbox]::before {
  background-color: #fff;
  border: 1px solid #DBDBDB;
  height: 30px;
  width: 30px;
}

.input-group label input[type=checkbox]:checked::after {
  background: #171717;
  height: 18px;
  left: 6px;
  width: 18px;
}

.input-group .radio-item {
  display: inline-block;
  float: left;
}

@media all and (max-width: 599px) {
  .input-group .radio-item {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}

.input-group .radio-item:not(:first-of-type) {
  margin-left: 53px;
}

@media all and (max-width: 599px) {
  .input-group .radio-item:not(:first-of-type) {
    margin-left: 20px;
  }
}

.input-group .radio-item label {
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0;
  padding-left: 45px;
  position: relative;
  display: flex;
  align-items: center;
}

.input-group input[type=radio] {
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 0;
}

.input-group input[type=radio]::before, .input-group input[type=radio]::after {
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.input-group input[type=radio]::before {
  background-color: #fff;
  border: 1px solid #DBDBDB;
  height: 30px;
  width: 30px;
}

.input-group input[type=radio]:checked::after {
  background: #171717;
  height: 18px;
  left: 6px;
  width: 18px;
}

.formrow-radio label {
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0;
  padding-left: 45px;
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
}

.formrow-radio label + label {
  margin-top: 0;
  margin-left: 53px;
}

@media all and (max-width: 599px) {
  .formrow-radio label + label {
    margin-left: 20px;
  }
}

.input-note {
  font-size: 13px;
  flex-basis: 100%;
  margin-top: 0.5rem;
}

@media all and (max-width: 599px) {
  .input-note {
    font-size: 10px;
  }
}

.btn-submit,
.formrow-submit {
  text-align: center;
}

@media all and (max-width: 599px) {
  .btn-submit,
  .formrow-submit {
    margin-top: 20px;
  }
}

.signup-note,
button.btn-submit {
  margin-top: 60px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .signup-note,
  button.btn-submit {
    margin-top: 47px;
  }
}

.nmessage-default {
  color: #c77064;
}

/*
  ----------------
  Itemdetails & price-total | カート内アイテム & 合計金額
  ----------------
*/
.itemdetails .itemdetails-header {
  background-color: #f2f2f2;
  font-weight: 500;
}

@media all and (max-width: 850px) {
  .itemdetails .itemdetails-header {
    display: none;
  }
}

.itemdetails .itemdetails-body {
  display: flex;
  flex-wrap: wrap;
}

.view-order .itemdetails .itemdetails-body,
.view-orders .itemdetails .itemdetails-body,
.order-journal-history .itemdetails .itemdetails-body,
.rp-entry .itemdetails .itemdetails-body {
  display: block;
}

.itemdetails .itemdetail-row {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 100%;
  justify-content: space-between;
  padding: 10px 0;
}

.itemdetails .itemdetail-row:not(:first-child) {
  border-top: 1px solid #dbdbdb;
}

@media all and (max-width: 850px) {
  .itemdetails .itemdetail-row {
    display: block;
    padding: 20px 0;
  }
}

.view-order .itemdetails .itemdetail-row,
.view-orders .itemdetails .itemdetail-row,
.order-journal-history .itemdetails .itemdetail-row {
  border-bottom: 1px solid lightgray;
  padding: 30px 0;
}

@media all and (min-width: 600px) {
  .view-order .itemdetails .itemdetail-row,
  .view-orders .itemdetails .itemdetail-row,
  .order-journal-history .itemdetails .itemdetail-row {
    display: flex;
    align-items: center;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .itemdetails .itemdetail-row,
  .view-orders .itemdetails .itemdetail-row,
  .order-journal-history .itemdetails .itemdetail-row {
    display: block;
    padding: 30px 20px;
  }
}

@media all and (max-width: 599px) {
  .view-order .itemdetails .itemdetail-row,
  .view-orders .itemdetails .itemdetail-row,
  .order-journal-history .itemdetails .itemdetail-row {
    padding: 30px 20px;
  }
}

.view-order .itemdetails .itemdetail-row:not(:first-child),
.view-orders .itemdetails .itemdetail-row:not(:first-child),
.order-journal-history .itemdetails .itemdetail-row:not(:first-child) {
  border-top: 0;
}

.itemdetails .item {
  display: inline;
  text-align: center;
  width: 50%;
}

@media all and (max-width: 850px) {
  .itemdetails .item {
    display: block;
    text-align: left;
    width: 100%;
  }
}

.view-order .itemdetails .item,
.view-orders .itemdetails .item,
.order-journal-history .itemdetails .item,
.rp-entry .itemdetails .item {
  flex-basis: 100%;
}

.itemdetails .item .control {
  margin-top: 10px;
}

.itemdetails .item .remove-item,
.itemdetails .item .save-item {
  text-align: right;
}

.itemdetails .goods {
  display: flex;
  align-items: center;
}

.view-order .itemdetails .goods,
.view-orders .itemdetails .goods,
.order-journal-history .itemdetails .goods,
.rp-entry .itemdetails .goods {
  text-align: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .itemdetails .goods,
  .view-orders .itemdetails .goods,
  .order-journal-history .itemdetails .goods,
  .rp-entry .itemdetails .goods {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .view-order .itemdetails .goods,
  .view-orders .itemdetails .goods,
  .order-journal-history .itemdetails .goods,
  .rp-entry .itemdetails .goods {
    display: block;
  }
}

.itemdetails .entry-item {
  display: flex;
  align-items: center;
}

.itemdetails .purchase-contents {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}

@media all and (max-width: 850px) {
  .itemdetails .purchase-contents {
    display: block;
    margin-top: 20px;
  }
}

.view-order .itemdetails .purchase-contents,
.view-orders .itemdetails .purchase-contents,
.order-journal-history .itemdetails .purchase-contents {
  color: #9b0000;
  flex: 0 0 150px;
  display: flex;
  justify-content: flex-end;
}

@media all and (min-width: 600px) {
  .view-order .itemdetails .purchase-contents,
  .view-orders .itemdetails .purchase-contents,
  .order-journal-history .itemdetails .purchase-contents {
    padding: 0 20px 0 10px;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .itemdetails .purchase-contents,
  .view-orders .itemdetails .purchase-contents,
  .order-journal-history .itemdetails .purchase-contents {
    margin-top: 20px;
    justify-content: flex-end;
  }
}

@media all and (max-width: 599px) {
  .view-order .itemdetails .purchase-contents,
  .view-orders .itemdetails .purchase-contents,
  .order-journal-history .itemdetails .purchase-contents {
    margin-top: 20px;
    justify-content: flex-end;
  }
}

.itemdetails .quantity,
.itemdetails .subtotal {
  flex-basis: 50%;
  text-align: center;
}

@media all and (max-width: 850px) {
  .itemdetails .quantity,
  .itemdetails .subtotal {
    text-align: right;
  }
}

.view-order .itemdetails .quantity,
.view-orders .itemdetails .quantity,
.order-journal-history .itemdetails .quantity,
.rp-entry .itemdetails .quantity,
.rp-future-entries .itemdetails .quantity, .view-order
.itemdetails .subtotal,
.view-orders
.itemdetails .subtotal,
.order-journal-history
.itemdetails .subtotal,
.rp-entry
.itemdetails .subtotal,
.rp-future-entries
.itemdetails .subtotal {
  flex-basis: auto;
}

.view-order .itemdetails .subtotal,
.view-orders .itemdetails .subtotal,
.order-journal-history .itemdetails .subtotal,
.rp-entry .itemdetails .subtotal {
  margin-left: 20px;
}

@media all and (max-width: 599px) {
  .view-order .itemdetails .subtotal,
  .view-orders .itemdetails .subtotal,
  .order-journal-history .itemdetails .subtotal,
  .rp-entry .itemdetails .subtotal {
    margin-left: 15px;
  }
}

.itemdetails .item-thumbnail-img-wrapper {
  max-width: 100px;
  width: 100%;
}

.itemdetails .item-thumbnail-img {
  text-align: center;
}

.itemdetails .goods-text {
  margin-left: 30px;
  text-align: left;
}

@media all and (max-width: 850px) {
  .itemdetails .goods-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
  }
}

.view-order .itemdetails .goods-text,
.view-orders .itemdetails .goods-text,
.order-journal-history .itemdetails .goods-text,
.rp-entry .itemdetails .goods-text,
.rp-future-entries .itemdetails .goods-text {
  display: inline-block;
  margin-left: 10px;
}

@media all and (min-width: 600px) {
  .view-order .itemdetails .goods-text,
  .view-orders .itemdetails .goods-text,
  .order-journal-history .itemdetails .goods-text,
  .rp-entry .itemdetails .goods-text,
  .rp-future-entries .itemdetails .goods-text {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .itemdetails .goods-text,
  .view-orders .itemdetails .goods-text,
  .order-journal-history .itemdetails .goods-text,
  .rp-entry .itemdetails .goods-text,
  .rp-future-entries .itemdetails .goods-text {
    font-size: 14px;
    margin-top: 10px;
    max-width: 400px;
  }
}

@media all and (max-width: 599px) {
  .view-order .itemdetails .goods-text,
  .view-orders .itemdetails .goods-text,
  .order-journal-history .itemdetails .goods-text,
  .rp-entry .itemdetails .goods-text,
  .rp-future-entries .itemdetails .goods-text {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 0;
  }
}

.itemdetails .goods-text-heading .item-name .code,
.itemdetails .goods-text-heading .manufacturer .makers {
  display: none;
}

.itemdetails .goods-text-heading .item-tag span {
  background-color: #9f9f9f;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
  padding: 0px 14px 1px 15px;
}

@media all and (max-width: 599px) {
  .itemdetails .goods-text-heading .item-tag span {
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px;
  }
}

.view-order .itemdetails .goods-text-heading .code,
.view-orders .itemdetails .goods-text-heading .code,
.order-journal-history .itemdetails .goods-text-heading .code,
.rp-entry .itemdetails .goods-text-heading .code {
  display: none;
}

@media all and (max-width: 850px) {
  .itemdetails .stock-message {
    font-size: 14px;
  }
}

.itemdetails .item-name a {
  text-decoration: underline;
}

@media (hover: hover) and (pointer: fine) {
  .itemdetails .item-name a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .itemdetails .item-name a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.itemdetails .item-name a::before, .itemdetails .item-name a::after {
  backface-visibility: hidden;
}

@media all and (max-width: 850px) {
  .itemdetails .item-name a {
    display: block;
  }
}

.itemdetails .item-name .code {
  display: none !important;
}

.itemdetails .item-options {
  padding-left: 20px;
}

.itemdetails .item-option .item-option-name,
.itemdetails .itemoptions .item-option-name {
  display: inline-block;
  padding-right: 10px;
  width: 110px;
}

@media all and (max-width: 850px) {
  .itemdetails .item-option .item-option-name,
  .itemdetails .itemoptions .item-option-name {
    width: auto;
  }
}

.itemdetails .update-itemoptions {
  text-decoration: underline;
}

@media (hover: hover) and (pointer: fine) {
  .itemdetails .update-itemoptions {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .itemdetails .update-itemoptions:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.itemdetails .update-itemoptions::before, .itemdetails .update-itemoptions::after {
  backface-visibility: hidden;
}

.itemdetails .unit-price {
  margin-top: 10px;
}

@media all and (max-width: 850px) {
  .itemdetails .unit-price .price:before {
    content: "金額：";
  }
}

.itemdetails .clickable {
  cursor: pointer;
  font-size: 15px;
  text-decoration: underline;
  transition: all 0.2s;
}

.itemdetails .clickable:hover {
  opacity: 0.7;
}

@media all and (max-width: 850px) {
  .itemdetails .clickable {
    font-size: 14px;
  }
}

.itemdetails .show-update-qty {
  margin-top: 10px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .itemdetails .show-update-qty {
    display: inline;
    text-align: left;
  }
}

.rp-batch {
  padding: 10px 0;
}

@media all and (max-width: 599px) {
  .rp-batch {
    padding: 20px 0;
  }
}

.price-total .row {
  background-color: #f2f2f2;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  font-weight: 500;
  padding: 20px 0;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

@media all and (max-width: 850px) {
  .price-total .row {
    justify-content: center;
    padding: 20px 5px;
  }
}

.rp-entry .price-total .row,
.rp-future-entries .price-total .row {
  background-color: #fff;
  border-top: 0;
  border-bottom: 0;
}

@media all and (max-width: 850px) {
  .price-total .row .label {
    flex-basis: 30%;
  }
}

.rp-entry .price-total .row .label,
.rp-future-entries .price-total .row .label {
  flex-basis: auto;
}

@media all and (max-width: 850px) {
  .price-total .row .qty {
    flex-basis: 30%;
  }
}

.rp-entry .price-total .row .qty,
.rp-future-entries .price-total .row .qty {
  flex-basis: auto;
}

.price-total .subtotal {
  margin-left: 40px;
}

@media all and (max-width: 850px) {
  .price-total .subtotal {
    margin: 0;
  }
}

/*
  ----------------
  Modal | モーダル
  ----------------
*/
#modal {
  display: none;
}

#modal.is-open {
  display: block;
}

#modal .modal__wrap {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  padding-top: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
}

#modal .modal__lists {
  margin: 0 auto;
  width: 80%;
}

#modal .modal__list {
  border-bottom: 1px solid #fff;
  display: block;
  margin-top: 2rem;
}

#modal .modal__link {
  text-decoration: none;
  color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  #modal .modal__link {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #modal .modal__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

#modal .modal__link::before, #modal .modal__link::after {
  backface-visibility: hidden;
}

#modal .modal__close {
  color: #fff;
  margin-top: 4rem;
  text-align: center;
  text-decoration: underline;
}

.modal__btn {
  display: none;
  border: 1px solid #171717;
}

@media all and (max-width: 599px) {
  .modal__btn {
    display: block;
  }
}

/*
  ----------------
  Stiky side link | FAQ | ご注文ガイド
  ----------------
*/
.stiky-side-link {
  width: 100%;
}

@media all and (min-width: 600px) {
  .stiky-side-link {
    max-width: 284px;
  }
}

@media all and (max-width: 960px) and (min-width: 801px) {
  .stiky-side-link {
    max-width: 220px;
  }
}

@media all and (max-width: 800px) {
  .stiky-side-link {
    max-width: 100%;
  }
}

.stiky-side-link__list {
  background-color: #fafafa;
}

@media all and (min-width: 600px) {
  .stiky-side-link__list {
    position: sticky;
    top: 190px;
  }
}

.stiky-side-link__item {
  border-top: 1px solid #dbdbdb;
}

.stiky-side-link__item:last-child {
  border-bottom: 1px solid #dbdbdb;
}

.stiky-side-link__link {
  letter-spacing: 0;
  line-height: 1.714;
  padding: 12px 0 14px 21px;
  text-align: left;
  transition: background-color .2s ease-in-out;
  width: 100%;
}

.stiky-side-link__link.is-active {
  background-color: #171717;
  color: #fff;
}

@media all and (max-width: 599px) {
  .stiky-side-link__link {
    padding: 12px 0 13px 21px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .stiky-side-link__link:hover {
    background-color: #171717;
    color: #fff;
    opacity: 1;
    transition: background-color .2s ease-in-out;
  }
}

/*
  ----------------
  Top | Pick up
  ----------------
*/
.user-voice-slide {
  background-color: #fff;
}

@media all and (max-width: 599px) {
  .user-voice-slide {
    display: none;
  }
}

.top .user-voice-slide {
  background-color: #fafafa;
}

@media all and (max-width: 599px) {
  .top .user-voice-slide {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .news .user-voice-slide {
    display: block;
    padding-bottom: 110px;
  }
}

.user-voice-slide__wrap {
  padding: 0 80px 90px;
}

@media all and (max-width: 599px) {
  .user-voice-slide__wrap {
    padding: 0;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .user-voice-slide__wrap {
    padding: 0 40px 50px;
  }
}

.top .user-voice-slide__wrap {
  padding-top: 217px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top .user-voice-slide__wrap {
    padding-top: 100px;
  }
}

@media all and (max-width: 599px) {
  .top .user-voice-slide__wrap {
    padding-top: 130px;
    padding-bottom: 134px;
  }
}

.user-voice-slide__title {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.56;
  text-align: center;
}

@media all and (max-width: 599px) {
  .user-voice-slide__title {
    font-size: 20px;
    letter-spacing: 0.08em;
    line-height: 1.2;
  }
}

.user-voice-slide__slider {
  position: relative;
}

.user-voice-slide__container {
  display: none;
  margin-top: 38px;
  overflow: hidden;
  padding-bottom: 47px;
  position: relative;
}

.user-voice-slide__container.swiper-container-initialized {
  display: block;
}

@media all and (max-width: 599px) {
  .user-voice-slide__container {
    margin-top: 33px;
    padding-bottom: 46px;
  }
}

.user-voice-slide__footer {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .user-voice-slide__footer {
    margin-top: 38px;
  }
}

.user-voice-slide__item {
  background-color: #fafafa;
  height: 360px;
  padding: 44px;
}

.top .user-voice-slide__item {
  background-color: #fff;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .user-voice-slide__item {
    padding: 20px;
  }
}

@media all and (max-width: 599px) {
  .user-voice-slide__item {
    height: 378px;
    padding: 36px 34px;
  }
}

.user-voice-slide__text {
  max-height: 100%;
  overflow: hidden;
}

@media all and (max-width: 599px) {
  .user-voice-slide__text {
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 2.3;
  }
}

.user-voice-slide__link.is-new-item::before {
  display: block;
  content: "";
  background: url(/assets/img/top/icon_new_badge.svg) 50%/contain no-repeat;
  position: absolute;
  top: -25px;
  right: -15px;
  width: 50px;
  height: 50px;
}

.user-voice-slide .swiper-button-next,
.user-voice-slide .swiper-button-prev {
  cursor: pointer;
  height: 57px;
  top: 45%;
  width: 22px;
}

@media (hover: hover) and (pointer: fine) {
  .user-voice-slide .swiper-button-next,
  .user-voice-slide .swiper-button-prev {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .user-voice-slide .swiper-button-next:hover,
  .user-voice-slide .swiper-button-prev:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.user-voice-slide .swiper-button-next::before, .user-voice-slide .swiper-button-next::after,
.user-voice-slide .swiper-button-prev::before,
.user-voice-slide .swiper-button-prev::after {
  backface-visibility: hidden;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .user-voice-slide .swiper-button-next,
  .user-voice-slide .swiper-button-prev {
    height: 45px;
    width: 15px;
  }
}

@media all and (max-width: 599px) {
  .user-voice-slide .swiper-button-next,
  .user-voice-slide .swiper-button-prev {
    display: none;
  }
}

.user-voice-slide .swiper-button-next::after,
.user-voice-slide .swiper-button-prev::after {
  background: url(/assets/img/top/icon_arrow_pickup_prev.svg) 50%/contain no-repeat;
  content: '';
  display: block;
  height: 57px;
  width: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .user-voice-slide .swiper-button-next::after,
  .user-voice-slide .swiper-button-prev::after {
    height: 45px;
    width: 15px;
  }
}

.user-voice-slide__link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
}

@media (hover: hover) and (pointer: fine) {
  .user-voice-slide__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.user-voice-slide__link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.user-voice-slide__link::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .user-voice-slide__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.user-voice-slide__link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.user-voice-slide__link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.user-voice-slide__link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.user-voice-slide__link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

.user-voice-slide .swiper-button-prev {
  left: -55px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .user-voice-slide .swiper-button-prev {
    left: -30px;
  }
}

.user-voice-slide .swiper-button-next {
  right: -55px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .user-voice-slide .swiper-button-next {
    right: -30px;
  }
}

.user-voice-slide .swiper-button-next::after {
  background-image: url(/assets/img/top/icon_arrow_pickup_next.svg);
}

.user-voice-slide .swiper-container-horizontal .swiper-pagination-bullet {
  background-color: #f2f2f2;
  border-radius: 25px;
  margin: 0 5px;
  opacity: 1;
  height: 8px;
  width: 8px;
}

.user-voice-slide .swiper-container-horizontal .swiper-pagination-bullet-active {
  background-color: #171717;
}

.user-voice-slide .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

/*
  ---------------
  accountページ共通スタイル
  ---------------
*/
.form-auth,
.account,
.order-journal-history,
.order-process-main-payment-status,
.rp-subscriptions,
.rp-future-entries,
.rp-entry,
.form-rps-consignee,
.form-regular-purchase-payment,
.form-edit-account,
.form-edit-address,
.form-update-password-by-token,
.signin-form {
  padding-top: 110px;
  padding-bottom: 100px;
}

@media all and (max-width: 599px) {
  .form-auth,
  .account,
  .order-journal-history,
  .order-process-main-payment-status,
  .rp-subscriptions,
  .rp-future-entries,
  .rp-entry,
  .form-rps-consignee,
  .form-regular-purchase-payment,
  .form-edit-account,
  .form-edit-address,
  .form-update-password-by-token,
  .signin-form {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

.form-auth #page-title h1,
.account #page-title h1,
.order-journal-history #page-title h1,
.order-process-main-payment-status #page-title h1,
.rp-subscriptions #page-title h1,
.rp-future-entries #page-title h1,
.rp-entry #page-title h1,
.form-rps-consignee #page-title h1,
.form-regular-purchase-payment #page-title h1,
.form-edit-account #page-title h1,
.form-edit-address #page-title h1,
.form-update-password-by-token #page-title h1,
.signin-form #page-title h1 {
  text-align: center;
  margin-top: 60px;
}

@media all and (max-width: 599px) {
  .form-auth #page-title h1,
  .account #page-title h1,
  .order-journal-history #page-title h1,
  .order-process-main-payment-status #page-title h1,
  .rp-subscriptions #page-title h1,
  .rp-future-entries #page-title h1,
  .rp-entry #page-title h1,
  .form-rps-consignee #page-title h1,
  .form-regular-purchase-payment #page-title h1,
  .form-edit-account #page-title h1,
  .form-edit-address #page-title h1,
  .form-update-password-by-token #page-title h1,
  .signin-form #page-title h1 {
    font-weight: 500;
  }
}

.form-auth h2,
.account h2,
.order-journal-history h2,
.order-process-main-payment-status h2,
.rp-subscriptions h2,
.rp-future-entries h2,
.rp-entry h2,
.form-rps-consignee h2,
.form-regular-purchase-payment h2,
.form-edit-account h2,
.form-edit-address h2,
.form-update-password-by-token h2,
.signin-form h2 {
  border-bottom: 2px solid #707070;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
  padding-bottom: 5px;
  margin-top: 120px;
}

@media all and (max-width: 599px) {
  .form-auth h2,
  .account h2,
  .order-journal-history h2,
  .order-process-main-payment-status h2,
  .rp-subscriptions h2,
  .rp-future-entries h2,
  .rp-entry h2,
  .form-rps-consignee h2,
  .form-regular-purchase-payment h2,
  .form-edit-account h2,
  .form-edit-address h2,
  .form-update-password-by-token h2,
  .signin-form h2 {
    font-weight: 500;
    margin-top: 80px;
  }
}

.form-auth h4,
.form-auth h5,
.form-auth h6,
.account h4,
.account h5,
.account h6,
.order-journal-history h4,
.order-journal-history h5,
.order-journal-history h6,
.order-process-main-payment-status h4,
.order-process-main-payment-status h5,
.order-process-main-payment-status h6,
.rp-subscriptions h4,
.rp-subscriptions h5,
.rp-subscriptions h6,
.rp-future-entries h4,
.rp-future-entries h5,
.rp-future-entries h6,
.rp-entry h4,
.rp-entry h5,
.rp-entry h6,
.form-rps-consignee h4,
.form-rps-consignee h5,
.form-rps-consignee h6,
.form-regular-purchase-payment h4,
.form-regular-purchase-payment h5,
.form-regular-purchase-payment h6,
.form-edit-account h4,
.form-edit-account h5,
.form-edit-account h6,
.form-edit-address h4,
.form-edit-address h5,
.form-edit-address h6,
.form-update-password-by-token h4,
.form-update-password-by-token h5,
.form-update-password-by-token h6,
.signin-form h4,
.signin-form h5,
.signin-form h6 {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .form-auth h4,
  .form-auth h5,
  .form-auth h6,
  .account h4,
  .account h5,
  .account h6,
  .order-journal-history h4,
  .order-journal-history h5,
  .order-journal-history h6,
  .order-process-main-payment-status h4,
  .order-process-main-payment-status h5,
  .order-process-main-payment-status h6,
  .rp-subscriptions h4,
  .rp-subscriptions h5,
  .rp-subscriptions h6,
  .rp-future-entries h4,
  .rp-future-entries h5,
  .rp-future-entries h6,
  .rp-entry h4,
  .rp-entry h5,
  .rp-entry h6,
  .form-rps-consignee h4,
  .form-rps-consignee h5,
  .form-rps-consignee h6,
  .form-regular-purchase-payment h4,
  .form-regular-purchase-payment h5,
  .form-regular-purchase-payment h6,
  .form-edit-account h4,
  .form-edit-account h5,
  .form-edit-account h6,
  .form-edit-address h4,
  .form-edit-address h5,
  .form-edit-address h6,
  .form-update-password-by-token h4,
  .form-update-password-by-token h5,
  .form-update-password-by-token h6,
  .signin-form h4,
  .signin-form h5,
  .signin-form h6 {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .form-auth h4,
  .form-auth h5,
  .form-auth h6,
  .account h4,
  .account h5,
  .account h6,
  .order-journal-history h4,
  .order-journal-history h5,
  .order-journal-history h6,
  .order-process-main-payment-status h4,
  .order-process-main-payment-status h5,
  .order-process-main-payment-status h6,
  .rp-subscriptions h4,
  .rp-subscriptions h5,
  .rp-subscriptions h6,
  .rp-future-entries h4,
  .rp-future-entries h5,
  .rp-future-entries h6,
  .rp-entry h4,
  .rp-entry h5,
  .rp-entry h6,
  .form-rps-consignee h4,
  .form-rps-consignee h5,
  .form-rps-consignee h6,
  .form-regular-purchase-payment h4,
  .form-regular-purchase-payment h5,
  .form-regular-purchase-payment h6,
  .form-edit-account h4,
  .form-edit-account h5,
  .form-edit-account h6,
  .form-edit-address h4,
  .form-edit-address h5,
  .form-edit-address h6,
  .form-update-password-by-token h4,
  .form-update-password-by-token h5,
  .form-update-password-by-token h6,
  .signin-form h4,
  .signin-form h5,
  .signin-form h6 {
    border-right: 0;
  }
}

.form-auth .extra-controls,
.account .extra-controls,
.order-journal-history .extra-controls,
.order-process-main-payment-status .extra-controls,
.rp-subscriptions .extra-controls,
.rp-future-entries .extra-controls,
.rp-entry .extra-controls,
.form-rps-consignee .extra-controls,
.form-regular-purchase-payment .extra-controls,
.form-edit-account .extra-controls,
.form-edit-address .extra-controls,
.form-update-password-by-token .extra-controls,
.signin-form .extra-controls {
  display: none;
}

.form-auth div.add-all-ordered-items.panel-footer,
.account div.add-all-ordered-items.panel-footer,
.order-journal-history div.add-all-ordered-items.panel-footer,
.order-process-main-payment-status div.add-all-ordered-items.panel-footer,
.rp-subscriptions div.add-all-ordered-items.panel-footer,
.rp-future-entries div.add-all-ordered-items.panel-footer,
.rp-entry div.add-all-ordered-items.panel-footer,
.form-rps-consignee div.add-all-ordered-items.panel-footer,
.form-regular-purchase-payment div.add-all-ordered-items.panel-footer,
.form-edit-account div.add-all-ordered-items.panel-footer,
.form-edit-address div.add-all-ordered-items.panel-footer,
.form-update-password-by-token div.add-all-ordered-items.panel-footer,
.signin-form div.add-all-ordered-items.panel-footer {
  display: none;
}

.form-auth .message,
.form-auth .alert,
.account .message,
.account .alert,
.order-journal-history .message,
.order-journal-history .alert,
.order-process-main-payment-status .message,
.order-process-main-payment-status .alert,
.rp-subscriptions .message,
.rp-subscriptions .alert,
.rp-future-entries .message,
.rp-future-entries .alert,
.rp-entry .message,
.rp-entry .alert,
.form-rps-consignee .message,
.form-rps-consignee .alert,
.form-regular-purchase-payment .message,
.form-regular-purchase-payment .alert,
.form-edit-account .message,
.form-edit-account .alert,
.form-edit-address .message,
.form-edit-address .alert,
.form-update-password-by-token .message,
.form-update-password-by-token .alert,
.signin-form .message,
.signin-form .alert {
  border: 1px solid #171717;
  border-radius: 8px;
  margin: 40px auto 0;
  max-width: 650px;
  padding: 15px;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .panel-heading > div.code.shipment-code,
  .view-orders .panel-heading > div.code.shipment-code,
  .order-journal-history .panel-heading > div.code.shipment-code,
  .order-process-main-payment-status .panel-heading > div.code.shipment-code,
  .rp-subscriptions .panel-heading > div.code.shipment-code {
    border-bottom: 0;
  }
}

@media all and (max-width: 599px) {
  .view-order .panel-heading > div.code.shipment-code,
  .view-orders .panel-heading > div.code.shipment-code,
  .order-journal-history .panel-heading > div.code.shipment-code,
  .order-process-main-payment-status .panel-heading > div.code.shipment-code,
  .rp-subscriptions .panel-heading > div.code.shipment-code {
    border-bottom: 0;
  }
}

.view-order .panel-heading > div,
.view-orders .panel-heading > div,
.order-journal-history .panel-heading > div,
.order-process-main-payment-status .panel-heading > div,
.rp-subscriptions .panel-heading > div {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .view-order .panel-heading > div,
  .view-orders .panel-heading > div,
  .order-journal-history .panel-heading > div,
  .order-process-main-payment-status .panel-heading > div,
  .rp-subscriptions .panel-heading > div {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .panel-heading > div,
  .view-orders .panel-heading > div,
  .order-journal-history .panel-heading > div,
  .order-process-main-payment-status .panel-heading > div,
  .rp-subscriptions .panel-heading > div {
    display: block;
  }
}

.view-order .panel-heading > div > div,
.view-orders .panel-heading > div > div,
.order-journal-history .panel-heading > div > div,
.order-process-main-payment-status .panel-heading > div > div,
.rp-subscriptions .panel-heading > div > div {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .view-order .panel-heading > div > div,
  .view-orders .panel-heading > div > div,
  .order-journal-history .panel-heading > div > div,
  .order-process-main-payment-status .panel-heading > div > div,
  .rp-subscriptions .panel-heading > div > div {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .panel-heading > div > div,
  .view-orders .panel-heading > div > div,
  .order-journal-history .panel-heading > div > div,
  .order-process-main-payment-status .panel-heading > div > div,
  .rp-subscriptions .panel-heading > div > div {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .view-order .panel-heading > div > div,
  .view-orders .panel-heading > div > div,
  .order-journal-history .panel-heading > div > div,
  .order-process-main-payment-status .panel-heading > div > div,
  .rp-subscriptions .panel-heading > div > div {
    text-align: center;
  }
}

/*
  ----------------
  Amount table | 料金合計表
  ----------------
*/
.amount-table {
  font-weight: normal;
  margin-left: auto;
  margin-right: 0;
  width: 50%;
}

@media all and (max-width: 599px) {
  .amount-table {
    width: 100%;
  }
}

.amount-table table {
  border-collapse: collapse;
  width: 100%;
}

.amount-table table tr {
  border-bottom: 1px solid lightgray;
}

@media all and (max-width: 599px) {
  .amount-table table tr {
    margin-left: auto;
    width: 50%;
  }
}

.amount-table table tr:last-child {
  border-bottom: 0;
}

.view-order .amount-table table tr:last-child {
  border-bottom: 1px solid lightgray;
}

@media all and (max-width: 599px) {
  .amount-table table th {
    border-right: 1px solid lightgray;
  }
}

.amount-table table .amount {
  text-align: right;
}

.amount-table .total.odr-ttl-amt .amount,
.amount-table .odr-ttl-earned-pts .amount {
  color: #c77064;
}

@media all and (min-width: 600px) {
  .view-order .item-thumbnail-img-wrapper,
  .view-orders .item-thumbnail-img-wrapper,
  .order-journal-history .item-thumbnail-img-wrapper,
  .rp-subscriptions .item-thumbnail-img-wrapper,
  .rp-future-entries .item-thumbnail-img-wrapper,
  .rp-entry .item-thumbnail-img-wrapper {
    margin: 0 auto;
    max-width: 260px;
    flex: 0 0 260px;
  }
}

@media all and (max-width: 599px) {
  .view-order .item-thumbnail-img-wrapper,
  .view-orders .item-thumbnail-img-wrapper,
  .order-journal-history .item-thumbnail-img-wrapper,
  .rp-subscriptions .item-thumbnail-img-wrapper,
  .rp-future-entries .item-thumbnail-img-wrapper,
  .rp-entry .item-thumbnail-img-wrapper {
    margin: 0 auto;
    max-width: 220px;
  }
}

.view-order .item-thumbnail-img-wrapper img,
.view-orders .item-thumbnail-img-wrapper img,
.order-journal-history .item-thumbnail-img-wrapper img,
.rp-subscriptions .item-thumbnail-img-wrapper img,
.rp-future-entries .item-thumbnail-img-wrapper img,
.rp-entry .item-thumbnail-img-wrapper img {
  max-width: 150px;
}

/*--------------------------------------------------
 Pages
--------------------------------------------------*/
/*
  ----------------
  Pages common
  ----------------
*/
/********** Error page  **********/
/*
  ----------------
  Error page
  ----------------
*/
.error-page #container {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 200px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}

@media all and (min-width: 600px) {
  .error-page #container {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .error-page #container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 599px) {
  .error-page #container {
    padding-top: 100px;
  }
}

.error-page h1 {
  font-size: 40px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .error-page h1 {
    font-size: 36px;
  }
}

.error-page .error-description {
  font-size: 20px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .error-page .error-description {
    font-size: 18px;
  }
}

.error-page .error-note {
  display: inline-block;
  line-height: 1.5;
  margin: 40px auto 0;
  max-width: 700px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .error-page .error-note {
    text-align: left;
  }
}

.error-page .error-note p {
  margin-top: 10px;
}

/********** Top  **********/
/*
  ----------------
  TOP | Common
  ----------------
*/
body.top {
  overflow-x: hidden;
}

.online-counseling-sp {
  background-color: rgba(23, 23, 23, 0.8);
  color: #fff;
  display: none;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.7;
  padding: 14px 0 12px 47px;
  text-align: center;
  transition: none;
  width: 100vw;
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
}

.online-counseling-sp__inner {
  position: relative;
}

.online-counseling-sp__inner::before {
  display: block;
  content: "";
  background: url(/assets/img/nav/icon_gnav_online.svg) 50%/contain no-repeat;
  position: absolute;
  top: 50%;
  left: -32px;
  width: 25px;
  height: 28px;
  transform: translateY(-50%);
}

/*
  ----------------
  Top | hero
  ----------------
*/
.top-hero {
  overflow: hidden;
}

.top-hero__wrap {
  position: relative;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-hero__wrap {
    padding-top: 109px;
  }
}

.top-hero__container {
  position: relative;
}

.top-hero__box {
  width: 482px;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 55.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 599px) {
  .top-hero__box {
    top: 51.1%;
    width: 100%;
  }
}

.top-hero__title {
  color: #fff;
  font-size: 50px;
  line-height: 1.4;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-hero__title {
    font-size: 40px;
  }
}

@media all and (max-width: 599px) {
  .top-hero__title {
    font-size: 25px;
    line-height: 0.96;
    padding-left: 10px;
  }
}

.top-hero__link-wrap {
  margin-top: 34px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .top-hero__link-wrap {
    margin-top: 46px;
  }
}

.top-hero__link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  max-width: 200px;
  padding: 12px 10px;
  transition: background-color 0.2s ease-in-out;
}

@media (hover: hover) and (pointer: fine) {
  .top-hero__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-hero__link:hover {
  background-color: #171717;
  color: #fff;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

@media all and (max-width: 599px) {
  .top-hero__link {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
    color: #fff;
  }
}

.top-hero__scroll {
  width: 57px;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 3.4%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 599px) {
  .top-hero__scroll {
    bottom: 3%;
  }
}

.top-hero__text {
  font-size: 16px;
  font-weight: bold;
}

@media all and (max-width: 599px) {
  .top-hero__text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.714;
  }
}

.top-hero .swiper-pagination-bullet {
  background-color: #f2f2f2;
  border-radius: 0;
  opacity: 1;
  height: 5px;
  width: 80px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-hero .swiper-pagination-bullet {
    width: 50px;
  }
}

@media all and (max-width: 599px) {
  .top-hero .swiper-pagination-bullet {
    max-width: 53px;
    width: 100%;
  }
}

.top-hero .swiper-pagination-bullet-active {
  background-color: #171717;
}

.top-hero .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 15px;
}

@media all and (max-width: 599px) {
  .top-hero .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 8px;
  }
}

/*
  ----------------
  Top | Pick up
  ----------------
*/
.pick-up__wrap {
  padding: 95px 65px 83px 80px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .pick-up__wrap {
    padding: 75px 35px 53px 60px;
  }
}

@media all and (max-width: 599px) {
  .pick-up__wrap {
    padding: 55px 0 58px;
  }
}

.pick-up__title {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15em;
  line-height: 1.56;
  text-align: center;
}

@media all and (max-width: 599px) {
  .pick-up__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 0.96;
  }
}

.pick-up__slider {
  position: relative;
}

.pick-up__container {
  display: none;
  overflow: hidden;
  padding: 33px 15px 45px 0;
  position: relative;
}

.pick-up__container.swiper-container-initialized {
  display: block;
}

@media all and (max-width: 599px) {
  .pick-up__container {
    padding: 23px 0 38px;
  }
}

.pick-up__link {
  display: inline-block;
  position: relative;
  width: 100%;
}

.pick-up__link.is-new-item::before {
  display: block;
  content: "";
  background: url(/assets/img/top/icon_new_badge.svg) 50%/contain no-repeat;
  position: absolute;
  top: -25px;
  right: -15px;
  z-index: 1;
  height: 50px;
  width: 50px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .pick-up__link.is-new-item::before {
    top: -20px;
    right: -10px;
    height: 35px;
    width: 35px;
  }
}

@media all and (max-width: 599px) {
  .pick-up__link.is-new-item::before {
    top: -20px;
    right: -10px;
    height: 35px;
    width: 35px;
  }
}

.pick-up .swiper-button-next,
.pick-up .swiper-button-prev {
  cursor: pointer;
  height: 57px;
  top: 45%;
  width: 22px;
}

@media (hover: hover) and (pointer: fine) {
  .pick-up .swiper-button-next,
  .pick-up .swiper-button-prev {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .pick-up .swiper-button-next:hover,
  .pick-up .swiper-button-prev:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.pick-up .swiper-button-next::before, .pick-up .swiper-button-next::after,
.pick-up .swiper-button-prev::before,
.pick-up .swiper-button-prev::after {
  backface-visibility: hidden;
}

@media all and (max-width: 599px) {
  .pick-up .swiper-button-next,
  .pick-up .swiper-button-prev {
    display: none;
  }
}

.pick-up .swiper-button-next::after,
.pick-up .swiper-button-prev::after {
  background: url(/assets/img/top/icon_arrow_pickup_prev.svg) 50%/contain no-repeat;
  content: '';
  display: block;
  height: 57px;
  width: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .pick-up .swiper-button-next::after,
  .pick-up .swiper-button-prev::after {
    height: 45px;
    width: 15px;
  }
}

.pick-up .swiper-button-prev {
  left: -55px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .pick-up .swiper-button-prev {
    left: -30px;
  }
}

.pick-up .swiper-button-next {
  right: -40px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .pick-up .swiper-button-next {
    right: -25px;
  }
}

.pick-up .swiper-button-next::after {
  background-image: url(/assets/img/top/icon_arrow_pickup_next.svg);
}

.pick-up .swiper-container-horizontal .swiper-pagination-bullet {
  background-color: #f2f2f2;
  border-radius: 25px;
  margin: 0 5px;
  opacity: 1;
  height: 8px;
  width: 8px;
}

.pick-up .swiper-container-horizontal .swiper-pagination-bullet-active {
  background-color: #171717;
}

.pick-up .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

/*
  ----------------
  Top | Product
  ----------------
*/
.top-product {
  background-color: #fafafa;
}

.top-product__wrap {
  padding-top: 177px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__wrap {
    padding-top: 144px;
  }
}

@media all and (max-width: 599px) {
  .top-product__wrap {
    padding-top: 81px;
  }
}

.top-product__header {
  padding: 0 50px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__header {
    padding: 0 30px;
  }
}

@media all and (max-width: 599px) {
  .top-product__header {
    padding: 0 34px;
  }
}

@media all and (min-width: 600px) {
  .top-product__body {
    margin: 70px auto 0;
    max-width: 1366px;
  }
}

@media all and (max-width: 599px) {
  .top-product__body {
    margin-top: 50px;
  }
}

.top-product__footer {
  margin-top: 119px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .top-product__footer {
    margin-top: 60px;
  }
}

.top-product__title-sub {
  text-align: center;
}

.top-product__title-sub img {
  height: 59px;
  width: auto;
}

@media all and (max-width: 599px) {
  .top-product__title-sub img {
    height: 39px;
  }
}

.top-product__title-sub img {
  height: 23.46px;
}

@media all and (max-width: 599px) {
  .top-product__title-sub img {
    height: 15.64px;
  }
}

.top-product__title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.15em;
  line-height: 1.75;
  line-height: 1.45;
  margin-top: 21px;
}

@media all and (max-width: 599px) {
  .top-product__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1.6;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__title {
    font-size: 28px;
  }
}

@media all and (max-width: 599px) {
  .top-product__title {
    line-height: 1.6;
    margin-top: 14px;
  }
}

.top-product__text {
  letter-spacing: 0.1em;
  line-height: 2.5;
  margin-top: 48px;
  text-align: center;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__text {
    margin-top: 30px;
  }
}

@media all and (max-width: 599px) {
  .top-product__text {
    line-height: 2.2;
    margin-top: 27px;
    text-align: left;
  }
}

.top-product__block {
  display: flex;
}

@media all and (max-width: 599px) {
  .top-product__block {
    flex-wrap: wrap;
  }
}

.top-product__block--premium {
  padding-right: 50px;
}

@media all and (min-width: 600px) {
  .top-product__block--premium {
    height: 463px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--premium {
    padding-right: 30px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--premium {
    padding-right: 35px;
  }
}

.top-product__block--deep-growth {
  margin-top: -130px;
  padding-right: 80px;
  padding-left: 50px;
}

@media all and (min-width: 600px) {
  .top-product__block--deep-growth {
    flex-direction: row-reverse;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--deep-growth {
    padding-right: 40px;
    padding-left: 25px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--deep-growth {
    margin-top: 52px;
    padding: 0 0 0 34px;
  }
}

.top-product__block--day-protecct {
  margin-top: 85px;
}

@media all and (min-width: 600px) {
  .top-product__block--day-protecct {
    height: 320px;
    padding-right: 18.5%;
    display: flex;
    justify-content: center;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--day-protecct {
    padding-right: 30px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--day-protecct {
    margin-top: 80px;
    padding-right: 34px;
    padding-left: 34px;
  }
}

.top-product__block--briliant-soap {
  margin-top: 20px;
}

@media all and (min-width: 600px) {
  .top-product__block--briliant-soap {
    flex-direction: row-reverse;
    height: 375px;
    padding-left: 50px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--briliant-soap {
    margin-top: 0;
    padding-left: 25px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--briliant-soap {
    margin-top: 82px;
    padding-left: 34px;
  }
}

.top-product__block--skincare {
  margin-top: 65px;
}

@media all and (min-width: 600px) {
  .top-product__block--skincare {
    height: 374px;
    padding-right: 50px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--skincare {
    height: 44vw;
    margin-top: 0;
    padding-right: 25px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--skincare {
    margin-top: 57px;
    padding-right: 34px;
  }
}

.top-product__view {
  overflow: hidden;
}

@media all and (max-width: 599px) {
  .top-product__view {
    width: 100%;
  }
}

.top-product__block--deep-growth .top-product__view {
  margin-left: 50px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--deep-growth .top-product__view {
    margin-left: 20px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--deep-growth .top-product__view {
    margin-left: 0;
    padding-bottom: 0;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--day-protecct .top-product__view {
    display: flex;
    align-items: flex-end;
  }
}

.top-product__block--briliant-soap .top-product__view {
  margin-left: 32px;
  padding-bottom: 7px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--briliant-soap .top-product__view {
    display: flex;
    align-items: flex-end;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--briliant-soap .top-product__view {
    margin-left: 0;
    padding-bottom: 0;
  }
}

.top-product__block--skincare .top-product__view {
  padding-bottom: 6px;
}

@media all and (min-width: 600px) {
  .top-product__block--skincare .top-product__view {
    display: flex;
    align-items: flex-end;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--skincare .top-product__view {
    padding-bottom: 0;
  }
}

.top-product__info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.top-product__block--premium .top-product__info {
  justify-content: center;
  max-width: 308px;
  margin-left: 84px;
  padding-top: 10px;
  width: 100%;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--premium .top-product__info {
    margin-left: 30px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--premium .top-product__info {
    margin: 0;
    padding-top: 34px;
    padding-left: 34px;
  }
}

@media all and (min-width: 600px) {
  .top-product__block--deep-growth .top-product__info {
    min-width: 275px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--deep-growth .top-product__info {
    min-width: 245px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--deep-growth .top-product__info {
    margin-top: 42px;
  }
}

.top-product__block--day-protecct .top-product__info {
  margin-left: 55px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--day-protecct .top-product__info {
    margin-left: 30px;
    min-width: 255px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--day-protecct .top-product__info {
    margin-top: 34px;
    margin-left: 0;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--briliant-soap .top-product__info {
    min-width: 270px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--briliant-soap .top-product__info {
    margin-top: 30px;
  }
}

.top-product__block--skincare .top-product__info {
  margin-left: 74px;
}

@media all and (min-width: 600px) {
  .top-product__block--skincare .top-product__info {
    min-width: 310px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__block--skincare .top-product__info {
    margin-left: 30px;
    min-width: 270px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--skincare .top-product__info {
    margin-left: 0;
    padding-top: 34px;
    padding-left: 34px;
  }
}

.top-product__name {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15em;
  line-height: 1.47;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-product__name {
    font-size: 26px;
  }
}

@media all and (max-width: 599px) {
  .top-product__name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1.75;
  }
}

.top-product__description {
  margin-top: 15px;
}

@media all and (max-width: 599px) {
  .top-product__description {
    letter-spacing: 0.08em;
    margin-top: 9px;
  }
}

@media all and (min-width: 600px) {
  .top-product__block--deep-growth .top-product__description,
  .top-product__block--skincare .top-product__description {
    margin-top: 22px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--day-protecct .top-product__description {
    margin-top: 16px;
  }
}

@media all and (max-width: 599px) {
  .top-product__block--skincare .top-product__description {
    margin-top: 12px;
  }
}

.top-product__link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
}

@media (hover: hover) and (pointer: fine) {
  .top-product__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-product__link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.top-product__link::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .top-product__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-product__link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.top-product__link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.top-product__link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.top-product__link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

/*
  ----------------
  Top | Concept
  ----------------
*/
.top-concept {
  background: url(/assets/img/top/bg_concept_dummy.jpg) 50%/cover no-repeat;
}

@media all and (min-width: 600px) {
  .top-concept {
    background: url(/assets/img/top/bg_concept_dummy.jpg) 50%/cover no-repeat;
    margin-top: 69px;
  }
}

.top-concept__wrap {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 65px 50px 69px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (max-width: 599px) {
  .top-concept__wrap {
    padding: 42px 33px 55px;
  }
}

.top-concept__title-sub {
  text-align: center;
}

.top-concept__title-sub img {
  height: 59px;
  width: auto;
}

@media all and (max-width: 599px) {
  .top-concept__title-sub img {
    height: 39px;
  }
}

.top-concept__title-sub img {
  height: 23.55px;
}

@media all and (max-width: 599px) {
  .top-concept__title-sub img {
    height: 15.7px;
  }
}

.top-concept__title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.15em;
  line-height: 1.75;
  color: #fff;
  line-height: 1;
  margin-top: 18px;
}

@media all and (max-width: 599px) {
  .top-concept__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1.6;
  }
}

@media all and (max-width: 599px) {
  .top-concept__title {
    margin-top: 9px;
  }
}

.top-concept__text {
  color: #fff;
  letter-spacing: 0.1em;
  line-height: 2.5;
  margin-top: 48px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .top-concept__text {
    font-size: 13px;
    letter-spacing: 0.1em;
    line-height: 2.692;
    margin-top: 26px;
    text-align: left;
  }
}

.top-concept__link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid #fff;
  color: #fff;
  margin-top: 25px;
}

@media (hover: hover) and (pointer: fine) {
  .top-concept__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-concept__link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.top-concept__link::before {
  border-color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .top-concept__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-concept__link::after {
  background-color: #fff;
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.top-concept__link:hover {
  color: #171717;
  opacity: 1;
}

.top-concept__link:hover::before {
  border-top: 1px solid #171717;
  border-right: 1px solid #171717;
}

.top-concept__link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

@media all and (max-width: 599px) {
  .top-concept__link {
    margin-top: 30px;
  }
}

/*
  ----------------
  TOP | Column
  ----------------
*/
.top-column__wrap {
  margin: 0 -31px;
  padding: 95px 80px 155px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-column__wrap {
    padding: 55px 40px 46px;
  }
}

@media all and (max-width: 599px) {
  .top-column__wrap {
    margin: 0;
    padding: 80px 35px 85px;
  }
}

.top-column__title {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15em;
  line-height: 1.566;
  text-align: center;
}

@media all and (max-width: 599px) {
  .top-column__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 0.96;
  }
}

.top-column__list {
  margin: 25px auto 0;
  max-width: 1268px;
  display: flex;
  flex-wrap: wrap;
}

@media all and (max-width: 599px) {
  .top-column__list {
    display: block;
    margin-top: 58px;
    max-width: 100%;
  }
}

.top-column__item {
  flex-basis: calc((100% - 186px) / 3);
  margin: 40px 31px 0;
}

@media all and (min-width: 600px) {
  .top-column__item:nth-child(n + 7) {
    display: none;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-column__item {
    flex-basis: calc((100% - 120px) / 3);
    margin: 20px 20px 0;
  }
}

@media all and (max-width: 599px) {
  .top-column__item {
    display: block;
    margin: 0;
  }
  .top-column__item:nth-child(n + 4) {
    display: none;
  }
  .top-column__item:not(:first-child) {
    margin: 36px 0 0;
  }
}

.top-column__link {
  display: inline-block;
  width: 100%;
}

.top-column__title-column {
  font-size: 20px;
  font-weight: normal;
  margin-top: 28px;
  letter-spacing: 0;
  line-height: 1.6;
  text-align: center;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-column__title-column {
    font-size: 18px;
    margin-top: 15px;
  }
}

@media all and (max-width: 599px) {
  .top-column__title-column {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.333;
  }
}

.top-column__text {
  letter-spacing: 0.08em;
  margin-top: 17px;
  text-align: center;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-column__text {
    margin-top: 12px;
  }
}

@media all and (max-width: 599px) {
  .top-column__text {
    letter-spacing: 0.03em;
    line-height: 2.142;
    margin-top: 14px;
  }
}

.top-column__more {
  margin-top: 65px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .top-column__more {
    margin-top: 37px;
  }
}

.top-column__btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
}

@media (hover: hover) and (pointer: fine) {
  .top-column__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-column__btn::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.top-column__btn::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .top-column__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-column__btn::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.top-column__btn:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.top-column__btn:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.top-column__btn:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

/*
  ----------------
  Top | News
  ----------------
*/
.top-news__wrap {
  padding: 110px 80px 150px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .top-news__wrap {
    padding: 55px 40px 75px;
  }
}

@media all and (max-width: 599px) {
  .top-news__wrap {
    padding: 80px 35px 145px;
  }
}

.top-news__title {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15em;
  line-height: 1.566;
  text-align: center;
}

@media all and (max-width: 599px) {
  .top-news__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 0.96;
  }
}

.top-news__list {
  margin-top: 55px;
  display: flex;
  flex-wrap: wrap;
}

@media all and (max-width: 599px) {
  .top-news__list {
    margin-top: 35px;
  }
}

.top-news__item {
  border-top: 1px solid #dbdbdb;
  flex-basis: 100%;
}

.top-news__item:last-child {
  border-bottom: 1px solid #dbdbdb;
}

.top-news__link {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  padding: 18px 0;
}

@media all and (max-width: 599px) {
  .top-news__link {
    display: block;
    padding: 20px 0;
  }
}

.top-news__header {
  display: flex;
  align-items: center;
}

.top-news__date {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  min-width: 86px;
}

@media all and (max-width: 599px) {
  .top-news__date {
    letter-spacing: -0.3px;
  }
}

.top-news__tag {
  border: 1px solid #171717;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  margin-left: 17px;
  text-align: center;
  width: 100px;
}

@media all and (max-width: 599px) {
  .top-news__tag {
    font-size: 12px;
    margin-left: 11px;
    padding: 0 14px;
    width: auto;
  }
}

.top-news__text {
  font-weight: 400;
  margin-left: 20px;
  padding-right: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media all and (max-width: 599px) {
  .top-news__text {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 2.307;
    margin-top: 12px;
    margin-left: 0;
    padding-right: 0;
    -webkit-line-clamp: 2;
  }
}

.top-news__more {
  margin-top: 65px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .top-news__more {
    margin-top: 38px;
  }
}

.top-news__btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  padding-top: 9px;
  padding-bottom: 9px;
}

@media (hover: hover) and (pointer: fine) {
  .top-news__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-news__btn::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.top-news__btn::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .top-news__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.top-news__btn::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.top-news__btn:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.top-news__btn:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.top-news__btn:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

.js-text-animation.show .js-text-animation-span {
  display: inline-block;
  overflow: hidden;
}

.js-text-animation.show span {
  display: inline-block;
  letter-spacing: 0.1em;
  animation: showText .5s backwards;
}

@keyframes showText {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/********** About | 会社について  **********/
/*
  ----------------
  会社について
  ----------------
*/
.about-heading__wrap {
  position: relative;
}

.about-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .about-heading__box {
    top: calc(50% + 44px);
  }
}

.about-heading__title {
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .about-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .about-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .about-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

/*
  ----------------
  会社について | Mission
  ----------------
*/
.about-mission__wrap {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 77px;
}

@media all and (min-width: 600px) {
  .about-mission__wrap {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .about-mission__wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .about-mission__wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media all and (max-width: 599px) {
  .about-mission__wrap {
    padding: 48px 10px 0;
  }
}

.about-mission__title {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.15em;
  line-height: 1.175;
  text-align: center;
}

@media all and (max-width: 599px) {
  .about-mission__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1.6;
  }
}

.about-mission__lead {
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -0.3px;
  line-height: 2;
  margin-top: 48px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .about-mission__lead {
    font-size: 25px;
    letter-spacing: 0;
    margin-top: 36px;
  }
}

.about-mission__text {
  letter-spacing: 0;
  line-height: 2.857;
  margin-top: 41px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .about-mission__text {
    letter-spacing: -0.1px;
    padding: 0 10px;
    margin-top: 64px;
    text-align: left;
  }
}

/*
  ----------------
  会社について | Philosophy
  ----------------
*/
.about-philosophy__wrap {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 95px;
}

@media all and (min-width: 600px) {
  .about-philosophy__wrap {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .about-philosophy__wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .about-philosophy__wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media all and (max-width: 599px) {
  .about-philosophy__wrap {
    padding-top: 115px;
  }
}

.about-philosophy__title-sub {
  text-align: center;
}

.about-philosophy__title-sub img {
  height: 59px;
  width: auto;
}

@media all and (max-width: 599px) {
  .about-philosophy__title-sub img {
    height: 39px;
  }
}

@media all and (max-width: 599px) {
  .about-philosophy__title-sub img {
    height: 59px;
  }
}

.about-philosophy__title {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 0.8;
  text-align: center;
}

@media all and (max-width: 599px) {
  .about-philosophy__title {
    font-size: 25px;
    line-height: 1.6;
  }
}

.about-philosophy__list {
  padding-top: 40px;
  display: flex;
  gap: 40px;
  justify-content: center;
}

@media all and (max-width: 800px) {
  .about-philosophy__list {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media all and (max-width: 599px) {
  .about-philosophy__list {
    gap: 50px;
  }
}

.about-philosophy__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: calc((100% - 80px) / 3);
}

@media all and (max-width: 800px) {
  .about-philosophy__item {
    width: 330px;
  }
}

.about-philosophy figure {
  width: 55%;
}

@media all and (max-width: 599px) {
  .about-philosophy figure {
    max-width: 207px;
    width: 100%;
  }
}

.about-philosophy__meaning {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.15em;
  line-height: 1;
  margin-top: 10px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .about-philosophy__meaning {
    margin-top: 15px;
  }
}

.about-philosophy__text {
  font-size: 15px;
  letter-spacing: 0.08em;
  line-height: 1.6;
  margin-top: 20px;
  text-align: center;
}

/*
  ----------------
  会社について | 会社情報
  Add break point 900px
  ----------------
*/
.about-info__wrap {
  padding: 140px 0 107px;
}

@media all and (max-width: 599px) {
  .about-info__wrap {
    padding: 145px 0 60px;
  }
}

.about-info__title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.2;
  text-align: center;
}

.about-info__contents {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  display: flex;
  margin-top: 53px;
}

@media all and (min-width: 600px) {
  .about-info__contents {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .about-info__contents {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 900px) {
  .about-info__contents {
    flex-direction: column-reverse;
  }
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .about-info__contents {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.about-info__main {
  width: 100%;
}

@media all and (max-width: 900px) {
  .about-info__main {
    margin-top: 48px;
  }
}

.about-info__view {
  display: flex;
}

@media all and (min-width: 600px) {
  .about-info__view {
    margin-left: 54px;
    max-width: 258px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media all and (max-width: 900px) {
  .about-info__view {
    flex-direction: row-reverse;
    margin-left: 0;
    max-width: 100%;
  }
}

@media all and (max-width: 599px) {
  .about-info__view {
    margin: auto calc((50vw - 50%) * -1);
    max-width: none;
  }
}

.about-info table {
  border-collapse: collapse;
  width: 100%;
}

.about-info tr {
  border-top: 1px solid #dbdbdb;
  text-align: left;
  display: flex;
  align-items: flex-start;
}

.about-info tr:last-child {
  border-bottom: 1px solid #dbdbdb;
}

@media all and (max-width: 599px) {
  .about-info tr {
    padding: 12px 0 16px 30px;
    align-items: flex-start;
    flex-direction: column;
  }
}

.about-info th,
.about-info td {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.15em;
  line-height: 2;
  padding: 18px 0 17px;
}

@media all and (max-width: 599px) {
  .about-info th,
  .about-info td {
    letter-spacing: 0.15em;
    padding: 0;
  }
}

@media all and (min-width: 600px) {
  .about-info th {
    min-width: 217px;
    padding-left: 28px;
  }
}

@media all and (max-width: 599px) {
  .about-info th {
    font-size: 16px;
    font-weight: 500;
  }
}

@media all and (max-width: 599px) {
  .about-info td {
    font-size: 13px;
  }
}

.about-info__name {
  margin-left: 17px;
}

@media all and (max-width: 900px) {
  .about-info__view-img {
    flex-basis: 50%;
  }
}

/********** FAQ | よくあるご質問  **********/
/*
  ----------------
  よくあるご質問 | Heading
  ----------------
*/
.faq-heading__wrap {
  position: relative;
}

.faq-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .faq-heading__box {
    top: calc(50% + 30px);
  }
}

.faq-heading__title {
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .faq-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .faq-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .faq-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

/*
  ----------------
  よくある質問 | faq-contents
  ----------------
*/
@media all and (min-width: 600px) {
  .faq-contents {
    margin-left: 80px;
  }
}

@media all and (max-width: 960px) and (min-width: 801px) {
  .faq-contents {
    margin-left: 40px;
  }
}

@media all and (max-width: 800px) {
  .faq-contents {
    margin-left: 0;
  }
}

.faq-contents__wrap {
  width: 100%;
}

@media all and (min-width: 600px) {
  .faq-contents__wrap {
    max-width: 850px;
  }
}

@media all and (max-width: 800px) {
  .faq-contents__wrap {
    padding-top: 53px;
    max-width: 100%;
  }
}

.faq-contents__title {
  background-color: #f2f2f2;
  border-top: 1px solid #171717;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2;
  padding: 11px 17px 8px;
}

@media all and (max-width: 599px) {
  .faq-contents__title {
    font-weight: 500;
    padding: 5px 21px 4px;
  }
}

.faq-contents__title-sub {
  font-size: 18px;
  padding: 11px 17px 0;
}

@media all and (max-width: 599px) {
  .faq-contents__title-sub {
    font-size: 16px;
    font-weight: 500;
  }
}

.faq-contents details {
  border-bottom: 1px dashed #707070;
  position: relative;
}

.faq-contents details[open] .faq-contents__answer {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.faq-contents summary, .faq-contents__answer-title {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.777;
  position: relative;
}

.faq-contents summary::before, .faq-contents__answer-title::before {
  content: "Q";
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  position: absolute;
  left: 35px;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .faq-contents summary, .faq-contents__answer-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.714;
  }
}

@media (hover: hover) and (pointer: fine) {
  summary:hover .faq-contents__icon {
    background-color: #171717;
  }
  summary:hover .faq-contents__icon::before {
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.faq-contents summary {
  padding: 38px 65px 32px;
}

@media (hover: hover) and (pointer: fine) {
  .faq-contents summary {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .faq-contents summary:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.faq-contents summary::before, .faq-contents summary::after {
  backface-visibility: hidden;
}

.faq-contents summary::before {
  top: 50%;
}

@media all and (max-width: 599px) {
  .faq-contents summary {
    padding: 24px 50px 14px 44px;
  }
  .faq-contents summary::before {
    top: 14px;
    left: 20px;
    transform: none;
  }
}

.faq-contents__icon {
  border: 1px solid #171717;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  width: 30px;
  transform: translateY(-50%);
}

.faq-contents__icon::before {
  content: "▼";
  display: block;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 599px) {
  .faq-contents__icon {
    top: 54%;
  }
}

details[open] .faq-contents__icon {
  background-color: #171717;
}

details[open] .faq-contents__icon::before {
  content: "▲";
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.faq-contents__answer {
  background-color: #fafafa;
  margin-bottom: 20px;
  padding: 30px 36px 40px;
}

@media all and (max-width: 599px) {
  .faq-contents__answer {
    margin: 8px 0 12px;
    padding: 18px 20px 14px;
  }
}

.faq-contents__answer-title {
  padding-left: 30px;
}

@media all and (max-width: 599px) {
  .faq-contents__answer-title {
    display: flex;
    padding-left: 26px;
  }
}

.faq-contents__answer-title::before {
  content: "A";
  top: 50%;
  left: 0;
}

@media all and (max-width: 599px) {
  .faq-contents__answer-title::before {
    top: -5px;
    transform: none;
  }
}

.faq-contents__answer-title a {
  display: inline-block;
}

.faq-contents__answer-title + .faq-contents__answer-text {
  margin-top: 29px;
}

@media all and (max-width: 599px) {
  .faq-contents__answer-title + .faq-contents__answer-text {
    margin-top: 14px;
  }
}

.faq-contents__answer-text {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 2.307;
}

.faq-contents__answer-text a {
  text-decoration: underline;
}

@media (hover: hover) and (pointer: fine) {
  .faq-contents__answer-text a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .faq-contents__answer-text a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.faq-contents__answer-text a::before, .faq-contents__answer-text a::after {
  backface-visibility: hidden;
}

.faq-contents__section + .faq-contents__section {
  margin-top: 80px;
}

@media all and (max-width: 599px) {
  .faq-contents__section + .faq-contents__section {
    margin-top: 53px;
  }
}

/*
  ----------------
  よくある質問 | common
  ----------------
*/
.faq-main {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 185px;
}

@media all and (min-width: 600px) {
  .faq-main {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .faq-main {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) {
  .faq-main {
    display: flex;
    overflow: none;
  }
}

@media all and (max-width: 960px) {
  .faq-main {
    padding: 40px 40px 90px;
  }
}

@media all and (max-width: 800px) {
  .faq-main {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .faq-main {
    padding-top: 50px;
    padding-bottom: 140px;
  }
}

/********** Guide | ご注文ガイド  **********/
/*
  ----------------
  ご注文ガイド | Heading
  ----------------
*/
.guide-heading__wrap {
  position: relative;
}

.guide-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .guide-heading__box {
    top: calc(50% + 30px);
  }
}

.guide-heading__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .guide-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .guide-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .guide-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

/*
  ----------------
  ご注文ガイド | guide-contents
  ----------------
*/
.guide-contents {
  width: 100%;
}

@media all and (min-width: 600px) {
  .guide-contents {
    margin-left: 80px;
  }
}

@media all and (max-width: 960px) and (min-width: 801px) {
  .guide-contents {
    margin-left: 40px;
  }
}

@media all and (max-width: 800px) {
  .guide-contents {
    margin-left: 0;
  }
}

.guide-contents__wrap {
  border-top: 1px solid #707070;
  width: 100%;
}

@media all and (min-width: 600px) {
  .guide-contents__wrap {
    max-width: 850px;
  }
}

@media all and (max-width: 800px) {
  .guide-contents__wrap {
    margin-top: 53px;
    max-width: 100%;
  }
}

@media all and (max-width: 599px) {
  .guide-contents__wrap {
    margin-top: 0;
  }
}

.guide-contents__title {
  background-color: #f2f2f2;
  border-top: 1px solid #171717;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2;
  padding: 11px 17px 8px;
}

@media all and (max-width: 599px) {
  .guide-contents__title {
    font-weight: 500;
    padding: 5px 21px 4px;
  }
}

.guide-contents__title-sub {
  font-size: 18px;
  padding: 11px 17px 0;
}

@media all and (max-width: 599px) {
  .guide-contents__title-sub {
    font-size: 16px;
    font-weight: 500;
  }
}

.guide-contents details {
  border-bottom: 1px dashed #707070;
  position: relative;
}

.guide-contents details[open] .guide-contents__inner {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.guide-contents summary, .guide-contents__inner-title {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.777;
  position: relative;
}

@media all and (max-width: 599px) {
  .guide-contents summary, .guide-contents__inner-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.714;
  }
}

@media (hover: hover) and (pointer: fine) {
  summary:hover .guide-contents__icon {
    background-color: #171717;
  }
  summary:hover .guide-contents__icon::before {
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.guide-contents summary {
  padding: 30px 50px 30px 20px;
}

@media (hover: hover) and (pointer: fine) {
  .guide-contents summary {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .guide-contents summary:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.guide-contents summary::before, .guide-contents summary::after {
  backface-visibility: hidden;
}

@media all and (max-width: 599px) {
  .guide-contents summary {
    font-size: 20px;
    line-height: 1.2;
    padding: 20px 50px 25px 20px;
  }
}

.guide-contents__icon {
  border: 1px solid #171717;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  width: 30px;
  transform: translateY(-50%);
}

.guide-contents__icon::before {
  content: "▼";
  display: block;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

details[open] .guide-contents__icon {
  background-color: #171717;
}

details[open] .guide-contents__icon::before {
  content: "▲";
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.guide-contents__inner {
  background-color: #fafafa;
  margin-bottom: 25px;
  padding: 25px 36px 32px;
}

@media all and (max-width: 599px) {
  .guide-contents__inner {
    margin: 0 0 15px;
    padding: 7px 19px 24px;
  }
}

.guide-contents__inner-title a {
  display: inline-block;
}

.guide-contents__inner-text {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 2.307;
  margin-top: 10px;
}

.guide-contents__inner-text a {
  text-decoration: underline;
}

@media (hover: hover) and (pointer: fine) {
  .guide-contents__inner-text a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .guide-contents__inner-text a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.guide-contents__inner-text a::before, .guide-contents__inner-text a::after {
  backface-visibility: hidden;
}

.guide-contents__inner-card {
  max-width: 270px;
}

.guide-contents__inner-payment {
  max-width: 245px;
  margin-top: 20px;
  margin-left: 20px;
}

.guide-contents__inner-text + .guide-contents__inner-title {
  margin-top: 20px;
}

/*
  ----------------
  ご注文ガイド | common
  ----------------
*/
.guide-main {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 185px;
}

@media all and (min-width: 600px) {
  .guide-main {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .guide-main {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) {
  .guide-main {
    display: flex;
    overflow: none;
  }
}

@media all and (max-width: 960px) {
  .guide-main {
    padding: 40px 40px 90px;
  }
}

@media all and (max-width: 800px) {
  .guide-main {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .guide-main {
    padding: 50px 30px 87px;
  }
}

/********** Privacy policy | 個人情報保護方針 **********/
/*
  ----------------
  Privacy policy | 個人情報保護方針
  ----------------
*/
.privacy-policy__wrap {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 110px;
  padding-bottom: 160px;
}

@media all and (min-width: 600px) {
  .privacy-policy__wrap {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .privacy-policy__wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 599px) {
  .privacy-policy__wrap {
    padding-top: 61px;
    padding-bottom: 80px;
  }
}

.privacy-policy__title {
  border-bottom: 2px solid #171717;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.08em;
  margin-top: 80px;
  padding-left: 5px;
}

@media all and (max-width: 599px) {
  .privacy-policy__title {
    font-size: 25px;
    margin-top: 40px;
  }
}

.privacy-policy__content {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
}

@media all and (max-width: 599px) {
  .privacy-policy__content {
    padding: 30px 10px 0;
  }
}

.privacy-policy__lead {
  line-height: 1.7;
}

.privacy-policy__title-sub {
  font-size: 20px;
  margin-top: 40px;
}

@media all and (max-width: 599px) {
  .privacy-policy__title-sub {
    font-size: 18px;
    text-align: center;
  }
}

.privacy-policy__text {
  line-height: 1.7;
  margin-top: 10px;
}

.privacy-policy__list {
  margin-top: 10px;
  padding-left: 18px;
}

.privacy-policy__item {
  line-height: 1.7;
  position: relative;
}

.privacy-policy__item::before {
  content: "・";
  display: block;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: -18px;
}

/********** tokusho | 特定商取引に関する法律に基づく表記 **********/
/*
  ----------------
  tokusho | 特定商取引に関する法律に基づく表記
  ----------------
*/
.order-info__wrap {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 110px;
  padding-bottom: 160px;
}

@media all and (min-width: 600px) {
  .order-info__wrap {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .order-info__wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 599px) {
  .order-info__wrap {
    padding-top: 61px;
    padding-bottom: 80px;
  }
}

.order-info__title {
  border-bottom: 2px solid #171717;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.08em;
  margin-top: 80px;
  padding-left: 5px;
}

@media all and (max-width: 599px) {
  .order-info__title {
    font-size: 18px;
    letter-spacing: 0;
    margin-top: 40px;
    padding-left: 0;
    text-align: center;
  }
}

.order-info__content {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
}

@media all and (max-width: 599px) {
  .order-info__content {
    padding: 30px 0 0;
  }
}

.order-info table {
  border-collapse: collapse;
  border-top: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  margin: 0 auto;
  width: 100%;
}

.order-info th,
.order-info td {
  padding: 8px;
  border-bottom: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}

@media all and (max-width: 599px) {
  .order-info th,
  .order-info td {
    display: inline-block;
    width: 100%;
  }
}

.order-info th {
  background-color: #f2f2f2;
}

@media all and (min-width: 600px) {
  .order-info th {
    min-width: 155px;
    width: 20%;
  }
}

@media all and (max-width: 599px) {
  .order-info th {
    padding: 4px;
  }
}

@media all and (min-width: 600px) {
  .order-info td {
    width: 80%;
  }
}

/********** User voice | お客様の声集 **********/
/*
  ----------------
  お客様の声集
  ----------------
*/
.voice-lead__body {
  position: relative;
}

.voice-lead__copy {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.666;
  text-align: center;
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 599px) {
  .voice-lead__copy {
    font-size: 20px;
    font-weight: 500;
    line-height: 2.5;
    top: 52%;
  }
}

/*
  ----------------
  お客様の声集
  ----------------
*/
.voice-main input[type="checkbox"] {
  display: none;
}

.voice-main .is-hide {
  display: none;
}

.voice-main__wrap {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 57px;
  padding-bottom: 180px;
}

@media all and (min-width: 600px) {
  .voice-main__wrap {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .voice-main__wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 1050px) {
  .voice-main__wrap {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media all and (max-width: 599px) {
  .voice-main__wrap {
    padding: 0 0 107px;
  }
}

.voice-main__title {
  background-color: #f2f2f2;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.777;
  padding: 9px 17px;
  position: relative;
}

@media all and (max-width: 599px) {
  .voice-main__title {
    font-size: 14px;
    letter-spacing: 0.08em;
    line-height: 2.142;
    padding: 11px 30px 8px;
  }
}

.voice-main__reset {
  border: 1px solid #171717;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.846;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .voice-main__reset {
    right: 29px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .voice-main__reset:hover {
    background-color: #171717;
    color: #fff;
  }
}

.voice-main__reset::before {
  display: block;
  content: "";
  background: url(/assets/img/voice/icon_closs.svg) 50%/contain no-repeat;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
}

.voice-main__reset input {
  appearance: none;
  padding: 5px 35px 5px 10px;
}

@media all and (max-width: 599px) {
  .voice-main__reset input {
    padding-top: 4px;
  }
}

.voice-main__product {
  padding: 20px 0 48px;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}

@media all and (max-width: 599px) {
  .voice-main__product {
    padding: 20px 30px 33px;
    flex-wrap: wrap;
    gap: 9px;
  }
}

.voice-main__product label {
  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  .voice-main__product label {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .voice-main__product label:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.voice-main__product label::before, .voice-main__product label::after {
  backface-visibility: hidden;
}

@media all and (max-width: 599px) {
  .voice-main__product label {
    flex-basis: calc((100% - 18px) / 3);
  }
}

.voice-main__product label::before {
  display: block;
  content: "";
  background: rgba(0, 0, 0, 0.5) url(/assets/img/voice/icon_check.svg) 50%/34px 24px no-repeat;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 599px) {
  .voice-main__product label::before {
    background: rgba(0, 0, 0, 0.5) url(/assets/img/voice/icon_check.svg) 50%/16px 11px no-repeat;
  }
}

.voice-main__product input:checked + label::before {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.voice-main__age {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.777;
  padding-top: 22px;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}

@media all and (max-width: 599px) {
  .voice-main__age {
    font-size: 16px;
    padding: 20px 30px 0;
    flex-wrap: wrap;
    gap: 9px;
  }
}

.voice-main__age-type {
  width: 100%;
}

@media all and (max-width: 599px) {
  .voice-main__age-type {
    flex-basis: calc((100% - 18px) / 3);
  }
}

.voice-main__age-type label {
  border: 1px solid #171717;
  cursor: pointer;
  display: inline-block;
  padding: 13px;
  text-align: center;
  transition: background-color .2s ease-in-out;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .voice-main__age-type label:hover {
    background-color: #171717;
    color: #fff;
    opacity: .7;
    transition: background-color .2s ease-in-out;
  }
}

@media all and (max-width: 599px) {
  .voice-main__age-type label {
    padding: 10px;
  }
}

.voice-main__age-type input:checked + label {
  background-color: #171717;
  color: #fff;
  transition: background-color .2s ease-in-out;
}

@media (hover: hover) and (pointer: fine) {
  .voice-main__age-type input:checked + label:hover {
    background-color: #171717;
    color: #fff;
    opacity: 1;
    transition: background-color .2s ease-in-out;
  }
}

.voice-main__list {
  margin-top: 90px;
  max-height: 730px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px 63px;
}

@media all and (max-width: 1050px) {
  .voice-main__list {
    gap: 30px;
    max-height: 690px;
  }
}

@media all and (max-width: 900px) {
  .voice-main__list {
    max-height: 1070px;
  }
}

@media all and (max-width: 599px) {
  .voice-main__list {
    display: block;
    margin-top: 50px;
    max-height: 990px;
    padding: 0 30px;
  }
}

.voice-main__item {
  background: url(/assets/img/voice/bg_gradient_red.jpg) 50%/cover no-repeat;
  border-radius: 20px;
  flex-basis: calc((100% - 126px) / 3);
  overflow: hidden;
  padding: 10px 30px 28px;
}

@media all and (max-width: 1050px) {
  .voice-main__item {
    flex-basis: calc((100% - 60px) / 3);
  }
}

@media all and (max-width: 900px) {
  .voice-main__item {
    flex-basis: calc((100% - 30px) / 2);
  }
}

@media all and (max-width: 599px) {
  .voice-main__item {
    padding: 25px 30px 36px;
  }
}

.voice-main__item:nth-child(even) {
  background: url(/assets/img/voice/bg_gradient_blue.jpg) 50%/cover no-repeat;
}

.voice-main__item:nth-child(even) span {
  background: linear-gradient(to right, #4879ff 0%, #72aed9 100%);
}

@media all and (max-width: 599px) {
  .voice-main__item:not(:first-child) {
    margin-top: 15px;
  }
}

.voice-main__copy-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media all and (min-width: 600px) {
  .voice-main__copy-wrap {
    min-height: 112px;
  }
}

@media all and (max-width: 599px) {
  .voice-main__copy-wrap {
    margin-right: 0;
    flex-direction: row-reverse;
    gap: 20px;
    justify-content: flex-end;
  }
}

.voice-main__copy {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media all and (max-width: 599px) {
  .voice-main__copy {
    letter-spacing: 0.08em;
    line-height: 1.5;
  }
}

@media all and (min-width: 600px) {
  .voice-main__copy-icon {
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.16));
    max-width: 70px;
    padding-left: 15px;
  }
}

@media all and (max-width: 599px) {
  .voice-main__copy-icon {
    min-width: 70px;
  }
}

.voice-main__tag {
  margin-top: 5px;
}

@media all and (min-width: 600px) {
  .voice-main__tag {
    overflow: hidden;
    white-space: nowrap;
  }
}

@media all and (max-width: 599px) {
  .voice-main__tag {
    line-height: 1;
    margin-top: 14px;
    padding-left: 0;
  }
}

.voice-main__tag span {
  background: linear-gradient(to right, #ff6562 0%, #ffc362 100%);
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  letter-spacing: 0.03em;
  line-height: 1.8;
  padding: 2px 12px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media all and (max-width: 1230px) {
  .voice-main__tag span:first-child {
    max-width: 68%;
  }
}

.voice-main__tag span + span {
  margin-left: 5px;
}

.voice-main__text {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.923;
  margin-top: 15px;
  position: relative;
}

@media all and (max-width: 599px) {
  .voice-main__text {
    font-size: 13px;
    line-height: 1.923;
  }
}

.voice-main__text.is-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.voice-main__text-more {
  color: #ba0000;
  display: none;
  text-decoration: underline;
}

.voice-main__text-more:hover {
  text-decoration: none;
}

.voice-main__info {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.923;
  margin-top: 3px;
}

@media all and (max-width: 599px) {
  .voice-main__info {
    font-size: 13px;
    line-height: 1.923;
    margin-top: 6px;
  }
}

.voice-main__more {
  margin-top: 88px;
  position: relative;
  text-align: center;
}

@media all and (max-width: 599px) {
  .voice-main__more {
    margin-top: 50px;
  }
}

.voice-main__more::before {
  background: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
  content: "";
  display: block;
  height: 50px;
  position: absolute;
  top: -138px;
  left: 0;
  width: 100%;
}

@media all and (max-width: 599px) {
  .voice-main__more::before {
    top: -100px;
  }
}

.voice-main__button {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  padding-top: 14px;
  padding-bottom: 14px;
}

@media (hover: hover) and (pointer: fine) {
  .voice-main__button:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.voice-main__button::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.voice-main__button::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .voice-main__button:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.voice-main__button::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.voice-main__button:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.voice-main__button:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.voice-main__button:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

/********** User interview | お客様インタビュー | CMS **********/
/*
  ----------------
  お客様インタビュー
  ----------------
*/
.interview-heading__wrap {
  position: relative;
}

.interview-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .interview-heading__box {
    top: calc(50% + 30px);
  }
}

.interview-heading__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .interview-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .interview-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .interview-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

/*
  ----------------
  お客様インタビュー
  ----------------
*/
.interview-lead__wrap {
  padding: 80px 0 105px;
}

@media all and (max-width: 599px) {
  .interview-lead__wrap {
    padding: 39px 0 55px;
  }
}

.interview-lead__title-sub {
  text-align: center;
}

.interview-lead__title-sub img {
  height: 59px;
  width: auto;
}

@media all and (max-width: 599px) {
  .interview-lead__title-sub img {
    height: 39px;
  }
}

.interview-lead__title-sub img {
  height: 14.64px;
}

.interview-lead__title {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 0.8;
  margin-top: 24px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .interview-lead__title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.6;
    margin-top: 10px;
  }
}

/*
  ----------------
  お客様インタビュー | 最新３件
  ----------------
*/
@media all and (min-width: 600px) {
  .interview-new__wrap {
    position: relative;
    padding-right: 5.8565%;
    padding-left: 5.8565%;
    padding: 0;
  }
}

@media all and (min-width: 600px) and (min-width: 600px) {
  .interview-new__wrap {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (min-width: 600px) and (max-width: 599px) {
  .interview-new__wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.interview-new__item {
  padding: 60px 25px 20px 0;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.interview-new__item:not(:first-child) {
  margin-top: 10px;
}

@media all and (max-width: 850px) {
  .interview-new__item {
    padding: 30px 25px 20px 0;
  }
}

@media all and (max-width: 599px) {
  .interview-new__item {
    flex-direction: column-reverse;
    padding: 0;
  }
  .interview-new__item:not(:first-child) {
    margin-top: 20px;
  }
  .interview-new__item:nth-child(1) {
    background-color: #f1f6f6;
  }
  .interview-new__item:nth-child(2) {
    background-color: #cde0e7;
  }
  .interview-new__item:nth-child(3) {
    background-color: #f4f7f8;
  }
}

@media all and (min-width: 600px) {
  .interview-new .interview-new__item:nth-child(2) {
    padding: 50px 0 20px 50px;
    flex-direction: row-reverse;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .interview-new .interview-new__item:nth-child(2) {
    padding-left: 25px;
  }
}

.interview-new__bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 75% 50%;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.interview-new__item:nth-child(2) .interview-new__bg {
  background-position: 25% 50%;
}

@media all and (max-width: 599px) {
  .interview-new__bg {
    display: none;
  }
}

@media all and (max-width: 599px) {
  .interview-new__main {
    padding: 18px 30px 37px;
  }
}

.interview-new__info {
  padding-left: 132px;
}

@media all and (min-width: 600px) {
  .interview-new__item:nth-child(2) .interview-new__info {
    padding-left: 0;
    padding-right: 138px;
    align-items: flex-end;
  }
}

@media all and (max-width: 850px) {
  .interview-new__info {
    padding-left: 60px;
  }
  .interview-new__item:nth-child(2) .interview-new__info {
    padding-right: 60px;
  }
}

@media all and (max-width: 750px) {
  .interview-new__info {
    padding-left: 30px;
  }
  .interview-new__item:nth-child(2) .interview-new__info {
    padding-right: 30px;
  }
}

@media all and (max-width: 599px) {
  .interview-new__info {
    padding: 0;
  }
}

.interview-new .interview-info-work {
  font-size: 18px;
  line-height: 1.777;
  letter-spacing: 0;
}

@media all and (max-width: 850px) {
  .interview-new .interview-info-work {
    font-size: 16px;
  }
}

@media all and (max-width: 750px) {
  .interview-new .interview-info-work {
    font-size: 14px;
  }
}

@media all and (max-width: 599px) {
  .interview-new .interview-info-work {
    line-height: 2.285;
  }
}

.interview-new .interview-info-name,
.interview-new .interview-info-age {
  display: inline-block;
  font-size: 30px;
  line-height: 1.133;
  letter-spacing: 0.08em;
}

@media all and (max-width: 850px) {
  .interview-new .interview-info-name,
  .interview-new .interview-info-age {
    font-size: 26px;
  }
}

@media all and (max-width: 750px) {
  .interview-new .interview-info-name,
  .interview-new .interview-info-age {
    font-size: 22px;
  }
}

@media all and (max-width: 599px) {
  .interview-new .interview-info-name,
  .interview-new .interview-info-age {
    font-size: 20px;
    line-height: .95;
  }
}

.interview-new .interview-info-age {
  margin-left: 32px;
}

@media all and (max-width: 599px) {
  .interview-new .interview-info-age {
    margin-left: 20px;
  }
}

.interview-new__link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  background-color: #fff;
  margin-top: 25px;
}

@media (hover: hover) and (pointer: fine) {
  .interview-new__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.interview-new__link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.interview-new__link::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .interview-new__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.interview-new__link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.interview-new__link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.interview-new__link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.interview-new__link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

@media all and (max-width: 599px) {
  .interview-new__link {
    max-width: 268px;
  }
}

.interview-new__link-wrap {
  text-align: center;
}

.interview-new__product {
  background-color: #fff;
  margin-top: 60px;
  padding: 14px 19px 14px 132px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media all and (min-width: 600px) {
  .interview-new__item:nth-child(2) .interview-new__product {
    padding: 14px 132px 14px 19px;
    flex-direction: row-reverse;
  }
}

@media all and (max-width: 850px) {
  .interview-new__product {
    margin-top: 30px;
    padding-left: 60px;
  }
  .interview-new__item:nth-child(2) .interview-new__product {
    padding-left: 14px;
    padding-right: 60px;
  }
}

@media all and (max-width: 750px) {
  .interview-new__product {
    padding-left: 30px;
  }
  .interview-new__item:nth-child(2) .interview-new__product {
    padding-left: 14px;
    padding-right: 30px;
  }
}

@media all and (max-width: 599px) {
  .interview-new__product {
    margin-top: 23px;
    margin-left: calc((50vw - 50%) * -1);
    padding: 10px 10px 10px 30px;
  }
  .interview-new__item:nth-child(2) .interview-new__product {
    padding: 10px 10px 10px 30px;
  }
}

.interview-new__product-text {
  font-size: 13px;
  line-height: 1.846;
  letter-spacing: 0;
  white-space: nowrap;
}

@media all and (max-width: 599px) {
  .interview-new__product-text {
    font-size: 12px;
  }
}

.interview-new__product-box {
  display: flex;
  margin-left: 85px;
}

@media all and (min-width: 600px) {
  .interview-new__item:nth-child(2) .interview-new__product-box {
    margin-left: 0;
    margin-right: 85px;
  }
}

@media all and (max-width: 850px) {
  .interview-new__product-box {
    margin-left: 40px;
  }
  .interview-new__item:nth-child(2) .interview-new__product-box {
    margin-left: 0;
    margin-right: 40px;
  }
}

@media all and (max-width: 599px) {
  .interview-new__product-box {
    margin: 0;
  }
  .interview-new__item:nth-child(2) .interview-new__product-box {
    margin: 0;
  }
}

.interview-new__product-box figure:not(:first-child) {
  margin-left: 10px;
}

@media all and (max-width: 599px) {
  .interview-new__product-box figure:not(:first-child) {
    margin-left: 7px;
  }
}

.interview-new__product-img {
  width: 80px;
}

@media all and (max-width: 599px) {
  .interview-new__product-img {
    width: 58px;
  }
}

.interview-new__copy {
  position: relative;
  min-width: 248px;
  width: 248px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media all and (min-width: 600px) {
  .interview-new__item:nth-child(2) .interview-new__copy {
    align-items: flex-start;
  }
}

@media all and (max-width: 599px) {
  .interview-new__copy {
    display: block;
    width: 100%;
  }
}

@media all and (min-width: 600px) {
  .interview-new__copy-img {
    display: none;
  }
}

.interview-new__copy-img img {
  aspect-ratio: 39 / 20;
  object-fit: cover;
  object-position: top right;
}

.interview-new__item:nth-child(2) .interview-new__copy-img img {
  object-position: top left;
}

.interview-new__text {
  font-size: 30px;
  line-height: 1.666;
  letter-spacing: 0.08em;
  display: none;
}

@media all and (min-width: 600px) {
  .interview-new__text {
    padding-left: 20px;
  }
  .interview-new__item:nth-child(2) .interview-new__text {
    padding-left: 0;
    padding-right: 20px;
  }
}

@media all and (max-width: 850px) {
  .interview-new__text {
    font-size: 28px;
  }
}

@media all and (max-width: 750px) {
  .interview-new__text {
    font-size: 24px;
  }
}

@media all and (max-width: 599px) {
  .interview-new__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.08em;
    padding: 0;
    width: 155px;
    position: absolute;
    top: 16%;
    left: 10%;
  }
  .interview-new__item:nth-child(2) .interview-new__text {
    padding-right: 0;
  }
}

/*
  ----------------
  お客様インタビュー
  ----------------
*/
.interview-main__wrap {
  padding: 88px 80px 109px;
}

@media all and (min-width: 600px) {
  .interview-main__wrap {
    max-width: 1366px;
    margin: 0 auto;
  }
}

@media all and (max-width: 950px) {
  .interview-main__wrap {
    padding: 44px 40px 50px;
  }
}

@media all and (max-width: 599px) {
  .interview-main__wrap {
    padding: 43px 30px 0px;
  }
}

.interview-main__list {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}

@media all and (max-width: 950px) {
  .interview-main__list {
    gap: 30px;
  }
}

@media all and (max-width: 599px) {
  .interview-main__list {
    margin: 0 auto;
    max-width: 400px;
    gap: 20px;
  }
}

.interview-main__item {
  background-color: #fafafa;
  flex-basis: calc((100% - 120px) / 3);
}

@media all and (max-width: 950px) {
  .interview-main__item {
    flex-basis: calc((100% - 60px) / 3);
  }
}

@media all and (max-width: 800px) {
  .interview-main__item {
    flex-basis: calc((100% - 30px) / 2);
  }
}

@media all and (max-width: 599px) {
  .interview-main__item {
    flex-basis: 100%;
  }
}

.interview-main__view {
  position: relative;
}

@media all and (max-width: 599px) {
  .interview-main__view {
    max-width: 180px;
    width: 54%;
  }
}

.interview-main__img {
  aspect-ratio: 18 / 11;
  object-fit: cover;
  object-position: top right;
}

@media all and (max-width: 599px) {
  .interview-main__img {
    aspect-ratio: 75 / 67;
    object-position: 80% 50%;
  }
}

.interview-main__copy {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .interview-main__copy {
    text-align: right;
    width: 145px;
    position: absolute;
    bottom: 15px;
    right: 13px;
    display: none;
  }
}

@media all and (max-width: 599px) {
  .interview-main__copy {
    font-size: 13px;
    letter-spacing: 0.03em;
    margin-top: 15px;
  }
}

@media all and (max-width: 599px) {
  .interview-main__header {
    display: flex;
  }
}

.interview-main__body {
  padding: 0 20px 25px;
}

.interview-main__info {
  margin-top: 26px;
  padding: 0 20px;
}

@media all and (min-width: 600px) {
  .interview-main__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media all and (max-width: 599px) {
  .interview-main__info {
    line-height: 0;
    margin-top: 0;
    padding: 13px 15px 0;
  }
}

.interview-main .interview-info-work {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.923;
  letter-spacing: 0.03em;
  text-align: center;
}

@media all and (min-width: 600px) {
  .interview-main .interview-info-work {
    flex-basis: 100%;
  }
}

@media all and (max-width: 599px) {
  .interview-main .interview-info-work {
    font-size: 12px;
    text-align: left;
  }
}

.interview-main .interview-info-name,
.interview-main .interview-info-age {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  line-height: 1.18;
  letter-spacing: 0.03em;
  text-align: center;
}

@media all and (max-width: 599px) {
  .interview-main .interview-info-name,
  .interview-main .interview-info-age {
    font-size: 14px;
  }
}

@media all and (min-width: 600px) {
  .interview-main .interview-info-age {
    margin-left: 15px;
  }
}

.interview-main__product {
  background-color: #fff;
  margin-top: 31px;
  padding: 5px 15px 15px;
}

@media all and (max-width: 599px) {
  .interview-main__product {
    margin-top: 21px;
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.interview-main__product-text {
  font-size: 13px;
  line-height: 1.846;
  letter-spacing: 0;
  text-align: center;
}

@media all and (max-width: 599px) {
  .interview-main__product-text {
    font-size: 12px;
    line-height: 1.416;
  }
}

.interview-main__product-box {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

@media all and (max-width: 599px) {
  .interview-main__product-box {
    margin-top: 0;
    gap: 7px;
  }
}

@media all and (max-width: 599px) {
  .interview-main__product-img {
    width: 58px;
  }
}

.interview-main__link-wrap {
  margin-top: 19px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .interview-main__link-wrap {
    margin-top: 13px;
  }
}

.interview-main__link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
}

@media (hover: hover) and (pointer: fine) {
  .interview-main__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.interview-main__link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.interview-main__link::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .interview-main__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.interview-main__link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.interview-main__link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.interview-main__link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.interview-main__link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

@media all and (max-width: 599px) {
  .interview-main__link {
    max-width: 268px;
  }
}

/*
  ----------------
  お客さまインタビュー詳細 | 記事ページ
  ----------------
*/
.news-archive-voice {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding: 0;
}

.news-archive-voice #page-title,
.news-archive-voice .news-entry-icon,
.news-archive-voice .news-entry-header,
.news-archive-voice .news-images,
.news-archive-voice .trailer-link {
  display: none;
}

@media all and (min-width: 600px) {
  .news-archive-voice {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice {
    padding: 0;
  }
}

.news-archive-voice .article-block + .article-block {
  margin-top: 80px;
}

@media all and (max-width: 599px) {
  .news-archive-voice .article-block + .article-block {
    margin-top: 40px;
  }
}

.news-archive-voice .voice-interview-lead {
  position: relative;
}

@media all and (max-width: 599px) {
  .news-archive-voice .voice-interview-lead img {
    aspect-ratio: 39 / 20;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.news-archive-voice .voice-interview-lead div {
  position: absolute;
  top: 48%;
  left: 17.7%;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .news-archive-voice .voice-interview-lead div {
    top: 31%;
    left: 10%;
  }
}

.news-archive-voice .voice-interview-lead p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.777;
  letter-spacing: 0;
}

.news-archive-voice .voice-interview-lead h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.333;
  margin-top: 5px;
}

@media all and (max-width: 599px) {
  .news-archive-voice .voice-interview-lead h2 {
    font-size: 20px;
    font-family: YuMincho;
    line-height: 1.5;
    letter-spacing: 0.08em;
    margin: 0;
  }
}

.news-archive-voice .interviwe-body {
  padding: 74px 80px 90px;
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body {
    padding: 74px 40px 90px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body {
    padding: 0 30px 40px;
  }
}

.news-archive-voice .interviwe-body h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.666;
  letter-spacing: 0;
}

.news-archive-voice .interviwe-body h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 0;
  text-align: center;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.222;
    letter-spacing: 0.08em;
  }
}

.news-archive-voice .interviwe-body h4 {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body h4 {
    font-size: 20px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.571;
    letter-spacing: 0;
  }
}

.news-archive-voice .interviwe-body p {
  font-weight: 400;
  line-height: 2.9;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body p {
    line-height: 2.142;
  }
}

.news-archive-voice .interviwe-body h2 + p {
  margin-top: 40px;
}

.news-archive-voice .interviwe-body p + p {
  margin-top: 40px;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body p + p {
    margin-top: 30px;
  }
}

.news-archive-voice .article-block {
  display: flex;
  align-items: flex-start;
  gap: 0 9%;
}

@media all and (max-width: 900px) {
  .news-archive-voice .article-block {
    gap: 0 6%;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .article-block {
    flex-direction: column;
  }
}

.news-archive-voice .article-block.message, .news-archive-voice .article-block.favorite {
  display: block;
}

.news-archive-voice .article-block img {
  width: 32%;
}

@media all and (min-width: 600px) {
  .news-archive-voice .article-block img {
    max-width: 380px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .article-block img {
    width: 100%;
  }
}

.news-archive-voice .interviwe-body .lead {
  margin-top: -21%;
  min-height: 360px;
  display: flex;
  align-items: flex-end;
}

@media all and (min-width: 600px) {
  .news-archive-voice .interviwe-body .lead {
    gap: 0;
  }
}

@media all and (max-width: 750px) {
  .news-archive-voice .interviwe-body .lead {
    margin-top: -15%;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .lead {
    align-items: flex-start;
    margin-top: -28%;
  }
}

.news-archive-voice .interviwe-body .lead span {
  letter-spacing: 0;
  margin-top: 15px;
}

.news-archive-voice .interviwe-body .lead h3 {
  font-size: 20px;
  line-height: 1.15;
  letter-spacing: 0.08em;
}

.news-archive-voice .interviwe-body .lead img {
  filter: drop-shadow(0px 33px 76px rgba(0, 0, 0, 0.18));
  margin-left: -10px;
  width: 18%;
}

@media all and (min-width: 600px) {
  .news-archive-voice .interviwe-body .lead img {
    max-width: 216px;
  }
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body .lead img {
    min-width: 140px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .lead img {
    min-width: 96px;
    margin-left: -15px;
  }
}

.news-archive-voice .interviwe-body .lead p {
  line-height: 2.9;
  letter-spacing: 0;
  padding-left: 70px;
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body .lead p {
    padding-left: 35px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .lead p {
    line-height: 2.142;
    margin-top: 37px;
    padding-left: 0;
  }
}

.news-archive-voice .profile {
  border: 1px solid #ddd;
  display: block;
  padding: 40px;
}

@media all and (max-width: 599px) {
  .news-archive-voice .profile {
    margin-top: 34px;
    padding: 20px;
  }
}

.news-archive-voice .profile span {
  display: block;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .news-archive-voice .profile span {
    font-size: 16px;
  }
}

.news-archive-voice .profile h3 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.333;
  margin-top: 5px;
  text-align: left;
}

@media all and (max-width: 599px) {
  .news-archive-voice .profile h3 {
    font-size: 20px;
  }
}

.news-archive-voice .profile p {
  line-height: 2.9;
  letter-spacing: 0;
  margin-top: 34px;
}

@media all and (max-width: 599px) {
  .news-archive-voice .profile p {
    line-height: 2.142;
  }
}

.news-archive-voice .interviwe-body .title,
.news-archive-voice .interviwe-body .left-box,
.news-archive-voice .interviwe-body .right-box,
.news-archive-voice .interviwe-body .center-box {
  display: flex;
  align-items: flex-start;
  gap: 0 8%;
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body .title,
  .news-archive-voice .interviwe-body .left-box,
  .news-archive-voice .interviwe-body .right-box,
  .news-archive-voice .interviwe-body .center-box {
    gap: 0 6%;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .title,
  .news-archive-voice .interviwe-body .left-box,
  .news-archive-voice .interviwe-body .right-box,
  .news-archive-voice .interviwe-body .center-box {
    margin-top: 34px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .title h2,
  .news-archive-voice .interviwe-body .left-box h2,
  .news-archive-voice .interviwe-body .right-box h2,
  .news-archive-voice .interviwe-body .center-box h2 {
    font-size: 20px;
    margin-top: 34px;
  }
}

.news-archive-voice .interviwe-body .title p,
.news-archive-voice .interviwe-body .left-box p,
.news-archive-voice .interviwe-body .right-box p,
.news-archive-voice .interviwe-body .center-box p {
  margin-top: 40px;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .title p,
  .news-archive-voice .interviwe-body .left-box p,
  .news-archive-voice .interviwe-body .right-box p,
  .news-archive-voice .interviwe-body .center-box p {
    margin-top: 34px;
  }
}

.news-archive-voice .interviwe-body .title img,
.news-archive-voice .interviwe-body .left-box img,
.news-archive-voice .interviwe-body .right-box img,
.news-archive-voice .interviwe-body .center-box img {
  max-width: 556px;
  width: 47%;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .title img,
  .news-archive-voice .interviwe-body .left-box img,
  .news-archive-voice .interviwe-body .right-box img,
  .news-archive-voice .interviwe-body .center-box img {
    width: 100%;
  }
}

@media all and (min-width: 600px) {
  .news-archive-voice .interviwe-body .right-box {
    flex-direction: row-reverse;
  }
}

.news-archive-voice .interviwe-body .center-box {
  display: block;
}

.news-archive-voice .interviwe-body .center-box h2 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .center-box h2 {
    margin-top: 34px;
  }
}

.news-archive-voice .interviwe-body .center-box img {
  max-width: none;
  width: 100%;
}

.news-archive-voice .interviwe-body .right-set {
  flex-direction: row-reverse;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .right-set {
    flex-direction: column;
    margin-top: 47px;
  }
  .news-archive-voice .interviwe-body .right-set img + div {
    margin-top: 40px;
  }
}

.news-archive-voice .interviwe-body .message {
  background-color: #fafafa;
  margin-top: 90px;
  padding: 75px 84px 65px;
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body .message {
    padding: 35px 40px 35px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .message {
    margin-top: 70px;
    padding: 42px 30px 52px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .message h3 {
    letter-spacing: 0;
  }
}

.news-archive-voice .interviwe-body .message p {
  margin-top: 45px;
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body .message p {
    margin-top: 20px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .message p {
    margin-top: 18px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .favorite {
    margin-top: 70px;
  }
}

.news-archive-voice .interviwe-body .favorite ul {
  margin-top: 55px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 26px;
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .favorite ul {
    display: block;
    margin: 20px auto 0;
    max-width: 400px;
  }
}

.news-archive-voice .interviwe-body .favorite li {
  background-size: cover;
  background-position: right 50%;
  height: 220px;
  padding: 30px 47px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: calc((100% - 26px) / 2);
}

@media all and (max-width: 900px) {
  .news-archive-voice .interviwe-body .favorite li {
    height: 185px;
    padding: 15px 25px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .favorite li {
    height: 125px;
  }
  .news-archive-voice .interviwe-body .favorite li:not(:first-child) {
    margin-top: 20px;
  }
}

.news-archive-voice .interviwe-body .favorite a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  margin-top: 15px;
}

@media (hover: hover) and (pointer: fine) {
  .news-archive-voice .interviwe-body .favorite a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media all and (max-width: 599px) {
  .news-archive-voice .interviwe-body .favorite a {
    font-size: 12px;
    margin-top: 10px;
    padding: 3px 10px;
    max-width: 150px;
    width: 44%;
  }
}

/*
  ----------------
  お客様インタビュー詳細 | その他のインタビュー記事
  ----------------
*/
.interview-article-slide__wrap {
  padding: 48px 80px 130px;
}

@media all and (min-width: 600px) {
  .interview-article-slide__wrap {
    max-width: 1366px;
    margin: 0 auto;
  }
}

@media all and (max-width: 800px) {
  .interview-article-slide__wrap {
    padding: 44px 40px 50px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide__wrap {
    margin: 0 auto;
    max-width: 463px;
    padding: 43px 30px 130px;
  }
}

.interview-article-slide__title {
  font-size: 30px;
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 0;
  text-align: center;
}

@media all and (max-width: 599px) {
  .interview-article-slide__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.222;
    letter-spacing: 0.08em;
  }
}

.interview-article-slide__main {
  position: relative;
}

.interview-article-slide__content {
  margin-top: 95px;
  padding-bottom: 115px;
  position: relative;
  overflow: hidden;
}

@media all and (max-width: 599px) {
  .interview-article-slide__content {
    margin-top: 30px;
    padding-bottom: 82px;
  }
}

.interview-article-slide__link {
  display: inline-block;
}

.interview-article-slide__view {
  position: relative;
}

@media all and (max-width: 599px) {
  .interview-article-slide__view {
    max-width: 180px;
    width: 54%;
  }
}

.interview-article-slide__img {
  aspect-ratio: 18 / 11;
  object-fit: cover;
  object-position: top right;
}

@media all and (max-width: 599px) {
  .interview-article-slide__img {
    aspect-ratio: 75 / 67;
    object-position: 80% 50%;
  }
}

.interview-article-slide__copy {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .interview-article-slide__copy {
    text-align: right;
    width: 145px;
    position: absolute;
    bottom: 15px;
    right: 13px;
    display: none;
  }
}

@media all and (max-width: 900px) {
  .interview-article-slide__copy {
    font-size: 18px;
  }
}

@media all and (max-width: 750px) {
  .interview-article-slide__copy {
    font-size: 16px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide__copy {
    font-size: 13px;
    letter-spacing: 0.03em;
    margin-top: 15px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide__header {
    display: flex;
  }
}

.interview-article-slide__body {
  padding: 0 20px 25px;
}

.interview-article-slide__info {
  margin-top: 26px;
  padding: 0 20px;
}

@media all and (min-width: 600px) {
  .interview-article-slide__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide__info {
    line-height: 0;
    margin-top: 0;
    padding: 13px 15px 0;
  }
}

.interview-article-slide .interview-info-work {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.923;
  letter-spacing: 0.03em;
  text-align: center;
}

@media all and (min-width: 600px) {
  .interview-article-slide .interview-info-work {
    flex-basis: 100%;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide .interview-info-work {
    font-size: 12px;
    text-align: left;
  }
}

.interview-article-slide .interview-info-name,
.interview-article-slide .interview-info-age {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  line-height: 1.18;
  letter-spacing: 0.03em;
  text-align: center;
}

@media all and (max-width: 900px) {
  .interview-article-slide .interview-info-name,
  .interview-article-slide .interview-info-age {
    font-size: 16px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide .interview-info-name,
  .interview-article-slide .interview-info-age {
    font-size: 14px;
  }
}

@media all and (min-width: 600px) {
  .interview-article-slide .interview-info-age {
    margin-left: 15px;
  }
}

.interview-article-slide__read-wrap {
  margin-top: 28px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .interview-article-slide__read-wrap {
    margin-top: 13px;
  }
}

.interview-article-slide__read {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
}

@media (hover: hover) and (pointer: fine) {
  .interview-article-slide__read:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.interview-article-slide__read::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.interview-article-slide__read::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .interview-article-slide__read:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.interview-article-slide__read::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.interview-article-slide__read:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.interview-article-slide__read:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.interview-article-slide__read:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

@media all and (max-width: 599px) {
  .interview-article-slide__read {
    max-width: 268px;
  }
}

.interview-article-slide .swiper-pagination-bullet {
  background: #dbdbdb;
  color: #000;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  opacity: 1;
  height: 30px;
  width: 30px;
}

.interview-article-slide .swiper-pagination-bullet-active {
  background: #171717;
  color: #fff;
}

.interview-article-slide .swiper-button-next,
.interview-article-slide .swiper-button-prev {
  cursor: pointer;
  height: 57px;
  top: 36.5%;
  width: 22px;
}

@media (hover: hover) and (pointer: fine) {
  .interview-article-slide .swiper-button-next,
  .interview-article-slide .swiper-button-prev {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .interview-article-slide .swiper-button-next:hover,
  .interview-article-slide .swiper-button-prev:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.interview-article-slide .swiper-button-next::before, .interview-article-slide .swiper-button-next::after,
.interview-article-slide .swiper-button-prev::before,
.interview-article-slide .swiper-button-prev::after {
  backface-visibility: hidden;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .interview-article-slide .swiper-button-next,
  .interview-article-slide .swiper-button-prev {
    height: 45px;
    width: 15px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide .swiper-button-next,
  .interview-article-slide .swiper-button-prev {
    height: 26px;
    width: 10px;
  }
}

.interview-article-slide .swiper-button-next::after,
.interview-article-slide .swiper-button-prev::after {
  background: url(/assets/img/top/icon_arrow_pickup_prev.svg) 50%/contain no-repeat;
  content: '';
  display: block;
  height: 57px;
  width: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .interview-article-slide .swiper-button-next::after,
  .interview-article-slide .swiper-button-prev::after {
    height: 45px;
    width: 15px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide .swiper-button-next::after,
  .interview-article-slide .swiper-button-prev::after {
    height: 26px;
    width: 10px;
  }
}

.interview-article-slide .swiper-button-prev {
  left: -45px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .interview-article-slide .swiper-button-prev {
    left: -30px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide .swiper-button-prev {
    left: -20px;
  }
}

.interview-article-slide .swiper-button-next {
  right: -45px;
}

@media all and (max-width: 800px) and (min-width: 600px) {
  .interview-article-slide .swiper-button-next {
    right: -30px;
  }
}

@media all and (max-width: 599px) {
  .interview-article-slide .swiper-button-next {
    right: -20px;
  }
}

.interview-article-slide .swiper-button-next::after {
  background-image: url(/assets/img/top/icon_arrow_pickup_next.svg);
}

/********** Signin | サインイン **********/
/*
  ----------------
  .form-normal-signin | サインイン
  ----------------
*/
.form-order-checkout-forms h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .form-order-checkout-forms h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

.form-order-checkout-forms h2 {
  display: none;
}

@media all and (max-width: 599px) {
  .form-order-checkout-forms .form-note {
    padding: 0;
    text-align-last: center;
  }
}

@media all and (min-width: 600px) {
  .form-order-checkout-forms .form-body {
    padding-bottom: 70px;
  }
}

@media all and (max-width: 599px) {
  .form-order-checkout-forms .form-body {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.form-order-checkout-forms .form-body .signout {
  width: 100%;
}

.form-order-checkout-forms .form-body .signout > a {
  margin-top: 40px;
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  background-color: #fff;
  display: inline-block;
  max-width: none;
  width: auto;
}

@media (hover: hover) and (pointer: fine) {
  .form-order-checkout-forms .form-body .signout > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.form-order-checkout-forms .form-body .formrow-submit {
  margin-top: 40px;
}

.form-order-checkout-forms .reminder {
  margin-top: 40px;
  text-align: center;
}

.form-order-checkout-forms .reminder > a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  display: inline-block;
  max-width: none;
  width: auto;
}

@media (hover: hover) and (pointer: fine) {
  .form-order-checkout-forms .reminder > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.form-order-checkout-forms #signout-at-signin {
  margin-top: 40px;
  text-align: center;
}

.form-order-checkout-forms #signout-at-signin > a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  display: inline-block;
  max-width: none;
  width: auto;
}

@media (hover: hover) and (pointer: fine) {
  .form-order-checkout-forms #signout-at-signin > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.signin-signup .go-signup-to-order {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  display: block;
  margin: 60px auto 0;
  max-width: 300px;
}

@media (hover: hover) and (pointer: fine) {
  .signin-signup .go-signup-to-order:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.signin-signup .note {
  margin-top: 20px;
  text-align: center;
}

/********** Signup | サインアップ **********/
/*
  ----------------
  .signup-form | サインアップ
  ----------------
*/
.signin-form h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .signin-form h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

.signin-form h2 {
  font-weight: normal;
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .signin-form h2 {
    font-size: 20px;
  }
}

.signin-form h3 {
  margin-top: 60px;
}

@media all and (max-width: 599px) {
  .signin-form h3 {
    margin-top: 40px;
  }
}

.signin-form .form-note {
  margin-top: 20px;
}

@media all and (max-width: 599px) {
  .signin-form .form-note {
    padding: 0;
    text-align-last: center;
  }
}

@media all and (min-width: 600px) {
  .signin-form .form-body {
    padding-bottom: 70px;
  }
}

@media all and (max-width: 599px) {
  .signin-form .form-body {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.signin-form .form-body .formrow-submit {
  margin-top: 40px;
}

.signin-form .reminder {
  margin-top: 40px;
  text-align: center;
}

.signin-form .reminder > a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  max-width: 300px;
}

@media (hover: hover) and (pointer: fine) {
  .signin-form .reminder > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.signin-form #signout-at-signin {
  margin-top: 20px;
  text-align: center;
}

/********** Cart | カート **********/
/*
  ----------------
  .cart-view | ショッピングカート
  ----------------
*/
.cart-view {
  padding: 110px 80px 100px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 599px) {
  .cart-view {
    padding: 60px 30px 80px;
  }
}

.cart-view .goods-text-heading .item-name .code,
.cart-view .goods-text-heading .manufacturer .makers {
  display: none;
}

.cart-view .goods-text-heading .item-tag span {
  background-color: #9f9f9f;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
  padding: 0px 14px 1px 15px;
}

@media all and (max-width: 599px) {
  .cart-view .goods-text-heading .item-tag span {
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px;
  }
}

.cart-view h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .cart-view h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

.cart-view .cart-contents-wrapper {
  padding-top: 60px;
}

@media all and (max-width: 850px) {
  .cart-view .cart-contents-wrapper {
    padding-top: 25px;
  }
}

.cart-view h3 {
  font-weight: 400;
  text-align: center;
}

@media all and (max-width: 850px) {
  .cart-view h3 {
    font-size: 18px;
  }
}

.cart-view h3.itemdetails-caption {
  border-bottom: 1px solid #171717;
  padding-bottom: 10px;
  text-align: left;
}

@media all and (max-width: 850px) {
  .cart-view h3.itemdetails-caption {
    margin-top: 25px;
    padding-bottom: 5px;
  }
}

.cart-view .alert-cart-empty {
  border: 1px solid #171717;
  border-radius: 8px;
  margin: 40px auto 0;
  max-width: 650px;
  padding: 15px;
  text-align: center;
  width: 100%;
}

.cart-view .itemdetails-header {
  margin-top: 20px;
}

.cart-view .itemdetails-cart {
  margin-top: 50px;
}

@media all and (max-width: 850px) {
  .cart-view .itemdetails-cart {
    margin-top: 0;
  }
}

.cart-view .cart-msgs {
  color: #ba0000;
}

.cart-view .remove-cartitem,
.cart-view .save-cartitem {
  text-decoration: underline;
}

@media all and (max-width: 599px) {
  .cart-view .remove-cartitem,
  .cart-view .save-cartitem {
    margin-top: 20px;
  }
}

.cart-view .cart-checkout {
  text-align: center;
  margin-top: 10px;
}

.cart-view .cart-checkout .btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-image: none;
  border: none;
  color: #fff;
  padding: 15px 10px 15px 12px;
  margin-top: 60px;
}

@media (hover: hover) and (pointer: fine) {
  .cart-view .cart-checkout .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .cart-view .cart-checkout .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.cart-view .cart-checkout .btn::after {
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  z-index: -2;
}

.cart-view .cart-checkout .btn:hover {
  background-image: none;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

.cart-view .cart-checkout .btn:hover::before {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.cart-view .cart-checkout .btn::before, .cart-view .cart-checkout .btn::after {
  content: "";
  display: block;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cart-view .cart-checkout .btn::before {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  z-index: -1;
}

.cart-view .cart-checkout .btn:disabled {
  cursor: default;
  opacity: .5;
  animation: none;
}

.cart-view .cart-checkout .btn:disabled:hover {
  transition: none;
}

.cart-view .cart-checkout .btn:disabled::before {
  transition: none;
}

.cart-view .cart-checkout .btn:disabled::after {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  transition: none;
}

.cart-view .cart-checkout .btn:disabled::after:hover {
  opacity: none;
  transition: none;
}

.cart-view .error {
  margin-top: 10px;
}

.cart-view .error .message {
  color: #ba0000;
}

.cart-view .cart-clear-all {
  margin-top: 30px;
  text-align: center;
}

@media all and (min-width: 600px) {
  .cart-view .rp-conf {
    margin-left: 130px;
  }
}

.cart-view .rp-opts {
  background: #f2f2f2;
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

@media all and (max-width: 599px) {
  .cart-view .rp-opts {
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.cart-view .rp-shipping-day {
  margin-left: 20px;
  flex: 1;
}

.cart-view .rp-shipping-day select {
  color: #171717;
}

@media (hover: hover) and (pointer: fine) {
  .cart-view .rp-shipping-day select {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .cart-view .rp-shipping-day select:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.cart-view .rp-shipping-day select::before, .cart-view .rp-shipping-day select::after {
  backface-visibility: hidden;
}

.cart-view .rp-batch h4 {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
}

@media all and (min-width: 600px) {
  .cart-view .rp-batch .rp-conf-wrapper {
    width: 50%;
  }
}

.cart-view .rp-batch .rp-conf-wrapper .rp-conf {
  margin-left: 0;
}

/********** Account | アカウント **********/
/*
  ----------------
  Account pages | Common
  ----------------
*/
.order-inquiry,
.review-list,
.write-review,
.account-overview,
.edit-account-properties,
.entry-reminder-update,
.form-close-account,
.form-form-CLOSE-ACCOUNT,
.form-consignee,
.form-deliveryservice,
.form-edit-address,
.form-normal-signin,
.form-orderer,
.form-order-checkout-forms,
.form-update-password-by-token,
.form-payment,
.form-points,
.form-sender,
.fovorites-items,
.manage-addressbook,
.manage-paymentmethod,
.form-register-exauth,
.order-journal-history,
.order-process-main-complete,
.order-process-main-payment-status,
.points-history,
.view-order,
.view-orders,
.view-inquiries,
.your-reviews,
.yourcoupons,
.rp-subscriptions,
.rp-future-entries,
.rp-entry,
.form-rps-consignee,
.form-regular-purchase-payment,
.signin-form {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
}

@media all and (min-width: 600px) {
  .order-inquiry,
  .review-list,
  .write-review,
  .account-overview,
  .edit-account-properties,
  .entry-reminder-update,
  .form-close-account,
  .form-form-CLOSE-ACCOUNT,
  .form-consignee,
  .form-deliveryservice,
  .form-edit-address,
  .form-normal-signin,
  .form-orderer,
  .form-order-checkout-forms,
  .form-update-password-by-token,
  .form-payment,
  .form-points,
  .form-sender,
  .fovorites-items,
  .manage-addressbook,
  .manage-paymentmethod,
  .form-register-exauth,
  .order-journal-history,
  .order-process-main-complete,
  .order-process-main-payment-status,
  .points-history,
  .view-order,
  .view-orders,
  .view-inquiries,
  .your-reviews,
  .yourcoupons,
  .rp-subscriptions,
  .rp-future-entries,
  .rp-entry,
  .form-rps-consignee,
  .form-regular-purchase-payment,
  .signin-form {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .order-inquiry,
  .review-list,
  .write-review,
  .account-overview,
  .edit-account-properties,
  .entry-reminder-update,
  .form-close-account,
  .form-form-CLOSE-ACCOUNT,
  .form-consignee,
  .form-deliveryservice,
  .form-edit-address,
  .form-normal-signin,
  .form-orderer,
  .form-order-checkout-forms,
  .form-update-password-by-token,
  .form-payment,
  .form-points,
  .form-sender,
  .fovorites-items,
  .manage-addressbook,
  .manage-paymentmethod,
  .form-register-exauth,
  .order-journal-history,
  .order-process-main-complete,
  .order-process-main-payment-status,
  .points-history,
  .view-order,
  .view-orders,
  .view-inquiries,
  .your-reviews,
  .yourcoupons,
  .rp-subscriptions,
  .rp-future-entries,
  .rp-entry,
  .form-rps-consignee,
  .form-regular-purchase-payment,
  .signin-form {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/*
  ----------------
  .account-overview | アカウントトップ
  ----------------
*/
.account-overview .account-menu-list {
  margin-top: 20px;
}

.account-overview .account-menu-list li:not(:first-child) {
  margin-top: 20px;
}

.account-overview .account-menu-list.account-close {
  display: none;
}

.account-overview .account-menu-list a {
  padding-left: 20px;
  position: relative;
}

.account-overview .account-menu-list a::before {
  content: '';
  display: inline-block;
  background: #171717;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 50%;
  left: 0;
}

@media all and (min-width: 600px) {
  .account-overview .account-menu-list a::before {
    transform: translateY(-50%);
  }
}

@media all and (max-width: 599px) {
  .account-overview .account-menu-list a::before {
    width: 16px;
    height: 16px;
    top: 7px;
  }
}

.account-overview .account-menu-list a::after {
  content: '';
  display: inline-block;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 3px;
}

@media all and (min-width: 600px) {
  .account-overview .account-menu-list a::after {
    transform: rotate(45deg) translateY(-50%);
  }
}

@media all and (max-width: 599px) {
  .account-overview .account-menu-list a::after {
    top: 13px;
    left: 4px;
    transform: rotate(45deg);
  }
}

.account-overview .custompropkeys {
  display: none;
}

/*
  ----------------
  .view-order | 未発送の注文
  ----------------
*/
.view-orders {
  /*================================================
    .panel-heading | 受注番号〜注文ステータス
  ================================================*/
  /*================================================
    .panel-body | 出荷受付状況〜商品詳細(画像、金額など)
  ================================================*/
}

.view-orders .order-list {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .view-orders .order-list {
    margin-top: 60px;
  }
}

.view-orders .order-list li {
  border: 1px solid lightgray;
  border-bottom: none;
}

.view-orders .order-list li + li {
  margin-top: 40px;
}

.view-orders .order-list li .request-paymentmethod-reset {
  border-bottom: 1px solid lightgray;
  text-align: center;
}

.view-orders .order-list li .request-paymentmethod-reset > h3 {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  color: #171717;
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2.142;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.view-orders .order-list li .request-paymentmethod-reset .order-reset-paymentmethod-form {
  padding: 30px;
}

@media all and (max-width: 599px) {
  .view-orders .order-list li .request-paymentmethod-reset .order-reset-paymentmethod-form {
    padding: 30px 15px;
  }
}

.view-orders .order-list li .request-paymentmethod-reset .order-reset-paymentmethod-form .btn {
  max-width: 300px;
}

.view-orders .order-list li .request-paymentmethod-reset .order-reset-paymentmethod-form .description {
  margin-top: 20px;
}

.view-orders .order-list li:not(:first-child) {
  border-top: 1px solid lightgray;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-orders .order-list li:last-child .itemdetail-row {
    padding-bottom: 20px;
  }
}

@media all and (max-width: 599px) {
  .view-orders .order-list li:last-child .itemdetail-row {
    padding-bottom: 20px;
  }
}

.view-orders .panel-heading .order-code > div {
  display: flex;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-orders .panel-heading .order-code > div {
    justify-content: center;
  }
}

@media all and (max-width: 599px) {
  .view-orders .panel-heading .order-code > div {
    justify-content: center;
  }
}

.view-orders .panel-heading .order-code .order-code {
  border-bottom: none;
}

.view-orders .panel-heading .order-code .detail {
  margin-left: 10px;
}

.view-orders .panel-heading .order-code .detail a {
  text-decoration: underline;
}

@media all and (min-width: 600px) {
  .view-orders .panel-body .panel-heading {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-orders .panel-body .panel-heading {
    display: block;
  }
}

.view-orders .panel-body .shipment-code {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  text-align: center;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .view-orders .panel-body .shipment-code {
    flex: 0 0 260px;
    display: flex;
    justify-content: center;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-orders .panel-body .shipment-code {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .view-orders .panel-body .shipment-code {
    border-right: 0;
  }
}

.view-orders .panel-body .shipment-status {
  padding: 10px;
}

@media all and (min-width: 600px) {
  .view-orders .panel-body .shipment-status {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-orders .panel-body .shipment-status {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .view-orders .panel-body .shipment-status {
    text-align: center;
  }
}

.view-orders .panel-body .goods {
  flex-basis: 100%;
}

/*
  ---------------
  .view-order | 注文の詳細
  ---------------
*/
.view-order {
  /*================================================
    .shipment-header | 出荷番号〜お支払い方法
  ================================================*/
  /*==========================================
    .shipment-delivery-address | 購入者情報〜お届け先
  ==========================================*/
  /*==========================================
    .shipment-contents | お届けに関する情報〜出荷合計金額
  ==========================================*/
}

.view-order .wrapper {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .view-order .wrapper {
    margin-top: 60px;
  }
}

.view-order .order {
  padding-bottom: 60px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .order {
    padding-bottom: 30px;
  }
}

.view-order .order .request-paymentmethod-reset {
  border-bottom: 1px solid lightgray;
  text-align: center;
}

.view-order .order .request-paymentmethod-reset > h3 {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  color: #171717;
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2.142;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.view-order .order .request-paymentmethod-reset .order-reset-paymentmethod-form {
  padding: 30px;
}

@media all and (max-width: 599px) {
  .view-order .order .request-paymentmethod-reset .order-reset-paymentmethod-form {
    padding: 30px 15px;
  }
}

.view-order .order .request-paymentmethod-reset .order-reset-paymentmethod-form .btn {
  max-width: 300px;
}

.view-order .order .request-paymentmethod-reset .order-reset-paymentmethod-form .description {
  margin-top: 20px;
}

.view-order .panel-heading {
  border: 1px solid lightgray;
}

.view-order .panel-heading > div:last-child {
  border-bottom: none;
}

.view-order .panel-body {
  margin-top: 50px;
}

.view-order .shipment-valid {
  border: 1px solid lightgray;
}

.view-order .shipment-valid:not(:first-child) {
  margin-top: 50px;
}

.view-order .shipment-header .shipment-code,
.view-order .shipment-header .shipment-status,
.view-order .shipment-header .payments {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .view-order .shipment-header .shipment-code,
  .view-order .shipment-header .shipment-status,
  .view-order .shipment-header .payments {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-header .shipment-code,
  .view-order .shipment-header .shipment-status,
  .view-order .shipment-header .payments {
    display: block;
  }
}

.view-order .shipment-header .shipment-code > div,
.view-order .shipment-header .shipment-status > div,
.view-order .shipment-header .payments > div {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .view-order .shipment-header .shipment-code > div,
  .view-order .shipment-header .shipment-status > div,
  .view-order .shipment-header .payments > div {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-header .shipment-code > div,
  .view-order .shipment-header .shipment-status > div,
  .view-order .shipment-header .payments > div {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .view-order .shipment-header .shipment-code > div,
  .view-order .shipment-header .shipment-status > div,
  .view-order .shipment-header .payments > div {
    text-align: center;
  }
}

.view-order .shipment-header .shipment-code > div .link-detail,
.view-order .shipment-header .shipment-status > div .link-detail,
.view-order .shipment-header .payments > div .link-detail {
  text-decoration: underline;
}

.view-order .shipment-header .shipment-code > div .link-detail:hover,
.view-order .shipment-header .shipment-status > div .link-detail:hover,
.view-order .shipment-header .payments > div .link-detail:hover {
  text-decoration: none;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-header > div.code.shipment-code {
    border-bottom: 0;
  }
}

.view-order .shipment-delivery-address .person {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .view-order .shipment-delivery-address .person {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-delivery-address .person {
    display: block;
  }
}

.view-order .shipment-delivery-address .person-label {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-delivery-address .person-label {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .view-order .shipment-delivery-address .person-label {
    border-right: 0;
  }
}

.view-order .shipment-delivery-address .person-contents {
  padding: 10px;
}

@media all and (min-width: 600px) {
  .view-order .shipment-delivery-address .person-contents {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-delivery-address .person-contents {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .view-order .shipment-delivery-address .person-contents {
    text-align: center;
  }
}

.view-order .shipment-contents .delivery table {
  border-collapse: collapse;
  width: 100%;
}

.view-order .shipment-contents .delivery table caption {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  display: block;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.view-order .shipment-contents .delivery table tr {
  border-bottom: 1px solid lightgray;
  display: flex;
}

.view-order .shipment-contents .delivery table th,
.view-order .shipment-contents .delivery table td {
  display: block;
}

.view-order .shipment-contents .delivery table th {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  font-weight: 400;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-contents .delivery table th {
    flex: 0 0 160px;
  }
}

@media all and (max-width: 599px) {
  .view-order .shipment-contents .delivery table th {
    flex: 0 0 145px;
  }
}

.view-order .shipment-contents .delivery table td {
  padding: 10px;
  flex: 0 1 100%;
}

.view-order .shipment-contents .order-items h4 {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  text-align: center;
  padding: 10px;
}

.view-order .shipment-contents .itemdetails-header {
  display: none;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-order .shipment-contents .rpoption {
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
}

@media all and (max-width: 599px) {
  .view-order .shipment-contents .rpoption {
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
}

.view-order .applied-coupons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.view-order .applied-coupons li {
  display: inline-block;
  background: -webkit-gradient(linear, right top, left top, from(#c10000), to(#d35900));
  background: -o-linear-gradient(right, #c10000 0%, #d35900 100%);
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.view-order .order-buttons {
  text-align: center;
  padding: 20px 10px 10px;
}

.view-order .order-buttons .shipment-cancel .note {
  margin-top: 10px;
}

.view-order .panel-footer {
  margin-top: 30px;
}

/*
  ----------------
  .order-journal-history | 注文履歴
  ----------------
*/
.order-journal-history {
  /*================================================
    .panel-heading | 注文日時〜出荷番号
  ================================================*/
  /*================================================
    .panel-body | お届け先〜請求金額合計
  ================================================*/
}

.order-journal-history .order {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .order-journal-history .order {
    margin-top: 60px;
  }
}

.order-journal-history .panel {
  border: 1px solid lightgray;
}

.order-journal-history .panel-heading .status {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .order-journal-history .panel-heading .status {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-journal-history .panel-heading .status {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .order-journal-history .panel-heading .status {
    text-align: center;
  }
}

.order-journal-history .panel-heading .control a {
  text-decoration: underline;
}

.order-journal-history .panel-body .panels li {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .order-journal-history .panel-body .panels li {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-journal-history .panel-body .panels li {
    display: block;
  }
}

.order-journal-history .panel-body .panels li > div {
  padding: 10px;
}

@media all and (min-width: 600px) {
  .order-journal-history .panel-body .panels li > div {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-journal-history .panel-body .panels li > div {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .order-journal-history .panel-body .panels li > div {
    text-align: center;
  }
}

.order-journal-history .panel-body .panels h6 {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-journal-history .panel-body .panels h6 {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .order-journal-history .panel-body .panels h6 {
    border-right: 0;
  }
}

@media all and (min-width: 600px) {
  .order-journal-history .panel-body .item {
    flex: 0 1 85%;
  }
}

/*
  ---------------
  .rp-subscription | 定期アイテム一覧(定期基本情報)
  ---------------
*/
ol.rp-subscriptions-nav-list {
  margin-top: 30px;
  text-align: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

@media all and (min-width: 600px) and (max-width: 850px) {
  ol.rp-subscriptions-nav-list {
    margin-top: 15px;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media all and (max-width: 599px) {
  ol.rp-subscriptions-nav-list {
    margin-top: 15px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
}

ol.rp-subscriptions-nav-list a,
ol.rp-subscriptions-nav-list span {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  width: 100%;
  padding: 14px 5px;
}

ol.rp-subscriptions-nav-list li.active span {
  background-color: #171717;
  border: 1px solid #171717;
  color: #fff;
}

.rp-subscriptions {
  /*================================================
    .panel | お届け先〜お支払い方法
  ================================================*/
  /*================================================
    .regular-purchase | 出荷予定日〜単価
  ================================================*/
  /*================================================
  .panel-heading | 出荷予定日〜利用状態
  ================================================*/
  /*================================================
    .panel-body | 商品画像〜単価(金額)
  ================================================*/
}

.rp-subscriptions .panel {
  border: 1px solid lightgray;
  margin-top: 40px;
}

.rp-subscriptions .panel .consignee {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .rp-subscriptions .panel .consignee {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel .consignee {
    display: block;
  }
}

@media all and (min-width: 600px) {
  .rp-subscriptions .panel .payment-method {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel .payment-method {
    display: block;
  }
}

.rp-subscriptions .panel .person-contents,
.rp-subscriptions .panel .payment-method-name {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .rp-subscriptions .panel .person-contents,
  .rp-subscriptions .panel .payment-method-name {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel .person-contents,
  .rp-subscriptions .panel .payment-method-name {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .rp-subscriptions .panel .person-contents,
  .rp-subscriptions .panel .payment-method-name {
    text-align: center;
  }
}

.rp-subscriptions .regular-purchase {
  margin-top: 40px;
}

.rp-subscriptions .regular-purchase .panel {
  margin-top: 0;
}

.rp-subscriptions .panel-heading > div > span {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .rp-subscriptions .panel-heading > div > span {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel-heading > div > span {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .rp-subscriptions .panel-heading > div > span {
    text-align: center;
  }
}

.rp-subscriptions .panel-heading .next-shipping-date div {
  color: #ba0000;
  font-weight: bold;
}

.rp-subscriptions .panel-heading .next-shipping-date-prospect div {
  color: #ba0000;
  font-weight: bold;
}

.rp-subscriptions .panel-body .message-wrapper .error {
  background-color: #ba0000;
  color: white;
  text-align: center;
  padding: 4px 8px 0;
}

.rp-subscriptions .panel-body .item {
  text-align: center;
  padding: 30px 0;
}

@media all and (min-width: 600px) {
  .rp-subscriptions .panel-body .item {
    display: flex;
    align-items: center;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel-body .item {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .rp-subscriptions .panel-body .item {
    padding: 30px 20px;
  }
}

.rp-subscriptions .panel-body .item-text {
  text-align: left;
}

@media all and (min-width: 600px) {
  .rp-subscriptions .panel-body .item-text {
    flex: 0 1 100%;
    margin-left: 10px;
  }
}

@media all and (min-width: 600px) and (max-width: 1047px) {
  .rp-subscriptions .panel-body .item-text {
    margin-left: 0;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel-body .item-text {
    display: inline-block;
    margin-top: 10px;
    margin-left: 0;
  }
}

@media all and (max-width: 599px) {
  .rp-subscriptions .panel-body .item-text {
    display: inline-block;
    margin-top: 10px;
    margin-left: 0;
  }
}

.rp-subscriptions .panel-body .unit-price {
  display: flex;
}

.rp-subscriptions .panel-body .unit-price > h6 {
  background: none;
  border: none;
  display: inline;
  flex: inherit;
  padding: 0;
}

.rp-subscriptions .panel-body .unit-price > div {
  margin-left: 5px;
}

.rp-subscriptions .panel-footer .note {
  border-top: 1px solid lightgray;
  display: block;
  padding: 10px;
}

.rp-subscriptions .panel-footer .btn-group {
  padding: 0 15px 15px;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}

@media (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel-footer .btn-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (max-width: 599px) {
  .rp-subscriptions .panel-footer .btn-group {
    padding: 0 10px 10px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.rp-subscriptions .panel-footer .btn-group .edit-rp-coupon,
.rp-subscriptions .panel-footer .btn-group .edit-rp-cons,
.rp-subscriptions .panel-footer .btn-group .edit-rp-pay,
.rp-subscriptions .panel-footer .btn-group .edit-rp-skip-next,
.rp-subscriptions .panel-footer .btn-group .edit-rp-stop-all {
  margin-top: 0;
}

.rp-subscriptions .panel-footer .btn-group .btn {
  line-height: 2.142;
  max-width: 100%;
}

.rp-subscriptions .panel-footer .update-shipping-schedule,
.rp-subscriptions .panel-footer .control {
  border-top: 1px solid lightgray;
  display: flex;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel-footer .update-shipping-schedule,
  .rp-subscriptions .panel-footer .control {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .rp-subscriptions .panel-footer .update-shipping-schedule,
  .rp-subscriptions .panel-footer .control {
    display: block;
  }
}

.rp-subscriptions .panel-footer .update-shipping-schedule .link,
.rp-subscriptions .panel-footer .control .link {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rp-subscriptions .panel-footer .update-shipping-schedule .link + div,
.rp-subscriptions .panel-footer .control .link + div {
  display: block !important;
  padding: 10px;
  flex: 0 1 100%;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-subscriptions .panel-footer .update-shipping-schedule .link + div,
  .rp-subscriptions .panel-footer .control .link + div {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .rp-subscriptions .panel-footer .update-shipping-schedule .link + div,
  .rp-subscriptions .panel-footer .control .link + div {
    text-align: center;
  }
}

.rp-subscriptions .panel-footer .update-shipping-schedule .link + div .btn,
.rp-subscriptions .panel-footer .control .link + div .btn {
  display: block;
  margin-top: 10px;
  max-width: 250px;
}

/*
  ---------------
  .rp-future-entries | 定期出荷予定(一覧)
  ---------------
*/
.rp-future-entries {
  /*==========================================
    .panel-heading | 商品コード〜お届け予定日
  ==========================================*/
  /*==========================================
    .panel-body | 商品明細タイトル〜商品合計
  ==========================================*/
}

.rp-future-entries ol.rp-subscriptions-nav-list {
  margin-top: 30px;
  text-align: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-future-entries ol.rp-subscriptions-nav-list {
    margin-top: 15px;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries ol.rp-subscriptions-nav-list {
    margin-top: 15px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
}

.rp-future-entries ol.rp-subscriptions-nav-list a,
.rp-future-entries ol.rp-subscriptions-nav-list span {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  width: 100%;
  padding: 14px 5px;
}

.rp-future-entries ol.rp-subscriptions-nav-list li.active span {
  background-color: #171717;
  border: 1px solid #171717;
  color: #fff;
}

.rp-future-entries .rp-orderentry {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .rp-future-entries .rp-orderentry {
    margin-top: 60px;
  }
}

.rp-future-entries .panel {
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}

.rp-future-entries .panel-heading {
  font-weight: normal;
  margin-left: auto;
  margin-right: 0;
  width: 50%;
  order: 2;
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-heading {
    width: 100%;
  }
}

.rp-future-entries .panel-heading .code,
.rp-future-entries .panel-heading .status,
.rp-future-entries .panel-heading .control,
.rp-future-entries .panel-heading .date-to-checkout,
.rp-future-entries .panel-heading .date-to-ship,
.rp-future-entries .panel-heading .date-to-delivery {
  border-bottom: 1px solid lightgray;
  padding: 5px;
  text-align: right;
}

.rp-future-entries .panel-heading .code a,
.rp-future-entries .panel-heading .status a,
.rp-future-entries .panel-heading .control a,
.rp-future-entries .panel-heading .date-to-checkout a,
.rp-future-entries .panel-heading .date-to-ship a,
.rp-future-entries .panel-heading .date-to-delivery a {
  text-decoration: underline;
}

.rp-future-entries .panel-heading .control {
  padding: 10px;
}

.rp-future-entries .panel-heading .date-to-delivery {
  border-bottom: none;
}

.rp-future-entries .panel-body .itemdetails-caption h3 {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  font-weight: 400;
  text-align: center;
  padding: 10px;
}

.rp-future-entries .panel-body .itemdetails-header {
  display: none;
}

.rp-future-entries .panel-body .itemdetail-row {
  border-bottom: 1px solid lightgray;
  padding: 30px 0;
}

@media all and (min-width: 600px) {
  .rp-future-entries .panel-body .itemdetail-row {
    display: block;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-future-entries .panel-body .itemdetail-row {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .itemdetail-row {
    padding: 30px 20px;
  }
}

.rp-future-entries .panel-body .item {
  text-align: center;
}

@media all and (min-width: 600px) {
  .rp-future-entries .panel-body .goods {
    display: flex;
    align-items: center;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-future-entries .panel-body .goods {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .goods {
    display: block;
  }
}

.rp-future-entries .panel-body .goods-text {
  text-align: left;
}

@media all and (min-width: 600px) {
  .rp-future-entries .panel-body .goods-text {
    flex: 0 1 100%;
    margin-left: 10px;
  }
}

@media all and (min-width: 600px) and (max-width: 1047px) {
  .rp-future-entries .panel-body .goods-text {
    margin-left: 0;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-future-entries .panel-body .goods-text {
    text-align: center;
    margin-top: 10px;
    margin-left: 0;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .goods-text {
    text-align: center;
    margin-top: 10px;
    margin-left: 0;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-future-entries .panel-body .goods-text-heading {
    text-align: left;
    margin: 0 auto;
    max-width: 400px;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .goods-text-heading {
    text-align: left;
    margin: 0 auto;
    max-width: 400px;
  }
}

.rp-future-entries .panel-body .goods-text-heading .code {
  display: none;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-future-entries .panel-body .rpoption,
  .rp-future-entries .panel-body .unit-price {
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .rpoption,
  .rp-future-entries .panel-body .unit-price {
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
}

.rp-future-entries .panel-body .purchase-contents {
  color: #9b0000;
  display: flex;
  justify-content: flex-end;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-future-entries .panel-body .purchase-contents {
    margin-top: 20px;
    justify-content: flex-end;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .purchase-contents {
    margin-top: 20px;
    justify-content: flex-end;
  }
}

@media all and (max-width: 448px) {
  .rp-future-entries .panel-body .purchase-contents {
    justify-content: flex-start;
  }
}

.rp-future-entries .panel-body .purchase-contents .subtotal {
  margin-left: 20px;
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .purchase-contents .subtotal {
    margin-left: 15px;
  }
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .price-total {
    text-align: right;
  }
}

.rp-future-entries .panel-body .price-total .row {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .price-total .row {
    padding: 20px;
  }
}

@media all and (max-width: 448px) {
  .rp-future-entries .panel-body .price-total .row {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.rp-future-entries .panel-body .price-total .qty {
  margin-left: 20px;
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .price-total .qty {
    margin-left: 10px;
  }
}

@media all and (max-width: 448px) {
  .rp-future-entries .panel-body .price-total .qty {
    margin-right: 10px;
  }
}

.rp-future-entries .panel-body .price-total .subtotal {
  margin-left: 20px;
}

@media all and (max-width: 599px) {
  .rp-future-entries .panel-body .price-total .subtotal {
    margin-left: 10px;
  }
}

@media all and (max-width: 448px) {
  .rp-future-entries .panel-body .price-total .subtotal {
    margin-left: 0;
  }
}

/*
  ---------------
  .rp-entry | 定期出荷予定(詳細)
  ---------------
*/
.rp-entry {
  /*==========================================
    #order-entry-order | 購入者情報〜お支払い方法
  ==========================================*/
  /*==========================================
    .shipment-entry-adr | お届け先〜送り主
  ==========================================*/
  /*==========================================
    .shipment-content | 配達予定日〜ご購入商品明細
  ==========================================*/
}

.rp-entry .shipments > ul {
  margin-top: 40px;
}

.rp-entry #order-entry-order {
  border: 1px solid lightgray;
  border-bottom: 0;
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .rp-entry #order-entry-order {
    margin-top: 60px;
  }
}

.rp-entry #order-entry-order > div {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .rp-entry #order-entry-order > div {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry #order-entry-order > div {
    display: block;
  }
}

.rp-entry #order-entry-order .person-label,
.rp-entry #order-entry-order .header {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry #order-entry-order .person-label,
  .rp-entry #order-entry-order .header {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .rp-entry #order-entry-order .person-label,
  .rp-entry #order-entry-order .header {
    border-right: 0;
  }
}

.rp-entry #order-entry-order .person > .person-contents,
.rp-entry #order-entry-order .body {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .rp-entry #order-entry-order .person > .person-contents,
  .rp-entry #order-entry-order .body {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry #order-entry-order .person > .person-contents,
  .rp-entry #order-entry-order .body {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .rp-entry #order-entry-order .person > .person-contents,
  .rp-entry #order-entry-order .body {
    text-align: center;
  }
}

.rp-entry .shipment {
  border: 1px solid lightgray;
}

.rp-entry .shipment-entry-adr .consignee,
.rp-entry .shipment-entry-adr .sender {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .rp-entry .shipment-entry-adr .consignee,
  .rp-entry .shipment-entry-adr .sender {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry .shipment-entry-adr .consignee,
  .rp-entry .shipment-entry-adr .sender {
    display: block;
  }
}

.rp-entry .shipment-entry-adr .header {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry .shipment-entry-adr .header {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-entry-adr .header {
    border-right: 0;
  }
}

.rp-entry .shipment-entry-adr .body {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .rp-entry .shipment-entry-adr .body {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry .shipment-entry-adr .body {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-entry-adr .body {
    text-align: center;
  }
}

.rp-entry .shipment-content .header {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  display: block;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.rp-entry .shipment-content .body li {
  border-bottom: 1px solid lightgray;
  display: flex;
}

.rp-entry .shipment-content .body li > div {
  padding: 10px;
  flex: 0 1 100%;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry .shipment-content .body li h4 {
    border-right: 0;
    flex: 0 0 160px;
  }
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .body li h4 {
    border-right: 0;
    flex: 0 0 125px;
  }
}

.rp-entry .shipment-content .itemdetails .itemdetails-caption {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  text-align: center;
  padding: 10px;
}

.rp-entry .shipment-content .itemdetails .itemdetails-caption h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.142;
}

.rp-entry .shipment-content .itemdetails .itemdetails-header {
  display: none;
}

.rp-entry .shipment-content .itemdetails .itemdetails-body .itemdetail-row {
  border-bottom: 1px solid lightgray;
  padding: 30px 0 10px;
}

@media all and (min-width: 600px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .itemdetail-row {
    display: block;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .itemdetail-row {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .itemdetail-row {
    padding: 30px 20px;
  }
}

.rp-entry .shipment-content .itemdetails .itemdetails-body .itemdetail-row:not(:first-child) {
  border-top: 0;
}

@media all and (min-width: 600px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .goods {
    display: flex;
    align-items: center;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .goods {
    display: block;
  }
}

.rp-entry .shipment-content .itemdetails .itemdetails-body .purchase-contents {
  color: #9b0000;
  display: flex;
  justify-content: flex-end;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .purchase-contents {
    margin-top: 20px;
    justify-content: flex-end;
  }
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .purchase-contents {
    margin-top: 20px;
    justify-content: flex-end;
  }
}

@media all and (max-width: 448px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .purchase-contents {
    justify-content: flex-start;
  }
}

.rp-entry .shipment-content .itemdetails .itemdetails-body .purchase-contents .subtotal {
  margin-left: 20px;
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .itemdetails .itemdetails-body .purchase-contents .subtotal {
    margin-left: 15px;
  }
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .price-total {
    text-align: right;
  }
}

.rp-entry .shipment-content .price-total .row {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .price-total .row {
    padding: 20px;
  }
}

@media all and (max-width: 448px) {
  .rp-entry .shipment-content .price-total .row {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.rp-entry .shipment-content .price-total .qty {
  margin-left: 20px;
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .price-total .qty {
    margin-left: 10px;
  }
}

@media all and (max-width: 448px) {
  .rp-entry .shipment-content .price-total .qty {
    margin-right: 10px;
  }
}

.rp-entry .shipment-content .price-total .subtotal {
  margin-left: 20px;
}

@media all and (max-width: 599px) {
  .rp-entry .shipment-content .price-total .subtotal {
    margin-left: 10px;
  }
}

@media all and (max-width: 448px) {
  .rp-entry .shipment-content .price-total .subtotal {
    margin-left: 0;
  }
}

/*
  ---------------
  .form-rps-consignee | 定期お届け先の編集
  ---------------
*/
@media all and (max-width: 599px) {
  .form-rps-consignee #page-title,
  .form-rps-consignee .form-note {
    line-height: 2;
  }
}

.form-rps-consignee .input-group-address {
  padding-top: 40px;
}

/*
  ---------------
  .form-regular-purchase-payment | 定期お支払い方法
  ---------------
*/
.form-regular-purchase-payment h4 {
  border-right: 0;
}

.form-regular-purchase-payment .main-payment-method {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .form-regular-purchase-payment .main-payment-method {
    margin-top: 60px;
  }
}

.form-regular-purchase-payment .main-payment-method h4 {
  background-color: transparent;
  border-bottom: 1px solid #171717;
  display: block;
  font-size: 25px;
  text-align: left;
  line-height: 1.3;
  padding: 0 0 10px;
}

.form-regular-purchase-payment .main-payment-method .wrapper {
  font-size: 20px;
}

.form-regular-purchase-payment .main-payment-method .note {
  margin-top: 5px;
  color: #ba0000;
}

.form-regular-purchase-payment .note {
  font-size: 13px;
}

@media all and (max-width: 599px) {
  .form-regular-purchase-payment .note {
    font-weight: 400;
  }
}

.form-regular-purchase-payment .note:before {
  content: "✳︎";
  display: inline-block;
}

.form-regular-purchase-payment #update-rp-payment {
  margin-top: 100px;
}

.form-regular-purchase-payment #update-rp-payment h4 {
  background-color: transparent;
  border-bottom: 1px solid #171717;
  display: block;
  font-size: 25px;
  text-align: left;
  line-height: 1.3;
  padding: 0 0 10px;
}

.form-regular-purchase-payment #update-rp-payment .payment-method:not(:first-child) {
  border-top: 1px solid lightgray;
  margin-top: 40px;
}

.form-regular-purchase-payment .pm-wrapper {
  text-align: center;
  padding-top: 10px;
}

.form-regular-purchase-payment .pm-heading {
  font-size: 20px;
  text-align: left;
}

.form-regular-purchase-payment .pm-body {
  margin-top: 30px;
  text-align-last: left;
}

.form-regular-purchase-payment .pm-body .input-group .date.date-mm {
  margin-right: 28px;
  margin-left: 0;
}

@media all and (max-width: 599px) {
  .form-regular-purchase-payment .pm-body .input-group .date.date-mm {
    margin-left: 12px;
  }
}

.form-regular-purchase-payment .pm-body .formrow {
  border: 1px solid lightgray;
  margin-top: 0;
}

.form-regular-purchase-payment .pm-body .formrow:not(:first-child) {
  border-top: none;
}

.form-regular-purchase-payment .pm-body .formrow:last-child {
  border-bottom: none;
}

.form-regular-purchase-payment .pm-body .input-label {
  background-color: #f2f2f2;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .form-regular-purchase-payment .pm-body .input-label {
    margin-top: 0;
  }
}

.form-regular-purchase-payment .pm-body .input-control {
  padding: 10px 20px;
}

@media all and (max-width: 599px) {
  .form-regular-purchase-payment .pm-body .input-control {
    margin-top: 5px;
  }
}

.form-regular-purchase-payment .pm-body .form-control {
  display: block;
  min-height: 100%;
}

.form-regular-purchase-payment .pm-submit {
  margin-top: 20px;
}

.form-regular-purchase-payment .btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  display: inline-block;
  width: 100%;
  max-width: 350px;
  margin-top: 30px;
  padding: 10px;
}

@media (hover: hover) and (pointer: fine) {
  .form-regular-purchase-payment .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

/*
  ----------------
  .edit-account-properties | アカウント情報の変更
  ----------------
*/
/*
  ----------------
  .form-edit-account | ID、パスワード編集
  ----------------
*/
.form-edit-account #page-title {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
}

@media all and (min-width: 600px) {
  .form-edit-account #page-title {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .form-edit-account #page-title {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/*
  ----------------
  .manage-addressbook | アドレス編集（ご登録住所）
  ----------------
*/
.manage-addressbook .btn-group {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .manage-addressbook .btn-group {
    margin-top: 60px;
  }
}

.manage-addressbook .btn-group .btn {
  background-color: #fff;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  border-image-slice: 1;
  color: #ba0000;
  font-weight: 500;
  line-height: normal;
  padding: 15px 10px 15px 12px;
}

.manage-addressbook .btn-group .btn:hover {
  border-radius: 0;
}

.manage-addressbook .panel-list {
  margin-top: 60px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(410px, auto);
}

@media all and (max-width: 1195px) {
  .manage-addressbook .panel-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (max-width: 838px) {
  .manage-addressbook .panel-list {
    grid-template-columns: 1fr;
  }
}

@media all and (max-width: 599px) {
  .manage-addressbook .panel-list {
    margin-top: 40px;
  }
}

.manage-addressbook .panel-list li {
  border: 1px solid lightgray;
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;
}

.manage-addressbook .panel-list li:first-child .btn-group {
  margin-top: 0;
}

.manage-addressbook .panel-list li .btn-group {
  text-align: center;
  padding: 0;
  margin-top: 40px;
}

.manage-addressbook .panel-list .btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-image: none;
  border: none;
  color: #fff;
  padding: 15px 10px 15px 12px;
}

@media (hover: hover) and (pointer: fine) {
  .manage-addressbook .panel-list .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .manage-addressbook .panel-list .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.manage-addressbook .panel-list .btn::after {
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  z-index: -2;
}

.manage-addressbook .panel-list .btn:hover {
  background-image: none;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

.manage-addressbook .panel-list .btn:hover::before {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.manage-addressbook .panel-list .btn::before, .manage-addressbook .panel-list .btn::after {
  content: "";
  display: block;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.manage-addressbook .panel-list .btn::before {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  z-index: -1;
}

.manage-addressbook .panel {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.manage-addressbook .panel-body {
  flex-grow: 1;
}

.manage-addressbook .person-contents {
  padding-bottom: 20px;
}

.manage-addressbook .person-contents .kana:before,
.manage-addressbook .person-contents .name:before,
.manage-addressbook .person-contents .organization-name:before,
.manage-addressbook .person-contents .postal-code:before,
.manage-addressbook .person-contents .address:before,
.manage-addressbook .person-contents .phone-number:before {
  display: inline-block;
  padding-right: 10px;
}

.manage-addressbook .person-contents .kana .label,
.manage-addressbook .person-contents .name .label,
.manage-addressbook .person-contents .organization-name .label,
.manage-addressbook .person-contents .postal-code .label,
.manage-addressbook .person-contents .address .label,
.manage-addressbook .person-contents .phone-number .label {
  display: inline-block;
}

.manage-addressbook .person-contents .phone-number {
  border-bottom: 0;
}

.manage-addressbook .msg-default-addr {
  display: block;
  text-align: center;
  margin-top: 40px;
}

.manage-addressbook .msg-default-addr::before {
  content: "✳︎";
  display: inline-block;
}

/*
  ----------------
  .manage-paymentmethod | ID、パスワード編集
  ----------------
*/
.manage-paymentmethod h4 {
  margin-top: 40px;
}

.manage-paymentmethod .note {
  margin-top: 20px;
}

.manage-paymentmethod .panel-list {
  margin-top: 30px;
  text-align: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .manage-paymentmethod .panel-list {
    margin-top: 15px;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media all and (max-width: 599px) {
  .manage-paymentmethod .panel-list {
    margin-top: 15px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
}

.manage-paymentmethod .panel-list > li {
  border: 1px solid lightgray;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
}

.manage-paymentmethod .panel-list .panel-heading {
  margin-bottom: 20px;
}

.manage-paymentmethod .panel-list .use-as-default,
.manage-paymentmethod .panel-list .delete-payment {
  margin: 10px 0;
}

.manage-paymentmethod .panel-list .paymententry-note {
  display: none;
}

.manage-paymentmethod .payment-method-list {
  margin-top: 40px;
}

.manage-paymentmethod .pm-wrapper {
  background-color: #f2f2f2;
  padding: 70px 103px 130px;
}

@media all and (min-width: 600px) and (max-width: 794px) {
  .manage-paymentmethod .pm-wrapper {
    padding: 50px 30px 70px;
  }
}

@media all and (max-width: 599px) {
  .manage-paymentmethod .pm-wrapper {
    padding: 50px 30px 70px;
  }
}

.manage-paymentmethod .pm-label {
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  line-height: 1.6;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .manage-paymentmethod .pm-label {
    font-size: 20px;
    font-weight: 500;
  }
}

.manage-paymentmethod .pm-body {
  margin-top: 100px;
}

@media all and (min-width: 600px) and (max-width: 794px) {
  .manage-paymentmethod .pm-body {
    margin-top: 60px;
  }
}

@media all and (max-width: 599px) {
  .manage-paymentmethod .pm-body {
    margin-top: 60px;
  }
}

.manage-paymentmethod .pm-trailing {
  margin-top: 60px;
}

.manage-paymentmethod .pm-submit {
  text-align: center;
}

.manage-paymentmethod .btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-image: none;
  border: none;
  color: #fff;
  padding: 15px 10px 15px 12px;
}

@media (hover: hover) and (pointer: fine) {
  .manage-paymentmethod .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .manage-paymentmethod .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.manage-paymentmethod .btn::after {
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  z-index: -2;
}

.manage-paymentmethod .btn:hover {
  background-image: none;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

.manage-paymentmethod .btn:hover::before {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.manage-paymentmethod .btn::before, .manage-paymentmethod .btn::after {
  content: "";
  display: block;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.manage-paymentmethod .btn::before {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  z-index: -1;
}

.manage-paymentmethod .main-payment-method .wrapper {
  border: 1px solid lightgray;
  margin-top: 30px;
  padding: 30px 20px;
  text-align: center;
  width: 31%;
  display: flex;
  flex-direction: column;
}

@media all and (min-width: 600px) and (max-width: 794px) {
  .manage-paymentmethod .main-payment-method .wrapper {
    margin-top: 15px;
    width: 48%;
  }
}

@media all and (max-width: 599px) {
  .manage-paymentmethod .main-payment-method .wrapper {
    margin-top: 15px;
    width: 100%;
  }
}

.manage-paymentmethod .main-payment-method .method-name {
  margin-bottom: 20px;
}

@media all and (max-width: 599px) {
  .manage-paymentmethod .main-payment-method .control {
    text-align: center;
  }
}

.manage-paymentmethod label {
  margin-top: 2rem;
}

.manage-paymentmethod .extra-control {
  margin-top: 2rem;
  text-align: left;
}

.manage-paymentmethod .panel-list .panel {
  justify-content: start;
}

.manage-paymentmethod .input-may-required {
  color: #c77064;
  padding-left: 1rem;
}

/*
  ----------------
  .yourcoupons | クーポン一覧
  ----------------
*/
.yourcoupons #page-title .description {
  margin: 100px auto 0;
  width: 100%;
  max-width: 650px;
}

@media all and (max-width: 599px) {
  .yourcoupons #page-title .description {
    margin-top: 60px;
  }
}

.yourcoupons .yourcoupon-list {
  margin-top: 2rem;
}

@media all and (max-width: 599px) {
  .yourcoupons .yourcoupon-list {
    display: flex;
    flex-direction: column;
  }
}

.yourcoupons .yourcoupon-list .page-error-msg {
  border: 1px solid #171717;
  border-radius: 8px;
  margin-right: auto;
  margin-left: auto;
  max-width: 650px;
  padding: 15px;
  text-align: center;
  width: 100%;
}

.yourcoupons .yourcoupon-list .coupon-props {
  border: 1px solid darkgray;
  border-radius: 4px;
}

.yourcoupons .label {
  text-align: center;
  padding: 4px 8px;
}

.yourcoupons .label .code {
  display: inline-block;
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

@media all and (max-width: 599px) {
  .yourcoupons .label .code {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.yourcoupons .label .name {
  font-size: 16px;
  font-weight: bold;
}

@media all and (min-width: 600px) {
  .yourcoupons .label .name {
    margin-left: 10px;
  }
}

.yourcoupons .expiry {
  text-align: right;
  font-size: 12px;
  padding: 2px 8px;
  color: #c77064;
}

@media all and (max-width: 599px) {
  .yourcoupons .expiry {
    text-align: center;
  }
}

.yourcoupons .expiry .description {
  padding: 4px 8px;
  font-size: 12px;
}

.yourcoupons .coupon {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 650px;
}

.yourcoupons .coupon:not(:first-child) {
  margin-top: 6rem;
}

.yourcoupons .coupon .coupon-props {
  padding: 5rem 1rem;
}

/*
  ---------------
  .view-inquiries | お問い合わせ一覧
  ---------------
*/
.view-inquiries .inquiries-list {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .view-inquiries .inquiries-list {
    margin-top: 60px;
  }
}

.view-inquiries .inquiries-list .msg {
  display: block;
  font-size: 20px;
  margin-top: -50px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .view-inquiries .inquiries-list .msg {
    font-size: 18px;
    margin-top: -25px;
  }
}

.view-inquiries .panel {
  border: 1px solid lightgray;
}

.view-inquiries .panel + .panel {
  margin-top: 60px;
}

.view-inquiries .panel-heading,
.view-inquiries .inq-item,
.view-inquiries .inq-resp,
.view-inquiries .inq-re-entry {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .view-inquiries .panel-heading,
  .view-inquiries .inq-item,
  .view-inquiries .inq-resp,
  .view-inquiries .inq-re-entry {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-inquiries .panel-heading,
  .view-inquiries .inq-item,
  .view-inquiries .inq-resp,
  .view-inquiries .inq-re-entry {
    display: block;
  }
}

.view-inquiries .inq-type,
.view-inquiries .inq-item-label,
.view-inquiries .inq-resp-heading {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-inquiries .inq-type,
  .view-inquiries .inq-item-label,
  .view-inquiries .inq-resp-heading {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .view-inquiries .inq-type,
  .view-inquiries .inq-item-label,
  .view-inquiries .inq-resp-heading {
    border-right: 0;
  }
}

.view-inquiries .inq-date,
.view-inquiries .inq-item-values,
.view-inquiries .inq-resp-content {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .view-inquiries .inq-date,
  .view-inquiries .inq-item-values,
  .view-inquiries .inq-resp-content {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-inquiries .inq-date,
  .view-inquiries .inq-item-values,
  .view-inquiries .inq-resp-content {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .view-inquiries .inq-date,
  .view-inquiries .inq-item-values,
  .view-inquiries .inq-resp-content {
    text-align: center;
  }
}

.view-inquiries .inq-resp-heading {
  flex-direction: column;
}

.view-inquiries .inq-re-entry:last-child {
  border-bottom: 0;
}

.view-inquiries #re_entry_form {
  display: block;
  text-align: center;
  padding: 40px;
}

@media all and (min-width: 600px) {
  .view-inquiries #re_entry_form {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-inquiries #re_entry_form {
    padding: 20px;
  }
}

@media all and (max-width: 599px) {
  .view-inquiries #re_entry_form {
    padding: 10px;
  }
}

.view-inquiries textarea {
  resize: vertical;
  width: 100%;
  min-height: 140px;
}

@media all and (max-width: 599px) {
  .view-inquiries textarea {
    width: 100%;
  }
}

.view-inquiries button.btn-submit {
  margin-top: 40px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .view-inquiries button.btn-submit {
    margin-top: 20px;
  }
}

@media all and (max-width: 599px) {
  .view-inquiries button.btn-submit {
    margin-top: 0;
  }
}

/********** Order | 注文 **********/
/*
  ----------------
  Order pages | Import pages
  ----------------
*/
/********** Common | 注文ページ共通 **********/
/*
  ----------------
  Order pages | Common
  ----------------
*/
.order-process-main-complete,
#order-entry,
.form-consignee,
.form-orderer,
.form-sender,
.form-deliveryservice,
.form-itemoptions,
.form-points,
.form-payment,
.form-rps-consignee {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  font-size: 15px;
  padding-bottom: 10rem;
}

@media all and (min-width: 600px) {
  .order-process-main-complete,
  #order-entry,
  .form-consignee,
  .form-orderer,
  .form-sender,
  .form-deliveryservice,
  .form-itemoptions,
  .form-points,
  .form-payment,
  .form-rps-consignee {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-complete,
  #order-entry,
  .form-consignee,
  .form-orderer,
  .form-sender,
  .form-deliveryservice,
  .form-itemoptions,
  .form-points,
  .form-payment,
  .form-rps-consignee {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.order-process-main-complete .addressbook,
#order-entry .addressbook,
.form-consignee .addressbook,
.form-orderer .addressbook,
.form-sender .addressbook,
.form-deliveryservice .addressbook,
.form-itemoptions .addressbook,
.form-points .addressbook,
.form-payment .addressbook,
.form-rps-consignee .addressbook {
  text-align: center;
}

.order-process-main-complete .addressbook .ab-list,
#order-entry .addressbook .ab-list,
.form-consignee .addressbook .ab-list,
.form-orderer .addressbook .ab-list,
.form-sender .addressbook .ab-list,
.form-deliveryservice .addressbook .ab-list,
.form-itemoptions .addressbook .ab-list,
.form-points .addressbook .ab-list,
.form-payment .addressbook .ab-list,
.form-rps-consignee .addressbook .ab-list {
  margin-top: 30px;
}

@media all and (max-width: 599px) {
  .order-process-main-complete .addressbook .ab-list,
  #order-entry .addressbook .ab-list,
  .form-consignee .addressbook .ab-list,
  .form-orderer .addressbook .ab-list,
  .form-sender .addressbook .ab-list,
  .form-deliveryservice .addressbook .ab-list,
  .form-itemoptions .addressbook .ab-list,
  .form-points .addressbook .ab-list,
  .form-payment .addressbook .ab-list,
  .form-rps-consignee .addressbook .ab-list {
    margin-top: 15px;
  }
}

@media all and (min-width: 600px) {
  .order-process-main-complete .addressbook .ab-list > ul,
  #order-entry .addressbook .ab-list > ul,
  .form-consignee .addressbook .ab-list > ul,
  .form-orderer .addressbook .ab-list > ul,
  .form-sender .addressbook .ab-list > ul,
  .form-deliveryservice .addressbook .ab-list > ul,
  .form-itemoptions .addressbook .ab-list > ul,
  .form-points .addressbook .ab-list > ul,
  .form-payment .addressbook .ab-list > ul,
  .form-rps-consignee .addressbook .ab-list > ul {
    margin: 0 auto;
    text-align: center;
    width: 90%;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center;
  }
}

.order-process-main-complete .addressbook .ab-list .ab-item,
#order-entry .addressbook .ab-list .ab-item,
.form-consignee .addressbook .ab-list .ab-item,
.form-orderer .addressbook .ab-list .ab-item,
.form-sender .addressbook .ab-list .ab-item,
.form-deliveryservice .addressbook .ab-list .ab-item,
.form-itemoptions .addressbook .ab-list .ab-item,
.form-points .addressbook .ab-list .ab-item,
.form-payment .addressbook .ab-list .ab-item,
.form-rps-consignee .addressbook .ab-list .ab-item {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  width: 100%;
}

@media all and (max-width: 599px) {
  .order-process-main-complete .addressbook .ab-list .ab-item + li,
  #order-entry .addressbook .ab-list .ab-item + li,
  .form-consignee .addressbook .ab-list .ab-item + li,
  .form-orderer .addressbook .ab-list .ab-item + li,
  .form-sender .addressbook .ab-list .ab-item + li,
  .form-deliveryservice .addressbook .ab-list .ab-item + li,
  .form-itemoptions .addressbook .ab-list .ab-item + li,
  .form-points .addressbook .ab-list .ab-item + li,
  .form-payment .addressbook .ab-list .ab-item + li,
  .form-rps-consignee .addressbook .ab-list .ab-item + li {
    margin-top: 15px;
  }
}

.order-process-main-complete .addressbook .ab-list .ab-item:hover,
#order-entry .addressbook .ab-list .ab-item:hover,
.form-consignee .addressbook .ab-list .ab-item:hover,
.form-orderer .addressbook .ab-list .ab-item:hover,
.form-sender .addressbook .ab-list .ab-item:hover,
.form-deliveryservice .addressbook .ab-list .ab-item:hover,
.form-itemoptions .addressbook .ab-list .ab-item:hover,
.form-points .addressbook .ab-list .ab-item:hover,
.form-payment .addressbook .ab-list .ab-item:hover,
.form-rps-consignee .addressbook .ab-list .ab-item:hover {
  color: #fff;
  background-color: #171717;
  cursor: pointer;
}

.order-process-main-complete .addressbook .ab-list .name,
.order-process-main-complete .addressbook .ab-list .address,
#order-entry .addressbook .ab-list .name,
#order-entry .addressbook .ab-list .address,
.form-consignee .addressbook .ab-list .name,
.form-consignee .addressbook .ab-list .address,
.form-orderer .addressbook .ab-list .name,
.form-orderer .addressbook .ab-list .address,
.form-sender .addressbook .ab-list .name,
.form-sender .addressbook .ab-list .address,
.form-deliveryservice .addressbook .ab-list .name,
.form-deliveryservice .addressbook .ab-list .address,
.form-itemoptions .addressbook .ab-list .name,
.form-itemoptions .addressbook .ab-list .address,
.form-points .addressbook .ab-list .name,
.form-points .addressbook .ab-list .address,
.form-payment .addressbook .ab-list .name,
.form-payment .addressbook .ab-list .address,
.form-rps-consignee .addressbook .ab-list .name,
.form-rps-consignee .addressbook .ab-list .address {
  display: block;
  line-height: 1.5;
}

.order-process-main-complete .input-group-address,
#order-entry .input-group-address,
.form-consignee .input-group-address,
.form-orderer .input-group-address,
.form-sender .input-group-address,
.form-deliveryservice .input-group-address,
.form-itemoptions .input-group-address,
.form-points .input-group-address,
.form-payment .input-group-address,
.form-rps-consignee .input-group-address {
  margin-top: 1rem;
}

.order-process-main-complete .order-cancel-edit,
#order-entry .order-cancel-edit,
.form-consignee .order-cancel-edit,
.form-orderer .order-cancel-edit,
.form-sender .order-cancel-edit,
.form-deliveryservice .order-cancel-edit,
.form-itemoptions .order-cancel-edit,
.form-points .order-cancel-edit,
.form-payment .order-cancel-edit,
.form-rps-consignee .order-cancel-edit {
  margin-top: 1rem;
  text-align: center;
}

.order-process-main-complete .order-cancel-edit > a,
#order-entry .order-cancel-edit > a,
.form-consignee .order-cancel-edit > a,
.form-orderer .order-cancel-edit > a,
.form-sender .order-cancel-edit > a,
.form-deliveryservice .order-cancel-edit > a,
.form-itemoptions .order-cancel-edit > a,
.form-points .order-cancel-edit > a,
.form-payment .order-cancel-edit > a,
.form-rps-consignee .order-cancel-edit > a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .order-process-main-complete .order-cancel-edit > a:hover,
  #order-entry .order-cancel-edit > a:hover,
  .form-consignee .order-cancel-edit > a:hover,
  .form-orderer .order-cancel-edit > a:hover,
  .form-sender .order-cancel-edit > a:hover,
  .form-deliveryservice .order-cancel-edit > a:hover,
  .form-itemoptions .order-cancel-edit > a:hover,
  .form-points .order-cancel-edit > a:hover,
  .form-payment .order-cancel-edit > a:hover,
  .form-rps-consignee .order-cancel-edit > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

/********** Deliveryservice | 購入者情報の入力 **********/
/*
  ----------------
  .form-orderer | 購入者情報の入力
  ----------------
*/
.form-orderer {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 850px) {
  .form-orderer {
    padding: 0 30px 120px;
  }
}

@media all and (max-width: 599px) {
  .form-orderer {
    padding-top: 61px;
  }
}

.form-orderer h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .form-orderer h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

@media all and (max-width: 599px) {
  .form-orderer .form-note {
    padding: 0;
    text-align-last: center;
  }
}

@media all and (min-width: 600px) {
  .form-orderer .form-body {
    padding-bottom: 70px;
  }
}

@media all and (max-width: 599px) {
  .form-orderer .form-body {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.form-orderer .form-body .formrow-submit {
  margin-top: 40px;
}

.form-orderer .order-cancel-edit {
  margin-top: 40px;
}

/********** Orderer | 配送に関するご要望設定 **********/
/*
  ----------------
  .form-deliveryservice | 配送に関するご要望設定
  ----------------
*/
.form-deliveryservice {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 850px) {
  .form-deliveryservice {
    padding: 0 30px 120px;
  }
}

@media all and (max-width: 599px) {
  .form-deliveryservice {
    padding-top: 61px;
  }
}

.form-deliveryservice h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .form-deliveryservice h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

@media all and (max-width: 599px) {
  .form-deliveryservice .form-note {
    padding: 0;
    text-align-last: center;
  }
}

@media all and (min-width: 600px) {
  .form-deliveryservice .form-body {
    padding-bottom: 70px;
  }
}

@media all and (max-width: 599px) {
  .form-deliveryservice .form-body {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.form-deliveryservice .form-body .formrow-submit {
  margin-top: 40px;
}

.form-deliveryservice .order-cancel-edit {
  margin-top: 40px;
}

/********** paymentstatus | お支払いの状態 **********/
/*
  ---------------
  .order-process-main-payment-status | お支払いの状態
  ---------------
*/
.order-process-main-payment-status {
  /*================================================
    .shipment-header | 出荷番号〜お支払い方法
  ================================================*/
  /*==========================================
    .shipment-delivery-address | 購入者情報〜お届け先
  ==========================================*/
  /*==========================================
    .shipment-contents | お届けに関する情報〜出荷合計金額
  ==========================================*/
}

.order-process-main-payment-status .wrapper {
  margin-top: 100px;
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .wrapper {
    margin-top: 60px;
  }
}

.order-process-main-payment-status .order {
  border: 1px solid lightgray;
  padding-bottom: 60px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .order {
    padding-bottom: 30px;
  }
}

.order-process-main-payment-status .order .request-paymentmethod-reset {
  border-bottom: 1px solid lightgray;
  text-align: center;
}

.order-process-main-payment-status .order .request-paymentmethod-reset > h3 {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  color: #171717;
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2.142;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.order-process-main-payment-status .order .request-paymentmethod-reset .order-reset-paymentmethod-form {
  padding: 30px;
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .order .request-paymentmethod-reset .order-reset-paymentmethod-form {
    padding: 30px 15px;
  }
}

.order-process-main-payment-status .order .request-paymentmethod-reset .order-reset-paymentmethod-form .btn {
  max-width: 300px;
}

.order-process-main-payment-status .order .request-paymentmethod-reset .order-reset-paymentmethod-form .description {
  margin-top: 20px;
}

.order-process-main-payment-status .panel-heading {
  border-top: 1px solid lightgray;
}

.order-process-main-payment-status .panel-heading > div {
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .panel-heading > div > div {
    border-top: 1px solid lightgray;
  }
}

.order-process-main-payment-status .panel-heading > div a {
  text-decoration: underline;
}

.order-process-main-payment-status .panel-heading > div a:hover {
  text-decoration: none;
}

.order-process-main-payment-status .panel-body div.status {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .order-process-main-payment-status .panel-body div.status {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .panel-body div.status {
    display: block;
  }
}

.order-process-main-payment-status .panel-body div.status > div {
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .order-process-main-payment-status .panel-body div.status > div {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .panel-body div.status > div {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .panel-body div.status > div {
    text-align: center;
  }
}

.order-process-main-payment-status .panel-body div.status .label {
  background-color: #f2f2f2;
  border-left: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .panel-body div.status .label {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .panel-body div.status .label {
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
}

.order-process-main-payment-status .panel-body div.status .status-text {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .order-process-main-payment-status .panel-body div.status .status-text {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .panel-body div.status .status-text {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .panel-body div.status .status-text {
    text-align: center;
  }
}

.order-process-main-payment-status .shipment h4 {
  border: 1px solid lightgray;
  border-top: none;
  font-weight: normal;
}

.order-process-main-payment-status .shipment-header .shipment-code,
.order-process-main-payment-status .shipment-header .shipment-status,
.order-process-main-payment-status .shipment-header .payments {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .order-process-main-payment-status .shipment-header .shipment-code,
  .order-process-main-payment-status .shipment-header .shipment-status,
  .order-process-main-payment-status .shipment-header .payments {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-header .shipment-code,
  .order-process-main-payment-status .shipment-header .shipment-status,
  .order-process-main-payment-status .shipment-header .payments {
    display: block;
  }
}

.order-process-main-payment-status .shipment-header .shipment-code > h5,
.order-process-main-payment-status .shipment-header .shipment-status > h5,
.order-process-main-payment-status .shipment-header .payments > h5 {
  border-left: 1px solid lightgray;
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .shipment-header .shipment-code > h5,
  .order-process-main-payment-status .shipment-header .shipment-status > h5,
  .order-process-main-payment-status .shipment-header .payments > h5 {
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
}

.order-process-main-payment-status .shipment-header .shipment-code > div,
.order-process-main-payment-status .shipment-header .shipment-status > div,
.order-process-main-payment-status .shipment-header .payments > div {
  border-right: 1px solid lightgray;
  display: block;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .order-process-main-payment-status .shipment-header .shipment-code > div,
  .order-process-main-payment-status .shipment-header .shipment-status > div,
  .order-process-main-payment-status .shipment-header .payments > div {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-header .shipment-code > div,
  .order-process-main-payment-status .shipment-header .shipment-status > div,
  .order-process-main-payment-status .shipment-header .payments > div {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .shipment-header .shipment-code > div,
  .order-process-main-payment-status .shipment-header .shipment-status > div,
  .order-process-main-payment-status .shipment-header .payments > div {
    border-left: 1px solid lightgray;
    text-align: center;
  }
}

.order-process-main-payment-status .shipment-header .shipment-code > div .link-detail,
.order-process-main-payment-status .shipment-header .shipment-status > div .link-detail,
.order-process-main-payment-status .shipment-header .payments > div .link-detail {
  text-decoration: underline;
}

.order-process-main-payment-status .shipment-header .shipment-code > div .link-detail:hover,
.order-process-main-payment-status .shipment-header .shipment-status > div .link-detail:hover,
.order-process-main-payment-status .shipment-header .payments > div .link-detail:hover {
  text-decoration: none;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-header > div.code.shipment-code {
    border-bottom: 0;
  }
}

.order-process-main-payment-status .shipment-delivery-address .person {
  border-bottom: 1px solid lightgray;
}

@media all and (min-width: 600px) {
  .order-process-main-payment-status .shipment-delivery-address .person {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-delivery-address .person {
    display: block;
  }
}

.order-process-main-payment-status .shipment-delivery-address .person-label {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-delivery-address .person-label {
    border-right: 0;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .shipment-delivery-address .person-label {
    border-bottom: 1px solid lightgray;
  }
}

.order-process-main-payment-status .shipment-delivery-address .person-contents {
  border-right: 1px solid lightgray;
  padding: 10px;
}

@media all and (min-width: 600px) {
  .order-process-main-payment-status .shipment-delivery-address .person-contents {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-delivery-address .person-contents {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .shipment-delivery-address .person-contents {
    border-left: 1px solid lightgray;
    text-align: center;
  }
}

.order-process-main-payment-status .shipment-contents .delivery table {
  border-collapse: collapse;
  width: 100%;
}

.order-process-main-payment-status .shipment-contents .delivery table caption {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  display: block;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.order-process-main-payment-status .shipment-contents .delivery table tr {
  border-bottom: 1px solid lightgray;
  display: flex;
}

.order-process-main-payment-status .shipment-contents .delivery table th,
.order-process-main-payment-status .shipment-contents .delivery table td {
  display: block;
}

.order-process-main-payment-status .shipment-contents .delivery table th {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  font-weight: 400;
  flex: 0 0 260px;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-contents .delivery table th {
    flex: 0 0 160px;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .shipment-contents .delivery table th {
    flex: 0 0 145px;
  }
}

.order-process-main-payment-status .shipment-contents .delivery table td {
  border-right: 1px solid lightgray;
  padding: 10px;
  flex: 0 1 100%;
}

.order-process-main-payment-status .shipment-contents .order-items h4 {
  background-color: #f2f2f2;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  font-weight: normal;
  text-align: center;
  padding: 10px;
}

.order-process-main-payment-status .shipment-contents .itemdetails-header {
  display: none;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .order-process-main-payment-status .shipment-contents .rpoption {
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-payment-status .shipment-contents .rpoption {
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
}

.order-process-main-payment-status .applied-coupons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.order-process-main-payment-status .applied-coupons li {
  display: inline-block;
  background: -webkit-gradient(linear, right top, left top, from(#c10000), to(#d35900));
  background: -o-linear-gradient(right, #c10000 0%, #d35900 100%);
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.order-process-main-payment-status .order-buttons {
  text-align: center;
  margin-top: 60px;
}

.order-process-main-payment-status .order-buttons .btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-image: none;
  border: none;
  color: #fff;
  padding: 15px 10px 15px 12px;
}

@media (hover: hover) and (pointer: fine) {
  .order-process-main-payment-status .order-buttons .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .order-process-main-payment-status .order-buttons .btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.order-process-main-payment-status .order-buttons .btn::after {
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  z-index: -2;
}

.order-process-main-payment-status .order-buttons .btn:hover {
  background-image: none;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

.order-process-main-payment-status .order-buttons .btn:hover::before {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.order-process-main-payment-status .order-buttons .btn::before, .order-process-main-payment-status .order-buttons .btn::after {
  content: "";
  display: block;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.order-process-main-payment-status .order-buttons .btn::before {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  z-index: -1;
}

.order-process-main-payment-status .order-buttons .note {
  margin-top: 15px;
}

.order-process-main-payment-status .panel-footer {
  margin-top: 30px;
}

/********** Payment | お支払い方法 **********/
/*
  ----------------
  .form-payment | お支払い方法
  ----------------
*/
.form-payment {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 850px) {
  .form-payment {
    padding: 0 30px 120px;
  }
}

@media all and (max-width: 599px) {
  .form-payment {
    padding-top: 61px;
  }
}

.form-payment h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .form-payment h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

@media all and (max-width: 599px) {
  .form-payment .form-note {
    padding: 0;
    text-align-last: center;
  }
}

.form-payment h2 {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  margin-top: 50px;
  border-bottom: 1px solid #171717;
  padding-bottom: 10px;
  text-align: left;
}

@media all and (max-width: 599px) {
  .form-payment h2 {
    font-size: 20px;
    margin-top: 20px;
    padding-bottom: 5px;
  }
}

.form-payment #paymet-order-invoice .amount-table {
  width: 100%;
}

.form-payment #paymet-order-invoice table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}

.form-payment #paymet-order-invoice th,
.form-payment #paymet-order-invoice td {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid lightgray;
  padding: 10px 20px;
}

@media all and (max-width: 599px) {
  .form-payment #paymet-order-invoice th,
  .form-payment #paymet-order-invoice td {
    font-size: 14px;
    font-weight: 500;
  }
}

.form-payment #paymet-order-invoice th {
  background: #f2f2f2;
  border-right: none;
}

.form-payment .form-body {
  background: none;
  padding: 0;
}

.form-payment .wrapper {
  margin-top: 1rem;
}

.form-payment .wrapper .paymententry-note table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}

.form-payment .wrapper .paymententry-note th,
.form-payment .wrapper .paymententry-note td {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid lightgray;
  padding: 10px 20px;
}

@media all and (max-width: 599px) {
  .form-payment .wrapper .paymententry-note th,
  .form-payment .wrapper .paymententry-note td {
    font-size: 14px;
    font-weight: 500;
  }
}

.form-payment .wrapper .paymententry-note th {
  background: #f2f2f2;
  border-right: none;
}

@media all and (min-width: 600px) {
  .form-payment .wrapper .paymententry-note th {
    width: 418px;
  }
}

.form-payment .wrapper .control {
  text-align: center;
}

.form-payment .wrapper .control .btn-payment {
  margin-top: 30px;
  max-width: 350px;
}

.form-payment #select-payment-method .payment-method {
  padding-top: 1rem;
}

.form-payment #select-payment-method .payment-method:not(:first-child) {
  border-top: 1px solid lightgray;
  margin-top: 2rem;
}

.form-payment #select-payment-method .payment-method .pm-wrapper.open .pm-label:before {
  transform: translateY(3px) rotate(90deg);
}

.form-payment #select-payment-method .payment-method .pm-label {
  position: relative;
}

@media all and (max-width: 599px) {
  .form-payment #select-payment-method .payment-method:nth-child(2) {
    padding-top: 20px;
  }
}

.form-payment #select-payment-method .payment-method:nth-child(2) .pm-label {
  display: inline-block;
  position: relative;
}

.form-payment #select-payment-method .payment-method:nth-child(3) .pm-label {
  display: inline-block;
  position: relative;
}

.form-payment #select-payment-method .payment-method:last-child {
  border-bottom: 1px solid lightgray;
  padding-bottom: 2rem;
}

.form-payment #select-payment-method .pm-body {
  margin-top: 1rem;
}

.form-payment #select-payment-method .pm-body .formrow {
  border: 1px solid lightgray;
  margin-top: 0;
  display: flex;
}

.form-payment #select-payment-method .pm-body .formrow:not(:first-child), .form-payment #select-payment-method .pm-body .formrow.formrow-checkbox {
  border-top: none;
}

@media all and (max-width: 599px) {
  .form-payment #select-payment-method .pm-body .formrow {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
  }
}

.form-payment #select-payment-method .pm-body .input-label {
  background-color: #f2f2f2;
  border-right: 1px solid lightgray;
  font-weight: bold;
  padding: 10px 20px;
  display: flex;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
}

@media all and (min-width: 600px) {
  .form-payment #select-payment-method .pm-body .input-label {
    width: 418px;
  }
}

@media all and (max-width: 599px) {
  .form-payment #select-payment-method .pm-body .input-label {
    font-size: 1.6rem;
    padding: 0.5rem;
    width: 100%;
  }
}

.form-payment #select-payment-method .pm-body .input-control {
  padding: 10px 20px;
}

.form-payment #select-payment-method .pm-body .input-control .input-group .date.date-mm {
  margin-right: 28px;
  margin-left: 0;
}

@media all and (max-width: 599px) {
  .form-payment #select-payment-method .pm-body .input-control .input-group .date.date-mm {
    margin-right: 12px;
  }
}

.form-payment #select-payment-method .pm-label {
  font-weight: 400;
  font-size: 20px;
}

@media all and (max-width: 599px) {
  .form-payment #select-payment-method .pm-label {
    font-size: 15px;
  }
}

.form-payment #select-payment-method .pm-trailing {
  text-align: center;
}

.form-payment #select-payment-method .btn-payment {
  display: inline-block;
  max-width: 350px;
  margin-top: 30px;
  padding: 10px;
  width: 100%;
}

.form-payment #select-payment-method .pm-use-as-default {
  margin-top: 1rem;
}

.form-payment .input-may-required {
  border: 1px solid #9b0000;
  border-radius: 10px;
  color: #9b0000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08em;
  margin-left: 1rem;
  height: 21px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .form-payment .input-may-required {
    height: 20px;
  }
}

.form-payment .order-cancel-edit {
  text-align: center;
  margin-top: 2rem;
}

/********** Consignee | お届け先の編集 **********/
/*
  ----------------
  .form-consignee | お届け先の編集
  ----------------
*/
.form-consignee {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 850px) {
  .form-consignee {
    padding: 0 30px 120px;
  }
}

@media all and (max-width: 599px) {
  .form-consignee {
    padding-top: 61px;
  }
}

.form-consignee h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .form-consignee h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

@media all and (max-width: 599px) {
  .form-consignee .form-note {
    padding: 0;
    text-align-last: center;
  }
}

@media all and (min-width: 600px) {
  .form-consignee .form-body {
    padding-bottom: 70px;
  }
}

@media all and (max-width: 599px) {
  .form-consignee .form-body {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.form-consignee .form-body .formrow-submit {
  margin-top: 40px;
}

.form-consignee .order-cancel-edit {
  margin-top: 40px;
}

/********** Confirm | 注文内容の確認 **********/
/*
  ----------------
  #order-entry | 注文内容の確認
  ----------------
*/
#order-entry,
.order-process-main-payment-status {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 850px) {
  #order-entry,
  .order-process-main-payment-status {
    padding: 110px 30px 120px;
  }
}

@media all and (max-width: 599px) {
  #order-entry,
  .order-process-main-payment-status {
    padding-top: 61px;
  }
}

#order-entry h1,
.order-process-main-payment-status h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  #order-entry h1,
  .order-process-main-payment-status h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  #order-entry #order-entry-content #publish-coupon > div.body,
  .order-process-main-payment-status #order-entry-content #publish-coupon > div.body {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (max-width: 599px) {
  #order-entry #apply-coupons .coupon,
  .order-process-main-payment-status #apply-coupons .coupon {
    width: calc(50% - 7.5px);
  }
}

@media all and (max-width: 599px) {
  #order-entry #apply-coupons .coupon .btn,
  .order-process-main-payment-status #apply-coupons .coupon .btn {
    max-width: 100%;
  }
}

#order-entry .form-note,
.order-process-main-payment-status .form-note {
  margin: 0;
  padding: 0;
}

#order-entry #page-title + .order-confirm-upselling .cartMessage_order_edit,
.order-process-main-payment-status #page-title + .order-confirm-upselling .cartMessage_order_edit {
  display: block;
}

#order-entry .order-confirm-upselling,
.order-process-main-payment-status .order-confirm-upselling {
  margin-top: 20px;
  text-align: center;
}

#order-entry .order-confirm-upselling .order-upselling-contents,
.order-process-main-payment-status .order-confirm-upselling .order-upselling-contents {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media all and (max-width: 599px) {
  #order-entry .order-confirm-upselling .order-upselling-contents,
  .order-process-main-payment-status .order-confirm-upselling .order-upselling-contents {
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

#order-entry .order-confirm-upselling .anchorlink,
.order-process-main-payment-status .order-confirm-upselling .anchorlink {
  max-width: 390px;
  margin: 0 auto;
  width: 100%;
}

@media all and (max-width: 599px) {
  #order-entry .order-confirm-upselling .anchorlink,
  .order-process-main-payment-status .order-confirm-upselling .anchorlink {
    max-width: 500px;
  }
}

#order-entry .order-confirm-upselling .cartMessage_order_edit,
.order-process-main-payment-status .order-confirm-upselling .cartMessage_order_edit {
  display: none;
  margin-right: 50px;
}

@media all and (max-width: 599px) {
  #order-entry .order-confirm-upselling .cartMessage_order_edit,
  .order-process-main-payment-status .order-confirm-upselling .cartMessage_order_edit {
    margin-top: 20px;
    margin-right: 0;
  }
}

#order-entry .order-confirm-upselling .cartMessage_order_edit a,
.order-process-main-payment-status .order-confirm-upselling .cartMessage_order_edit a {
  max-width: 370px;
  margin: 0 auto;
  width: 100%;
}

@media all and (max-width: 599px) {
  #order-entry .order-confirm-upselling .cartMessage_order_edit a,
  .order-process-main-payment-status .order-confirm-upselling .cartMessage_order_edit a {
    max-width: 500px;
  }
}

#order-entry .order-confirm-upselling .order-upselling-form .checkbox,
.order-process-main-payment-status .order-confirm-upselling .order-upselling-form .checkbox {
  display: none;
}

#order-entry .checkout,
.order-process-main-payment-status .checkout {
  text-align: center;
}

#order-entry .checkout button,
.order-process-main-payment-status .checkout button {
  max-width: 250px;
  padding: 10px 0;
}

#order-entry h2,
.order-process-main-payment-status h2 {
  margin-top: 3rem;
}

@media (max-width: 768px) {
  #order-entry h2,
  .order-process-main-payment-status h2 {
    margin-top: 60px;
  }
}

#order-entry h3,
.order-process-main-payment-status h3 {
  border-bottom: 1px solid #171717;
  font-weight: 400;
  margin-top: 50px;
  padding-bottom: 10px;
  text-align: left;
}

@media all and (max-width: 599px) {
  #order-entry h3,
  .order-process-main-payment-status h3 {
    margin-top: 60px;
  }
}

#order-entry h4,
.order-process-main-payment-status h4 {
  font-weight: bold;
}

#order-entry .coupons,
.order-process-main-payment-status .coupons {
  margin-top: 15px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

#order-entry .coupons .coupon .btn-apply-coupon,
.order-process-main-payment-status .coupons .coupon .btn-apply-coupon {
  text-decoration: none;
}

#order-entry #publish-coupon .body,
.order-process-main-payment-status #publish-coupon .body {
  margin-top: 3rem;
  padding-right: 2rem;
}

#order-entry #publish-coupon .body .input-group,
.order-process-main-payment-status #publish-coupon .body .input-group {
  flex-wrap: nowrap;
}

#order-entry #publish-coupon .body .input-group .form-control,
.order-process-main-payment-status #publish-coupon .body .input-group .form-control {
  border-color: #171717;
  margin-right: 10px;
}

#order-entry #publish-coupon .body .input-group .btn-publish-coupon,
.order-process-main-payment-status #publish-coupon .body .input-group .btn-publish-coupon {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  #order-entry #publish-coupon .body .input-group .btn-publish-coupon,
  .order-process-main-payment-status #publish-coupon .body .input-group .btn-publish-coupon {
    font-size: 12px;
    max-width: 120px;
    padding: 10px;
  }
}

#order-entry #order-entry-order,
.order-process-main-payment-status #order-entry-order {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  #order-entry #order-entry-order,
  .order-process-main-payment-status #order-entry-order {
    display: block;
  }
}

@media (max-width: 768px) {
  #order-entry #order-entry-order .panel,
  .order-process-main-payment-status #order-entry-order .panel {
    margin-left: 0;
  }
}

#order-entry .btn-apply-coupon,
.order-process-main-payment-status .btn-apply-coupon {
  text-decoration: underline;
}

#order-entry .panel,
.order-process-main-payment-status .panel {
  flex: 1;
  margin-top: 3rem;
  position: relative;
}

@media all and (max-width: 599px) {
  #order-entry .panel,
  .order-process-main-payment-status .panel {
    margin-top: 4rem;
  }
}

#order-entry .panel.payment,
.order-process-main-payment-status .panel.payment {
  margin-left: 3rem;
  padding-left: 0;
  padding-right: 0;
}

@media all and (max-width: 599px) {
  #order-entry .panel.payment,
  .order-process-main-payment-status .panel.payment {
    margin-left: 0;
  }
}

#order-entry .panel .header,
.order-process-main-payment-status .panel .header {
  background-color: #f2f2f2;
  padding: 1em;
  width: 100%;
}

#order-entry .panel .body,
.order-process-main-payment-status .panel .body {
  position: relative;
  margin-top: 1rem;
  padding-left: 2rem;
}

@media (max-width: 768px) {
  #order-entry .panel .body,
  .order-process-main-payment-status .panel .body {
    padding-left: 0;
  }
}

#order-entry .panel .person-contents,
.order-process-main-payment-status .panel .person-contents {
  padding: 0.5em;
}

@media all and (max-width: 599px) {
  #order-entry .panel .person-contents,
  .order-process-main-payment-status .panel .person-contents {
    padding: 0;
  }
}

#order-entry .panel .delivery-service-conf li,
.order-process-main-payment-status .panel .delivery-service-conf li {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

#order-entry .panel .delivery-service-conf li:first-of-type,
.order-process-main-payment-status .panel .delivery-service-conf li:first-of-type {
  display: none;
}

#order-entry .panel .delivery-service-conf .dlv-flags li,
.order-process-main-payment-status .panel .delivery-service-conf .dlv-flags li {
  display: block;
}

#order-entry .panel .delivery-service-conf li h4,
.order-process-main-payment-status .panel .delivery-service-conf li h4 {
  flex-basis: 20%;
}

#order-entry .panel .delivery-service-conf li:not(:first-child),
.order-process-main-payment-status .panel .delivery-service-conf li:not(:first-child) {
  margin-top: 1rem;
}

#order-entry .itemdetails-header,
.order-process-main-payment-status .itemdetails-header {
  margin-top: 8rem;
}

@media all and (max-width: 599px) {
  #order-entry .itemdetails-body,
  .order-process-main-payment-status .itemdetails-body {
    margin-top: 4rem;
  }
}

#order-entry .itemdetails-body .remove-one .btn,
.order-process-main-payment-status .itemdetails-body .remove-one .btn {
  padding: 0 1rem;
}

#order-entry .itemdetails-body .remove-one .btn:hover,
.order-process-main-payment-status .itemdetails-body .remove-one .btn:hover {
  cursor: pointer;
}

#order-entry .itemdetails-caption,
.order-process-main-payment-status .itemdetails-caption {
  padding: 0 2rem;
}

@media all and (max-width: 599px) {
  #order-entry .itemdetails-caption,
  .order-process-main-payment-status .itemdetails-caption {
    padding: 0;
  }
}

#order-entry .itemdetails-caption .active .label,
.order-process-main-payment-status .itemdetails-caption .active .label {
  color: #9b0000;
  font-weight: bold;
}

#order-entry .itemdetails-caption .active .label:after,
.order-process-main-payment-status .itemdetails-caption .active .label:after {
  border-left-color: #9b0000;
}

#order-entry .itemdetails-caption .move-item-dst li[data-dst-param="copy"],
.order-process-main-payment-status .itemdetails-caption .move-item-dst li[data-dst-param="copy"] {
  display: none;
}

#order-entry div.move-item-wrapper ul.move-item-dst > li.inactive,
.order-process-main-payment-status div.move-item-wrapper ul.move-item-dst > li.inactive {
  background-color: transparent;
}

#order-entry .control,
.order-process-main-payment-status .control {
  position: absolute;
  top: 0.75em;
  right: 1em;
  text-align: right;
}

@media all and (min-width: 600px) {
  #order-entry .control,
  .order-process-main-payment-status .control {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media all and (max-width: 599px) {
  #order-entry .control,
  .order-process-main-payment-status .control {
    position: static;
    text-align: center;
  }
}

#order-entry .control a:not(.btn-use-points),
.order-process-main-payment-status .control a:not(.btn-use-points) {
  background-color: #fff;
  border: 1px solid #000;
  padding: 0 1rem;
}

@media all and (max-width: 599px) {
  #order-entry .control a:not(.btn-use-points),
  .order-process-main-payment-status .control a:not(.btn-use-points) {
    margin-top: 3rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (max-width: 599px) {
  #order-entry .control.allow-update-deliveryrequest-control,
  .order-process-main-payment-status .control.allow-update-deliveryrequest-control {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  #order-entry .control a.btn-edit-deliveryservice,
  .order-process-main-payment-status .control a.btn-edit-deliveryservice {
    margin-top: 2rem;
    margin-left: 0;
  }
}

@media all and (min-width: 600px) {
  #order-entry .header .header .btn,
  .order-process-main-payment-status .header .header .btn {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    display: inline-block;
    font-size: 1.3rem;
    padding: 0 10px;
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  #order-entry .header .header .btn,
  .order-process-main-payment-status .header .header .btn {
    padding: 10px 20px;
    margin-top: 1rem;
  }
}

@media all and (max-width: 599px) {
  #order-entry .body .control .btn,
  .order-process-main-payment-status .body .control .btn {
    margin-top: 10px;
  }
}

#order-entry .body .control .btn img,
.order-process-main-payment-status .body .control .btn img {
  max-width: 150px;
}

@media all and (max-width: 599px) {
  #order-entry .body .control .btn img,
  .order-process-main-payment-status .body .control .btn img {
    max-width: 170px;
  }
}

#order-entry .addressbook,
.order-process-main-payment-status .addressbook {
  text-align: center;
}

@media all and (min-width: 600px) {
  #order-entry .addressbook .btn-addressbook,
  .order-process-main-payment-status .addressbook .btn-addressbook {
    max-width: 220px;
  }
}

@media all and (max-width: 599px) {
  #order-entry .payment-total-invoice,
  .order-process-main-payment-status .payment-total-invoice {
    margin-top: 2rem;
  }
}

@media all and (max-width: 599px) {
  #order-entry .panel-view,
  .order-process-main-payment-status .panel-view {
    margin-top: 1rem;
  }
}

#order-entry .move-item-toggle,
.order-process-main-payment-status .move-item-toggle {
  margin-top: 3rem;
  padding-left: 2rem;
}

@media (max-width: 768px) {
  #order-entry .move-item-toggle,
  .order-process-main-payment-status .move-item-toggle {
    padding-left: 0;
  }
}

#order-entry .move-item-toggle .btn-moveitem.disable-moveitem,
.order-process-main-payment-status .move-item-toggle .btn-moveitem.disable-moveitem {
  position: relative;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  #order-entry .move-item-toggle .btn-moveitem.disable-moveitem,
  .order-process-main-payment-status .move-item-toggle .btn-moveitem.disable-moveitem {
    padding: 0 1.5rem;
  }
}

#order-entry .move-item-toggle .btn-moveitem.disable-moveitem::after,
.order-process-main-payment-status .move-item-toggle .btn-moveitem.disable-moveitem::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  margin: auto;
  box-sizing: border-box;
  border: 5px solid transparent;
  border-left: 8px solid #555;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  #order-entry .move-item-toggle .btn-moveitem.disable-moveitem::after,
  .order-process-main-payment-status .move-item-toggle .btn-moveitem.disable-moveitem::after {
    left: 0;
  }
}

#order-entry .move-item-toggle .btn-moveitem.disable-moveitem .btn,
.order-process-main-payment-status .move-item-toggle .btn-moveitem.disable-moveitem .btn {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  text-align: left;
  text-decoration: underline;
}

#order-entry .move-item-toggle .btn-moveitem.enable-moveitem,
.order-process-main-payment-status .move-item-toggle .btn-moveitem.enable-moveitem {
  text-align: center;
}

#order-entry .move-item-toggle .btn-moveitem.enable-moveitem .btn,
.order-process-main-payment-status .move-item-toggle .btn-moveitem.enable-moveitem .btn {
  max-width: 410px;
}

@media (max-width: 768px) {
  #order-entry .move-item-wrapper-main,
  .order-process-main-payment-status .move-item-wrapper-main {
    padding: 0 1.5rem;
  }
}

#order-entry .new-destination-consignee,
.order-process-main-payment-status .new-destination-consignee {
  margin-top: 8rem;
}

@media all and (max-width: 599px) {
  #order-entry .new-destination-consignee,
  .order-process-main-payment-status .new-destination-consignee {
    margin-top: 4rem;
  }
}

#order-entry .form,
.order-process-main-payment-status .form {
  margin-top: 1rem;
}

#order-entry .form-body,
.order-process-main-payment-status .form-body {
  margin: 4rem -4rem 0;
  padding: 50px 8.5406%;
}

@media all and (max-width: 599px) {
  #order-entry .form-body,
  .order-process-main-payment-status .form-body {
    padding: 25px 30px;
  }
}

#order-entry .move-item-dst li,
.order-process-main-payment-status .move-item-dst li {
  margin-top: 1rem;
  padding: 0 2rem;
}

@media (max-width: 768px) {
  #order-entry .move-item-dst li,
  .order-process-main-payment-status .move-item-dst li {
    padding: 0;
  }
}

#order-entry .move-item-dst li .label,
.order-process-main-payment-status .move-item-dst li .label {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
}

#order-entry .move-item-dst li .label::after,
.order-process-main-payment-status .move-item-dst li .label::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: -15px;
  margin: auto;
  box-sizing: border-box;
  border: 5px solid transparent;
  border-left: 8px solid #555;
  margin-bottom: 20px;
}

#order-entry .move-item-dst li:nth-of-type(n + 3),
.order-process-main-payment-status .move-item-dst li:nth-of-type(n + 3) {
  display: none;
}

#order-entry .shipment-items-total,
.order-process-main-payment-status .shipment-items-total {
  margin-top: 8rem;
}

@media all and (max-width: 599px) {
  #order-entry .shipment-items-total,
  .order-process-main-payment-status .shipment-items-total {
    margin-top: 4rem;
  }
}

#order-entry .shipment-items-total .row,
.order-process-main-payment-status .shipment-items-total .row {
  justify-content: center;
}

#order-entry .update-present-option,
.order-process-main-payment-status .update-present-option {
  display: none;
}

#order-entry .present-option-add,
.order-process-main-payment-status .present-option-add {
  background-color: #ceefff;
  font-weight: 700;
  padding: 2rem 0;
  align-items: center;
  display: flex;
  margin-top: 3rem;
  text-align: center;
  justify-content: center;
  font-size: 2rem;
}

#order-entry .present-option-add__note,
.order-process-main-payment-status .present-option-add__note {
  text-align: right;
}

#order-entry .present-applied .caption,
.order-process-main-payment-status .present-applied .caption {
  height: 0;
  overflow: hidden;
}

#order-entry .present-option-add__note,
.order-process-main-payment-status .present-option-add__note {
  display: none;
}

#order-entry .present-list,
.order-process-main-payment-status .present-list {
  background-color: #ceefff;
  font-weight: 700;
  padding: 2rem 0;
  align-items: center;
  display: flex;
  margin-top: 50px;
  text-align: center;
  justify-content: center;
  font-size: 2rem;
}

#order-entry .present-list li + li,
.order-process-main-payment-status .present-list li + li {
  margin-top: 10px;
}

#order-entry .present-list .present-option-code,
.order-process-main-payment-status .present-list .present-option-code {
  display: none;
}

@media all and (max-width: 599px) {
  #order-entry .present-list .present-3380,
  .order-process-main-payment-status .present-list .present-3380 {
    font-size: 12px;
  }
}

#order-entry .present-list .present-3380 .property,
.order-process-main-payment-status .present-list .present-3380 .property {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

#order-entry .present-list .present-3380 .description:after,
.order-process-main-payment-status .present-list .present-3380 .description:after {
  content: '：';
}

@media all and (max-width: 599px) {
  #order-entry .present-list .present-P001,
  .order-process-main-payment-status .present-list .present-P001 {
    font-size: 12px;
  }
}

#order-entry .present-list .present-P001 .property,
.order-process-main-payment-status .present-list .present-P001 .property {
  display: inline;
}

#order-entry .present-list .present-P001 .property:after,
.order-process-main-payment-status .present-list .present-P001 .property:after {
  content: '：';
}

#order-entry .present-list .present-P001 .present-option,
.order-process-main-payment-status .present-list .present-P001 .present-option {
  display: inline;
}

#order-entry .present-list .present-P001 .description,
.order-process-main-payment-status .present-list .present-P001 .description {
  display: inline;
}

#order-entry .move-item-submit .submit-moveitem,
.order-process-main-payment-status .move-item-submit .submit-moveitem {
  margin-top: 40px;
  text-align: center;
}

#order-entry .move-item-submit .submit-moveitem .btn,
.order-process-main-payment-status .move-item-submit .submit-moveitem .btn {
  max-width: 310px;
  max-width: fit-content;
  padding: 10px 20px;
  text-decoration: none;
}

@media all and (max-width: 599px) {
  #order-entry .move-item-submit .submit-moveitem .btn,
  .order-process-main-payment-status .move-item-submit .submit-moveitem .btn {
    width: 100%;
  }
}

#order-entry .goods-text .update-itemoptions a,
.order-process-main-payment-status .goods-text .update-itemoptions a {
  margin-top: 10px;
  text-decoration: none;
}

#order-entry .goods-text .unit-price,
.order-process-main-payment-status .goods-text .unit-price {
  margin-top: 10px;
}

#order-entry .goods-text .applied-promotions,
.order-process-main-payment-status .goods-text .applied-promotions {
  display: none;
}

#order-entry .amount-table,
.order-process-main-payment-status .amount-table {
  margin-top: 3rem;
  width: 100%;
}

#order-entry .amount-table table,
.order-process-main-payment-status .amount-table table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}

#order-entry .amount-table th,
#order-entry .amount-table td,
.order-process-main-payment-status .amount-table th,
.order-process-main-payment-status .amount-table td {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid lightgray;
  padding: 10px 20px;
}

@media all and (max-width: 599px) {
  #order-entry .amount-table th,
  #order-entry .amount-table td,
  .order-process-main-payment-status .amount-table th,
  .order-process-main-payment-status .amount-table td {
    font-size: 14px;
    font-weight: 500;
  }
}

#order-entry .amount-table th,
.order-process-main-payment-status .amount-table th {
  background: #f2f2f2;
  border-right: none;
}

#order-entry #detach-coupons ul,
.order-process-main-payment-status #detach-coupons ul {
  padding-top: 15px;
}

#order-entry #detach-coupons .detach-enabled,
.order-process-main-payment-status #detach-coupons .detach-enabled {
  margin-top: 5px;
}

#order-entry #detach-coupons .cpn-exp,
.order-process-main-payment-status #detach-coupons .cpn-exp {
  display: inline-block;
  margin-left: 10px;
}

#order-entry #detach-coupons .date,
.order-process-main-payment-status #detach-coupons .date {
  margin-left: 4px;
}

#order-entry #detach-coupons .btn-detack-coupon,
.order-process-main-payment-status #detach-coupons .btn-detack-coupon {
  margin-top: 15px;
}

@media (max-width: 469px) {
  #order-entry #detach-coupons ul,
  .order-process-main-payment-status #detach-coupons ul {
    padding-top: 20px;
  }
  #order-entry #detach-coupons .detach-enabled:nth-child(2),
  .order-process-main-payment-status #detach-coupons .detach-enabled:nth-child(2) {
    margin-top: 15px;
  }
  #order-entry #detach-coupons label,
  .order-process-main-payment-status #detach-coupons label {
    display: inline-block;
    line-height: 1.75;
  }
  #order-entry #detach-coupons .btn-detack-coupon,
  .order-process-main-payment-status #detach-coupons .btn-detack-coupon {
    margin-top: 20px;
  }
}

/********** Complete | 注文処理の完了 **********/
/*
  ----------------
  .order-process-main-complete | 注文処理の完了
  ----------------
*/
.order-process-main-complete {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 850px) {
  .order-process-main-complete {
    padding: 0 30px 120px;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-complete {
    padding-top: 61px;
  }
}

.order-process-main-complete h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .order-process-main-complete h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

.order-process-main-complete h3 {
  font-weight: normal;
  margin-top: 40px;
  text-align: center;
}

.order-process-main-complete .main-message {
  font-size: 20px;
  margin-top: 40px;
  text-align: center;
}

.order-process-main-complete .main-contents {
  margin-top: 30px;
  text-align: center;
}

.order-process-main-complete .message {
  margin: auto;
  max-width: 700px;
}

.order-process-main-complete .order-code,
.order-process-main-complete .request-paymentmethod-reset {
  margin-top: 2px;
}

.order-process-main-complete .proto-signin-init-password {
  margin-top: 80px;
}

@media all and (max-width: 599px) {
  .order-process-main-complete .proto-signin-init-password {
    margin-top: 60px;
  }
}

@media all and (max-width: 599px) {
  .order-process-main-complete .proto-signin-init-password h2 {
    font-size: 22px;
  }
}

.order-process-main-complete .proto-signin-init-password .form-body {
  padding: 60px 8.5406% 30px;
}

@media all and (max-width: 599px) {
  .order-process-main-complete .proto-signin-init-password .form-body {
    padding-top: 30px;
  }
}

.order-process-main-complete .proto-signin-init-password .input-note {
  text-align: left;
}

@media all and (max-width: 599px) {
  .order-process-main-complete .proto-signin-init-password .btn-submit {
    margin-top: 20px;
  }
}

.order-process-main-complete form .description {
  margin-top: 30px;
}

.order-process-main-complete .view-order-detail a,
.order-process-main-complete .form-reset-paymentmethod button {
  border: 1px solid #171717;
  box-sizing: border-box;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  margin-top: 30px;
  height: 50px;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 300px;
  width: 100%;
}

/********** Points | ポイント利用設定 **********/
/*
  ----------------
  .form-points | ポイント利用設定
  ----------------
*/
.form-points {
  padding: 110px 80px 120px;
}

@media all and (max-width: 850px) {
  .form-points {
    padding: 110px 30px 120px;
  }
}

@media all and (max-width: 599px) {
  .form-points {
    padding-top: 60px;
  }
}

.form-points h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .form-points h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

.form-points .form-body {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.form-points .form-body .btn-submit {
  margin-top: 40px;
}

/********** Sender | 送り主の編集 **********/
/*
  ----------------
  .form-sender | 送り主の編集
  ----------------
*/
.form-sender {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 850px) {
  .form-sender {
    padding: 0 30px 120px;
  }
}

@media all and (max-width: 599px) {
  .form-sender {
    padding-top: 61px;
  }
}

.form-sender h1 {
  margin-top: 40px;
  text-align: center;
}

@media all and (max-width: 850px) {
  .form-sender h1 {
    font-size: 28px;
    margin-top: 30px;
  }
}

@media all and (max-width: 599px) {
  .form-sender .form-note {
    padding: 0;
    text-align-last: center;
  }
}

@media all and (min-width: 600px) {
  .form-sender .form-body {
    padding-bottom: 70px;
  }
}

@media all and (max-width: 599px) {
  .form-sender .form-body {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.form-sender .form-body .formrow-submit {
  margin-top: 40px;
}

.form-sender .order-cancel-edit {
  margin-top: 40px;
}

/********** Sender | 受注エラー **********/
/*
  ----------------
  Error page
  ----------------
*/
.order__order_error #container {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 200px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}

@media all and (min-width: 600px) {
  .order__order_error #container {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .order__order_error #container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 599px) {
  .order__order_error #container {
    padding-top: 100px;
  }
}

.order__order_error h1 {
  font-size: 40px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .order__order_error h1 {
    font-size: 36px;
  }
}

.order__order_error .signin-form {
  padding: 0;
}

.order__order_error .signin-form h3 {
  font-size: 40px;
  font-weight: normal;
  line-height: 1.75;
  text-align: center;
}

@media all and (max-width: 599px) {
  .order__order_error .signin-form h3 {
    font-size: 28px;
    line-height: 1.6;
    letter-spacing: 0.08em;
  }
}

.order__order_error .signin-form .form-note {
  margin: 43px 0;
}

@media all and (max-width: 599px) {
  .order__order_error .signin-form .form-note {
    margin: 30px 0;
  }
}

.order__order_error .error-heading {
  font-size: 20px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .order__order_error .error-heading {
    font-size: 18px;
  }
}

.order__order_error .error-message {
  line-height: 1.5;
  margin: 40px auto 0;
  max-width: 700px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .order__order_error .error-message {
    text-align: left;
  }
}

/********** Updatepass | パスワード更新 **********/
/*
  ----------------
  #updatepass-main | パスワードの更新
  ----------------
*/
#update_password .form-update-password-by-token {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
}

@media all and (min-width: 600px) {
  #update_password .form-update-password-by-token {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  #update_password .form-update-password-by-token {
    padding-right: 30px;
    padding-left: 30px;
  }
}

#update_password div.btn-submit {
  margin-top: 1rem;
  text-align: center;
}

/********** Riminder | 仮パスワードの発行 **********/
/*
  ----------------
  .entry-reminder-update | 仮パスワードの発行
  ----------------
*/
@media all and (min-width: 600px) and (max-width: 850px) {
  .entry-reminder-update {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.entry-reminder-update .form-body .signout {
  width: 100%;
}

.entry-reminder-update .form-body .signout > a {
  margin-top: 20px;
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  background-color: #fff;
  display: inline-block;
  max-width: none;
  width: auto;
}

@media (hover: hover) and (pointer: fine) {
  .entry-reminder-update .form-body .signout > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.entry_password_reminder #container {
  padding-top: 110px;
}

@media all and (max-width: 599px) {
  .entry_password_reminder #container {
    padding-top: 80px;
  }
}

.entry_password_reminder .msg {
  text-align: center;
}

.entry_password_reminder .msg .msg-head {
  font-size: 20px;
  text-align: center;
}

.entry_password_reminder .msg .msg-body {
  display: inline-block;
  line-height: 1.5;
  margin: 40px auto 0;
  max-width: 700px;
  text-align: left;
}

.entry_password_reminder .btn-submit .link-updatepass {
  margin-top: 40px;
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
}

@media all and (max-width: 599px) {
  .entry_password_reminder .btn-submit .link-updatepass {
    margin-top: 20px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .entry_password_reminder .btn-submit .link-updatepass:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

/********** List | 商品一覧 **********/
/*
  ----------------
  .item-list-fv | 商品一覧FV
  ----------------
*/
@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-list-fv {
    padding-top: 109px;
  }
}

.item-list-fv__wrap {
  position: relative;
}

.item-list-fv__img {
  position: relative;
}

@media all and (max-width: 599px) {
  .item-list-fv__img {
    height: calc(100vw * 1.34871795);
  }
}

.item-list-fv__img::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #171717;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}

.item-list-fv__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 33px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-list-fv__box {
    top: 50%;
  }
}

@media all and (max-width: 599px) {
  .item-list-fv__box {
    top: calc(50% + 11px);
  }
}

.item-list-fv__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .item-list-fv__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .item-list-fv__title {
    font-size: 25px;
  }
}

@media all and (max-width: 599px) {
  .item-list-fv__title {
    font-weight: 500;
    line-height: 2;
  }
}

/*
  ----------------
  .item-list-lead | 商品一覧 FV下リード文
  ----------------
*/
.item-list-lead {
  margin-top: 6.51537335%;
}

@media all and (min-width: 600px) and (max-width: 1222px) {
  .item-list-lead {
    margin-top: 7.36497545%;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead {
    margin-top: 45px;
  }
}

@media all and (min-width: 600px) {
  .item-list-lead__wrap {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 80px;
    display: flex;
    align-items: center;
  }
}

@media all and (min-width: 600px) and (max-width: 1222px) {
  .item-list-lead__wrap {
    padding: 0 6.54664484%;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead__header {
    padding: 0 33px;
  }
}

.item-list-lead__header-wrap {
  border: 1px solid #171717;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media all and (min-width: 600px) {
  .item-list-lead__header-wrap {
    width: 320px;
    height: 320px;
  }
}

@media screen and (min-width: 787px) and (max-width: 1222px) {
  .item-list-lead__header-wrap {
    width: 260px;
    height: 260px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .item-list-lead__header-wrap {
    width: 200px;
    height: 200px;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead__header-wrap {
    padding: 26px 0 29px;
    width: 100%;
  }
}

.item-list-lead__header-title {
  width: 100%;
  max-width: 233px;
  height: 59px;
  margin: 0 auto;
}

@media all and (min-width: 600px) {
  .item-list-lead__header-title {
    padding-top: 7px;
  }
}

@media screen and (min-width: 787px) and (max-width: 1222px) {
  .item-list-lead__header-title {
    max-width: 194px;
    height: 49px;
  }
}

@media screen and (min-width: 600px) and (max-width: 786px) {
  .item-list-lead__header-title {
    max-width: 174px;
    height: 40px;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead__header-title {
    max-width: 194px;
    height: 49px;
  }
}

.item-list-lead__header-text {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 2.14285714;
  margin-top: 8px;
}

@media all and (min-width: 600px) and (max-width: 1222px) {
  .item-list-lead__header-text {
    font-size: 12px;
    margin-top: 0;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead__header-text {
    letter-spacing: 0.08em;
    margin-top: -12px;
  }
}

@media all and (min-width: 600px) {
  .item-list-lead__body {
    margin-left: 6.38474295%;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead__body {
    padding: 0 23px 0 34px;
    margin-top: 23px;
  }
}

.item-list-lead__body-title {
  font-size: 25px;
  font-weight: 400;
  line-height: 2.4;
  letter-spacing: 0;
}

@media all and (min-width: 787px) and (max-width: 1222px) {
  .item-list-lead__body-title {
    font-size: 20px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .item-list-lead__body-title {
    font-size: 15px;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead__body-title {
    font-size: 16px;
    line-height: 2.5;
    letter-spacing: 0.08em;
  }
}

@media screen and (min-width: 600px) and (max-width: 1015px) {
  .item-list-lead__body-title .tab-only {
    display: block !important;
  }
}

.item-list-lead__body-text {
  font-size: 25px;
  font-weight: 400;
  line-height: 2.4;
  letter-spacing: 0;
}

@media all and (min-width: 787px) and (max-width: 1222px) {
  .item-list-lead__body-text {
    font-size: 20px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .item-list-lead__body-text {
    font-size: 15px;
  }
}

@media all and (max-width: 599px) {
  .item-list-lead__body-text {
    font-size: 16px;
    line-height: 2.8125;
    letter-spacing: 0.08em;
    margin-top: 11px;
  }
}

/*
  ---------------
  .item-list-main | 商品一覧 ラメリアプレミアム・スキンケア3点セット部分
  ---------------
*/
.item-list-main {
  /*---------- 共通スタイル (inner & outer) ----------*/
  /*---------- 個別スタイル (inner care商品) ----------*/
  /*---------- 個別スタイル (outer care 商品) ----------*/
}

.item-list-main .item-list-main-each__ruby {
  text-align: center;
}

.item-list-main .item-list-main-each__ruby img {
  height: 59px;
  width: auto;
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__ruby img {
    height: 39px;
  }
}

.item-list-main .item-list-main-each__title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.15em;
  line-height: 1.75;
  margin-top: 20px;
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1.6;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-list-main .item-list-main-each__title {
    font-size: 25px;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .item-list-main-each__block {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .item-list-main-each__block {
    display: block;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .item-list-main-each__header {
    flex: 0 1 56.7993367%;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .item-list-main-each__img {
    position: relative;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .item-list-main-each__message {
    position: absolute;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .item-list-main-each__message {
    position: static;
    padding: 0 35px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__message {
    padding: 0 28px 0 35px;
  }
}

.item-list-main .item-list-main-each__message-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.56666667;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-list-main .item-list-main-each__message-title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.03em;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__message-title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.03em;
  }
}

.item-list-main .item-list-main-each__message-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
  margin-top: 3px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .item-list-main-each__message-text {
    font-weight: 400;
    line-height: 2.69230769;
    letter-spacing: 0.03em;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__message-text {
    font-weight: 400;
    line-height: 2.69230769;
    letter-spacing: 0.03em;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .item-list-main-each__body {
    flex: 0 0 380px;
    margin-left: 6.55058043%;
  }
}

@media all and (min-width: 851px) and (max-width: 1015px) {
  .item-list-main .item-list-main-each__body {
    flex: 0 0 330px;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .item-list-main-each__body {
    padding: 0 35px;
    margin-left: 0;
  }
}

.item-list-main .item-list-main-each__description-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.85714286;
}

@media all and (min-width: 851px) and (max-width: 1015px) {
  .item-list-main .item-list-main-each__description-text {
    font-size: 12px;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .item-list-main-each__description-text {
    font-size: 14px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__description-text {
    letter-spacing: 0;
  }
}

.item-list-main .item-list-main-each__content {
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-list-main .item-list-main-each__content {
    font-size: 16px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__content {
    font-size: 14px;
    line-height: 2.14285714;
    letter-spacing: 0.08em;
  }
}

.item-list-main .item-list-main-each__price {
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0;
  padding-left: 5.25em;
  text-indent: -5.25em;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-list-main .item-list-main-each__price {
    font-size: 16px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__price {
    font-size: 14px;
    line-height: 2.14285714;
    letter-spacing: 0.08em;
    padding-left: 5.375em;
    text-indent: -5.375em;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .item-list-main-each__btn {
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .item-list-main-each__btn {
    text-align: center;
  }
}

.item-list-main .item-list-main-each__btn-link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.14285714;
  padding: 8px 10px 10px 12px;
}

@media (hover: hover) and (pointer: fine) {
  .item-list-main .item-list-main-each__btn-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-list-main .item-list-main-each__btn-link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.item-list-main .item-list-main-each__btn-link::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .item-list-main .item-list-main-each__btn-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-list-main .item-list-main-each__btn-link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.item-list-main .item-list-main-each__btn-link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.item-list-main .item-list-main-each__btn-link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.item-list-main .item-list-main-each__btn-link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

.item-list-main .inner-care .item-list-main-each__ruby img {
  height: 23.52px;
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__ruby img {
    height: 15.68px;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .inner-care .item-list-main-each__title {
    line-height: 1.35;
    margin-top: 22px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__title {
    margin-top: 7px;
  }
}

.item-list-main .inner-care .item-list-main-each__block {
  margin-top: 4.312%;
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__block {
    margin-top: 37px;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .inner-care .item-list-main-each__message {
    bottom: 6.0475162%;
    left: 6.56934307%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .inner-care .item-list-main-each__message {
    margin-top: 45.7px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__message {
    margin-top: 45.7px;
  }
}

.item-list-main .inner-care .item-list-main-each__message-title {
  letter-spacing: 0.145em;
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__message-title {
    letter-spacing: 0.03em;
  }
}

.item-list-main .inner-care .item-list-main-each__message-text {
  margin-top: 3px;
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__message-text {
    margin-top: 8px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__body {
    padding: 0 34px 0 35px;
  }
}

.item-list-main .inner-care .item-list-main-each__description {
  margin-top: 5px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .inner-care .item-list-main-each__description {
    margin-top: 38px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__description {
    margin-top: 38px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__description-text {
    line-height: 2.5;
  }
}

.item-list-main .inner-care .item-list-main-each__info {
  margin-top: 5%;
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__info {
    margin-top: 29px;
  }
}

.item-list-main .inner-care .item-list-main-each__btn {
  margin-top: 15.7894737%;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .inner-care .item-list-main-each__btn {
    margin-top: 58px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .inner-care .item-list-main-each__btn {
    margin-top: 58px;
  }
}

.item-list-main .outer-care {
  margin-top: 9.5356550%;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .outer-care {
    margin-top: 86px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care {
    margin-top: 86px;
  }
}

.item-list-main .outer-care .item-list-main-each__ruby img {
  height: 19.65px;
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__ruby img {
    height: 13.1px;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .outer-care .item-list-main-each__title {
    line-height: 1.3;
    margin-top: 23px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__title {
    margin-top: 8px;
  }
}

.item-list-main .outer-care .item-list-main-each__block {
  margin-top: 6.05306799%;
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__block {
    margin-top: 35px;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .outer-care .item-list-main-each__message {
    top: 6.69546436%;
    left: 6.86131387%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .outer-care .item-list-main-each__message {
    margin-top: 45.7px;
  }
}

.item-list-main .outer-care .item-list-main-each__message-title {
  letter-spacing: 0.15em;
}

@media all and (min-width: 600px) and (max-width: 1100px) {
  .item-list-main .outer-care .item-list-main-each__message-title {
    font-size: 22px;
    font-weight: 400;
    line-height: 1.56666667;
    letter-spacing: 0.03em;
  }
}

.item-list-main .outer-care .item-list-main-each__message-text {
  margin-top: 2px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .outer-care .item-list-main-each__body {
    margin-top: 34px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__body {
    padding: 0 28px 0 35px;
    margin-top: 34px;
  }
}

@media all and (min-width: 600px) {
  .item-list-main .outer-care .item-list-main-each__description {
    margin-top: -13px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__description-text {
    line-height: 2.14285714;
  }
}

.item-list-main .outer-care .item-list-main-each__info {
  margin-top: 4.47368421%;
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__info {
    margin-top: 17px;
  }
}

.item-list-main .outer-care .item-list-main-each__deals {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.84615385;
  letter-spacing: 0;
  margin-top: 6.84210526%;
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__deals {
    font-weight: 500;
    line-height: 2.69230769;
    letter-spacing: 0.08em;
    margin-top: 14px;
  }
}

.item-list-main .outer-care .item-list-main-each__btn {
  margin-top: 11.315789%;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list-main .outer-care .item-list-main-each__btn {
    margin-top: 59px;
  }
}

@media all and (max-width: 599px) {
  .item-list-main .outer-care .item-list-main-each__btn {
    margin-top: 59px;
  }
}

/*
  ---------------
  .item-list-sub | 商品一覧 3点セット単品販売部分
  ---------------
*/
@media all and (min-width: 600px) {
  .item-list-sub {
    margin: 5.18242123% -31px 0;
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 1100px) {
  .item-list-sub {
    flex-wrap: wrap;
  }
}

@media all and (min-width: 600px) and (max-width: 709px) {
  .item-list-sub {
    margin: 69px 0 0;
  }
}

@media all and (max-width: 599px) {
  .item-list-sub {
    padding: 0 34px 0 36px;
    margin-top: 69px;
  }
}

@media all and (min-width: 600px) {
  .item-list-sub .item-list-sub-each {
    width: calc((100% - 186px) / 3);
    margin: 31px;
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 710px) and (max-width: 1100px) {
  .item-list-sub .item-list-sub-each {
    width: calc((100% - 124px) / 2);
  }
}

@media all and (min-width: 600px) and (max-width: 709px) {
  .item-list-sub .item-list-sub-each {
    display: block;
    width: 100%;
  }
}

@media all and (min-width: 600px) and (max-width: 709px) {
  .item-list-sub .item-list-sub-each + .item-list-sub-each {
    margin-top: 72px;
  }
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each + .item-list-sub-each {
    margin-top: 72px;
  }
}

.item-list-sub .item-list-sub-each__title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.03em;
  text-align: center;
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__title {
    font-weight: 500;
    line-height: 1.15;
  }
}

.item-list-sub .item-list-sub-each__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.78571429;
  letter-spacing: 0.03em;
  text-align: center;
  margin-top: 4px;
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__text {
    line-height: 1.625;
    margin-top: 13px;
  }
}

.item-list-sub .item-list-sub-each__img {
  margin-top: 9.9815157%;
}

@media all and (min-width: 600px) and (max-width: 709px) {
  .item-list-sub .item-list-sub-each__img {
    margin-top: 43px;
  }
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__img {
    margin-top: 43px;
  }
}

.item-list-sub .item-list-sub-each__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.14285714;
  letter-spacing: 0.03em;
  margin-top: 5.68391866%;
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__description {
    line-height: 2.5;
    margin-top: 23px;
  }
}

.item-list-sub .item-list-sub-each__info {
  flex-grow: 1;
  margin-top: 5.40665434%;
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__info {
    margin-top: 25px;
  }
}

.item-list-sub .item-list-sub-each__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.78571429;
  letter-spacing: 0.03em;
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__content {
    line-height: 2.14285714;
  }
}

.item-list-sub .item-list-sub-each__price {
  font: 14px;
  font-weight: 400;
  line-height: 1.78571429;
  letter-spacing: 0.03em;
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__price {
    line-height: 2.14285714;
  }
}

.item-list-sub .item-list-sub-each__btn {
  text-align: center;
  margin-top: 12.1996303%;
}

@media all and (max-width: 599px) {
  .item-list-sub .item-list-sub-each__btn {
    margin-top: 46px;
  }
}

.item-list-sub .item-list-sub-each__btn-link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.14285714;
  padding: 8px 10px 10px 12px;
}

@media (hover: hover) and (pointer: fine) {
  .item-list-sub .item-list-sub-each__btn-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-list-sub .item-list-sub-each__btn-link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.item-list-sub .item-list-sub-each__btn-link::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .item-list-sub .item-list-sub-each__btn-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-list-sub .item-list-sub-each__btn-link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.item-list-sub .item-list-sub-each__btn-link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.item-list-sub .item-list-sub-each__btn-link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.item-list-sub .item-list-sub-each__btn-link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

/*
  ---------------
  .item-list | 商品一覧 main~subの親要素 main下の4連アロー
  ---------------
*/
.item-list {
  background-color: #fafafa;
  padding: 10.7613469% 0 11.7130307%;
  margin-top: 6.44216691%;
}

@media all and (max-width: 599px) {
  .item-list {
    margin-top: 47px;
    padding: 71px 0 84px;
  }
}

.item-list__wrap {
  padding: 0 5.85651537%;
}

@media all and (max-width: 599px) {
  .item-list__wrap {
    padding: 0;
  }
}

.item-list__arrow {
  width: 57.22px;
  margin: 8.04311774% auto 0;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .item-list__arrow {
    margin: 80px auto 0;
  }
}

@media all and (max-width: 599px) {
  .item-list__arrow {
    margin: 80px auto 0;
  }
}

/*
  ---------------
  .item-list-howto | 商品一覧 お悩み方法ご案内エリア
  ---------------
*/
.item-list-howto {
  background: url(/assets/img/list/img_how_to_use.jpg) center/cover;
  height: calc(100vw * 0.32942899);
  position: relative;
}

@media all and (min-width: 600px) {
  .item-list-howto {
    max-height: 450px;
  }
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .item-list-howto {
    height: calc(100vw * 0.39408867);
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .item-list-howto {
    height: calc(100vw * 0.50890585);
  }
}

@media all and (max-width: 599px) {
  .item-list-howto {
    background: url(/assets/img/list/img_how_to_use_sp.jpg) center/contain no-repeat;
    padding-top: 108.205128%;
  }
}

.item-list-howto::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #171717;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
}

.item-list-howto__wrap {
  text-align: center;
  width: 100%;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 599px) {
  .item-list-howto__wrap {
    top: calc(50% - 5px);
  }
}

.item-list-howto__ruby {
  text-align: center;
}

.item-list-howto__ruby img {
  height: 59px;
  width: auto;
}

@media all and (max-width: 599px) {
  .item-list-howto__ruby img {
    height: 39px;
  }
}

.item-list-howto__ruby img {
  height: 24.12px;
}

@media all and (max-width: 599px) {
  .item-list-howto__ruby img {
    height: 16.08px;
  }
}

.item-list-howto__title {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.08em;
  margin-top: 13px;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .item-list-howto__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .item-list-howto__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .item-list-howto__title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.68;
    letter-spacing: 0.03em;
    margin-top: 15px;
  }
}

.item-list-howto__btn {
  margin-top: 4.09956076%;
}

@media all and (max-width: 599px) {
  .item-list-howto__btn {
    margin-top: 30px;
  }
}

.item-list-howto__btn-link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.14285714;
  padding: 8px 10px 10px 12px;
}

@media (hover: hover) and (pointer: fine) {
  .item-list-howto__btn-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-list-howto__btn-link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.item-list-howto__btn-link::before {
  border-color: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .item-list-howto__btn-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-list-howto__btn-link::after {
  background-color: #fff;
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.item-list-howto__btn-link:hover {
  color: #171717;
  opacity: 1;
}

.item-list-howto__btn-link:hover::before {
  border-top: 1px solid #171717;
  border-right: 1px solid #171717;
}

.item-list-howto__btn-link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

@media all and (max-width: 599px) {
  .item-list-howto__btn-link {
    padding: 8px 10px 10px 16px;
  }
}

/********** Item | 商品詳細 **********/
body.product .go-top {
  display: none;
}

.item-productclass-ITEMS_DRINKS .item-fv__box,
.item-productclass-ITEMS_COSMETICS .item-fv__box {
  display: none;
}

.item-description {
  margin-top: 68.5px;
}

@media all and (max-width: 599px) {
  .item-description {
    margin-top: 30.5px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-description {
    margin-top: 31.5px;
  }
}

.item-description__wrap {
  padding: 0 5.85651537%;
}

@media all and (max-width: 599px) {
  .item-description__wrap {
    padding: 0 30px;
  }
}

.item-detail-heading {
  margin: 68.5px auto 52px;
  position: relative;
}

@media all and (max-width: 599px) {
  .item-detail-heading {
    margin: 30.5px auto 40px;
    padding: 0;
  }
}

.item-detail-heading > h1 {
  background-color: rgba(255, 255, 255, 0.6);
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 2.4;
  padding: 4px 20px 4px 18px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  width: auto;
}

@media all and (max-width: 599px) {
  .item-detail-heading > h1 {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 2.1429;
  }
}

.item-detail-body {
  margin-bottom: 190px;
}

@media all and (max-width: 599px) {
  .item-detail-body {
    margin-bottom: 77px;
    padding: 0;
  }
}

.item-detail-body h2 {
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 0.15em;
  line-height: 1.5667;
}

@media all and (max-width: 599px) {
  .item-detail-body h2 {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 2;
    margin-top: 40px;
    text-align: center;
  }
}

.item-detail-body h2 > span {
  font-size: 25px;
  line-height: 1.6;
}

@media all and (max-width: 599px) {
  .item-detail-body h2 > span {
    font-size: 20px;
  }
}

.item-detail-body p {
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 2.5;
  margin-top: 64px;
}

@media all and (max-width: 599px) {
  .item-detail-body p {
    font-size: 14px;
    line-height: 2.5;
    margin-top: 40px;
  }
}

.item-detail-body p > span {
  font-weight: 500;
}

.item-detail-body > div {
  margin-top: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
}

@media all and (max-width: 599px) {
  .item-detail-body > div {
    margin-top: 110px;
    flex-direction: column-reverse;
  }
}

@media all and (min-width: 600px) {
  .item-detail-body > div:nth-of-type(1) {
    margin-top: 52px;
  }
}

@media all and (min-width: 600px) {
  .item-detail-body > div:nth-of-type(even) {
    justify-content: flex-end;
  }
}

@media all and (min-width: 600px) {
  .item-detail-body > div:nth-of-type(odd) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.item-detail-body .img-left {
  margin-right: 65px;
}

@media all and (max-width: 599px) {
  .item-detail-body .img-left {
    margin-right: 0;
    width: 100%;
  }
}

.item-detail-body .img-right {
  margin-left: 65px;
}

@media all and (max-width: 599px) {
  .item-detail-body .img-right {
    margin-left: 0;
    width: 100%;
  }
}

.item-detail-body .item-list-sub {
  margin-top: 0;
}

@media all and (max-width: 599px) {
  .item-detail-body .item-list-sub {
    padding: 0;
  }
}

@media all and (max-width: 599px) {
  .item-detail-body .item-list-sub-each__wrap {
    display: flex;
  }
}

@media all and (max-width: 599px) {
  .item-detail-body .item-list-sub-each__img {
    margin: 24px 16px 0 0;
    width: 120px;
  }
}

@media all and (max-width: 599px) {
  .item-detail-body .item-list-sub-each__box {
    flex: 1;
  }
}

@media all and (max-width: 599px) {
  .item-detail-body .item-list-sub-each__description {
    font-size: 13px;
    line-height: 2.6923;
    margin: 24px 0 0;
  }
}

@media all and (max-width: 599px) {
  .item-detail-body .item-list-sub-each__btn {
    margin-top: 19px;
    text-align: right;
  }
}

.item-detail-body .item-list-sub-each__link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
}

@media all and (min-width: 600px) {
  .item-detail-body .item-list-sub-each__link::before {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    right: 14px;
    width: 7px;
    height: 7px;
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
    transform: rotate(45deg) translateY(-50%);
  }
}

@media all and (max-width: 599px) {
  .item-detail-body .item-list-sub-each__link {
    border: none;
    padding: 0;
    max-width: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .item-detail-body .item-list-sub-each__link::after {
    background: url(/assets/img/item/skincare/icon_arrow.png) no-repeat center center;
    content: "";
    display: inline-block;
    height: 24px;
    margin-left: 12px;
    position: relative;
    width: 24px;
  }
}

@media all and (max-width: 599px) {
  .item-detail-body.premium > div {
    margin-top: 63px;
    flex-direction: column;
  }
}

.item-voice {
  margin: 0 auto;
  max-width: 800px;
  position: relative;
}

@media all and (max-width: 599px) {
  .item-voice {
    margin: -50px 30px 0;
  }
}

.item-voice__box {
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.item-voice__box-title {
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 0.15em;
  line-height: 1.5667;
}

@media all and (max-width: 599px) {
  .item-voice__box-title {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.08em;
  }
}

.item-voice__box-btn {
  margin-top: 32px;
  max-width: 220px;
  width: 100%;
}

@media all and (max-width: 599px) {
  .item-voice__box-btn {
    margin-top: 16px;
    width: 66.6667%;
  }
}

@media all and (max-width: 599px) {
  .item-voice__box-btn-link {
    font-size: 10px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.item-medical-doctor__wrap {
  background-color: #F2F2F2;
  margin: 97px auto 0;
  max-width: 800px;
  padding: 24px 39px 20px 24px;
}

@media all and (max-width: 599px) {
  .item-medical-doctor__wrap {
    margin-top: 120px;
    padding: 8px 8px 16px;
  }
}

.item-medical-doctor__wrap > div {
  display: flex;
}

.item-medical-doctor__img {
  margin-right: 37px;
  width: 200px;
}

@media all and (max-width: 599px) {
  .item-medical-doctor__img {
    margin-right: 9px;
    width: 120px;
  }
}

.item-medical-doctor__box {
  font-family: "Source Han Sans JP";
  font-weight: normal;
  flex: 1;
}

.item-medical-doctor__title {
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-bottom: 16px;
}

@media all and (max-width: 599px) {
  .item-medical-doctor__title {
    font-size: 13px;
    margin-bottom: 8px;
  }
}

.item-medical-doctor__description {
  border-bottom: 1px solid #171717;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.8462;
  margin-bottom: 16px;
  padding-bottom: 8px;
}

@media all and (max-width: 599px) {
  .item-medical-doctor__description {
    font-size: 11px;
    line-height: 1.6364;
    margin-bottom: 0;
    padding-bottom: 30px;
  }
}

.item-medical-doctor__text {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.7143;
}

@media all and (max-width: 599px) {
  .item-medical-doctor__text {
    font-size: 13px;
    line-height: 1.8462;
    margin-top: 21px;
  }
}

.item-faq-contents {
  margin-top: 180px;
  margin-left: auto;
}

@media all and (max-width: 599px) {
  .item-faq-contents {
    margin: 80px auto 0;
    padding: 0;
  }
}

.item-faq-contents .faq-contents__title {
  background: none;
  border-top: none;
  border-bottom: 1px solid #171717;
  color: #171717;
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 40px;
  letter-spacing: 0.08em;
  line-height: 1.6667;
  text-align: center;
}

@media all and (max-width: 599px) {
  .item-faq-contents .faq-contents__title {
    font-size: 25px;
    line-height: 1.6;
  }
}

.item-all-ingredients {
  margin-top: 113px;
}

@media all and (max-width: 599px) {
  .item-all-ingredients {
    margin-top: 81px;
  }
}

@media all and (max-width: 599px) {
  .item-all-ingredients__wrap {
    padding: 0;
  }
}

.item-all-ingredients__title {
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 1.6;
}

@media all and (max-width: 599px) {
  .item-all-ingredients__title {
    font-weight: 500;
    font-size: 20px;
  }
}

.item-all-ingredients__box {
  background: #fafafa;
  margin-top: 16px;
  padding: 25px 23px 20px;
}

@media all and (max-width: 599px) {
  .item-all-ingredients__box {
    margin-top: 13.5px;
    padding: 16.5px 15px 14.5px;
  }
}

.item-all-ingredients__box-text {
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 2.1429;
}

@media all and (max-width: 599px) {
  .item-all-ingredients__box-text {
    font-size: 14px;
  }
}

.item-all-ingredients__box-text.is-open {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media all and (max-width: 599px) {
  .item-all-ingredients__box-text.is-open {
    -webkit-line-clamp: 4;
  }
}

.item-all-ingredients__box-btn {
  margin-top: 16px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .item-all-ingredients__box-btn {
    margin-top: 13px;
  }
}

@media all and (max-width: 599px) {
  .item-all-ingredients__box-btn-link {
    font-size: 11px;
    max-width: 151px;
    padding: 4px 11px;
  }
}

.modal-all-ingredients__bg {
  background: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.modal-all-ingredients__wrap {
  background-color: #fff;
  margin: 0 auto;
  padding: 49px 40px;
  width: 650px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 599px) {
  .modal-all-ingredients__wrap {
    padding: 19.6px 25.85px 21.4px;
    width: 85.5769%;
  }
}

.modal-all-ingredients__title {
  font-family: "DNP ShueiMGoStd";
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
}

@media all and (max-width: 599px) {
  .modal-all-ingredients__title {
    font-size: 20px;
  }
}

.modal-all-ingredients__text {
  border-top: 1px solid #171717;
  font-family: "Source Han Sans JP";
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 2.1429;
  margin-top: 29.6px;
  padding-top: 19.7px;
}

@media all and (max-width: 599px) {
  .modal-all-ingredients__text {
    margin-top: 10.3px;
  }
}

.modal-all-ingredients__btn {
  background: url(/assets/img/item/icon_close.svg) no-repeat center center;
  background-size: cover;
  display: inline-block;
  height: 40px;
  width: 40px;
  position: absolute;
  top: -60px;
  right: 0;
}

@media all and (max-width: 599px) {
  .modal-all-ingredients__btn {
    height: 23.46px;
    width: 23.46px;
    top: -35.16px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-fv {
    padding-top: 109px;
  }
}

.item-fv__wrap {
  position: relative;
}

.item-fv__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-fv__box {
    top: 50%;
  }
}

@media all and (max-width: 599px) {
  .item-fv__box {
    top: calc(50% + 44px);
  }
}

.item-wrapper-02 .item-fv__box {
  top: calc(50% + 37px);
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .item-wrapper-02 .item-fv__box {
    top: 50%;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-fv__box {
    top: calc(50% + 30px);
  }
}

.item-fv__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .item-fv__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .item-fv__title {
    font-size: 25px;
  }
}

@media all and (max-width: 599px) {
  .item-fv__title {
    font-weight: 500;
    line-height: 2;
  }
}

.item-wrapper-02 .item-fv__title {
  color: #171717;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-fv__title {
    line-height: 1.6;
  }
}

.item-purchase {
  background-color: #fafafa;
  padding: 10.102489% 0 8.34553441%;
  margin-top: 89px;
}

@media all and (max-width: 599px) {
  .item-purchase {
    padding: 77px 0 58px;
    margin-top: 44px;
  }
}

.item-wrapper-02 .item-purchase {
  padding: 122px 0 100px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-purchase {
    padding: 64px 0 52px;
  }
}

.item-purchase__wrap {
  padding: 0 80px;
}

@media all and (min-width: 600px) and (max-width: 1366px) {
  .item-purchase__wrap {
    padding: 0 5.85651537%;
  }
}

@media all and (max-width: 599px) {
  .item-purchase__wrap {
    padding: 0 30px;
  }
}

.item-purchase__ruby {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.08em;
  text-align: center;
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-purchase__ruby {
    font-size: 18px;
    line-height: 2.14285714;
  }
}

@media all and (max-width: 599px) {
  .item-purchase__ruby {
    font-size: 14px;
    line-height: 2.14285714;
  }
}

.item-purchase__title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  margin-top: 21px;
}

@media all and (min-width: 600px) {
  .item-purchase__title {
    letter-spacing: 0.08em;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-purchase__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.6;
    margin-top: -1px;
    letter-spacing: 0;
  }
}

@media all and (max-width: 599px) {
  .item-purchase__title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.6;
    margin-top: -1px;
    letter-spacing: 0;
  }
}

.item-regular {
  margin-top: 102px;
}

@media all and (max-width: 599px) {
  .item-regular {
    margin-top: 83px;
  }
}

.item-wrapper-02 .item-regular {
  margin-top: 114px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular {
    margin-top: 59px;
  }
}

.item-regular__title {
  border-bottom: 2px solid #171717;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.6;
  padding-bottom: 13px;
}

@media all and (max-width: 599px) {
  .item-regular__title {
    border-bottom: 1px solid #171717;
    font-weight: 500;
    letter-spacing: 0.08em;
    padding-bottom: 9.5px;
  }
}

.item-regular__block {
  margin-top: 64px;
}

@media all and (max-width: 599px) {
  .item-regular__block {
    margin-top: 30.5px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__block {
    margin-top: 29.5px;
  }
}

@media all and (min-width: 600px) {
  .item-regular__box {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .item-regular__box {
    display: block;
  }
}

@media all and (min-width: 600px) {
  .item-regular__img {
    flex: 0 1 100%;
  }
}

.item-regular__description {
  margin-top: -7px;
}

@media all and (min-width: 600px) {
  .item-regular__description {
    flex: 0 0 442px;
    margin-left: 6.59204%;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .item-regular__description {
    margin-top: 24.6px;
    margin-left: 0;
  }
}

@media all and (max-width: 599px) {
  .item-regular__description {
    margin-top: 24.6px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__description {
    margin-top: 25.6px;
  }
}

.item-regular__description-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.85714286;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .item-regular__description-text {
    margin-right: -6px;
  }
}

@media all and (max-width: 599px) {
  .item-regular__description-text {
    line-height: 2.14285714;
  }
}

@media all and (min-width: 600px) {
  .item-wrapper-02 .item-regular__description-text {
    letter-spacing: -0.01em;
    margin-right: -5px;
  }
}

.item-regular__list {
  margin-top: 24px;
}

@media all and (min-width: 600px) {
  .item-regular__list {
    display: flex;
    flex-wrap: wrap;
  }
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-regular__list {
    margin-top: 20px;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-regular__list {
    display: block;
    margin-top: 32px;
  }
}

@media all and (max-width: 599px) {
  .item-regular__list {
    margin-top: 32px;
  }
}

.item-wrapper-02 .item-regular__list {
  margin-top: 79px;
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-wrapper-02 .item-regular__list {
    margin-top: 20px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__list {
    margin-top: 22px;
  }
}

.item-regular__item {
  padding: 0 40px 0 50px;
}

@media all and (min-width: 600px) {
  .item-regular__item {
    margin-top: 45px;
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-regular__item {
    width: calc(100% / 2);
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-regular__item {
    border-top: 1px dashed #707070;
    display: block;
    width: 100%;
    padding: 24.5px 0 23.5px 0;
    margin-top: 0;
  }
}

@media all and (max-width: 599px) {
  .item-regular__item {
    border-top: 1px dashed #707070;
    padding: 24.5px 0 23.5px 0;
  }
}

@media all and (min-width: 1192px) {
  .item-regular__item:nth-child(3n + 1) {
    border-left: 1px dashed #707070;
    border-right: 1px dashed #707070;
  }
  .item-regular__item:not(:nth-child(3n + 1)) {
    border-right: 1px dashed #707070;
  }
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-regular__item:nth-child(2n + 1) {
    border-left: 1px dashed #707070;
    border-right: 1px dashed #707070;
  }
  .item-regular__item:not(:nth-child(2n + 1)) {
    border-right: 1px dashed #707070;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-regular__item:last-child {
    border-bottom: 1px dashed #707070;
  }
}

@media all and (max-width: 599px) {
  .item-regular__item:last-child {
    border-bottom: 1px dashed #707070;
    padding-bottom: 33.5px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__item {
    padding: 17.5px 0 29.5px;
  }
}

.item-regular__item-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
  letter-spacing: 0;
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-regular__item-subtitle {
    font-size: 13px;
  }
}

@media all and (max-width: 599px) {
  .item-regular__item-subtitle {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
  }
}

.item-wrapper-02 .item-regular__item-subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0;
  margin-top: -2px;
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-wrapper-02 .item-regular__item-subtitle {
    font-size: 18px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__item-subtitle {
    font-weight: 500;
  }
}

.item-regular__item-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0;
  margin-top: 2px;
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-regular__item-title {
    font-size: 19px;
  }
}

@media all and (max-width: 599px) {
  .item-regular__item-title {
    font-weight: 500;
    line-height: 1.5;
    margin-top: 4px;
  }
}

.item-wrapper-02 .item-regular__item-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0;
  margin-top: 0;
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-wrapper-02 .item-regular__item-title {
    font-size: 18px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__item-title {
    font-weight: 500;
  }
}

.item-regular__label {
  font-size: 0;
  margin-left: -9px;
}

.item-regular__tag {
  background-color: #9f9f9f;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
  padding: 0px 14px 1px 15px;
  margin-top: 6px;
  margin-left: 9px;
}

@media all and (max-width: 599px) {
  .item-regular__tag {
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px;
    margin-top: 5px;
  }
}

.item-regular__tag + .item-regular__tag {
  padding: 0px 13px 1px 14px;
}

@media all and (max-width: 599px) {
  .item-regular__tag + .item-regular__tag {
    padding: 4px 10px;
  }
}

.item-wrapper-02 .item-regular__tag {
  margin-top: 9px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__tag {
    margin-top: 7px;
  }
}

.item-regular__price {
  font-size: 0;
  flex-grow: 1;
  line-height: 1.31818182;
  margin-top: 23px;
}

@media all and (max-width: 599px) {
  .item-regular__price {
    margin-top: 11px;
  }
}

.item-wrapper-02 .item-regular__price {
  margin-top: 18px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__price {
    margin-top: 10px;
  }
}

.item-regular__price-number {
  display: inline-block;
  font-size: 39px;
  font-weight: 400;
}

@media all and (min-width: 600px) {
  .item-regular__price-number {
    letter-spacing: 0;
  }
}

@media all and (max-width: 599px) {
  .item-regular__price-number {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
  }
}

.item-wrapper-02 .item-regular__price-number {
  font-size: 40px;
  letter-spacing: 0.08em;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__price-number {
    font-size: 25px;
  }
}

.item-regular__price-yen {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.28571429;
  letter-spacing: 0;
  margin-left: 12px;
}

@media all and (max-width: 599px) {
  .item-regular__price-yen {
    font-size: 13px;
    line-height: 2.46153846;
    margin-left: 6px;
  }
}

.item-wrapper-02 .item-regular__price-yen {
  margin-left: 6px;
}

.item-regular__price-guide {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 2.28571429;
  letter-spacing: 0;
  margin-left: 3px;
}

@media all and (max-width: 599px) {
  .item-regular__price-guide {
    font-size: 12px;
    line-height: 2;
    margin-left: 6px;
  }
}

.item-regular__form {
  text-align: right;
  margin-top: 27px;
}

@media all and (max-width: 599px) {
  .item-regular__form {
    margin-top: 6px;
  }
}

.item-wrapper-02 .item-regular__form {
  margin-top: 25px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-regular__form {
    margin-top: 11px;
  }
}

.item-regular__btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-image: none;
  border: none;
  color: #fff;
  padding: 15px 10px 15px 12px;
  line-height: 2.14285714;
  padding: 9px 10px 11px;
  max-width: 200px;
}

@media (hover: hover) and (pointer: fine) {
  .item-regular__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .item-regular__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .item-regular__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-regular__btn::after {
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  z-index: -2;
}

.item-regular__btn:hover {
  background-image: none;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

.item-regular__btn:hover::before {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.item-regular__btn::before, .item-regular__btn::after {
  content: "";
  display: block;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.item-regular__btn::before {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  z-index: -1;
}

.item-regular__btn .icon-cart {
  padding-left: 23.83px;
  position: relative;
}

@media all and (max-width: 599px) {
  .item-regular__btn .icon-cart {
    padding-left: 25.03px;
  }
}

.item-regular__btn .icon-cart::before {
  content: "";
  display: block;
  background-image: url(/assets/img/item/icon_cart.svg);
  width: 17.13px;
  height: 18.15px;
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .item-regular__btn .icon-cart::before {
    top: 51%;
    left: -1px;
  }
}

@media all and (max-width: 599px) {
  .item-regular__btn {
    max-width: 220px;
  }
}

.item-shop-guide {
  background-color: #fafafa;
}

@media all and (min-width: 600px) and (max-width: 1191px) {
  .item-shop-guide .tab-only {
    display: none;
  }
}

.item-shop-guide h3 {
  border-left: 5px solid #171717;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.08em;
  padding: 7px 24px 9px;
}

@media all and (max-width: 599px) {
  .item-shop-guide h3 {
    font-weight: 500;
    padding: 9px 29px 7px 30px;
    position: relative;
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide h3::after {
    content: "";
    display: block;
    background-image: url(/assets/img/item/icon_arrow_bottom.svg);
    width: 29px;
    height: 29px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: background-image .2s;
  }
}

.item-shop-guide h4 {
  background-color: #f2f2f2;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  padding: 6px 12px 8px;
  margin-bottom: 8px;
}

@media all and (max-width: 599px) {
  .item-shop-guide h4 {
    font-size: 20px;
    font-weight: 500;
    padding: 11px 11px 9px;
    margin-bottom: 2px;
  }
}

.item-shop-guide p {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.14285714;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .item-shop-guide p {
    padding-right: 8px;
  }
}

.item-shop-guide ul li {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.14285714;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .item-shop-guide ul li {
    padding-left: 1em;
    text-indent: -1em;
  }
}

.item-shop-guide a {
  text-decoration: underline #171717;
  text-indent: 0;
}

.item-shop-guide img {
  display: block;
  width: 224px;
  margin-top: 4px;
  margin-bottom: 26px;
}

@media all and (max-width: 599px) {
  .item-shop-guide img {
    margin-top: 5px;
    margin-bottom: 25px;
  }
}

@media all and (min-width: 600px) {
  .item-shop-guide__wrap {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 5.85651537%;
  }
}

.item-shop-guide__inner {
  background-color: #fff;
}

@media all and (min-width: 600px) {
  .item-shop-guide__inner {
    padding: 7.71144279% 8.54063018% 4.97512438% 9.2039801%;
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 1191px) {
  .item-shop-guide__inner {
    padding: 6.94246315% 6.94246315% 5.70613409% 7.70328103%;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-shop-guide__inner {
    display: block;
  }
}

.item-shop-guide__col {
  flex-basis: 50%;
}

@media all and (min-width: 600px) {
  .item-shop-guide__col + .item-shop-guide__col {
    margin-left: 5.64516129%;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-shop-guide__col + .item-shop-guide__col {
    margin-top: 80px;
    margin-left: 0;
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide__col + .item-shop-guide__col {
    padding-bottom: 0;
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide__col.is-open h3::after {
    background-image: url(/assets/img/item/icon_arrow_top.svg);
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide__col.is-open .purchase-box__title {
    border-bottom: 1px solid #707070;
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide__col.is-open .purchase-box__inner {
    display: block;
    padding-bottom: 50px;
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide__col.is-open .delivery-box__inner {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide .purchase-box__title {
    border-top: 1px solid #707070;
    padding: 20.5px 29px 19.5px 30px;
  }
}

.item-shop-guide .purchase-box__inner {
  margin-top: 36px;
}

@media all and (max-width: 599px) {
  .item-shop-guide .purchase-box__inner {
    display: none;
    padding: 0 30px;
    margin-top: 55px;
  }
}

.item-shop-guide .purchase-box__postage h4 {
  margin-top: 41px;
  margin-bottom: 4px;
}

@media all and (max-width: 599px) {
  .item-shop-guide .purchase-box__postage h4 {
    margin-top: 57px;
    margin-bottom: 11px;
  }
}

@media all and (min-width: 600px) {
  .item-shop-guide .purchase-box__postage ul {
    margin-right: -9px;
  }
}

.item-shop-guide .purchase-box__ban h4 {
  margin-top: 36px;
  margin-bottom: 18px;
}

@media all and (max-width: 599px) {
  .item-shop-guide .purchase-box__ban h4 {
    margin-top: 46px;
    margin-bottom: 7px;
  }
}

@media all and (max-width: 599px) {
  .item-shop-guide .delivery-box__title {
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    padding: 22.5px 29px 20.5px 33px;
  }
}

.item-shop-guide .delivery-box__title h3 {
  padding: 7px 24px 9px 20px;
}

@media all and (max-width: 599px) {
  .item-shop-guide .delivery-box__title h3 {
    padding: 9px 24px 7px 25px;
  }
}

.item-shop-guide .delivery-box__inner {
  margin-top: 36px;
}

@media all and (max-width: 599px) {
  .item-shop-guide .delivery-box__inner {
    display: none;
    padding: 0 30px 30px;
    margin-top: 55px;
  }
}

.item-shop-guide .delivery-box__date h4 {
  padding-left: 14px;
  margin-bottom: 3px;
}

.item-shop-guide .delivery-box__date p {
  line-height: 2.85714286;
}

.item-shop-guide .delivery-box__time h4 {
  padding-left: 14px;
  margin-top: 42px;
  margin-bottom: 17px;
}

.item-shop-guide .delivery-box__return h4 {
  padding-left: 14px;
  margin-top: 47px;
  margin-bottom: 15px;
}

.item-shop-guide .attention {
  color: #9b0000;
}

.item-shop-guide .strong {
  font-weight: bold;
}

.item-single {
  margin-top: 129px;
  /*================================================
    .item-wrapper-02 banner用クラス
  ================================================*/
}

@media all and (max-width: 599px) {
  .item-single {
    margin-top: 68.5px;
  }
}

.item-wrapper-02 .item-single {
  margin-top: 87px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single {
    margin-top: 47px;
  }
}

.item-single__title {
  border-bottom: 2px solid #171717;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.6;
  padding-bottom: 13px;
}

@media all and (max-width: 599px) {
  .item-single__title {
    border-bottom: 1px solid #171717;
    font-weight: 500;
    letter-spacing: 0.08em;
    padding-bottom: 9.5px;
  }
}

.item-single__block {
  margin-top: 64px;
}

@media all and (max-width: 599px) {
  .item-single__block {
    margin-top: 30.5px;
  }
}

.item-wrapper-02 .item-single__block {
  margin-top: 74px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single__block {
    margin-top: 30px;
  }
}

@media all and (min-width: 600px) {
  .item-single__box {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .item-single__box {
    display: block;
  }
}

@media all and (min-width: 600px) {
  .item-single__img {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) {
  .item-single__contents {
    flex: 0 0 442px;
    margin-left: 6.59204%;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .item-single__contents {
    margin-top: 26px;
    margin-left: 0;
  }
}

@media all and (max-width: 599px) {
  .item-single__contents {
    margin-top: 26px;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .item-wrapper-02 .item-single__contents {
    margin-top: 26px;
  }
}

.item-single__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.85714286;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .item-single__description {
    margin-top: -7px;
  }
}

@media all and (max-width: 599px) {
  .item-single__description {
    font-weight: 500;
    line-height: 2.14285714;
  }
}

.item-wrapper-02 .item-single__description {
  margin-left: 0;
}

@media all and (min-width: 600px) {
  .item-wrapper-02 .item-single__description {
    margin-right: -5px;
  }
}

.item-single__contents-item {
  border-top: 1px dashed #707070;
  margin-top: 26.5px;
}

@media all and (max-width: 599px) {
  .item-single__contents-item {
    margin-top: 18.5px;
  }
}

.item-single__contents-item.is-hidden {
  display: none;
}

.item-single__info {
  margin-top: 33.5px;
}

@media all and (max-width: 599px) {
  .item-single__info {
    margin-top: 17.5px;
  }
}

.item-single__info-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .item-single__info-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.66666667;
  }
}

.item-single__list {
  margin-top: 20px;
}

@media all and (min-width: 600px) {
  .item-single__list {
    display: flex;
    flex-wrap: wrap;
  }
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-single__list {
    margin-top: 20px;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-single__list {
    display: block;
    margin-top: 20px;
  }
}

@media all and (max-width: 599px) {
  .item-single__list {
    margin-top: 20px;
  }
}

.item-single__item {
  padding: 5px 40px 26px 45px;
}

@media all and (min-width: 600px) {
  .item-single__item {
    margin-top: 45px;
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 757px) and (max-width: 1209px) {
  .item-single__item {
    padding: 5px 3.31674959% 26px 3.73134328%;
  }
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-single__item {
    width: calc(100% / 2);
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-single__item {
    border-top: 1px dashed #707070;
    display: block;
    width: 100%;
    padding: 24.5px 0 23.5px 0;
    margin-top: 0;
  }
}

@media all and (max-width: 599px) {
  .item-single__item {
    border-top: 1px dashed #707070;
    padding: 27.5px 0 29.5px 3px;
  }
}

@media all and (min-width: 1192px) {
  .item-single__item:nth-child(3n + 1) {
    border-left: 1px dashed #707070;
    border-right: 1px dashed #707070;
  }
  .item-single__item:not(:nth-child(3n + 1)) {
    border-right: 1px dashed #707070;
  }
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-single__item:nth-child(2n + 1) {
    border-left: 1px dashed #707070;
    border-right: 1px dashed #707070;
  }
  .item-single__item:not(:nth-child(2n + 1)) {
    border-right: 1px dashed #707070;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-single__item:last-child {
    border-bottom: 1px dashed #707070;
  }
}

@media all and (max-width: 599px) {
  .item-single__item:last-child {
    border-bottom: 1px dashed #707070;
  }
}

.item-single__item-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0;
}

@media all and (min-width: 757px) and (max-width: 1191px) {
  .item-single__item-title {
    font-size: 19px;
  }
}

@media all and (max-width: 599px) {
  .item-single__item-title {
    font-weight: 500;
    line-height: 1.5;
  }
}

.item-single__label {
  font-size: 0;
  margin-left: -9px;
}

.item-single__tag {
  background-color: #9f9f9f;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
  padding: 0px 14px 1px 15px;
  margin-top: 5px;
  margin-left: 9px;
}

@media all and (max-width: 599px) {
  .item-single__tag {
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px;
    margin-top: 7px;
  }
}

.item-single__tag + .item-single__tag {
  padding: 0px 13px 1px 14px;
}

@media all and (max-width: 599px) {
  .item-single__tag + .item-single__tag {
    padding: 4px 10px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single__tag {
    margin-top: 5px;
  }
}

.item-single__tag.primary {
  background-color: transparent;
  border: 1px solid #9b0000;
  color: #9b0000;
  padding: 0px 13px 0px 14px;
  margin-top: 7px;
}

@media all and (max-width: 599px) {
  .item-single__tag.primary {
    padding: 3px 9px 3px 7px;
    margin-top: 10px;
  }
}

.item-single__price {
  font-size: 0;
  flex-grow: 1;
  line-height: 1.31818182;
  margin-top: 23px;
}

@media all and (max-width: 599px) {
  .item-single__price {
    margin-top: 11px;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single__price {
    margin-top: 15px;
  }
}

.item-single__price-number {
  display: inline-block;
  font-size: 39px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

@media all and (max-width: 599px) {
  .item-single__price-number {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
  }
}

.item-wrapper-02 .item-single__price-number {
  letter-spacing: 0.09em;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single__price-number {
    letter-spacing: 0.08em;
  }
}

.item-single__price-yen {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.28571429;
  letter-spacing: 0;
  margin-left: 10px;
}

@media all and (max-width: 599px) {
  .item-single__price-yen {
    font-size: 13px;
    line-height: 2.46153846;
    margin-left: 6px;
  }
}

.item-wrapper-02 .item-single__price-yen {
  margin-left: 11px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single__price-yen {
    margin-left: -1px;
  }
}

.item-single__price-guide {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  font-family: "游ゴシック体";
  line-height: 1.84615385;
  letter-spacing: 0;
  margin-left: -5px;
}

@media all and (max-width: 599px) {
  .item-single__price-guide {
    font-size: 12px;
    line-height: 2;
    margin-left: 0;
  }
}

.item-single__form {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media all and (max-width: 599px) {
  .item-single__form {
    margin-top: 10.5px;
  }
}

.item-wrapper-02 .item-single__form {
  margin-top: 25px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single__form {
    margin-top: 11px;
  }
}

.item-single__qty {
  font-size: 0;
  margin-left: -2px;
  height: 50px;
}

@media all and (min-width: 600px) {
  .item-single__qty {
    flex: 0 0 92.13px;
  }
}

.item-wrapper-02 .item-single__qty {
  flex: none;
}

.item-single__qty-label {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
}

.item-single__qty-select {
  appearance: none;
  border: 1px solid #707070;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Source Han Sans JP";
  line-height: 1.71428571;
  letter-spacing: 0;
  text-align: right;
  padding: 9px 14px 11px;
  margin-left: 7px;
  width: 60px;
  height: 50px;
}

@media all and (max-width: 599px) {
  .item-single__qty-select {
    margin-left: 7px;
  }
}

.item-wrapper-02 .item-single__qty-select {
  margin-left: 12px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-single__qty-select {
    margin-left: 9px;
  }
}

.item-single__btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-image: none;
  border: none;
  color: #fff;
  padding: 15px 10px 15px 12px;
  line-height: 2.14285714;
  padding: 9px 10px 11px;
  margin-left: 8.253968%;
  max-width: 200px;
}

@media (hover: hover) and (pointer: fine) {
  .item-single__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .item-single__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .item-single__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-single__btn::after {
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  z-index: -2;
}

.item-single__btn:hover {
  background-image: none;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

.item-single__btn:hover::before {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.item-single__btn::before, .item-single__btn::after {
  content: "";
  display: block;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.item-single__btn::before {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  z-index: -1;
}

.item-single__btn .icon-cart {
  padding-left: 23.83px;
  position: relative;
}

@media all and (max-width: 599px) {
  .item-single__btn .icon-cart {
    padding-left: 25.03px;
  }
}

.item-single__btn .icon-cart::before {
  content: "";
  display: block;
  background-image: url(/assets/img/item/icon_cart.svg);
  width: 17.13px;
  height: 18.15px;
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .item-single__btn .icon-cart::before {
    top: 51%;
    left: -1px;
  }
}

@media all and (min-width: 600px) and (max-width: 756px) {
  .item-single__btn {
    margin-left: 26px;
  }
}

@media all and (max-width: 599px) {
  .item-single__btn {
    margin-left: 14px;
    max-width: 220px;
  }
}

.item-wrapper-02 .item-single__btn {
  margin-left: 20px;
}

.item-single .outer-care {
  margin-top: 126px;
}

@media all and (max-width: 599px) {
  .item-single .outer-care {
    margin-top: 84px;
  }
}

.item-single .outer-care__wrap {
  max-width: 900px;
  margin: 0 auto;
}

.item-single .outer-care__img {
  position: relative;
}

.item-single .outer-care__img img {
  height: 195px;
  object-fit: cover;
}

@media all and (max-width: 599px) {
  .item-single .outer-care__img img {
    height: 121px;
  }
}

.item-single .outer-care__box {
  width: 100%;
  position: absolute;
  top: 28px;
  left: 47px;
}

@media all and (max-width: 599px) {
  .item-single .outer-care__box {
    top: 16px;
    left: 23px;
  }
}

.item-single .outer-care__box-title {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .item-single .outer-care__box-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.66666667;
  }
}

.item-single .outer-care__box-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57142857;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .item-single .outer-care__box-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.66666667;
  }
}

.item-single .outer-care__box-btn {
  margin-top: 15px;
}

@media all and (max-width: 599px) {
  .item-single .outer-care__box-btn {
    margin-top: 11px;
  }
}

.item-single .outer-care__box-btn-link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .item-single .outer-care__box-btn-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media all and (max-width: 599px) {
  .item-single .outer-care__box-btn-link {
    font-size: 9px;
    font-weight: 500;
    line-height: 1.66666667;
    letter-spacing: 0;
    padding: 7px 10px 8px 12px;
    max-width: 151px;
  }
}

.item-switch {
  margin-top: 58.5px;
}

@media all and (max-width: 599px) {
  .item-switch {
    margin-top: 36.5px;
  }
}

.item-switch__list {
  padding: 0 5.85651537%;
  display: flex;
}

@media all and (min-width: 600px) {
  .item-switch__list {
    align-items: center;
  }
}

@media all and (max-width: 599px) {
  .item-switch__list {
    padding: 0 30px;
  }
}

.item-switch__item {
  border-right: 1px solid #171717;
  padding: 0 10px;
  width: calc(100% / 3);
}

.item-switch__item:first-child {
  border-left: 1px solid #171717;
}

@media all and (max-width: 599px) {
  .item-switch__item {
    display: flex;
    justify-content: center;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-switch__item {
    width: calc(100% / 2);
  }
}

.item-switch__link {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.28571429;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 0;
}

@media (hover: hover) and (pointer: fine) {
  .item-switch__link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-switch__link:hover {
  background-color: #171717;
  border-radius: 5px;
  color: #fff;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

@media all and (max-width: 599px) {
  .item-switch__link {
    line-height: 1.42857143;
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-switch__link {
    min-height: 50px;
  }
}

.item-trial {
  margin-top: 82px;
}

@media all and (max-width: 599px) {
  .item-trial {
    margin-top: 42px;
  }
}

.item-trial__title {
  border-bottom: 2px solid #171717;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.6;
  padding-bottom: 13px;
}

@media all and (max-width: 599px) {
  .item-trial__title {
    border-bottom: 1px solid #171717;
    font-weight: 500;
    letter-spacing: 0.08em;
    padding-bottom: 10px;
  }
}

.item-trial__block {
  margin-top: 74px;
}

@media all and (max-width: 599px) {
  .item-trial__block {
    margin-top: 27px;
  }
}

@media all and (min-width: 600px) {
  .item-trial__box {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .item-trial__box {
    display: block;
  }
}

@media all and (min-width: 600px) {
  .item-trial__img {
    flex: 0 1 100%;
  }
}

@media all and (min-width: 600px) {
  .item-trial__contents {
    flex: 0 0 442px;
    margin-left: 6.59204%;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .item-trial__contents {
    margin-left: 0;
    margin-top: 25px;
  }
}

@media all and (max-width: 599px) {
  .item-trial__contents {
    margin-top: 25px;
  }
}

.item-trial__description {
  border-bottom: 1px dashed #707070;
  padding-bottom: 35.5px;
}

@media all and (min-width: 600px) {
  .item-trial__description {
    margin-top: -7px;
  }
}

@media all and (max-width: 599px) {
  .item-trial__description {
    padding-bottom: 19.5px;
  }
}

.item-trial__description-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.85714286;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .item-trial__description-text {
    margin-right: -4px;
  }
}

@media all and (max-width: 599px) {
  .item-trial__description-text {
    line-height: 2.14285714;
  }
}

.item-trial__info {
  margin-top: 31.5px;
}

@media all and (max-width: 599px) {
  .item-trial__info {
    margin-top: 16.5px;
  }
}

.item-trial__info-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .item-trial__info-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.66666667;
  }
}

.item-trial__label {
  margin-top: 2px;
}

@media all and (max-width: 599px) {
  .item-trial__label {
    margin-top: 3px;
  }
}

.item-trial__tag {
  background-color: #9f9f9f;
  border-radius: 50px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
  padding: 2px 14px 4px 15px;
}

@media all and (max-width: 599px) {
  .item-trial__tag {
    font-size: 12px;
    line-height: 2;
    padding: 1px 8px 2px;
  }
}

.item-trial__tag + .item-trial__tag {
  padding: 2px 13px 4px 14px;
  margin-left: 6px;
}

@media all and (max-width: 599px) {
  .item-trial__tag + .item-trial__tag {
    padding: 1px 11px 2px;
    margin-left: 5px;
  }
}

.item-trial__price {
  font-size: 0;
  margin-top: 6px;
}

@media all and (max-width: 599px) {
  .item-trial__price {
    line-height: 1.4875;
    margin-top: 2px;
  }
}

.item-trial__price-number {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.04em;
}

@media all and (max-width: 599px) {
  .item-trial__price-number {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
  }
}

.item-trial__price-yen {
  font-size: 14px;
  font-weight: 500;
  line-height: 2.28571429;
  letter-spacing: 0;
  margin-left: 8px;
}

@media all and (max-width: 599px) {
  .item-trial__price-yen {
    font-size: 13px;
    line-height: 2.46153846;
    margin-left: 6px;
  }
}

.item-trial__price-caution {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .item-trial__price-caution {
    margin-left: 3px;
  }
}

@media all and (max-width: 599px) {
  .item-trial__price-caution {
    font-size: 12px;
    font-weight: 400;
    line-height: 2;
  }
}

.item-trial__form {
  text-align: right;
  margin-top: 11px;
}

@media all and (max-width: 599px) {
  .item-trial__form {
    margin-top: 15px;
  }
}

.item-trial__btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  border-image: none;
  border: none;
  color: #fff;
  padding: 15px 10px 15px 12px;
  line-height: 2.14285714;
  padding: 9px 10px 11px;
  max-width: 200px;
}

@media (hover: hover) and (pointer: fine) {
  .item-trial__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .item-trial__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .item-trial__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-trial__btn::after {
  background: linear-gradient(to left, #c10000 0%, #d35900 100%);
  z-index: -2;
}

.item-trial__btn:hover {
  background-image: none;
  opacity: 1;
  transition: background-color 0.2s ease-in-out;
}

.item-trial__btn:hover::before {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.item-trial__btn::before, .item-trial__btn::after {
  content: "";
  display: block;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.item-trial__btn::before {
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  z-index: -1;
}

.item-trial__btn .icon-cart {
  padding-left: 23.83px;
  position: relative;
}

@media all and (max-width: 599px) {
  .item-trial__btn .icon-cart {
    padding-left: 25.03px;
  }
}

.item-trial__btn .icon-cart::before {
  content: "";
  display: block;
  background-image: url(/assets/img/item/icon_cart.svg);
  width: 17.13px;
  height: 18.15px;
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .item-trial__btn .icon-cart::before {
    top: 51%;
    left: -1px;
  }
}

@media all and (max-width: 599px) {
  .item-trial__btn {
    max-width: 220px;
  }
}

.item-inquiry {
  background-color: #fafafa;
  padding: 144px 0 148px;
}

@media all and (max-width: 599px) {
  .item-inquiry {
    padding: 86px 0 116px;
  }
}

.item-wrapper-02 .item-inquiry {
  padding: 104px 0 106px;
}

@media all and (max-width: 599px) {
  .item-wrapper-02 .item-inquiry {
    padding: 63px 0 144px;
  }
}

.item-inquiry__wrap {
  padding: 0 5.85651537%;
}

@media all and (max-width: 599px) {
  .item-inquiry__wrap {
    padding: 0 30px;
  }
}

.item-inquiry__title {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: center;
}

@media all and (max-width: 599px) {
  .item-inquiry__title {
    font-size: 25px;
    font-weight: 500;
  }
}

.item-inquiry__block {
  margin-top: 55px;
}

@media all and (min-width: 600px) {
  .item-inquiry__block {
    display: flex;
  }
}

@media all and (min-width: 600px) and (max-width: 810px) {
  .item-inquiry__block {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__block {
    margin-top: 44px;
  }
}

.item-inquiry__box {
  border: 1px solid #dbdbdb;
  flex-basis: 50%;
  text-align: center;
  padding: 52px 40px 45px;
}

@media all and (min-width: 600px) and (max-width: 1121px) {
  .item-inquiry__box {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__box {
    padding: 28px 19px 34px 21px;
  }
}

@media all and (min-width: 600px) {
  .item-inquiry__box:nth-child(2) {
    margin-left: 18px;
  }
}

@media all and (min-width: 600px) and (max-width: 810px) {
  .item-inquiry__box:nth-child(2) {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__box:nth-child(2) {
    padding: 27px 19px 20px 21px;
    margin-top: 20px;
  }
}

.item-inquiry__box-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.08em;
}

@media all and (min-width: 600px) and (max-width: 1121px) {
  .item-inquiry__box-title {
    font-size: 16px;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__box-title {
    font-weight: 500;
  }
}

.item-inquiry__box-phone {
  font-size: 0;
  line-height: 1.44;
  margin-top: 6px;
}

@media all and (max-width: 599px) {
  .item-inquiry__box-phone {
    margin-top: 14px;
  }
}

.item-inquiry__box-phone-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.08em;
  vertical-align: 2px;
}

@media all and (min-width: 600px) and (max-width: 1121px) {
  .item-inquiry__box-phone-text {
    font-size: 16px;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__box-phone-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.15384615;
    vertical-align: 3px;
  }
}

.item-inquiry__box-phone-number {
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 0.075em;
  margin-left: 17px;
}

@media all and (min-width: 600px) and (max-width: 1121px) {
  .item-inquiry__box-phone-number {
    font-size: 42px;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__box-phone-number {
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.08em;
    margin-left: 7px;
  }
}

.item-inquiry__box-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
  letter-spacing: 0;
}

@media all and (min-width: 600px) {
  .item-inquiry__box-text {
    margin-top: 3px;
  }
}

@media all and (min-width: 600px) and (max-width: 1121px) {
  .item-inquiry__box-text {
    font-size: 13px;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__box-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
  }
}

.item-inquiry__box-btn {
  margin-top: 27px;
}

@media all and (max-width: 599px) {
  .item-inquiry__box-btn {
    margin-top: 19px;
  }
}

.item-inquiry__box-link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  padding: 22px 20px;
  width: 100%;
  max-width: 460px;
}

@media (hover: hover) and (pointer: fine) {
  .item-inquiry__box-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .item-inquiry__box-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.item-inquiry__box-link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.item-inquiry__box-link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.item-inquiry__box-link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.item-inquiry__box-link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

@media all and (min-width: 600px) and (max-width: 1121px) {
  .item-inquiry__box-link {
    font-size: 16px;
    padding: 19px 20px;
  }
}

@media all and (max-width: 599px) {
  .item-inquiry__box-link {
    font-size: 18px;
    line-height: 1.33333333;
    padding: 17px 20px;
  }
}

/********** Contact | お問い合わせ・資料請求 **********/
/*
  ----------------
  .contact-catalog | お問い合わせ・資料請求
  ----------------
*/
.form-form-contact > h3,
.form-form-catalog > h3,
.form-form-contact_guest > h3,
.form-form-catalog_guest > h3 {
  display: none;
}

.form-form-contact .page-title,
.form-form-catalog .page-title,
.form-form-contact_guest .page-title,
.form-form-catalog_guest .page-title {
  color: #fff;
  background: url(/assets/img/contact/bg_page_title.jpg) no-repeat center center;
  background-size: cover;
  font-size: 40px;
  height: 448px;
  letter-spacing: 0.08em;
  line-height: 1.75;
  padding-top: 105px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .form-form-contact .page-title,
  .form-form-catalog .page-title,
  .form-form-contact_guest .page-title,
  .form-form-catalog_guest .page-title {
    background: url(/assets/img/contact/bg_page_title_sp.jpg) no-repeat center center;
    background-size: cover;
    font-size: 25px;
    height: 400px;
    letter-spacing: 0.08em;
    line-height: 1.6;
    padding-top: 58px;
  }
}

@media all and (min-width: 600px) {
  .form-form-contact .page-title,
  .form-form-catalog .page-title,
  .form-form-contact_guest .page-title,
  .form-form-catalog_guest .page-title {
    margin-bottom: 77px;
  }
}

.form-form-contact .form-note,
.form-form-catalog .form-note,
.form-form-contact_guest .form-note,
.form-form-catalog_guest .form-note {
  margin: 0;
}

@media all and (max-width: 599px) {
  .form-form-contact .form-note,
  .form-form-catalog .form-note,
  .form-form-contact_guest .form-note,
  .form-form-catalog_guest .form-note {
    text-align: center;
  }
}

@media all and (min-width: 600px) {
  .form-form-contact .form-body,
  .form-form-catalog .form-body,
  .form-form-contact_guest .form-body,
  .form-form-catalog_guest .form-body {
    margin-bottom: 177px;
    padding-bottom: 78px;
  }
  .form-form-contact .form-body .formrow-submit,
  .form-form-catalog .form-body .formrow-submit,
  .form-form-contact_guest .form-body .formrow-submit,
  .form-form-catalog_guest .form-body .formrow-submit {
    margin-top: 60px;
  }
}

.contact-catalog__wrap {
  margin: 0 auto;
  max-width: 1206px;
}

@media all and (max-width: 599px) {
  .contact-catalog__wrap {
    padding: 52px 30px 10px;
  }
}

.contact-catalog__title {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.5;
  text-align: center;
}

@media all and (max-width: 599px) {
  .contact-catalog__title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.6;
  }
}

.contact-catalog__text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.8571;
  margin-top: 43px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .contact-catalog__text {
    margin-top: 36px;
    text-align: left;
  }
}

.contact-catalog__select {
  margin: 76px auto 0;
  max-width: 1206px;
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(594px, 1fr));
}

@media all and (max-width: 599px) {
  .contact-catalog__select {
    display: block;
    margin-top: 53px;
  }
}

.contact-catalog__select-item {
  border: 1px solid #dbdbdb;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 600px) {
  .contact-catalog__select-item {
    min-height: 287px;
  }
}

@media all and (max-width: 599px) {
  .contact-catalog__select-item {
    padding: 28px 19px 19px;
  }
}

.contact-catalog__select-item:last-child {
  padding-top: 12px;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-item:last-child {
    margin-top: 20px;
    padding-top: 27px;
    padding-bottom: 24px;
  }
  .contact-catalog__select-item:last-child .contact-select__title {
    font-weight: 400;
  }
}

.contact-catalog__select-title {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.7;
  margin-top: 47px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-title {
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
  }
}

.contact-catalog__select-tel {
  margin-top: -15px;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-tel {
    margin-top: 4px;
  }
}

.contact-catalog__select-tel > span {
  font-size: 20px;
  letter-spacing: 0.08em;
  line-height: 1.2;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-tel > span {
    display: inline-block;
    font-size: 13px;
    line-height: 1.1538;
    transform: translateY(-4px);
  }
}

.contact-catalog__select-tel > a {
  display: inline-block;
  font-size: 50px;
  letter-spacing: 0.08em;
  line-height: 2.0833;
  margin-left: 13px;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-tel > a {
    font-size: 35px;
    margin-left: 5px;
  }
}

.contact-catalog__select-time {
  letter-spacing: 0;
  margin-top: -15px;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-time {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.8462;
  }
}

.contact-catalog__select-text {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.6667;
  margin-top: 19px;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.8462;
    margin-top: 16px;
  }
}

.contact-catalog__select-link {
  border-bottom: 1px solid #cc8600;
  border-image: linear-gradient(to right, #cc8600 0%, #ba0000 100%);
  border-image-slice: 1;
  color: #ba0000;
  font-size: 20px;
  height: 70px;
  letter-spacing: 0.08em;
  line-height: 1.2;
  margin: 46px auto 0;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .contact-catalog__select-link {
    font-size: 18px;
    font-weight: 400;
    height: 84px;
    line-height: 1.3333;
    margin-top: 22px;
  }
}

/*
  ----------------
  .contact-form | お問い合わせ・資料請求
  ----------------
*/
.form-title {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 88px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .form-title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.6;
    margin-top: 48px;
  }
}

.form-select {
  margin: 55px auto 0;
  max-width: 1206px;
  display: flex;
  align-items: flex-start;
  gap: 13px;
  justify-content: space-between;
}

@media all and (max-width: 599px) {
  .form-select {
    margin: 65px 15px 0;
  }
}

.form-select__item {
  max-width: 593px;
  width: 100%;
}

.form-select__item.is-active {
  max-width: 600px;
}

.form-select__link {
  background: #171717;
  border-top: 2px solid #171717;
  color: #fff;
  display: block;
  font-size: 16px;
  height: 50px;
  letter-spacing: 0;
  line-height: 1.5;
  padding-top: 9px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .form-select__link {
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
  }
}

.is-active .form-select__link {
  background: #fafafa;
  color: #171717;
  height: 62px;
}

.form #contact-type option {
  width: 50%;
}

.contact_contact_html > h3,
.form-form-contact_guest > h3,
.form-form-catalog_guest > h3 {
  display: none;
}

.contact_contact_html .form-note,
.form-form-contact_guest .form-note,
.form-form-catalog_guest .form-note {
  margin-top: 0;
  margin-bottom: 0;
}

@media all and (max-width: 599px) {
  .contact_contact_html .form-note,
  .form-form-contact_guest .form-note,
  .form-form-catalog_guest .form-note {
    padding: 0;
  }
}

.contact_contact_html .page-title,
.form-form-contact_guest .page-title,
.form-form-catalog_guest .page-title {
  color: #fff;
  background: url(/assets/img/contact/bg_page_title.jpg) no-repeat center center;
  background-size: cover;
  font-size: 40px;
  height: 448px;
  letter-spacing: 0.08em;
  line-height: 1.75;
  padding-top: 105px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .contact_contact_html .page-title,
  .form-form-contact_guest .page-title,
  .form-form-catalog_guest .page-title {
    background: url(/assets/img/contact/bg_page_title_sp.jpg) no-repeat center center;
    background-size: cover;
    font-size: 25px;
    height: 400px;
    letter-spacing: 0.08em;
    line-height: 1.6;
    padding-top: 58px;
  }
}

@media all and (min-width: 600px) {
  .contact_contact_html .page-title,
  .form-form-contact_guest .page-title,
  .form-form-catalog_guest .page-title {
    margin-bottom: 77px;
  }
}

.contact_contact_html .account-login,
.form-form-contact_guest .account-login,
.form-form-catalog_guest .account-login {
  background: #fafafa;
  max-width: 1206px;
  margin: 0 auto;
  padding-top: 40px;
}

@media all and (max-width: 599px) {
  .contact_contact_html .account-login,
  .form-form-contact_guest .account-login,
  .form-form-catalog_guest .account-login {
    padding: 30px 30px 0;
  }
}

.contact_contact_html .account-login > a,
.form-form-contact_guest .account-login > a,
.form-form-catalog_guest .account-login > a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  display: inline-block;
  max-width: none;
  width: auto;
}

@media (hover: hover) and (pointer: fine) {
  .contact_contact_html .account-login > a:hover,
  .form-form-contact_guest .account-login > a:hover,
  .form-form-catalog_guest .account-login > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media all and (max-width: 599px) {
  .contact_contact_html .account-login > a,
  .form-form-contact_guest .account-login > a,
  .form-form-catalog_guest .account-login > a {
    font-size: 12px;
    width: 100%;
  }
}

@media all and (min-width: 600px) {
  .contact_contact_html .form-body,
  .form-form-contact_guest .form-body,
  .form-form-catalog_guest .form-body {
    margin-bottom: 177px;
    padding-top: 41px;
    padding-bottom: 78px;
  }
}

@media all and (max-width: 599px) {
  .contact_contact_html .form-body,
  .form-form-contact_guest .form-body,
  .form-form-catalog_guest .form-body {
    padding-top: 30px;
    padding-bottom: 154px;
    position: relative;
  }
}

.contact_contact_html .formrow.name-input-row,
.contact_contact_html .formrow.formrow-radio,
.form-form-contact_guest .formrow.name-input-row,
.form-form-contact_guest .formrow.formrow-radio,
.form-form-catalog_guest .formrow.name-input-row,
.form-form-catalog_guest .formrow.formrow-radio {
  border-top: none;
}

.contact_contact_html .btn-submit-signup,
.form-form-contact_guest .btn-submit-signup,
.form-form-catalog_guest .btn-submit-signup {
  margin-top: 1rem;
}

.contact_contact_html .formrow-submit,
.form-form-contact_guest .formrow-submit,
.form-form-catalog_guest .formrow-submit {
  margin-top: 60px;
}

@media all and (max-width: 599px) {
  .contact_contact_html .formrow-submit,
  .form-form-contact_guest .formrow-submit,
  .form-form-catalog_guest .formrow-submit {
    margin-top: 47px;
  }
}

.form-precautions__title {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.6;
  text-align: center;
}

@media all and (max-width: 599px) {
  .form-precautions__title {
    font-size: 20px;
    line-height: 2;
  }
}

.form-precautions__text {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.8462;
  margin-top: 22px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .form-precautions__text {
    line-height: 1.8462;
    margin-top: 9px;
  }
}

.form-precautions__text-area {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  box-sizing: border-box;
  color: #171717;
  display: block;
  font-size: 13px;
  font-weight: 400;
  height: 100vh;
  line-height: 3.0769;
  margin: 45px auto 0;
  max-height: 447px;
  max-width: 1000px;
  overflow-y: scroll;
  padding: 50px;
  width: 100%;
}

@media all and (max-width: 599px) {
  .form-precautions__text-area {
    letter-spacing: 0;
    line-height: 1.9231;
    margin-top: 28px;
    max-height: 629px;
    padding: 20px 21px;
  }
}

.form-precautions__text-area > a {
  text-decoration: underline;
}

.form-precautions__text-area > a:hover {
  text-decoration: none;
}

/********** Online Counseling | オンラインカウンセリング **********/
.form-form-counseling > h3,
.form-form-counseling_guest > h3 {
  display: none;
}

.form-form-counseling .page-title,
.form-form-counseling_guest .page-title {
  color: #171717;
  background: url(/assets/img/counseling/bg_page_title.jpg) no-repeat center center;
  background-size: cover;
  font-size: 40px;
  height: 448px;
  letter-spacing: 0.08em;
  line-height: 1.75;
  padding-top: 105px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .form-form-counseling .page-title,
  .form-form-counseling_guest .page-title {
    background: url(/assets/img/counseling/bg_page_title_sp.jpg) no-repeat center center;
    background-size: cover;
    font-size: 25px;
    height: 400px;
    letter-spacing: 0.08em;
    line-height: 1.6;
    padding-top: 58px;
  }
}

@media all and (max-width: 599px) {
  .form-form-counseling .page-title,
  .form-form-counseling_guest .page-title {
    padding-top: 44px;
    text-align: center;
  }
}

.form-form-counseling .form-note,
.form-form-counseling_guest .form-note {
  margin-top: 0;
}

@media all and (max-width: 599px) {
  .form-form-counseling .form-note,
  .form-form-counseling_guest .form-note {
    padding: 0;
  }
}

.form-form-counseling .account-login,
.form-form-counseling_guest .account-login {
  max-width: 1206px;
  margin: 40px auto 0;
}

@media all and (max-width: 599px) {
  .form-form-counseling .account-login,
  .form-form-counseling_guest .account-login {
    margin-top: 30px;
    padding: 0 30px;
  }
}

.form-form-counseling .account-login > a,
.form-form-counseling_guest .account-login > a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  display: inline-block;
  max-width: none;
  width: auto;
}

@media (hover: hover) and (pointer: fine) {
  .form-form-counseling .account-login > a:hover,
  .form-form-counseling_guest .account-login > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media all and (max-width: 599px) {
  .form-form-counseling .account-login > a,
  .form-form-counseling_guest .account-login > a {
    font-size: 12px;
    width: 100%;
  }
}

.form-form-counseling .formrow-submit,
.form-form-counseling_guest .formrow-submit {
  margin-top: 60px;
}

@media all and (max-width: 599px) {
  .form-form-counseling .formrow-submit,
  .form-form-counseling_guest .formrow-submit {
    margin-top: 40px;
  }
}

/*
  ----------------
  Online Counseling | Guidance
  ----------------
*/
.counseling-guidance__wrap {
  margin: 0 auto;
  max-width: 1206px;
  padding-top: 91px;
}

@media all and (max-width: 900px) {
  .counseling-guidance__wrap {
    padding: 51px 30px 0;
  }
}

.counseling-guidance__title-sub {
  text-align: center;
}

.counseling-guidance__title-sub img {
  height: 59px;
  width: auto;
}

@media all and (max-width: 599px) {
  .counseling-guidance__title-sub img {
    height: 39px;
  }
}

.counseling-guidance__title-sub img {
  height: 28.56px;
}

@media all and (max-width: 900px) {
  .counseling-guidance__title-sub img {
    height: 52.56px;
  }
}

.counseling-guidance__title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.15em;
  line-height: 1.75;
  line-height: 1.45;
}

@media all and (max-width: 599px) {
  .counseling-guidance__title {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1.6;
  }
}

@media all and (max-width: 900px) {
  .counseling-guidance__title {
    margin-top: 5px;
  }
}

.counseling-guidance__list {
  margin-top: 70px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}

@media all and (max-width: 900px) {
  .counseling-guidance__list {
    display: block;
    margin-top: 35px;
  }
}

.counseling-guidance__item {
  background: #f2f2f2;
  border-radius: 5px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.6667;
  max-width: 307px;
  padding: 11px 0 12px;
  position: relative;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 900px) {
  .counseling-guidance__item {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.875;
    max-width: 100% !important;
    padding: 11px 0 10px;
  }
}

@media all and (min-width: 600px) {
  .counseling-guidance__item:nth-child(2) {
    max-width: 456px;
  }
}

@media all and (max-width: 900px) {
  .counseling-guidance__item:nth-child(2) {
    margin-top: 21px;
    padding: 16px 0 7px;
  }
  .counseling-guidance__item:nth-child(2)::after {
    transform: scale(-1, 1);
    right: 17px;
    left: auto;
  }
}

@media all and (min-width: 600px) {
  .counseling-guidance__item:nth-child(3) {
    max-width: 350px;
  }
}

@media all and (max-width: 900px) {
  .counseling-guidance__item:nth-child(3) {
    margin-top: 22px;
    padding: 22px 0 16px;
  }
}

.counseling-guidance__item::after {
  background: url(/assets/img/counseling/arrow_balloon.svg) no-repeat center center;
  content: "";
  display: block;
  height: 22.111px;
  width: 15.691px;
  position: absolute;
  bottom: -17px;
  left: 7px;
}

@media all and (max-width: 900px) {
  .counseling-guidance__item::after {
    left: 17px;
  }
}

.counseling-guidance__text {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 3.0769;
  margin-top: 89px;
  text-align: center;
}

@media all and (max-width: 900px) {
  .counseling-guidance__text {
    font-size: 14px;
    line-height: 2.1429;
    margin-top: 53px;
    text-align: left;
  }
}

.counseling-guidance__contact {
  border: 1px solid #dbdbdb;
  margin-top: 79px;
  padding: 39px 50px 39px 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media all and (max-width: 900px) {
  .counseling-guidance__contact {
    display: block;
    margin-top: 35px;
    padding: 24px 19px 18px;
    text-align: center;
  }
}

.counseling-guidance__contact-title {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.6;
}

@media all and (max-width: 900px) {
  .counseling-guidance__contact-title {
    font-weight: 500;
    text-align: center;
  }
}

.counseling-guidance__contact-link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  font-size: 20px;
  line-height: 1.2;
  max-width: 460px;
  padding: 22px 10px 22px 12px;
}

@media (hover: hover) and (pointer: fine) {
  .counseling-guidance__contact-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (hover: hover) and (pointer: fine) {
  .counseling-guidance__contact-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.counseling-guidance__contact-link::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.counseling-guidance__contact-link:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.counseling-guidance__contact-link:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.counseling-guidance__contact-link:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

@media all and (max-width: 900px) {
  .counseling-guidance__contact-link {
    font-size: 18px;
    letter-spacing: 0.08em;
    line-height: 1.3333;
    margin-top: 18px;
    max-width: 290px;
    padding: 17px 10px 17px 11px;
    text-align: center;
  }
}

/*
  ----------------
  Online Counseling | Flow
  ----------------
*/
.counseling-flow__wrap {
  margin: 0 auto;
  max-width: 1206px;
  padding: 110px 0 101px;
}

@media all and (max-width: 900px) {
  .counseling-flow__wrap {
    padding: 81px 30px 35px;
  }
}

.counseling-flow__title {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.08em;
  text-align: center;
}

@media all and (max-width: 900px) {
  .counseling-flow__title {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 1.6;
    text-align: center;
  }
}

.counseling-flow__list {
  counter-reset: number 0;
  margin-top: 74px;
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 900px) {
  .counseling-flow__list {
    display: block;
    margin-top: 63px;
  }
}

.counseling-flow__item {
  box-sizing: content-box;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 3.0769;
  padding: 0 16.5px;
  position: relative;
  width: 25%;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 900px) {
  .counseling-flow__item {
    display: block;
    padding: 0 0 46px 72px;
    width: auto;
  }
}

.counseling-flow__item:before {
  background: #fff;
  border: 1px solid #171717;
  border-radius: 50%;
  counter-increment: number;
  content: counter(number, decimal-leading-zero);
  font-size: 25px;
  height: 60px;
  letter-spacing: 0;
  margin: 0 auto;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 900px) {
  .counseling-flow__item:before {
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.counseling-flow__item::after {
  background-color: #171717;
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 31px;
  right: 0;
  z-index: -1;
}

@media all and (max-width: 900px) {
  .counseling-flow__item::after {
    height: 100%;
    width: 1px !important;
    right: auto;
    left: 30px;
  }
}

@media all and (min-width: 600px) {
  .counseling-flow__item:first-child {
    padding-left: 0;
  }
  .counseling-flow__item:first-child::after {
    width: 50%;
  }
}

@media all and (max-width: 900px) {
  .counseling-flow__item:first-child {
    padding-left: 72px;
  }
  .counseling-flow__item:first-child .counseling-flow__text {
    margin-top: 15px;
  }
}

@media all and (max-width: 900px) {
  .counseling-flow__item:nth-child(2) .counseling-flow__title-sub::after {
    background: url(/assets/img/counseling/icon_mail.svg) no-repeat center center;
    background-size: contain;
    height: 34.78px;
    margin-left: 22px;
    width: 54px;
  }
}

@media all and (max-width: 900px) {
  .counseling-flow__item:nth-child(3) .counseling-flow__title-sub::after {
    background: url(/assets/img/counseling/icon_zoom.svg) no-repeat center center;
    background-size: contain;
    height: 48.2px;
    margin: 0 0 0 61px;
    width: 64.48px;
  }
}

@media all and (min-width: 600px) {
  .counseling-flow__item:last-child {
    padding-right: 0;
  }
}

@media all and (max-width: 900px) {
  .counseling-flow__item:last-child .counseling-flow__title-sub {
    padding-top: 16px;
  }
  .counseling-flow__item:last-child .counseling-flow__title-sub::after {
    background: url(/assets/img/counseling/icon_counseling.svg) no-repeat center center;
    background-size: contain;
    height: 49.03px;
    margin: -9px 0 0 31px;
    width: 43.98px;
  }
  .counseling-flow__item:last-child .counseling-flow__text {
    margin-top: 12px;
  }
}

.counseling-flow__item:last-child::after {
  right: auto;
  left: 0;
  width: 50%;
}

@media all and (max-width: 900px) {
  .counseling-flow__item:last-child::after {
    display: none;
  }
}

.counseling-flow__title-sub {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  margin-top: 25px;
  text-align: center;
}

@media all and (min-width: 600px) {
  .counseling-flow__title-sub {
    min-height: 64px;
  }
}

@media all and (max-width: 900px) {
  .counseling-flow__title-sub {
    margin-top: 0;
    text-align: left;
    display: flex;
    transform: translateY(-2px);
  }
  .counseling-flow__title-sub::after {
    background: url(/assets/img/counseling/icon_form.svg) no-repeat center center;
    background-size: contain;
    content: "";
    display: block;
    height: 48.07px;
    margin: auto 0 auto 31px;
    width: 45.41px;
  }
}

.counseling-flow__img {
  background-color: #fafafa;
  height: 160px;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 900px) {
  .counseling-flow__img {
    display: none;
  }
}

.counseling-flow__icon {
  width: auto;
}

.counseling-flow__text {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 2.3077;
  margin-top: 15px;
}

@media all and (max-width: 900px) {
  .counseling-flow__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.1429;
    margin-top: 6px;
  }
}

.counseling-flow__zoom {
  background-color: #fff;
  border: 1px solid #171717;
  height: 82px;
  margin-top: 37px;
  padding: 16px 0 0 8px;
  position: relative;
  display: flex;
  align-items: inherit;
}

@media all and (max-width: 900px) {
  .counseling-flow__zoom {
    display: block;
    height: auto;
    margin: 30px 0 -6px;
    padding: 12px 0 19px;
    text-align: center;
  }
}

.counseling-flow__zoom-title {
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 2.3077;
  margin: 0 auto;
  padding: 0 17px;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
}

@media all and (max-width: 900px) {
  .counseling-flow__zoom-title {
    font-size: 15px;
    line-height: 2.3333;
    top: -20px;
  }
}

@media all and (min-width: 600px) {
  .counseling-flow__zoom-btn:nth-of-type(2) {
    padding-top: 3px;
  }
}

@media all and (max-width: 900px) {
  .counseling-flow__zoom-btn:nth-of-type(2) {
    margin-top: 2px;
  }
  .counseling-flow__zoom-btn:nth-of-type(2) img {
    width: 209.56px;
  }
}

.counseling-flow__zoom-btn img {
  width: auto;
}

@media all and (max-width: 900px) {
  .counseling-flow__zoom-btn img {
    width: 237.49px;
  }
}

/*
  ----------------
  Online Counseling | Precautions
  ----------------
*/
.counseling-precautions__wrap {
  background-color: #fafafa;
  margin: 0 auto 20px;
  max-width: 1206px;
  padding: 63px 103px 93px;
}

@media all and (max-width: 900px) {
  .counseling-precautions__wrap {
    margin-bottom: 10px;
    padding: 68px 30px 38px;
  }
}

.counseling-precautions__header {
  padding: 0 50px;
}

.counseling-precautions__title {
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.6;
  text-align: center;
}

.counseling-precautions__text {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.8462;
  margin-top: 13px;
  text-align: center;
}

@media all and (max-width: 900px) {
  .counseling-precautions__text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1.7857;
    margin-top: 20px;
  }
}

.counseling-precautions__reception-time {
  margin-top: 37px;
  display: flex;
  gap: 16px;
  justify-content: center;
}

@media all and (max-width: 900px) {
  .counseling-precautions__reception-time {
    display: block;
    margin-top: 32px;
  }
}

.counseling-precautions__reception-item {
  border: 1px solid #dbdbdb;
  max-width: 492px;
  padding: 24px 0 31px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media all and (max-width: 900px) {
  .counseling-precautions__reception-item {
    max-width: 100%;
    padding: 23px 0 21px;
  }
  .counseling-precautions__reception-item + li {
    margin-top: 20px;
  }
}

.counseling-precautions__reception-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7778;
}

@media all and (max-width: 900px) {
  .counseling-precautions__reception-title {
    font-weight: 500;
    line-height: 1.1667;
  }
}

.counseling-precautions__reception-text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 2.1429;
  margin-top: 19px;
}

@media all and (max-width: 900px) {
  .counseling-precautions__reception-text {
    font-weight: 400;
    line-height: 1.7857;
    margin-top: 7px;
  }
}

.counseling-precautions__reception-text > span {
  display: block;
  color: #9b0000;
  font-weight: 500;
}

@media all and (max-width: 900px) {
  .counseling-precautions__reception-text > span {
    margin: 4px 0 -4px;
  }
}

.counseling-precautions__list {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  margin-top: 20px;
  padding: 38px 50px 43px;
}

@media all and (max-width: 900px) {
  .counseling-precautions__list {
    margin-top: 23px;
    padding: 21px 19px 25px 22px;
  }
}

.counseling-precautions__item {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 3.0769;
}

@media all and (max-width: 900px) {
  .counseling-precautions__item {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.9231;
  }
}

/*
  ----------------
  Online Counseling | Form
  ----------------
*/
.form-form-counseling .form-body {
  margin-bottom: 173px;
  padding-top: 63px;
  padding-bottom: 108px;
}

@media all and (max-width: 900px) {
  .form-form-counseling .form-body {
    margin-bottom: 0;
    padding-top: 49px;
    padding-bottom: 256px;
  }
}

.form-form-counseling .form-body .input-group textarea {
  height: 196px;
}

@media all and (max-width: 900px) {
  .form-form-counseling .form-body .input-group textarea {
    height: 290px;
  }
}

.form-form-counseling .form-body .formrow-submit {
  margin-top: 53px;
}

@media all and (max-width: 900px) {
  .form-form-counseling .form-body .formrow-submit {
    margin-top: 51px;
  }
}

/********** Column | コラム一覧  **********/
/*
  ----------------
  コラム一覧 | Heading
  ----------------
*/
.column-heading {
  margin-bottom: 98px;
}

@media all and (max-width: 599px) {
  .column-heading {
    margin-bottom: 39px;
  }
}

.column-heading__wrap {
  position: relative;
}

.column-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .column-heading__box {
    top: calc(50% + 30px);
  }
}

.column-heading__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .column-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .column-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .column-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

.news-archive-column_pick_up h1,
.news-archive-column_less_is_more h1,
.news-archive-column_main h1 {
  color: #fff;
  background: url(/assets/img/column/bg_page_title.jpg) no-repeat center center;
  background-size: cover;
  font-size: 40px;
  height: 448px;
  letter-spacing: 0.08em;
  line-height: 1.75;
  padding-top: 105px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 98px;
}

@media all and (max-width: 599px) {
  .news-archive-column_pick_up h1,
  .news-archive-column_less_is_more h1,
  .news-archive-column_main h1 {
    background: url(/assets/img/column/bg_page_title_sp.jpg) no-repeat center center;
    background-size: cover;
    font-size: 25px;
    height: 400px;
    letter-spacing: 0.08em;
    line-height: 1.6;
    padding-top: 58px;
  }
}

@media all and (max-width: 599px) {
  .news-archive-column_pick_up h1,
  .news-archive-column_less_is_more h1,
  .news-archive-column_main h1 {
    margin-bottom: 39px;
  }
}

.news-archive-column_pick_up .page-breadcrumbs,
.news-archive-column_less_is_more .page-breadcrumbs,
.news-archive-column_main .page-breadcrumbs {
  display: none;
}

/*
  ----------------
  コラム一覧 | column-contents
  ----------------
*/
.column .newslist-column_less_is_more,
.newslist_column_index_html .newslist-column_less_is_more,
.news-archive-column_pick_up .newslist-column_less_is_more,
.news-archive-column_less_is_more .newslist-column_less_is_more,
.news-archive-column_main .newslist-column_less_is_more {
  margin-top: 82px;
}

@media all and (max-width: 599px) {
  .column .newslist-column_less_is_more,
  .newslist_column_index_html .newslist-column_less_is_more,
  .news-archive-column_pick_up .newslist-column_less_is_more,
  .news-archive-column_less_is_more .newslist-column_less_is_more,
  .news-archive-column_main .newslist-column_less_is_more {
    margin-top: 64px;
  }
}

.column .newslist-column_main,
.newslist_column_index_html .newslist-column_main,
.news-archive-column_pick_up .newslist-column_main,
.news-archive-column_less_is_more .newslist-column_main,
.news-archive-column_main .newslist-column_main {
  margin-top: 98px;
}

@media all and (max-width: 599px) {
  .column .newslist-column_main,
  .newslist_column_index_html .newslist-column_main,
  .news-archive-column_pick_up .newslist-column_main,
  .news-archive-column_less_is_more .newslist-column_main,
  .news-archive-column_main .newslist-column_main {
    margin-top: 87px;
  }
}

.column .newslist-column_less_is_more .entry-icon,
.column .newslist-column_main .entry-icon,
.newslist_column_index_html .newslist-column_less_is_more .entry-icon,
.newslist_column_index_html .newslist-column_main .entry-icon,
.news-archive-column_pick_up .newslist-column_less_is_more .entry-icon,
.news-archive-column_pick_up .newslist-column_main .entry-icon,
.news-archive-column_less_is_more .newslist-column_less_is_more .entry-icon,
.news-archive-column_less_is_more .newslist-column_main .entry-icon,
.news-archive-column_main .newslist-column_less_is_more .entry-icon,
.news-archive-column_main .newslist-column_main .entry-icon {
  position: relative;
  overflow: hidden;
  padding-top: 61.1111%;
}

@media all and (max-width: 599px) {
  .column .newslist-column_less_is_more .entry-icon,
  .column .newslist-column_main .entry-icon,
  .newslist_column_index_html .newslist-column_less_is_more .entry-icon,
  .newslist_column_index_html .newslist-column_main .entry-icon,
  .news-archive-column_pick_up .newslist-column_less_is_more .entry-icon,
  .news-archive-column_pick_up .newslist-column_main .entry-icon,
  .news-archive-column_less_is_more .newslist-column_less_is_more .entry-icon,
  .news-archive-column_less_is_more .newslist-column_main .entry-icon,
  .news-archive-column_main .newslist-column_less_is_more .entry-icon,
  .news-archive-column_main .newslist-column_main .entry-icon {
    padding-top: 61.0895%;
  }
}

.column .newslist-column_less_is_more .entry-icon img,
.column .newslist-column_main .entry-icon img,
.newslist_column_index_html .newslist-column_less_is_more .entry-icon img,
.newslist_column_index_html .newslist-column_main .entry-icon img,
.news-archive-column_pick_up .newslist-column_less_is_more .entry-icon img,
.news-archive-column_pick_up .newslist-column_main .entry-icon img,
.news-archive-column_less_is_more .newslist-column_less_is_more .entry-icon img,
.news-archive-column_less_is_more .newslist-column_main .entry-icon img,
.news-archive-column_main .newslist-column_less_is_more .entry-icon img,
.news-archive-column_main .newslist-column_main .entry-icon img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.column__title,
.column .panel-heading-text .news-name,
.newslist_column_index_html__title,
.newslist_column_index_html .panel-heading-text .news-name,
.news-archive-column_pick_up__title,
.news-archive-column_pick_up .panel-heading-text .news-name,
.news-archive-column_less_is_more__title,
.news-archive-column_less_is_more .panel-heading-text .news-name,
.news-archive-column_main__title,
.news-archive-column_main .panel-heading-text .news-name {
  border-bottom: 1px solid #171717;
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.6;
  padding-bottom: 14px;
  text-align: left;
}

@media all and (max-width: 599px) {
  .column__title,
  .column .panel-heading-text .news-name,
  .newslist_column_index_html__title,
  .newslist_column_index_html .panel-heading-text .news-name,
  .news-archive-column_pick_up__title,
  .news-archive-column_pick_up .panel-heading-text .news-name,
  .news-archive-column_less_is_more__title,
  .news-archive-column_less_is_more .panel-heading-text .news-name,
  .news-archive-column_main__title,
  .news-archive-column_main .panel-heading-text .news-name {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
  }
}

.column__list,
.column .news-entries,
.newslist_column_index_html__list,
.newslist_column_index_html .news-entries,
.news-archive-column_pick_up__list,
.news-archive-column_pick_up .news-entries,
.news-archive-column_less_is_more__list,
.news-archive-column_less_is_more .news-entries,
.news-archive-column_main__list,
.news-archive-column_main .news-entries {
  margin: 50px auto 0;
  max-width: 1206px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 63px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .column__list,
  .column .news-entries,
  .newslist_column_index_html__list,
  .newslist_column_index_html .news-entries,
  .news-archive-column_pick_up__list,
  .news-archive-column_pick_up .news-entries,
  .news-archive-column_less_is_more__list,
  .news-archive-column_less_is_more .news-entries,
  .news-archive-column_main__list,
  .news-archive-column_main .news-entries {
    grid-template-columns: repeat(2, 1fr);
    gap: 17px 26px;
  }
}

@media all and (max-width: 599px) {
  .column__list,
  .column .news-entries,
  .newslist_column_index_html__list,
  .newslist_column_index_html .news-entries,
  .news-archive-column_pick_up__list,
  .news-archive-column_pick_up .news-entries,
  .news-archive-column_less_is_more__list,
  .news-archive-column_less_is_more .news-entries,
  .news-archive-column_main__list,
  .news-archive-column_main .news-entries {
    margin-top: 25px;
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 17px 26px;
  }
}

.column__list.upper,
.column .news-entries.upper,
.newslist_column_index_html__list.upper,
.newslist_column_index_html .news-entries.upper,
.news-archive-column_pick_up__list.upper,
.news-archive-column_pick_up .news-entries.upper,
.news-archive-column_less_is_more__list.upper,
.news-archive-column_less_is_more .news-entries.upper,
.news-archive-column_main__list.upper,
.news-archive-column_main .news-entries.upper {
  grid-template-columns: repeat(2, 1fr);
  gap: 66px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .column__list.upper,
  .column .news-entries.upper,
  .newslist_column_index_html__list.upper,
  .newslist_column_index_html .news-entries.upper,
  .news-archive-column_pick_up__list.upper,
  .news-archive-column_pick_up .news-entries.upper,
  .news-archive-column_less_is_more__list.upper,
  .news-archive-column_less_is_more .news-entries.upper,
  .news-archive-column_main__list.upper,
  .news-archive-column_main .news-entries.upper {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
}

@media all and (max-width: 599px) {
  .column__list.upper,
  .column .news-entries.upper,
  .newslist_column_index_html__list.upper,
  .newslist_column_index_html .news-entries.upper,
  .news-archive-column_pick_up__list.upper,
  .news-archive-column_pick_up .news-entries.upper,
  .news-archive-column_less_is_more__list.upper,
  .news-archive-column_less_is_more .news-entries.upper,
  .news-archive-column_main__list.upper,
  .news-archive-column_main .news-entries.upper {
    margin-top: 39px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
}

.column__list.upper .is-new-item::before,
.column .news-entries.upper .is-new-item::before,
.newslist_column_index_html__list.upper .is-new-item::before,
.newslist_column_index_html .news-entries.upper .is-new-item::before,
.news-archive-column_pick_up__list.upper .is-new-item::before,
.news-archive-column_pick_up .news-entries.upper .is-new-item::before,
.news-archive-column_less_is_more__list.upper .is-new-item::before,
.news-archive-column_less_is_more .news-entries.upper .is-new-item::before,
.news-archive-column_main__list.upper .is-new-item::before,
.news-archive-column_main .news-entries.upper .is-new-item::before {
  top: 12px;
  right: 13px;
}

.column__list.upper .column__box,
.column .news-entries.upper .column__box,
.newslist_column_index_html__list.upper .column__box,
.newslist_column_index_html .news-entries.upper .column__box,
.news-archive-column_pick_up__list.upper .column__box,
.news-archive-column_pick_up .news-entries.upper .column__box,
.news-archive-column_less_is_more__list.upper .column__box,
.news-archive-column_less_is_more .news-entries.upper .column__box,
.news-archive-column_main__list.upper .column__box,
.news-archive-column_main .news-entries.upper .column__box {
  padding: 26px 30px 25px;
}

@media all and (max-width: 599px) {
  .column__list.upper .column__box,
  .column .news-entries.upper .column__box,
  .newslist_column_index_html__list.upper .column__box,
  .newslist_column_index_html .news-entries.upper .column__box,
  .news-archive-column_pick_up__list.upper .column__box,
  .news-archive-column_pick_up .news-entries.upper .column__box,
  .news-archive-column_less_is_more__list.upper .column__box,
  .news-archive-column_less_is_more .news-entries.upper .column__box,
  .news-archive-column_main__list.upper .column__box,
  .news-archive-column_main .news-entries.upper .column__box {
    padding: 19px 12px 23px;
  }
}

.column__list.upper .column__title-column,
.column .news-entries.upper .column__title-column,
.newslist_column_index_html__list.upper .column__title-column,
.newslist_column_index_html .news-entries.upper .column__title-column,
.news-archive-column_pick_up__list.upper .column__title-column,
.news-archive-column_pick_up .news-entries.upper .column__title-column,
.news-archive-column_less_is_more__list.upper .column__title-column,
.news-archive-column_less_is_more .news-entries.upper .column__title-column,
.news-archive-column_main__list.upper .column__title-column,
.news-archive-column_main .news-entries.upper .column__title-column {
  font-size: 16px;
  line-height: 1.6;
}

@media all and (max-width: 599px) {
  .column__list.upper .column__title-column,
  .column .news-entries.upper .column__title-column,
  .newslist_column_index_html__list.upper .column__title-column,
  .newslist_column_index_html .news-entries.upper .column__title-column,
  .news-archive-column_pick_up__list.upper .column__title-column,
  .news-archive-column_pick_up .news-entries.upper .column__title-column,
  .news-archive-column_less_is_more__list.upper .column__title-column,
  .news-archive-column_less_is_more .news-entries.upper .column__title-column,
  .news-archive-column_main__list.upper .column__title-column,
  .news-archive-column_main .news-entries.upper .column__title-column {
    font-size: 16px;
    line-height: 1.25;
  }
}

.column__list.upper .column__text,
.column .news-entries.upper .column__text,
.newslist_column_index_html__list.upper .column__text,
.newslist_column_index_html .news-entries.upper .column__text,
.news-archive-column_pick_up__list.upper .column__text,
.news-archive-column_pick_up .news-entries.upper .column__text,
.news-archive-column_less_is_more__list.upper .column__text,
.news-archive-column_less_is_more .news-entries.upper .column__text,
.news-archive-column_main__list.upper .column__text,
.news-archive-column_main .news-entries.upper .column__text {
  font-size: 25px;
  line-height: 1.6;
  margin-top: 5px;
}

@media all and (max-width: 599px) {
  .column__list.upper .column__text,
  .column .news-entries.upper .column__text,
  .newslist_column_index_html__list.upper .column__text,
  .newslist_column_index_html .news-entries.upper .column__text,
  .news-archive-column_pick_up__list.upper .column__text,
  .news-archive-column_pick_up .news-entries.upper .column__text,
  .news-archive-column_less_is_more__list.upper .column__text,
  .news-archive-column_less_is_more .news-entries.upper .column__text,
  .news-archive-column_main__list.upper .column__text,
  .news-archive-column_main .news-entries.upper .column__text {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 3px;
  }
}

.column__list .news-entry:hover,
.column .news-entries .news-entry:hover,
.newslist_column_index_html__list .news-entry:hover,
.newslist_column_index_html .news-entries .news-entry:hover,
.news-archive-column_pick_up__list .news-entry:hover,
.news-archive-column_pick_up .news-entries .news-entry:hover,
.news-archive-column_less_is_more__list .news-entry:hover,
.news-archive-column_less_is_more .news-entries .news-entry:hover,
.news-archive-column_main__list .news-entry:hover,
.news-archive-column_main .news-entries .news-entry:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

@media all and (max-width: 599px) {
  .column__item,
  .newslist_column_index_html__item,
  .news-archive-column_pick_up__item,
  .news-archive-column_less_is_more__item,
  .news-archive-column_main__item {
    display: block;
    margin: 0;
  }
}

.column__link,
.newslist_column_index_html__link,
.news-archive-column_pick_up__link,
.news-archive-column_less_is_more__link,
.news-archive-column_main__link {
  background-color: #fafafa;
  display: inline-block;
  width: 100%;
}

.column__img,
.newslist_column_index_html__img,
.news-archive-column_pick_up__img,
.news-archive-column_less_is_more__img,
.news-archive-column_main__img {
  display: block;
  position: relative;
}

.column__img.is-new-item::before,
.newslist_column_index_html__img.is-new-item::before,
.news-archive-column_pick_up__img.is-new-item::before,
.news-archive-column_less_is_more__img.is-new-item::before,
.news-archive-column_main__img.is-new-item::before {
  content: "";
  display: block;
  background: url(/assets/img/column/icon_new_badge.svg) 50%/contain no-repeat;
  position: absolute;
  top: 6px;
  right: 6px;
  height: 70px;
  width: 70px;
}

@media all and (max-width: 599px) {
  .column__img.is-new-item::before,
  .newslist_column_index_html__img.is-new-item::before,
  .news-archive-column_pick_up__img.is-new-item::before,
  .news-archive-column_less_is_more__img.is-new-item::before,
  .news-archive-column_main__img.is-new-item::before {
    height: 40px;
    width: 40px;
  }
}

.column__box,
.column .entry-text,
.newslist_column_index_html__box,
.newslist_column_index_html .entry-text,
.news-archive-column_pick_up__box,
.news-archive-column_pick_up .entry-text,
.news-archive-column_less_is_more__box,
.news-archive-column_less_is_more .entry-text,
.news-archive-column_main__box,
.news-archive-column_main .entry-text {
  background-color: #fafafa;
  padding: 26px 30px 25px;
}

@media all and (max-width: 599px) {
  .column__box,
  .column .entry-text,
  .newslist_column_index_html__box,
  .newslist_column_index_html .entry-text,
  .news-archive-column_pick_up__box,
  .news-archive-column_pick_up .entry-text,
  .news-archive-column_less_is_more__box,
  .news-archive-column_less_is_more .entry-text,
  .news-archive-column_main__box,
  .news-archive-column_main .entry-text {
    padding: 19px 12px 23px;
  }
}

.column .meta,
.newslist_column_index_html .meta,
.news-archive-column_pick_up .meta,
.news-archive-column_less_is_more .meta,
.news-archive-column_main .meta {
  display: none;
}

.column__title-column,
.column .headline,
.newslist_column_index_html__title-column,
.newslist_column_index_html .headline,
.news-archive-column_pick_up__title-column,
.news-archive-column_pick_up .headline,
.news-archive-column_less_is_more__title-column,
.news-archive-column_less_is_more .headline,
.news-archive-column_main__title-column,
.news-archive-column_main .headline {
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: center;
}

@media all and (max-width: 599px) {
  .column__title-column,
  .column .headline,
  .newslist_column_index_html__title-column,
  .newslist_column_index_html .headline,
  .news-archive-column_pick_up__title-column,
  .news-archive-column_pick_up .headline,
  .news-archive-column_less_is_more__title-column,
  .news-archive-column_less_is_more .headline,
  .news-archive-column_main__title-column,
  .news-archive-column_main .headline {
    padding-right: 3px;
    padding-left: 3px;
  }
}

.column__text,
.column .body,
.newslist_column_index_html__text,
.newslist_column_index_html .body,
.news-archive-column_pick_up__text,
.news-archive-column_pick_up .body,
.news-archive-column_less_is_more__text,
.news-archive-column_less_is_more .body,
.news-archive-column_main__text,
.news-archive-column_main .body {
  font-size: 20px;
  letter-spacing: 0.03em;
  line-height: 1.5;
  margin-top: 8px;
  text-align: center;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media all and (max-width: 599px) {
  .column__text,
  .column .body,
  .newslist_column_index_html__text,
  .newslist_column_index_html .body,
  .news-archive-column_pick_up__text,
  .news-archive-column_pick_up .body,
  .news-archive-column_less_is_more__text,
  .news-archive-column_less_is_more .body,
  .news-archive-column_main__text,
  .news-archive-column_main .body {
    font-size: 18px;
    line-height: 1.1111;
  }
}

.column__more,
.column .news-archives-link,
.newslist_column_index_html__more,
.newslist_column_index_html .news-archives-link,
.news-archive-column_pick_up__more,
.news-archive-column_pick_up .news-archives-link,
.news-archive-column_less_is_more__more,
.news-archive-column_less_is_more .news-archives-link,
.news-archive-column_main__more,
.news-archive-column_main .news-archives-link {
  margin-top: 65px;
  text-align: center;
}

@media all and (max-width: 599px) {
  .column__more,
  .column .news-archives-link,
  .newslist_column_index_html__more,
  .newslist_column_index_html .news-archives-link,
  .news-archive-column_pick_up__more,
  .news-archive-column_pick_up .news-archives-link,
  .news-archive-column_less_is_more__more,
  .news-archive-column_less_is_more .news-archives-link,
  .news-archive-column_main__more,
  .news-archive-column_main .news-archives-link {
    margin-top: 53px;
  }
}

.column__more > a,
.column .news-archives-link > a,
.newslist_column_index_html__more > a,
.newslist_column_index_html .news-archives-link > a,
.news-archive-column_pick_up__more > a,
.news-archive-column_pick_up .news-archives-link > a,
.news-archive-column_less_is_more__more > a,
.news-archive-column_less_is_more .news-archives-link > a,
.news-archive-column_main__more > a,
.news-archive-column_main .news-archives-link > a {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
  max-width: 300px;
}

@media (hover: hover) and (pointer: fine) {
  .column__more > a:hover,
  .column .news-archives-link > a:hover,
  .newslist_column_index_html__more > a:hover,
  .newslist_column_index_html .news-archives-link > a:hover,
  .news-archive-column_pick_up__more > a:hover,
  .news-archive-column_pick_up .news-archives-link > a:hover,
  .news-archive-column_less_is_more__more > a:hover,
  .news-archive-column_less_is_more .news-archives-link > a:hover,
  .news-archive-column_main__more > a:hover,
  .news-archive-column_main .news-archives-link > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.column__more > a::before,
.column .news-archives-link > a::before,
.newslist_column_index_html__more > a::before,
.newslist_column_index_html .news-archives-link > a::before,
.news-archive-column_pick_up__more > a::before,
.news-archive-column_pick_up .news-archives-link > a::before,
.news-archive-column_less_is_more__more > a::before,
.news-archive-column_less_is_more .news-archives-link > a::before,
.news-archive-column_main__more > a::before,
.news-archive-column_main .news-archives-link > a::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.column__more > a::before,
.column .news-archives-link > a::before,
.newslist_column_index_html__more > a::before,
.newslist_column_index_html .news-archives-link > a::before,
.news-archive-column_pick_up__more > a::before,
.news-archive-column_pick_up .news-archives-link > a::before,
.news-archive-column_less_is_more__more > a::before,
.news-archive-column_less_is_more .news-archives-link > a::before,
.news-archive-column_main__more > a::before,
.news-archive-column_main .news-archives-link > a::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .column__more > a:hover,
  .column .news-archives-link > a:hover,
  .newslist_column_index_html__more > a:hover,
  .newslist_column_index_html .news-archives-link > a:hover,
  .news-archive-column_pick_up__more > a:hover,
  .news-archive-column_pick_up .news-archives-link > a:hover,
  .news-archive-column_less_is_more__more > a:hover,
  .news-archive-column_less_is_more .news-archives-link > a:hover,
  .news-archive-column_main__more > a:hover,
  .news-archive-column_main .news-archives-link > a:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.column__more > a::after,
.column .news-archives-link > a::after,
.newslist_column_index_html__more > a::after,
.newslist_column_index_html .news-archives-link > a::after,
.news-archive-column_pick_up__more > a::after,
.news-archive-column_pick_up .news-archives-link > a::after,
.news-archive-column_less_is_more__more > a::after,
.news-archive-column_less_is_more .news-archives-link > a::after,
.news-archive-column_main__more > a::after,
.news-archive-column_main .news-archives-link > a::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.column__more > a:hover,
.column .news-archives-link > a:hover,
.newslist_column_index_html__more > a:hover,
.newslist_column_index_html .news-archives-link > a:hover,
.news-archive-column_pick_up__more > a:hover,
.news-archive-column_pick_up .news-archives-link > a:hover,
.news-archive-column_less_is_more__more > a:hover,
.news-archive-column_less_is_more .news-archives-link > a:hover,
.news-archive-column_main__more > a:hover,
.news-archive-column_main .news-archives-link > a:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.column__more > a:hover::before,
.column .news-archives-link > a:hover::before,
.newslist_column_index_html__more > a:hover::before,
.newslist_column_index_html .news-archives-link > a:hover::before,
.news-archive-column_pick_up__more > a:hover::before,
.news-archive-column_pick_up .news-archives-link > a:hover::before,
.news-archive-column_less_is_more__more > a:hover::before,
.news-archive-column_less_is_more .news-archives-link > a:hover::before,
.news-archive-column_main__more > a:hover::before,
.news-archive-column_main .news-archives-link > a:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.column__more > a:hover::after,
.column .news-archives-link > a:hover::after,
.newslist_column_index_html__more > a:hover::after,
.newslist_column_index_html .news-archives-link > a:hover::after,
.news-archive-column_pick_up__more > a:hover::after,
.news-archive-column_pick_up .news-archives-link > a:hover::after,
.news-archive-column_less_is_more__more > a:hover::after,
.news-archive-column_less_is_more .news-archives-link > a:hover::after,
.news-archive-column_main__more > a:hover::after,
.news-archive-column_main .news-archives-link > a:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

.column__btn,
.newslist_column_index_html__btn,
.news-archive-column_pick_up__btn,
.news-archive-column_less_is_more__btn,
.news-archive-column_main__btn {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: #ba0000;
  border-image-slice: 1;
}

@media (hover: hover) and (pointer: fine) {
  .column__btn:hover,
  .newslist_column_index_html__btn:hover,
  .news-archive-column_pick_up__btn:hover,
  .news-archive-column_less_is_more__btn:hover,
  .news-archive-column_main__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.column__btn::before,
.newslist_column_index_html__btn::before,
.news-archive-column_pick_up__btn::before,
.news-archive-column_less_is_more__btn::before,
.news-archive-column_main__btn::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

.column__btn::before,
.newslist_column_index_html__btn::before,
.news-archive-column_pick_up__btn::before,
.news-archive-column_less_is_more__btn::before,
.news-archive-column_main__btn::before {
  border-color: #cc8600;
}

@media (hover: hover) and (pointer: fine) {
  .column__btn:hover,
  .newslist_column_index_html__btn:hover,
  .news-archive-column_pick_up__btn:hover,
  .news-archive-column_less_is_more__btn:hover,
  .news-archive-column_main__btn:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.column__btn::after,
.newslist_column_index_html__btn::after,
.news-archive-column_pick_up__btn::after,
.news-archive-column_less_is_more__btn::after,
.news-archive-column_main__btn::after {
  background: linear-gradient(to left, #d35900 0%, #c10000 100%);
  content: "";
  display: block;
  transition: width 0.2s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.column__btn:hover,
.newslist_column_index_html__btn:hover,
.news-archive-column_pick_up__btn:hover,
.news-archive-column_less_is_more__btn:hover,
.news-archive-column_main__btn:hover {
  border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
  border-image-slice: 1;
  color: #fff;
  opacity: 1;
}

.column__btn:hover::before,
.newslist_column_index_html__btn:hover::before,
.news-archive-column_pick_up__btn:hover::before,
.news-archive-column_less_is_more__btn:hover::before,
.news-archive-column_main__btn:hover::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.column__btn:hover::after,
.newslist_column_index_html__btn:hover::after,
.news-archive-column_pick_up__btn:hover::after,
.news-archive-column_less_is_more__btn:hover::after,
.news-archive-column_main__btn:hover::after {
  transition: width 0.2s ease-in-out;
  width: 100%;
}

.column .newslist-column_pick_up .news-entries,
.newslist_column_index_html .newslist-column_pick_up .news-entries,
.news-archive-column_pick_up .newslist-column_pick_up .news-entries,
.news-archive-column_less_is_more .newslist-column_pick_up .news-entries,
.news-archive-column_main .newslist-column_pick_up .news-entries {
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 66px;
}

@media all and (max-width: 599px) {
  .column .newslist-column_pick_up .news-entries,
  .newslist_column_index_html .newslist-column_pick_up .news-entries,
  .news-archive-column_pick_up .newslist-column_pick_up .news-entries,
  .news-archive-column_less_is_more .newslist-column_pick_up .news-entries,
  .news-archive-column_main .newslist-column_pick_up .news-entries {
    margin-top: 39px;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    gap: 15px;
  }
}

.column .newslist-column_pick_up .news-entries .news-entry:not(:nth-child(-n+2)),
.newslist_column_index_html .newslist-column_pick_up .news-entries .news-entry:not(:nth-child(-n+2)),
.news-archive-column_pick_up .newslist-column_pick_up .news-entries .news-entry:not(:nth-child(-n+2)),
.news-archive-column_less_is_more .newslist-column_pick_up .news-entries .news-entry:not(:nth-child(-n+2)),
.news-archive-column_main .newslist-column_pick_up .news-entries .news-entry:not(:nth-child(-n+2)) {
  display: none;
}

.column .newslist-column_pick_up .news-entries .is-new-item::before,
.newslist_column_index_html .newslist-column_pick_up .news-entries .is-new-item::before,
.news-archive-column_pick_up .newslist-column_pick_up .news-entries .is-new-item::before,
.news-archive-column_less_is_more .newslist-column_pick_up .news-entries .is-new-item::before,
.news-archive-column_main .newslist-column_pick_up .news-entries .is-new-item::before {
  top: 12px;
  right: 13px;
}

.column .newslist-column_pick_up .news-entries .column__box,
.newslist_column_index_html .newslist-column_pick_up .news-entries .column__box,
.news-archive-column_pick_up .newslist-column_pick_up .news-entries .column__box,
.news-archive-column_less_is_more .newslist-column_pick_up .news-entries .column__box,
.news-archive-column_main .newslist-column_pick_up .news-entries .column__box {
  padding: 26px 30px 25px;
}

@media all and (max-width: 599px) {
  .column .newslist-column_pick_up .news-entries .column__box,
  .newslist_column_index_html .newslist-column_pick_up .news-entries .column__box,
  .news-archive-column_pick_up .newslist-column_pick_up .news-entries .column__box,
  .news-archive-column_less_is_more .newslist-column_pick_up .news-entries .column__box,
  .news-archive-column_main .newslist-column_pick_up .news-entries .column__box {
    padding: 19px 12px 23px;
  }
}

.column .newslist-column_pick_up .news-entries .column__title-column,
.newslist_column_index_html .newslist-column_pick_up .news-entries .column__title-column,
.news-archive-column_pick_up .newslist-column_pick_up .news-entries .column__title-column,
.news-archive-column_less_is_more .newslist-column_pick_up .news-entries .column__title-column,
.news-archive-column_main .newslist-column_pick_up .news-entries .column__title-column {
  font-size: 16px;
  line-height: 1.6;
}

@media all and (max-width: 599px) {
  .column .newslist-column_pick_up .news-entries .column__title-column,
  .newslist_column_index_html .newslist-column_pick_up .news-entries .column__title-column,
  .news-archive-column_pick_up .newslist-column_pick_up .news-entries .column__title-column,
  .news-archive-column_less_is_more .newslist-column_pick_up .news-entries .column__title-column,
  .news-archive-column_main .newslist-column_pick_up .news-entries .column__title-column {
    font-size: 16px;
    line-height: 1.25;
  }
}

.column .newslist-column_pick_up .news-entries .column__text,
.newslist_column_index_html .newslist-column_pick_up .news-entries .column__text,
.news-archive-column_pick_up .newslist-column_pick_up .news-entries .column__text,
.news-archive-column_less_is_more .newslist-column_pick_up .news-entries .column__text,
.news-archive-column_main .newslist-column_pick_up .news-entries .column__text {
  font-size: 25px;
  line-height: 1.6;
  margin-top: 5px;
}

@media all and (max-width: 599px) {
  .column .newslist-column_pick_up .news-entries .column__text,
  .newslist_column_index_html .newslist-column_pick_up .news-entries .column__text,
  .news-archive-column_pick_up .newslist-column_pick_up .news-entries .column__text,
  .news-archive-column_less_is_more .newslist-column_pick_up .news-entries .column__text,
  .news-archive-column_main .newslist-column_pick_up .news-entries .column__text {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 3px;
  }
}

@media all and (min-width: 600px) {
  .column__list.pattern2,
  .newslist_column_index_html__list.pattern2,
  .news-archive-column_pick_up__list.pattern2,
  .news-archive-column_less_is_more__list.pattern2,
  .news-archive-column_main__list.pattern2 {
    display: block;
  }
  .column__list.pattern2 .column__item:not(:first-child),
  .newslist_column_index_html__list.pattern2 .column__item:not(:first-child),
  .news-archive-column_pick_up__list.pattern2 .column__item:not(:first-child),
  .news-archive-column_less_is_more__list.pattern2 .column__item:not(:first-child),
  .news-archive-column_main__list.pattern2 .column__item:not(:first-child) {
    margin: 20px 0 0;
  }
  .column__list.pattern2 .column__link,
  .newslist_column_index_html__list.pattern2 .column__link,
  .news-archive-column_pick_up__list.pattern2 .column__link,
  .news-archive-column_less_is_more__list.pattern2 .column__link,
  .news-archive-column_main__list.pattern2 .column__link {
    display: flex;
    align-items: center;
  }
  .column__list.pattern2 .column__img,
  .newslist_column_index_html__list.pattern2 .column__img,
  .news-archive-column_pick_up__list.pattern2 .column__img,
  .news-archive-column_less_is_more__list.pattern2 .column__img,
  .news-archive-column_main__list.pattern2 .column__img {
    width: 55.3068%;
  }
  .column__list.pattern2 .column__box,
  .newslist_column_index_html__list.pattern2 .column__box,
  .news-archive-column_pick_up__list.pattern2 .column__box,
  .news-archive-column_less_is_more__list.pattern2 .column__box,
  .news-archive-column_main__list.pattern2 .column__box {
    padding: 11px 51px 25px;
    flex: 1;
  }
  .column__list.pattern2 .column__title-column,
  .newslist_column_index_html__list.pattern2 .column__title-column,
  .news-archive-column_pick_up__list.pattern2 .column__title-column,
  .news-archive-column_less_is_more__list.pattern2 .column__title-column,
  .news-archive-column_main__list.pattern2 .column__title-column {
    text-align: left;
  }
  .column__list.pattern2 .column__text,
  .newslist_column_index_html__list.pattern2 .column__text,
  .news-archive-column_pick_up__list.pattern2 .column__text,
  .news-archive-column_less_is_more__list.pattern2 .column__text,
  .news-archive-column_main__list.pattern2 .column__text {
    text-align: left;
  }
}

@media all and (min-width: 600px) and (max-width: 1000px) {
  .column__list.pattern2 .column__link,
  .newslist_column_index_html__list.pattern2 .column__link,
  .news-archive-column_pick_up__list.pattern2 .column__link,
  .news-archive-column_less_is_more__list.pattern2 .column__link,
  .news-archive-column_main__list.pattern2 .column__link {
    display: block;
  }
  .column__list.pattern2 .column__img,
  .newslist_column_index_html__list.pattern2 .column__img,
  .news-archive-column_pick_up__list.pattern2 .column__img,
  .news-archive-column_less_is_more__list.pattern2 .column__img,
  .news-archive-column_main__list.pattern2 .column__img {
    width: 100%;
  }
  .column__list.pattern2 .column__box,
  .newslist_column_index_html__list.pattern2 .column__box,
  .news-archive-column_pick_up__list.pattern2 .column__box,
  .news-archive-column_less_is_more__list.pattern2 .column__box,
  .news-archive-column_main__list.pattern2 .column__box {
    padding: 29px 51px 28px;
  }
}

/*
  ----------------
  コラム一覧 | common
  ----------------
*/
.column-main {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  background-color: #fff;
  padding-bottom: 140px;
}

@media all and (min-width: 600px) {
  .column-main {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .column-main {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (max-width: 599px) {
  .column-main {
    padding-bottom: 144px;
  }
}

.newslist_column_index_html .newslist {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-right: 80px;
  padding-left: 80px;
}

@media all and (min-width: 600px) {
  .newslist_column_index_html .newslist {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .newslist_column_index_html .newslist {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.newslist_column_index_html .newslist .newslist-column_less_is_more {
  margin-top: 82px;
}

@media all and (max-width: 599px) {
  .newslist_column_index_html .newslist .newslist-column_less_is_more {
    margin-top: 64px;
  }
}

.newslist_column_index_html .newslist .newslist-column_main {
  margin-top: 98px;
}

@media all and (max-width: 599px) {
  .newslist_column_index_html .newslist .newslist-column_main {
    margin-top: 87px;
  }
}

.newslist_column_index_html .action-links-box {
  margin-top: 140px;
}

@media all and (max-width: 599px) {
  .newslist_column_index_html .action-links-box {
    margin-top: 144px;
  }
}

.newslist_column_index_html .action-links-box__wrap {
  padding: 57px 71px;
}

@media all and (max-width: 599px) {
  .newslist_column_index_html .action-links-box__wrap {
    padding: 83px 35px 70px;
  }
}

.news-archive-column_pick_up .news-entry-icon,
.news-archive-column_pick_up .news-entry-header,
.news-archive-column_pick_up .trailer-link,
.news-archive-column_less_is_more .news-entry-icon,
.news-archive-column_less_is_more .news-entry-header,
.news-archive-column_less_is_more .trailer-link,
.news-archive-column_main .news-entry-icon,
.news-archive-column_main .news-entry-header,
.news-archive-column_main .trailer-link {
  display: none;
}

.column-main-less_is_more .column__img,
.column-main-main .column__img {
  position: relative;
  overflow: hidden;
  padding-top: 61.1111%;
}

@media all and (max-width: 599px) {
  .column-main-less_is_more .column__img,
  .column-main-main .column__img {
    padding-top: 61.0895%;
  }
}

.column-main-less_is_more .column__img img,
.column-main-main .column__img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/********** Column Detail | コラム詳細  **********/
/*
  ----------------
  コラム詳細 | common-detail
  ----------------
*/
.column-detail-heading {
  margin-bottom: 90px;
}

@media all and (max-width: 599px) {
  .column-detail-heading {
    margin-bottom: 40px;
  }
}

.column-detail-heading__wrap {
  position: relative;
}

.column-detail-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .column-detail-heading__box {
    top: calc(50% + 30px);
  }
}

.column-detail-heading__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .column-detail-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .column-detail-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

.column-detail-main {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-bottom: 199px;
}

@media all and (min-width: 600px) {
  .column-detail-main {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 152px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main {
    padding-bottom: 152px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 66px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section.grid {
    gap: 33px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section.grid {
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid h3 {
    margin-top: 0;
  }
}

.column-detail-main > section.grid img {
  margin-top: 0;
}

.column-detail-main > section.grid:not(:first-of-type) {
  margin-top: 60px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section.grid:not(:first-of-type) {
    margin-top: 40px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section.grid:nth-of-type(1) {
    display: flex;
    flex-direction: column;
  }
}

.column-detail-main > section.grid:nth-of-type(1) h2 {
  padding: 17px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section.grid:nth-of-type(1) h2 {
    padding: 53px 10px 40px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid:nth-of-type(1) p {
    margin-top: -9px;
    margin-left: 8px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section.grid:nth-of-type(2) > div {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid:nth-of-type(2) h3 {
    margin-bottom: 14px;
  }
}

.column-detail-main > section.grid:nth-of-type(2) img {
  grid-row: 1;
}

@media all and (max-width: 599px) {
  .column-detail-main > section div {
    order: 2;
  }
}

.column-detail-main > section h2 {
  border: 1px solid #171717;
  font-size: 35px;
  font-weight: normal;
  height: 330px;
  letter-spacing: 0.08em;
  line-height: 1.7143;
  max-width: 574px;
  padding-top: 17px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1183px) {
  .column-detail-main > section h2 {
    font-size: 28px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section h2 {
    max-width: 100%;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section h2 {
    font-size: 20px;
    font-weight: 500;
    height: auto;
    line-height: 1.5;
    margin-bottom: 24px;
    padding: 53px 0 40px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.column-detail-main > section h2 > img {
  height: 21.93px;
  margin-bottom: 20px;
  width: auto;
}

@media all and (max-width: 599px) {
  .column-detail-main > section h2 > img {
    height: 14.62px;
    margin-bottom: 10px;
    order: 1;
  }
}

.column-detail-main > section h2 > span {
  display: block;
  font-family: adorn-garland, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;
  margin-bottom: 10px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section h2 > span {
    font-size: 20px;
    margin-bottom: 5px;
    order: 1;
  }
}

.column-detail-main > section h3 {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.6667;
  margin-top: 60px;
  padding-left: 2px;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section h3 {
    font-size: 25px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section h3 {
    font-size: 20px;
    line-height: 2;
    margin: 55px 0 0;
    padding-left: 0;
  }
}

.column-detail-main > section h3:not(:first-of-type) {
  margin-top: 41px;
}

.column-detail-main > section p {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 2.8571;
  margin-top: 41px;
  padding-left: 2px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section p {
    line-height: 2.5;
    padding-left: 0;
    margin-top: 14px;
  }
}

.column-detail-main > section:first-of-type img {
  margin-top: 0;
}

.column-detail-main > section img {
  max-width: 100%;
  margin-top: 30px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section img {
    display: block;
    margin: 15px auto 0;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section img + img {
    margin-top: 20px;
  }
}

.column-detail-main > section img.img-s {
  max-width: 350px;
}

.column-detail-main > section img.img-m {
  max-width: 600px;
}

.column-detail-main > section img.img-l {
  max-width: 900px;
}

.column-detail-main .column__more {
  margin-top: 144px;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main .column__more {
    margin-top: 116px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main .column__more {
    margin-top: 116px;
  }
}

.column-detail-main .column__more > a::before {
  top: 42%;
  right: auto;
  left: 11px;
  transform: rotate(-135deg);
}

.column-detail-main.pattern2 {
  padding-bottom: 124px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 {
    padding-bottom: 121px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section {
    margin-right: -80px;
    grid-template-columns: 1fr 650px;
    gap: 72px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section {
    flex-direction: column-reverse;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(odd) {
    margin-right: 0;
    margin-left: -80px;
    grid-template-columns: 650px 1fr;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(odd) img {
  grid-row: 1;
}

.column-detail-main.pattern2 > section:nth-of-type(1) {
  display: block;
  margin-left: 0;
}

.column-detail-main.pattern2 > section:nth-of-type(1) > div img {
  margin: 96px calc(50% - 50vw) 0;
  max-width: 100vw;
  width: 100vw;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) > div img {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) p {
    letter-spacing: 0;
    margin: 77px 0 0;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) p {
    margin-top: 44px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) img {
    margin-top: 13px;
    max-width: 100%;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(2) {
  margin-top: 77px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) {
    margin-top: 28px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) > div {
    margin-top: 0;
    padding-right: 44px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) h3 {
    margin-bottom: 26px;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(2) img {
  grid-row: auto;
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) img {
    margin-top: 86px;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(3) {
  margin-top: 109px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(3) {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(3) h3 {
    margin-bottom: 34px;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(4) {
  margin-top: 90px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(4) {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(4) > div {
    padding-top: 15px;
    padding-right: 44px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(4) h3 {
    margin-bottom: 36px;
  }
}

.column-detail-main.pattern2 h2 {
  border: none;
  font-size: 40px;
  height: auto;
  line-height: 1.5;
  margin-top: 0;
  max-width: 100%;
  padding-top: 0;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 h2 {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 0;
    padding: 14px 0 48px;
  }
}

.column-detail-main.pattern2 h2 > img {
  height: 44px;
  margin-bottom: 19px;
  width: auto;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 h2 > img {
    height: 33.9px;
    margin-top: 0;
    margin-bottom: 14px;
    order: 1;
  }
}

.column-detail-main.pattern2 h3 {
  margin-bottom: 41px;
  padding-left: 0;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 h3 {
    font-size: 20px;
    line-height: 2;
    margin: 44px 0 0;
  }
}

.column-detail-main.pattern2 p {
  letter-spacing: 0.03em;
  padding-left: 0;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 p {
    line-height: 2.5;
    letter-spacing: 0;
    margin-top: 15px;
  }
}

.column-detail-main.pattern2 img {
  max-width: 650px;
  width: 100vw;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 img {
    margin-top: 55px;
    width: 100%;
  }
}

.column-detail-main.pattern2 .column__more {
  margin-top: 78px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 .column__more {
    margin-top: 90px;
  }
}

/********** Story Detail | ラメリアストーリー  **********/
/*
  ----------------
  ラメリアストーリー | Heading
  ----------------
*/
.story-detail-heading__wrap {
  position: relative;
}

.story-detail-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .story-detail-heading__box {
    top: calc(50% + 30px);
  }
}

.story-detail-heading__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .story-detail-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .story-detail-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .story-detail-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

/*
  ----------------
  ラメリアストーリー | story-detail
  ----------------
*/
.news-archive-story #page-title,
.news-archive-story .news-entry-icon,
.news-archive-story .news-entry-header,
.news-archive-story .news-images,
.news-archive-story .trailer-link,
.news-archive-concept #page-title,
.news-archive-concept .news-entry-icon,
.news-archive-concept .news-entry-header,
.news-archive-concept .news-images,
.news-archive-concept .trailer-link {
  display: none;
}

.story-detail-main {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  overflow: hidden;
  padding: 80px 80px 100px;
}

@media all and (min-width: 600px) {
  .story-detail-main {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .story-detail-main {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .story-detail-main {
    padding: 80px 40px 100px;
  }
}

@media all and (max-width: 599px) {
  .story-detail-main {
    padding: 50px 30px 106px;
  }
}

.story-detail-main h2 {
  font-size: 40px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

@media all and (max-width: 599px) {
  .story-detail-main h2 {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 2;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: column;
    text-align: center;
  }
}

.story-detail-main h2 > span {
  border: 1px solid #171717;
  display: block;
  font-size: 50px;
  font-weight: normal;
  margin-right: 67px;
  height: 172px;
  width: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .story-detail-main h2 > span {
    font-size: 30px;
    letter-spacing: 0.08em;
    margin-right: 0;
    margin-bottom: 28px;
    height: 80px;
    width: 80px;
  }
}

.story-detail-main img {
  margin-top: 65px;
  max-width: 100%;
  width: auto;
}

@media all and (max-width: 599px) {
  .story-detail-main img {
    margin-top: 44px;
  }
}

@media all and (min-width: 600px) {
  .story-detail-main img.left, .story-detail-main img.right {
    float: left;
    margin-top: 27px;
    margin-right: 75px;
  }
}

.story-detail-main img.left + p, .story-detail-main img.right + p {
  margin-top: 18px;
}

@media all and (max-width: 599px) {
  .story-detail-main img.left + p, .story-detail-main img.right + p {
    margin-top: 28px;
  }
}

@media all and (min-width: 600px) {
  .story-detail-main img.right {
    float: right;
    margin-right: auto;
    margin-left: 75px;
  }
}

.story-detail-main p {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 2.8571;
  margin-top: 48px;
}

@media all and (max-width: 599px) {
  .story-detail-main p {
    margin-top: 48px;
  }
}

.story-detail-pager__list {
  display: flex;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .story-detail-pager__list {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .story-detail-pager__list {
    display: block;
  }
}

.story-detail-pager__prev, .story-detail-pager__next {
  overflow: hidden;
  position: relative;
}

@media all and (min-width: 600px) {
  .story-detail-pager__prev, .story-detail-pager__next {
    width: 50%;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .story-detail-pager__prev, .story-detail-pager__next {
    width: 100%;
  }
}

.story-detail-pager__prev:before, .story-detail-pager__next:before {
  background: url(/assets/img/newslist/story-detail/bg_pager_prev.jpg) no-repeat center center;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .3s ease-out;
}

.story-detail-pager__prev:hover:before, .story-detail-pager__next:hover:before {
  transform: scale(1.2);
}

.story-detail-pager__next:before {
  background: url(/assets/img/newslist/story-detail/bg_pager_next.jpg) no-repeat center center;
  background-size: cover;
}

.story-detail-pager__next .story-detail-pager__link {
  padding-left: 80px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .story-detail-pager__next .story-detail-pager__link {
    padding-left: 46px;
  }
}

@media all and (max-width: 599px) {
  .story-detail-pager__next .story-detail-pager__link {
    padding-left: 46px;
  }
}

.story-detail-pager__next .story-detail-pager__link::before {
  content: "Next Story";
  position: absolute;
  top: 72px;
  right: 19px;
  left: auto;
}

.story-detail-pager__next .story-detail-pager__link::after {
  background: url(/assets/img/newslist/story-detail/icon_pager_next.svg) no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 111px;
  right: 40px;
  left: auto;
}

@media all and (max-width: 599px) {
  .story-detail-pager__next .story-detail-pager__link::after {
    top: 63px;
    right: 23px;
  }
}

.story-detail-pager__disabled {
  pointer-events: none;
}

.story-detail-pager__disabled::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.story-detail-pager__link {
  font-size: 30px;
  font-weight: normal;
  height: 250px;
  letter-spacing: 0.08em;
  line-height: 1.6667;
  padding-left: 125px;
  position: relative;
  display: flex;
  align-items: center;
}

@media all and (max-width: 599px) {
  .story-detail-pager__link {
    font-size: 17px;
    height: 143px;
    line-height: 1.6471;
    padding-left: 71px;
  }
}

.story-detail-pager__link::before {
  content: "Prev Story";
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08em;
  position: absolute;
  top: 72px;
  left: 27px;
}

@media all and (max-width: 599px) {
  .story-detail-pager__link::before {
    display: none;
  }
}

.story-detail-pager__link::after {
  background: url(/assets/img/newslist/story-detail/icon_pager_prev.svg) no-repeat center center;
  background-size: cover;
  content: "";
  display: block;
  height: 29px;
  width: 29px;
  position: absolute;
  top: 111px;
  left: 44px;
}

@media all and (max-width: 599px) {
  .story-detail-pager__link::after {
    height: 17px;
    width: 17px;
    top: 63px;
    left: 25px;
  }
}

.story-detail-links {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-top: 74px;
  padding-bottom: 142px;
  display: flex;
}

@media all and (min-width: 600px) {
  .story-detail-links {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .story-detail-links {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .story-detail-links {
    display: block;
  }
}

@media all and (max-width: 599px) {
  .story-detail-links {
    padding-top: 46px;
    padding-bottom: 78px;
    display: block;
  }
}

.story-detail-links__title {
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.6;
  margin-right: 107px;
  margin-right: 91px;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .story-detail-links__title {
    margin-right: 0;
    text-align: center;
  }
}

@media all and (max-width: 599px) {
  .story-detail-links__title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 2;
    margin-right: 0;
    text-align: center;
  }
}

.story-detail-links__list {
  margin-top: 8px;
}

.story-detail-links__list:nth-of-type(1) {
  margin-right: auto;
}

@media all and (min-width: 600px) and (max-width: 850px) {
  .story-detail-links__list:nth-of-type(1) {
    margin-top: 25px;
    margin-right: 0;
  }
}

@media all and (max-width: 599px) {
  .story-detail-links__list:nth-of-type(1) {
    margin-top: 25px;
    margin-right: 0;
  }
}

.story-detail-links__item {
  list-style: none;
  margin-bottom: 16px;
  position: relative;
}

@media all and (max-width: 599px) {
  .story-detail-links__item {
    margin-bottom: 10px;
  }
}

.story-detail-links__link {
  border: 1px solid transparent;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 2;
  padding: 0px 22px 0 13px;
}

@media all and (max-width: 599px) {
  .story-detail-links__link {
    font-size: 13px;
    padding: 0px 22px 0 13px;
  }
}

.story-detail-links__link:hover {
  border: 1px solid #707070;
  box-sizing: border-box;
}

/*
  ----------------
  コラム詳細 | common-detail
  ----------------
*/
.column-detail-heading {
  margin-bottom: 90px;
}

@media all and (max-width: 599px) {
  .column-detail-heading {
    margin-bottom: 40px;
  }
}

.column-detail-heading__wrap {
  position: relative;
}

.column-detail-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (max-width: 599px) {
  .column-detail-heading__box {
    top: calc(50% + 30px);
  }
}

.column-detail-heading__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .column-detail-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .column-detail-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

.column-detail-main {
  position: relative;
  padding-right: 5.8565%;
  padding-left: 5.8565%;
  padding-bottom: 199px;
}

@media all and (min-width: 600px) {
  .column-detail-main {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 152px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main {
    padding-bottom: 152px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 66px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section.grid {
    gap: 33px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section.grid {
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid h3 {
    margin-top: 0;
  }
}

.column-detail-main > section.grid img {
  margin-top: 0;
}

.column-detail-main > section.grid:not(:first-of-type) {
  margin-top: 60px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section.grid:not(:first-of-type) {
    margin-top: 40px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section.grid:nth-of-type(1) {
    display: flex;
    flex-direction: column;
  }
}

.column-detail-main > section.grid:nth-of-type(1) h2 {
  padding: 17px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section.grid:nth-of-type(1) h2 {
    padding: 53px 10px 40px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid:nth-of-type(1) p {
    margin-top: -9px;
    margin-left: 8px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section.grid:nth-of-type(2) > div {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main > section.grid:nth-of-type(2) h3 {
    margin-bottom: 14px;
  }
}

.column-detail-main > section.grid:nth-of-type(2) img {
  grid-row: 1;
}

@media all and (max-width: 599px) {
  .column-detail-main > section div {
    order: 2;
  }
}

.column-detail-main > section h2 {
  border: 1px solid #171717;
  font-size: 35px;
  font-weight: normal;
  height: 330px;
  letter-spacing: 0.08em;
  line-height: 1.7143;
  max-width: 574px;
  padding-top: 17px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1183px) {
  .column-detail-main > section h2 {
    font-size: 28px;
  }
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section h2 {
    max-width: 100%;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section h2 {
    font-size: 20px;
    font-weight: 500;
    height: auto;
    line-height: 1.5;
    margin-bottom: 24px;
    padding: 53px 0 40px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.column-detail-main > section h2 > img {
  height: 21.93px;
  margin-bottom: 20px;
  width: auto;
}

@media all and (max-width: 599px) {
  .column-detail-main > section h2 > img {
    height: 14.62px;
    margin-bottom: 10px;
    order: 1;
  }
}

.column-detail-main > section h2 > span {
  display: block;
  font-family: adorn-garland, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;
  margin-bottom: 10px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section h2 > span {
    font-size: 20px;
    margin-bottom: 5px;
    order: 1;
  }
}

.column-detail-main > section h3 {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.6667;
  margin-top: 60px;
  padding-left: 2px;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main > section h3 {
    font-size: 25px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section h3 {
    font-size: 20px;
    line-height: 2;
    margin: 55px 0 0;
    padding-left: 0;
  }
}

.column-detail-main > section h3:not(:first-of-type) {
  margin-top: 41px;
}

.column-detail-main > section p {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 2.8571;
  margin-top: 41px;
  padding-left: 2px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section p {
    line-height: 2.5;
    padding-left: 0;
    margin-top: 14px;
  }
}

.column-detail-main > section:first-of-type img {
  margin-top: 0;
}

.column-detail-main > section img {
  max-width: 100%;
  margin-top: 30px;
}

@media all and (max-width: 599px) {
  .column-detail-main > section img {
    display: block;
    margin: 15px auto 0;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main > section img + img {
    margin-top: 20px;
  }
}

.column-detail-main > section img.img-s {
  max-width: 350px;
}

.column-detail-main > section img.img-m {
  max-width: 600px;
}

.column-detail-main > section img.img-l {
  max-width: 900px;
}

.column-detail-main .column__more {
  margin-top: 144px;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .column-detail-main .column__more {
    margin-top: 116px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main .column__more {
    margin-top: 116px;
  }
}

.column-detail-main .column__more > a::before {
  top: 42%;
  right: auto;
  left: 11px;
  transform: rotate(-135deg);
}

.column-detail-main.pattern2 {
  padding-bottom: 124px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 {
    padding-bottom: 121px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section {
    margin-right: -80px;
    grid-template-columns: 1fr 650px;
    gap: 72px;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section {
    flex-direction: column-reverse;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(odd) {
    margin-right: 0;
    margin-left: -80px;
    grid-template-columns: 650px 1fr;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(odd) img {
  grid-row: 1;
}

.column-detail-main.pattern2 > section:nth-of-type(1) {
  display: block;
  margin-left: 0;
}

.column-detail-main.pattern2 > section:nth-of-type(1) > div img {
  margin: 96px calc(50% - 50vw) 0;
  max-width: 100vw;
  width: 100vw;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) > div img {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) p {
    letter-spacing: 0;
    margin: 77px 0 0;
  }
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) p {
    margin-top: 44px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(1) img {
    margin-top: 13px;
    max-width: 100%;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(2) {
  margin-top: 77px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) {
    margin-top: 28px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) > div {
    margin-top: 0;
    padding-right: 44px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) h3 {
    margin-bottom: 26px;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(2) img {
  grid-row: auto;
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(2) img {
    margin-top: 86px;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(3) {
  margin-top: 109px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(3) {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(3) h3 {
    margin-bottom: 34px;
  }
}

.column-detail-main.pattern2 > section:nth-of-type(4) {
  margin-top: 90px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 > section:nth-of-type(4) {
    margin-top: 0;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(4) > div {
    padding-top: 15px;
    padding-right: 44px;
  }
}

@media all and (min-width: 600px) {
  .column-detail-main.pattern2 > section:nth-of-type(4) h3 {
    margin-bottom: 36px;
  }
}

.column-detail-main.pattern2 h2 {
  border: none;
  font-size: 40px;
  height: auto;
  line-height: 1.5;
  margin-top: 0;
  max-width: 100%;
  padding-top: 0;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 h2 {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 0;
    padding: 14px 0 48px;
  }
}

.column-detail-main.pattern2 h2 > img {
  height: 44px;
  margin-bottom: 19px;
  width: auto;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 h2 > img {
    height: 33.9px;
    margin-top: 0;
    margin-bottom: 14px;
    order: 1;
  }
}

.column-detail-main.pattern2 h3 {
  margin-bottom: 41px;
  padding-left: 0;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 h3 {
    font-size: 20px;
    line-height: 2;
    margin: 44px 0 0;
  }
}

.column-detail-main.pattern2 p {
  letter-spacing: 0.03em;
  padding-left: 0;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 p {
    line-height: 2.5;
    letter-spacing: 0;
    margin-top: 15px;
  }
}

.column-detail-main.pattern2 img {
  max-width: 650px;
  width: 100vw;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 img {
    margin-top: 55px;
    width: 100%;
  }
}

.column-detail-main.pattern2 .column__more {
  margin-top: 78px;
}

@media all and (max-width: 599px) {
  .column-detail-main.pattern2 .column__more {
    margin-top: 90px;
  }
}

/********** News | お知らせ一覧  CMS & Template **********/
/*
  ---------------
  #news .news-archive-news | お知らせ 記事詳細 標準機能用スタイル
  #news .news-archive-pressrelease | お知らせ 記事詳細 標準機能用スタイル
  ---------------
*/
#news .news-archive-news,
#news .news-archive-pressrelease {
  /*==========================================
  #page-title | FV
  ==========================================*/
  /*==========================================
  .news-entry-header | 投稿記事タイトル
  ==========================================*/
  /*==========================================
  .news-entry-body | 投稿記事内容
  ==========================================*/
  /*==========================================
  .trailer-link | その他のNewsリンク
  ==========================================*/
  /*==========================================
  .appendix | PDFダウンロードリンク包括要素
  .pdf-download | PDFダウンロードリンク
  ==========================================*/
}

#news .news-archive-news #page-title,
#news .news-archive-pressrelease #page-title {
  display: none;
}

#news .news-archive-news .news-entry-header,
#news .news-archive-pressrelease .news-entry-header {
  border-bottom: 1px solid #171717;
  padding-bottom: 9px;
  position: relative;
}

@media all and (max-width: 599px) {
  #news .news-archive-news .news-entry-header,
  #news .news-archive-pressrelease .news-entry-header {
    padding-bottom: 16px;
  }
}

#news .news-archive-news .news-entry-subject,
#news .news-archive-pressrelease .news-entry-subject {
  font-size: 25px;
  font-weight: normal;
  line-height: 1.8;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  #news .news-archive-news .news-entry-subject,
  #news .news-archive-pressrelease .news-entry-subject {
    font-size: 20px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: 0.08em;
  }
}

#news .news-archive-news .release-date,
#news .news-archive-pressrelease .release-date {
  display: inline-block;
  font-size: 13px;
  line-height: 1.84615385;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  #news .news-archive-news .release-date,
  #news .news-archive-pressrelease .release-date {
    font-weight: 500;
  }
}

#news .news-archive-news .news-entry-body,
#news .news-archive-pressrelease .news-entry-body {
  margin-top: 49px;
}

@media all and (max-width: 599px) {
  #news .news-archive-news .news-entry-body,
  #news .news-archive-pressrelease .news-entry-body {
    margin-top: 39px;
  }
}

#news .news-archive-news .news-entry-body p,
#news .news-archive-pressrelease .news-entry-body p {
  font-size: 14px;
  line-height: 2.14285714;
  letter-spacing: 0;
}

#news .news-archive-news .news-entry-body img,
#news .news-archive-pressrelease .news-entry-body img {
  margin: 39px 0 45px;
}

@media all and (max-width: 599px) {
  #news .news-archive-news .news-entry-body img,
  #news .news-archive-pressrelease .news-entry-body img {
    margin: 34px 0 40px;
  }
}

#news .news-archive-news .news-entry-body table,
#news .news-archive-pressrelease .news-entry-body table {
  margin: 15px auto 20px auto;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

#news .news-archive-news .news-entry-body table th,
#news .news-archive-pressrelease .news-entry-body table th {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #333;
  background-color: #f0f0f0;
  font-weight: normal;
}

#news .news-archive-news .news-entry-body table td,
#news .news-archive-pressrelease .news-entry-body table td {
  padding: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

#news .news-archive-news .news-entry-body table.cal th,
#news .news-archive-news .news-entry-body table.cal td,
#news .news-archive-pressrelease .news-entry-body table.cal th,
#news .news-archive-pressrelease .news-entry-body table.cal td {
  width: 14.285714286%;
  height: 38px;
  text-align: center;
}

#news .news-archive-news .news-entry-body table.cal td,
#news .news-archive-pressrelease .news-entry-body table.cal td {
  background-color: #fff;
}

#news .news-archive-news .news-entry-body table.cal .holiday,
#news .news-archive-pressrelease .news-entry-body table.cal .holiday {
  background-color: #ffebeb;
}

#news .news-archive-news .trailer-link,
#news .news-archive-pressrelease .trailer-link {
  display: none;
}

#news .news-archive-news .appendix,
#news .news-archive-pressrelease .appendix {
  position: absolute;
  bottom: 9px;
  right: 0;
}

@media all and (max-width: 599px) {
  #news .news-archive-news .appendix,
  #news .news-archive-pressrelease .appendix {
    bottom: 16px;
  }
}

#news .news-archive-news .pdf-download,
#news .news-archive-pressrelease .pdf-download {
  border-radius: 20px;
  background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.84615385;
  letter-spacing: 0;
  padding: 3px 19px 3px 36px;
  position: relative;
}

#news .news-archive-news .pdf-download::before,
#news .news-archive-pressrelease .pdf-download::before {
  content: "";
  background: url(/assets/img/newslist/news/icon_pdf_download.svg) center/contain no-repeat;
  display: block;
  width: 11.74px;
  height: 14.05px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

#news .news-archive-pressrelease .news-entry-body .text_wrap {
  margin-bottom: 4em;
  clear: both;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap:last-of-type {
  margin-bottom: 0;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap h4 {
  border-bottom: 2px solid #900;
  color: #c00;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 1em;
  padding-bottom: 0.25em;
  text-align: center;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap h5 {
  margin-bottom: 0.5em;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap p {
  line-height: 1.7;
  margin-bottom: 1em;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap p:last-child {
  margin-bottom: 0;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap p .img_right {
  float: right;
  max-width: 45%;
  height: auto;
  margin-left: 1em;
  margin-bottom: 1em;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap p .img_center {
  display: block;
  margin: 1em auto 1em auto;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap a {
  color: #c00;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap strong {
  color: #c00;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap .strong {
  font-weight: bold;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap ul li {
  margin-left: 1em;
  list-style: initial;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap .img_wrap {
  overflow: hidden;
  display: flex;
  gap: 20px;
}

@media all and (max-width: 599px) {
  #news .news-archive-pressrelease .news-entry-body .text_wrap .img_wrap {
    flex-direction: column;
  }
}

#news .news-archive-pressrelease .news-entry-body .text_wrap img {
  margin: 0;
  width: auto;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap .fr {
  float: right;
}

#news .news-archive-pressrelease .news-entry-body .text_wrap .fl {
  float: left;
}

#news .news-archive-pressrelease .news-entry-body .profile_wrap {
  border: 1px solid #ccc;
  padding: 1em;
}

#news .news-archive-pressrelease .news-entry-body .profile_wrap .profile {
  color: #000;
  font-weight: bold;
}

/*
  ---------------
  .entries | お知らせ・プレスリリース 記事一覧
  ---------------
*/
.entries__title {
  background-color: #f2f2f2;
  border-top: 1px solid #171717;
  font-size: 20px;
  font-weight: normal;
  line-height: 2;
  letter-spacing: 0;
  padding: 11px 22px 8px;
}

@media all and (max-width: 599px) {
  .entries__title {
    font-weight: 500;
    padding: 4px 22px 5px;
  }
}

.entries__list {
  margin-top: 20px;
}

@media all and (max-width: 599px) {
  .entries__list {
    margin-top: 29px;
  }
}

.entries__item {
  background-color: #fafafa;
  padding: 49px 53px 51px;
}

@media all and (max-width: 599px) {
  .entries__item {
    padding: 31px 31px 41px;
  }
}

.entries__item + .entries__item {
  margin-top: 15px;
}

@media all and (max-width: 599px) {
  .entries__item + .entries__item {
    margin-top: 20px;
  }
}

.entries__header {
  border-bottom: 1px solid #171717;
  padding-bottom: 9px;
}

@media all and (max-width: 599px) {
  .entries__header {
    padding-bottom: 14px;
  }
}

.entries__header-title {
  font-size: 25px;
  font-weight: normal;
  line-height: 1.44;
}

@media all and (max-width: 599px) {
  .entries__header-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0.08em;
  }
}

.entries__published {
  display: block;
  font-size: 13px;
  line-height: 1.84615385;
  letter-spacing: 0;
  margin-top: 4px;
}

@media all and (max-width: 599px) {
  .entries__published {
    font-weight: 500;
    margin-top: 6px;
  }
}

.entries__body {
  font-size: 14px;
  line-height: 2.14285714;
  letter-spacing: 0;
  margin-top: 30px;
  max-height: 210px;
  overflow: hidden;
}

@media all and (max-width: 599px) {
  .entries__body {
    margin-top: 25px;
    height: 330px;
  }
}

.entries__footer {
  text-align: center;
  margin-top: 39px;
}

@media all and (max-width: 599px) {
  .entries__footer {
    margin-top: 22px;
  }
}

.entries__footer-link {
  border: 1px solid #171717;
  color: #171717;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;
  max-width: 220px;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .entries__footer-link:hover {
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
  }
}

.entries__footer-link::before {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateY(-50%);
}

/*
  ---------------
  .news-heading | お知らせ・プレスリリース FVエリア
  ---------------
*/
.news-heading__wrap {
  position: relative;
}

.news-heading__box {
  width: 100%;
  position: absolute;
  top: calc(50% + 53px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media all and (min-width: 600px) and (max-width: 1015px) {
  .news-heading__box {
    top: 50%;
  }
}

@media all and (max-width: 599px) {
  .news-heading__box {
    top: calc(50% + 30px);
  }
}

.news-heading__title {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
}

@media all and (min-width: 787px) and (max-width: 1015px) {
  .news-heading__title {
    font-size: 36px;
  }
}

@media all and (min-width: 600px) and (max-width: 786px) {
  .news-heading__title {
    font-size: 29px;
  }
}

@media all and (max-width: 599px) {
  .news-heading__title {
    font-weight: 500;
    line-height: 2;
  }
}

/*
  ---------------
  .news-sidebar | お知らせ・プレスリリース サイドバー
  ---------------
*/
#news #page_aside2,
#newsentries #page_aside2 {
  flex-basis: 25.199645%;
}

@media all and (min-width: 600px) and (max-width: 899px) {
  #news #page_aside2,
  #newsentries #page_aside2 {
    margin-top: 80px;
  }
}

@media all and (max-width: 599px) {
  #news #page_aside2,
  #newsentries #page_aside2 {
    margin-top: 80px;
  }
}

.aside-widget.latest {
  border: 1px solid #dbdbdb;
}

.aside-widget.past {
  margin-bottom: 40px;
}

.aside-widget__title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.875;
  text-align: center;
  letter-spacing: 0;
}

@media all and (max-width: 599px) {
  .aside-widget__title {
    font-weight: 500;
  }
}

.latest .aside-widget__title {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px 0 8px;
}

.past .aside-widget__title {
  background-color: #dbdbdb;
  padding: 15px 0;
}

@media all and (max-width: 599px) {
  .past .aside-widget__title {
    padding: 14px 0 16px;
  }
}

.latest .aside-widget__list {
  padding: 22px 9.929078% 27px;
}

@media all and (min-width: 600px) and (max-width: 899px) {
  .latest .aside-widget__list {
    padding: 22px 40px 27px;
  }
}

@media all and (max-width: 599px) {
  .latest .aside-widget__list {
    padding: 22px 28px 27px;
  }
}

.latest .aside-widget__item {
  border-bottom: 1px dashed #707070;
}

.latest .aside-widget__item + .aside-widget__item {
  padding-top: 14px;
}

.latest .aside-widget__item:last-child {
  border-bottom: 0;
}

.past .aside-widget__item {
  background-color: #fafafa;
  border-bottom: 1px solid #dbdbdb;
}

.past .aside-widget__item.is-active {
  background-color: #171717;
  color: #fff;
}

.past .aside-widget__item:last-child {
  border-bottom: 0;
}

.aside-widget__link {
  display: block;
  padding-bottom: 8px;
}

.past .aside-widget__link {
  padding: 12px 21px 14px;
}

.past .aside-widget__link {
  font-size: 14px;
  line-height: 1.71428571;
  letter-spacing: 0;
}

.aside-widget__meta {
  line-height: 1.71428571;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.aside-widget__published {
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0;
  padding-right: 16px;
  margin-top: 5px;
}

@media all and (max-width: 599px) {
  .aside-widget__published {
    font-weight: bold;
    padding-right: 10px;
  }
}

.aside-widget__label {
  border: 1px solid #171717;
  font-size: 13px;
  line-height: 1.69230769;
  letter-spacing: 0;
  padding: 0 20px 0 21px;
  margin-top: 5px;
}

.aside-widget__item-title {
  font-size: 13px;
  line-height: 1.84615385;
  letter-spacing: 0;
  margin-top: 9px;
}

/*
  ---------------
  .news-tab | タブ切り替え
  ---------------
*/
.news-tab {
  padding: 0 80px;
  margin-top: 78px;
  margin-bottom: -1px;
}

@media all and (min-width: 600px) {
  .news-tab {
    margin-right: auto;
    margin-left: auto;
    max-width: 1366px;
    padding-right: 5.8565%;
    padding-left: 5.8565%;
  }
}

@media all and (max-width: 599px) {
  .news-tab {
    padding: 0 15px;
    margin-top: 50px;
    margin-bottom: -1px;
  }
}

.news-tab__list {
  display: flex;
  justify-content: center;
}

.news-tab__item {
  width: 50%;
  max-width: 600px;
}

.news-tab__item + .news-tab__item {
  margin-left: 7px;
}

@media all and (max-width: 599px) {
  .news-tab__item + .news-tab__item {
    margin-left: 11px;
  }
}

.news-tab__link {
  background-color: #171717;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  text-align: center;
  padding: 12px 0 14px;
  height: 50px;
  width: 100%;
}

@media all and (max-width: 599px) {
  .news-tab__link {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71428571;
  }
}

.is-active .news-tab__link {
  background-color: #fff;
  border-top: 2px solid #171717;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  color: #171717;
  margin-bottom: 0;
  height: 62px;
  pointer-events: none;
}

.is-active .news-tab__link:hover {
  opacity: 1;
}

/*
  ---------------
  .pagination | お知らせ・プレスリリース ページネーション
  ---------------
*/
.pagination {
  margin-top: 35px;
  overflow: hidden;
}

@media all and (max-width: 599px) {
  .pagination {
    margin-top: 27px;
  }
}

.pagination__wrap {
  display: flex;
}

.pagination__link {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71428571;
  letter-spacing: 0;
}

.pagination .prev {
  padding-left: 31px;
  margin-right: auto;
  position: relative;
}

.pagination .prev::before {
  content: "";
  background: url(/assets/img/newslist/news/icon_pagination_left.svg) center/contain no-repeat;
  display: block;
  width: 10.45px;
  height: 27.15px;
  position: absolute;
  top: 0;
  left: 0;
}

.pagination .next {
  float: right;
  padding-right: 31px;
  margin-left: auto;
  position: relative;
}

.pagination .next::before {
  content: "";
  background: url(/assets/img/newslist/news/icon_pagination_right.svg) center/contain no-repeat;
  display: block;
  width: 10.45px;
  height: 27.15px;
  position: absolute;
  top: 0;
  right: 0;
}
