/*
  ----------------
  Top | Pick up
  ----------------
*/
.pick-up {

  &__wrap {
    padding: 95px 65px 83px 80px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding: 75px 35px 53px 60px;
    }

    @media #{$sp} {
      padding: 55px 0 58px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.15em;
    line-height: 1.56;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0.08em;
      line-height: 0.96;
    }
  }

  &__slider {
    position: relative;
  }

  &__container {
    display: none;
    overflow: hidden;
    padding: 33px 15px 45px 0;
    position: relative;

    &.swiper-container-initialized {
      display: block;
    }

    @media #{$sp} {
      padding: 23px 0 38px;
    }
  }

  &__body {

  }

  &__link {
    display: inline-block;
    position: relative;
    width: 100%;

  }

  &__link.is-new-item {
    &::before {
      display: block;
      content: "";
      background: url(/assets/img/top/icon_new_badge.svg) 50% / contain no-repeat;

      position: absolute;
      top: -25px;
      right: -15px;
      z-index: 1;
      height: 50px;
      width: 50px;

      @media all and (max-width : 800px) and (min-width : 600px) {
        top: -20px;
        right: -10px;
        height: 35px;
        width: 35px;
      }

      @media #{$sp} {
        top: -20px;
        right: -10px;
        height: 35px;
        width: 35px;
      }
    }
  }

  &__img {

  }

  &__icon {

  }

  .swiper-button-next,
  .swiper-button-prev {
    @include hoverLink;
    cursor: pointer;
    height: 57px;
    top: 45%;
    width: 22px;

    @media #{$sp} {
      display: none;
    }

    &::after {
      background: url(/assets/img/top/icon_arrow_pickup_prev.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 57px;
      width: 22px;

      position: absolute;
      top: 0;
      left: 0;

      @media all and (max-width : 800px) and (min-width : 600px) {
        height: 45px;
        width: 15px;
      }

    }
  }

  .swiper-button-prev {
    left: -55px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      left: -30px;
    }
  }

  .swiper-button-next {
    right: -40px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      right: -25px;
    }

    &::after {
      background-image: url(/assets/img/top/icon_arrow_pickup_next.svg);
    }
  }


  .swiper-container-horizontal .swiper-pagination-bullet {
    background-color: #f2f2f2;
    border-radius: 25px;
    margin: 0 5px;
    opacity: 1;

    height: 8px;
    width: 8px;

    @media #{$sp} {
    }
  }

  .swiper-container-horizontal .swiper-pagination-bullet-active {
    background-color: $black-color;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0;
  }
}
