.item-inquiry {
  background-color: $main-color;
  padding: 144px 0 148px;

  @media #{$sp} {
    padding: 86px 0 116px;
  }

  .item-wrapper-02 & {
    padding: 104px 0 106px;

    @media #{$sp} {
      padding: 63px 0 144px;
    }
  }

  &__wrap {
    padding: 0 5.85651537%;

    @media #{$sp} {
      padding: 0 30px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.08em;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
    }
  }

  &__block {
    margin-top: 55px;

    @media #{$pc} {
      display: flex;
    }

    @media all and (min-width: 600px) and (max-width: 810px) {
      display: block;
    }

    @media #{$sp} {
      margin-top: 44px;
    }
  }

  &__box {
    border: 1px solid $light-gray-color;
    flex-basis: 50%;
    text-align: center;
    padding: 52px 40px 45px;

    @media all and (min-width: 600px) and (max-width: 1121px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @media #{$sp} {
      padding: 28px 19px 34px 21px;
    }

    &:nth-child(2) {

      @media #{$pc} {
        margin-left: 18px;
      }

      @media all and (min-width: 600px) and (max-width: 810px) {
        margin-top: 20px;
        margin-left: 0;
      }

      @media #{$sp} {
        padding: 27px 19px 20px 21px;
        margin-top: 20px;
      }
    }
  }

  &__box-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.08em;

    @media all and (min-width: 600px) and (max-width: 1121px) {
      font-size: 16px;
    }

    @media #{$sp} {
      font-weight: 500;
    }
  }

  &__box-phone {
    font-size: 0;
    line-height: 1.44;
    margin-top: 6px;

    @media #{$sp} {
      margin-top: 14px;
    }
  }

  &__box-phone-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.08em;
    vertical-align: 2px;

    @media all and (min-width: 600px) and (max-width: 1121px) {
      font-size: 16px;
    }

    @media #{$sp} {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.15384615;
      vertical-align: 3px;
    }
  }

  &__box-phone-number {
    font-size: 50px;
    font-weight: 400;
    letter-spacing: 0.075em;
    margin-left: 17px;

    @media all and (min-width: 600px) and (max-width: 1121px) {
      font-size: 42px;
    }

    @media #{$sp} {
      font-size: 35px;
      font-weight: 500;
      letter-spacing: 0.08em;
      margin-left: 7px;
    }
  }

  &__box-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71428571;
    letter-spacing: 0;

    @media #{$pc} {
      margin-top: 3px;
    }

    @media all and (min-width: 600px) and (max-width: 1121px) {
      font-size: 13px;
    }

    @media #{$sp} {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.84615385;
    }
  }

  &__box-btn {
    margin-top: 27px;

    @media #{$sp} {
      margin-top: 19px;
    }
  }

  &__box-link {
    @include btn(gradientBg);

    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    padding: 22px 20px;
    width: 100%;
    max-width: 460px;

    @media all and (min-width: 600px) and (max-width: 1121px) {
      font-size: 16px;
      padding: 19px 20px;
    }

    @media #{$sp} {
      font-size: 18px;
      line-height: 1.33333333;
      padding: 17px 20px;
    }
  }
}
