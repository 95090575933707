/*
  ----------------
  Online Counseling | Form
  ----------------
*/
.form-form-counseling {
  .form-body {
    margin-bottom: 173px;
    padding-top: 63px;
    padding-bottom: 108px;

    @media all and (max-width : 900px) {
      margin-bottom: 0;
      padding-top: 49px;
      padding-bottom: 256px;
    }

    .input-group {
      textarea {
        height: 196px;

        @media all and (max-width : 900px) {
          height: 290px;
        }
      }
    }

    .formrow-submit {
      margin-top: 53px;

      @media all and (max-width : 900px) {
        margin-top: 51px;
      }
    }
  }
}