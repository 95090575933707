/*
  ----------------
  お客様インタビュー | 最新３件
  ----------------
*/
.interview-new {
  &__wrap {
    @media #{$pc} {
      @include container;
      padding: 0;
    }
  }

  &__list {}

  &__item {
    padding: 60px 25px 20px 0;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 10px;
    }

    @media all and (max-width : 850px) {
      padding: 30px 25px 20px 0;
    }

    @media #{$sp} {
      flex-direction: column-reverse;
      padding: 0;

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:nth-child(1) {
        background-color: #f1f6f6;
      }
      &:nth-child(2) {
        background-color: #cde0e7;
      }
      &:nth-child(3) {
        background-color: #f4f7f8;
      }
    }
  }

  @media #{$pc} {
    .interview-new__item:nth-child(2) {
      padding: 50px 0 20px 50px;

      flex-direction: row-reverse;

      @media all and (max-width : 850px) {
        padding-left: 25px;
      }
    }
  }

  &__bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 75% 50%;
    z-index: -1;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .interview-new__item:nth-child(2) & {
      background-position: 25% 50%;
    }

    @media #{$sp} {
      display: none;
    }
  }

  &__main {
    @media #{$sp} {
      padding: 18px 30px 37px;
    }
  }

  &__info {
    padding-left: 132px;

    @media #{$pc} {
      .interview-new__item:nth-child(2) & {
        padding-left: 0;
        padding-right: 138px;

        align-items: flex-end;
      }
    }

    @media all and (max-width : 850px) {
      padding-left: 60px;

      .interview-new__item:nth-child(2) & {
        padding-right: 60px;
      }
    }

    @media all and (max-width : 750px) {
      padding-left: 30px;

      .interview-new__item:nth-child(2) & {
        padding-right: 30px;
      }
    }

    @media #{$sp} {
      padding:0;
    }
  }

  .interview-info-work {
    font-size: 18px;
    line-height: 1.777;
    letter-spacing: 0;

    @media all and (max-width : 850px) {
      font-size: 16px;
    }

    @media all and (max-width : 750px) {
      font-size: 14px;
    }

    @media #{$sp} {
      line-height: 2.285;
    }
  }

  .interview-info-name,
  .interview-info-age {
    display: inline-block;
    font-size: 30px;
    line-height: 1.133;
    letter-spacing: 0.08em;

    @media all and (max-width : 850px) {
      font-size: 26px;
    }

    @media all and (max-width : 750px) {
      font-size: 22px;
    }

    @media #{$sp} {
      font-size: 20px;
      line-height: .95;
    }
  }

  .interview-info-age {
    margin-left: 32px;

    @media #{$sp} {
      margin-left: 20px;
    }
  }

  &__link {
    @include btnLink(brown);
    background-color: $white-color;
    margin-top: 25px;

    @media #{$sp} {
      max-width: 268px;
    }
  }

  &__link-wrap {
    text-align: center;
  }

  &__product {
    background-color: $white-color;
    margin-top: 60px;
    padding: 14px 19px 14px 132px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$pc} {
      .interview-new__item:nth-child(2) & {
        padding: 14px 132px 14px 19px;

        flex-direction: row-reverse;
      }
    }

    @media all and (max-width : 850px) {
      margin-top: 30px;
      padding-left: 60px;

      .interview-new__item:nth-child(2) & {
        padding-left: 14px;
        padding-right: 60px;
      }
    }

    @media all and (max-width : 750px) {
      padding-left: 30px;

      .interview-new__item:nth-child(2) & {
        padding-left: 14px;
        padding-right: 30px;
      }
    }

    @media #{$sp} {
      margin-top: 23px;
      margin-left: calc((50vw - 50%) * -1);
      padding: 10px 10px 10px 30px;

      .interview-new__item:nth-child(2) & {
        padding: 10px 10px 10px 30px;
      }
    }
  }

  &__product-text {
    font-size: 13px;
    line-height: 1.846;
    letter-spacing: 0;
    white-space: nowrap;

    @media #{$sp} {
      font-size: 12px;
    }
  }

  &__product-box {
    display: flex;
    margin-left: 85px;

    @media #{$pc} {
      .interview-new__item:nth-child(2) & {
        margin-left: 0;
        margin-right: 85px;
      }
    }

    @media all and (max-width : 850px) {
      margin-left: 40px;

      .interview-new__item:nth-child(2) & {
        margin-left: 0;
        margin-right: 40px;
      }
    }

    @media #{$sp} {
      margin: 0;

      .interview-new__item:nth-child(2) & {
        margin: 0;
      }
    }
  }

  &__product-box figure:not(:first-child) {
    margin-left: 10px;

    @media #{$sp} {
      margin-left: 7px;
    }
  }

  &__product-img {
    width: 80px;

    @media #{$sp} {
      width: 58px;
    }
  }

  &__copy {
    position: relative;

    min-width: 248px;
    width: 248px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media #{$pc} {
      .interview-new__item:nth-child(2) & {
        align-items: flex-start;
      }
    }

    @media #{$sp} {
      display: block;
      width: 100%;
    }
  }

  &__copy-img {
    @media #{$pc} {
      display: none;
    }
  }

  &__copy-img img {
    aspect-ratio: 39 / 20;
    object-fit: cover;
    object-position: top right;

    .interview-new__item:nth-child(2) & {
      object-position: top left;
    }
  }

  &__text {
    font-size: 30px;
    line-height: 1.666;
    letter-spacing: 0.08em;

    //非表示追加
    display: none;

    @media #{$pc} {
      padding-left: 20px;

      .interview-new__item:nth-child(2) & {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    @media all and (max-width : 850px) {
      font-size: 28px;
    }

    @media all and (max-width : 750px) {
      font-size: 24px;
    }

    @media #{$sp} {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.08em;
      padding: 0;
      width: 155px;

      position: absolute;
      top: 16%;
      left: 10%;

      .interview-new__item:nth-child(2) & {
        padding-right: 0;
      }
    }
  }
}