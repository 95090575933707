/*
  ----------------
  Gnav
  Break point 追加: 800px
  ----------------
*/
.gnav {
  background-color: #2b2b2b;
  position: relative;

  &__wrap {
    padding: 0 9px 0 0px;

    display: flex;
    justify-content: space-between;
  }

  &__list {
    display: flex;
  }

  &__item {
    width: 90px;

    &--product {
      margin-left: 10px;
    }

    &--concept {
      margin-left: 40px;
    }

    &--column {
      margin-left: 20px;
    }

    &--voice {
      margin-left: 10px;
    }
  }

  .gnav__item--product {

    .gnav__link::after {
      display: block;
      content: "";
      background: url(/assets/img/nav/icon_arrow.svg) 50% / contain no-repeat;
      color: $white-color;

      position: absolute;
      top: 51%;
      right: -7px;
      width: 9px;
      height: 7px;
      transform: translateY(-50%);
    }
  }


  &__link {
    color: $white-color;
    font-size: 16px;
    height: 50px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1;
    }

    &::before {
      display: block;
      content: "";
      background-color: $white-color;
      transition: opacity .2s ease-in-out;
      opacity: 0;

      position: absolute;
      bottom: 1px;
      left: 0px;
      width: 90px;
      height: 2px;
    }
  }

  &__link:hover {
    &::before {
      left: 0px;
      opacity: 1;
      transition: opacity .2s ease-in-out;
    }
  }

  &__online {
    display: flex;
    align-items: center;

    @media all and (max-width : 800px) and (min-width : 600px) {
      justify-content: flex-end;
    }
  }

  &__online-link {
    border: 1px solid $white-color;
    color: $white-color;
    line-height: 1.714;
    letter-spacing: 0;
    padding: 4px 21px 4px 55px;
    position: relative;
    transition: background-color .2s ease-in-out;

    &::before {
      display: block;
      content: "";
      background: url(/assets/img/nav/icon_gnav_online.svg) 50% / contain no-repeat;

      position: absolute;
      top: 50%;
      left: 22px;
      width: 20px;
      height: 22px;
      transform: translateY(-50%);
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding: 5px 7px 0;

      &::before {
        display: none;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $white-color;
        color: $black-color;
        opacity: 1;
        transition: background-color .2s ease-in-out;
      }

      &:hover::before {
        background: url(/assets/img/nav/icon_gnav_online_black.svg) 50% / contain no-repeat;
      }
    }
  }

  &__online-text {

    @media all and (max-width : 800px) and (min-width : 600px) {
      display: none;
    }
  }

  &__online-img {
    display: none;

    &:hover svg g {
      fill: $black-color;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      display: block;
    }
  }
}