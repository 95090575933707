/*
  ----------------
  .order-process-main-complete | 注文処理の完了
  ----------------
*/
.order-process-main-complete {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;

  @media all and (max-width : 850px) {
    padding: 0 30px 120px;
  }

  @media #{$sp} {
    padding-top: 61px;
  }

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  h3 {
    font-weight: normal;
    margin-top: 40px;
    text-align: center;
  }

  .main-message {
    font-size: 20px;
    margin-top: 40px;
    text-align: center;
  }

  .main-contents {
    margin-top: 30px;
    text-align: center;
  }

  .message {
    margin: auto;
    max-width: 700px;
  }

  .order-code,
  .request-paymentmethod-reset {
    margin-top: 2px;
  }

  .proto-signin-init-password {
    margin-top: 80px;

    @media #{$sp} {
      margin-top: 60px;
    }

    h2 {
      @media #{$sp} {
        font-size: 22px;
      }
    }

    .form-body {
      padding: 60px 8.5406% 30px;

      @media #{$sp} {
        padding-top: 30px;
      }
    }

    .input-note {
      text-align: left;
    }

    .btn-submit {
      @media #{$sp} {
        margin-top: 20px;
      }
    }
  }

  form .description {
    margin-top: 30px;
  }

  .view-order-detail a,
  .form-reset-paymentmethod button {
    border: 1px solid #171717;
    box-sizing: border-box;
    color: #171717;
    display: inline-block;
    letter-spacing: .08em;
    margin-top: 30px;
    height: 50px;
    padding: 9px 10px 9px 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
    max-width: 300px;
    width: 100%;
  }
}
