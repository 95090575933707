/*
  ----------------
  .signup-form | サインアップ
  ----------------
*/
.signin-form {

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  h2 {
    font-weight: normal;
    margin-top: 40px;
    text-align: center;

    @media #{$sp} {
      font-size: 20px;
    }
  }

  h3 {
    margin-top: 60px;

    @media #{$sp} {
      margin-top: 40px;
    }
  }

  .form-note {
    margin-top: 20px;

    @media #{$sp} {
      padding: 0;
      text-align-last: center;
    }
  }

  .form-body {
    @media #{$pc} {
      padding-bottom: 70px;
    }

    @media #{$sp} {
      margin-right: -30px;
      margin-left: -30px;
    }

    .formrow-submit {
      margin-top: 40px;
    }
  }

  .reminder {
    margin-top: 40px;
    text-align: center;

    & > a {
      @include btn();
      max-width: 300px;
    }
  }

  #signout-at-signin {
    margin-top: 20px;
    text-align: center;
  }
}
