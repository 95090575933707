/*
  ----------------
  Online Counseling | Precautions
  ----------------
*/
.counseling-precautions {
  &__wrap {
    background-color: $main-color;
    margin: 0 auto 20px;
    max-width: 1206px;
    padding: 63px 103px 93px;

    @media all and (max-width : 900px) {
      margin-bottom: 10px;
      padding: 68px 30px 38px;
    }
  }

  &__header {
    padding: 0 50px;
  }

  &__title {
    font-size: 25px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.6;
    text-align: center;

    @media all and (max-width : 900px) {
    }
  }

  &__text {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.8462;
    margin-top: 13px;
    text-align: center;

    @media all and (max-width : 900px) {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.03em;
      line-height: 1.7857;
      margin-top: 20px;
    }
  }

  &__reception-time {
    margin-top: 37px;

    display: flex;
    gap: 16px;
    justify-content: center;

    @media all and (max-width : 900px) {
      display: block;
      margin-top: 32px;
    }
  }

  &__reception-item {
    border: 1px solid $light-gray-color;
    max-width: 492px;
    padding: 24px 0 31px;
    text-align: center;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media all and (max-width : 900px) {
      max-width: 100%;
      padding: 23px 0 21px;

      + li {
        margin-top: 20px;
      }
    }
  }

  &__reception-title {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7778;

    @media all and (max-width : 900px) {
      font-weight: 500;
      line-height: 1.1667;
    }
  }

  &__reception-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 2.1429;
    margin-top: 19px;

    @media all and (max-width : 900px) {
      font-weight: 400;
      line-height: 1.7857;
      margin-top: 7px;
    }

    & > span {
      display: block;
      color: $bronw-color;
      font-weight: 500;

      @media all and (max-width : 900px) {
        margin: 4px 0 -4px;
      }
    }
  }

  &__list {
    background-color: $white-color;
    border: 1px solid $light-gray-color;
    border-radius: 2px;
    margin-top: 20px;
    padding: 38px 50px 43px;

    @media all and (max-width : 900px) {
      margin-top: 23px;
      padding: 21px 19px 25px 22px;
    }
  }

  &__item {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 3.0769;

    @media all and (max-width : 900px) {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.9231;
    }
  }
}