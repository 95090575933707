/*
  ----------------
  .header-links
  Break point 追加: 1125px
  ----------------
*/
.header-links {
  display: flex;

  &__item {

    &+.header-links__item {
      margin-left: 28px;

      @media all and (max-width : 1125px) {
        margin-left: 5px;
      }
    }
  }

  &__link {
    @include hoverText;
  }

  &__link {
    color: #191919;
    font-weight: normal;
    height: 60px;
    letter-spacing: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width : 1125px) and (min-width : 600px) {
      font-size: 11px;
    }
  }
}