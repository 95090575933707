/*
  ----------------
  Amount table | 料金合計表
  ----------------
*/

.amount-table {
  font-weight: normal;
  margin-left: auto;
  margin-right: 0;
  width: 50%;

  @media #{$sp} {
    width: 100%;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    tr {
      border-bottom: 1px solid $form-border-color;

      @media #{$sp} {
        margin-left: auto;
        width: 50%;
      }

      &:last-child {
        border-bottom: 0;
      }

      .view-order & {
        
        &:last-child {
          border-bottom: 1px solid $form-border-color;
        }
      }
    }

    th {

      @media #{$sp} {
        border-right: 1px solid $form-border-color;
      }
    }

    .amount {
      text-align: right;
    }
  }

  .total.odr-ttl-amt .amount,
  .odr-ttl-earned-pts .amount {
    color: $alert-text-color;
  }
}
