/*
  ----------------
  ご注文ガイド | common
  ----------------
*/
.guide-main {
  @include container;
  background-color: $white-color;
  padding-top: 80px;
  padding-bottom: 185px;

  @media #{$pc} {
    display: flex;
    overflow: none;
  }

  @media all and (max-width : 960px) {
    padding: 40px 40px 90px;
  }

  @media all and (max-width : 800px) {
    display: block;
  }

  @media #{$sp} {
    padding: 50px 30px 87px;
  }
}
