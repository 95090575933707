/*
  ----------------
  お客様の声集
  ----------------
*/

.voice-lead {

  &__wrap {}

  &__body {
    position: relative;
  }

  &__body-bg {}

  &__body-img {}

  &__copy {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.08em;
    line-height: 1.666;
    text-align: center;
    z-index: 10;
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{$sp} {
      font-size: 20px;
      font-weight: 500;
      line-height: 2.5;
      top: 52%;
    }
  }
}