/*
  ---------------
  .form-regular-purchase-payment | 定期お支払い方法
  ---------------
*/
.form-regular-purchase-payment {

  h4 {
    border-right: 0;
  }

  .main-payment-method {
    // border: 1px solid $form-border-color;
    // text-align: center;
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }

    h4 {
      background-color: transparent;
      border-bottom: 1px solid $black-color;
      display: block;
      font-size: 25px;
      text-align: left;
      line-height: 1.3;
      padding: 0 0 10px;
    }

    .wrapper {
      font-size: 20px;
      // padding: 10px;
    }

    .note {
      margin-top: 5px;
      color: #ba0000;
    }
  }

  .note {
    font-size: 13px;
    // margin-top: 10px;

    @media #{$sp} {
      font-weight: 400;
    }

    &:before {
      content: "✳︎";
      display: inline-block;
    }
  }

  .method-name {}

  #update-rp-payment {
    // border: 1px solid $form-border-color;
    margin-top: 100px;

    h4 {
      background-color: transparent;
      border-bottom: 1px solid $black-color;
      display: block;
      font-size: 25px;
      text-align: left;
      line-height: 1.3;
      padding: 0 0 10px;
    }

    .payment-method:not(:first-child) {
      border-top: 1px solid lightgray;
      margin-top: 40px;
    }
  }

  .pm-wrapper {
    text-align: center;
    padding-top: 10px;
  }

  .pm-heading {
    font-size: 20px;
    text-align: left;
  }

  .pm-body {
    // border: 1px solid $form-border-color;
    margin-top: 30px;
    // padding: 0 7.1365%;
    text-align-last: left;

    .input-group .date.date-mm {
      margin-right: 28px;
      margin-left: 0;

      @media #{$sp} {
        margin-left: 12px;
      }
    }

    .formrow {
      border: 1px solid $form-border-color;
      margin-top: 0;
    }

    .formrow:not(:first-child) {
      border-top: none;
    }

    .formrow:last-child {
      border-bottom: none;
    }

    .input-label {
      background-color: $secondar-color;

      align-items: center;
      justify-content: center;

      @media #{$sp} {
        margin-top: 0;
      }
    }

    .input-control {
      padding: 10px 20px;

      @media #{$sp} {
        margin-top: 5px;
      }
    }

    .form-control {
      display: block;
      min-height: 100%;
    }
  }

  .pm-submit {
    margin-top: 20px;
  }

  .btn {
    @include btn;

    display: inline-block;
    width: 100%;
    max-width: 350px;
    margin-top: 30px;
    padding: 10px;
  }
}
