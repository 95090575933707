/*
  ----------------
  .form-payment | お支払い方法
  ----------------
*/
.form-payment {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;

  @media all and (max-width : 850px) {
    padding: 0 30px 120px;
  }

  @media #{$sp} {
    padding-top: 61px;
  }

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  .form-note {
    @media #{$sp} {
      padding: 0;
      text-align-last: center;
    }
  }

  h2 {
    font-size: 25px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0;
    margin-top: 50px;
    border-bottom: 1px solid #171717;
    padding-bottom: 10px;
    text-align: left;

    @media #{$sp} {
      font-size: 20px;
      margin-top: 20px;
      padding-bottom: 5px;
    }
  }

  #paymet-order-invoice {
    // margin-top: 8rem;

    // @media #{$sp} {
    //   margin-top: 4rem;
    // }

    .amount-table {
      width: 100%;
    }

    table {
      border-collapse: collapse;
      margin-bottom: 10px;
      width: 100%;
    }

    th,
    td {
      font-size: 16px;
      font-weight: 400;
      border: 1px solid lightgray;
      padding: 10px 20px;

      @media #{$sp} {
        font-size: 14px;
        font-weight: 500;
      }
    }

    th {
      background: #f2f2f2;
      border-right: none;
    }
  }

  .form-body {
    background: none;
    padding: 0;
  }

  .wrapper {
    margin-top: 1rem;

    .paymententry-note {

      table {
        border-collapse: collapse;
        margin-bottom: 10px;
        width: 100%;
      }

      th,
      td {
        font-size: 16px;
        font-weight: 400;
        border: 1px solid lightgray;
        padding: 10px 20px;

        @media #{$sp} {
          font-size: 14px;
          font-weight: 500;
        }
      }

      th {
        background: #f2f2f2;
        border-right: none;

        @media #{$pc} {
          width: 418px;
        }
      }
    }

    .control {
      text-align: center;

      .btn-payment {
        margin-top: 30px;
        max-width: 350px;
      }
    }
  }

  #select-payment-method {

    .payment-method {
      padding-top: 1rem;

      &:not(:first-child) {
        border-top: 1px solid lightgray;
        margin-top: 2rem;
      }

      .pm-wrapper.open {
        .pm-label:before {
          transform: translateY(3px) rotate(90deg);
        }
      }

      .pm-heading {
        // cursor: pointer;
      }

      .pm-label {
        // padding-left: 20px;
        position: relative;

        // &:before {
        //   content: '';
        //   left: 4px;
        //   box-sizing: border-box;
        //   width: 6px;
        //   height: 6px;
        //   border: 6px solid transparent;
        //   border-left: 6px solid lightgray;

        //   position: absolute;
        //   top: 0;
        //   bottom: 0;
        //   left: 0;
        //   transition: .2s ease;
        //   margin: auto;
        //   vertical-align: middle;
        // }
      }
    }

    .payment-method:nth-child(2) {
      @media #{$sp} {
        padding-top: 20px;
      }

      .pm-label {
        display: inline-block;
        position: relative;
      }
    }

    .payment-method:nth-child(3) {
      .pm-label {
        display: inline-block;
        position: relative;
      }
    }

    .payment-method:last-child {
      border-bottom: 1px solid lightgray;
      padding-bottom: 2rem;
    }

    .pm-body {
      margin-top: 1rem;

      .formrow {
        border: 1px solid lightgray;
        margin-top: 0;

        display: flex;
        // align-items: center;

        &:not(:first-child),
        &.formrow-checkbox {
          border-top: none;
        }
        @media #{$sp} {
          font-size: 1.5rem;
          display: flex;
          flex-direction: column;
        }
      }

      // .input-required {
      //   color: #e4002b;
      //   margin-left: 1rem;
      // }

      .input-label {
        background-color: #f2f2f2;
        border-right: 1px solid lightgray;
        font-weight: bold;
        padding: 10px 20px;

        display: flex;
        flex-basis: auto;
        align-items: center;
        justify-content: center;

        @media #{$pc} {
          width: 418px;
        }

        @media #{$sp} {
          font-size: 1.6rem;
          padding: 0.5rem;
          width: 100%;
        }
      }

      .input-control {
        padding: 10px 20px;

        .input-group .date.date-mm {
          margin-right: 28px;
          margin-left: 0;

          @media #{$sp} {
            margin-right: 12px;
          }
        }
      }
    }

    .pm-label {
      font-weight: 400;
      font-size: 20px;

      @media #{$sp} {
        font-size: 15px;
      }
    }

    .pm-trailing {
      text-align: center;
    }

    .btn-payment {
      display: inline-block;
      max-width: 350px;
      margin-top: 30px;
      padding: 10px;
      width: 100%;
    }

    .pm-use-as-default {
      margin-top: 1rem;
    }
  }

  .input-may-required {
    border: 1px solid $bronw-color;
    border-radius: 10px;
    color: $bronw-color;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.08em;
    margin-left: 1rem;

    height: 21px;
    width: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$sp} {
      height: 20px;
    }
  }

  .order-cancel-edit {
    text-align: center;
    margin-top: 2rem;
  }
}
