/*
  ----------------
  .manage-paymentmethod | ID、パスワード編集
  ----------------
*/

.manage-paymentmethod {

  h4 {
    margin-top: 40px;

    @media #{$sp} {
    }
  }

  .note {
    margin-top: 20px;

    @media #{$sp} {
    }
  }

  .panel-list {
    margin-top: 30px;
    text-align: center;

    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);

    @media all and (min-width: 600px) and (max-width: 850px) {
      margin-top: 15px;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    @media #{$sp} {
      margin-top: 15px;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }

    & > li {
      border: 1px solid lightgray;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
    }

    .panel-heading {
      margin-bottom: 20px;
    }

    .use-as-default,
    .delete-payment {
      margin: 10px 0;
    }

    .paymententry-note {
      display: none;
    }
  }

  .payment-method-list {
    margin-top: 40px;
  }

  .pm-wrapper {
    background-color: $secondar-color;
    padding: 70px 103px 130px;

    @media all and(min-width: 600px) and (max-width: 794px) {
      padding: 50px 30px 70px;
    }

    @media #{$sp} {
      padding: 50px 30px 70px;
    }
  }

  .pm-label {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    line-height: 1.6;
    letter-spacing: 0;

    @media #{$sp} {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .pm-body {
    margin-top: 100px;

    @media all and(min-width: 600px) and (max-width: 794px) {
      margin-top: 60px;
    }

    @media #{$sp} {
      margin-top: 60px;
    }
  }

  .pm-trailing {
    margin-top: 60px;
  }

  .pm-submit {
    text-align: center;
  }

  .btn {
    @include btn(brownBg);
  }

  .main-payment-method {
    .wrapper {
      border: 1px solid lightgray;
      margin-top: 30px;
      padding: 30px 20px;
      text-align: center;
      width: 31%;
      display: flex;
      flex-direction: column;

      @media all and(min-width: 600px) and (max-width: 794px) {
        margin-top: 15px;
        width: 48%;
      }

      @media #{$sp} {
        margin-top: 15px;
        width: 100%;
      }
    }

    .method-name {
      margin-bottom: 20px;
    }

    .control {
      @media #{$sp} {
        text-align: center;
      }
    }
  }

  label {
    margin-top: 2rem;
  }

  .extra-control {
    margin-top: 2rem;
    text-align: left;
  }

  .panel-list .panel {
    justify-content: start;
  }

  .input-may-required {
    color: $alert-text-color;
    padding-left: 1rem;
  }
}
