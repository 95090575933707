.js-text-animation {

  &.show {
    .js-text-animation-span {
      display: inline-block;
      overflow: hidden;

    }
    span {
      display: inline-block;
      letter-spacing: 0.1em;
      animation: showText .5s backwards;
    }
  }
}

@keyframes showText {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
