/*
  ----------------
  お客様インタビュー
  ----------------
*/
.interview-main {

  &__wrap {
    padding: 88px 80px 109px;

    @media #{$pc} {
      max-width: 1366px;
      margin: 0 auto;
    }

    @media all and (max-width : 950px) {
      padding: 44px 40px 50px;
    }

    @media #{$sp} {
      padding: 43px 30px 0px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;

    @media all and (max-width : 950px) {
      gap: 30px;
    }

    @media #{$sp} {
      margin: 0 auto;
      max-width: 400px;
      gap: 20px;
    }
  }

  &__item {
    background-color: $main-color;
    flex-basis: calc((100% - 120px) / 3);

    @media all and (max-width : 950px) {
      flex-basis: calc((100% - 60px) / 3);
    }

    @media all and (max-width : 800px) {
      flex-basis: calc((100% - 30px) / 2);
    }

    @media #{$sp} {
      flex-basis: 100%;
    }
  }

  &__view {
    // padding-top: 61.1111%;
    position: relative;

    @media #{$sp} {
      max-width: 180px;
      width: 54%;
    }
  }

  &__img {
    aspect-ratio: 18 / 11;
    object-fit: cover;
    object-position: top right;

    // position: absolute;
    // top: 0;
    // right: 0;
    // object-fit: cover;
    // height: 100%;
    // width: 100%;

    @media #{$sp} {
      aspect-ratio: 75 / 67;
      object-position: 80% 50%;
    }
  }

  &__copy {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6;
    letter-spacing: 0;

    @media #{$pc} {
      text-align: right;
      width: 145px;

      position: absolute;
      bottom: 15px;
      right: 13px;

      //非表示
      display: none;
    }

    @media #{$sp} {
      font-size: 13px;
      letter-spacing: 0.03em;
      margin-top: 15px;
    }
  }

  &__box {
  }

  &__header {
    @media #{$sp} {
      display: flex;
    }
  }

  &__body {
    padding: 0 20px 25px;
  }

  &__info {
    margin-top: 26px;
    padding: 0 20px;

    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media #{$sp} {
      line-height: 0;
      margin-top: 0;
      padding: 13px 15px 0;
    }
  }

  .interview-info-work {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.923;
    letter-spacing: 0.03em;
    text-align: center;

    @media #{$pc} {
      flex-basis: 100%;
    }

    @media #{$sp} {
      font-size: 12px;
      text-align: left;
    }
  }

  .interview-info-name,
  .interview-info-age {
    font-size: 18px;
    font-weight: 400;
    display: inline-block;
    line-height: 1.18;
    letter-spacing: 0.03em;
    text-align: center;

    @media #{$sp} {
      font-size: 14px;
    }
  }

  .interview-info-age {
    @media #{$pc} {
      margin-left: 15px;
    }
  }

  &__product {
    background-color: $white-color;
    margin-top: 31px;
    padding: 5px 15px 15px;

    @media #{$sp} {
      margin-top: 21px;
      padding: 10px 10px 10px 20px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__product-text {
    font-size: 13px;
    line-height: 1.846;
    letter-spacing: 0;
    text-align: center;

    @media #{$sp} {
      font-size: 12px;
      line-height: 1.416;
    }
  }

  &__product-box {
    margin-top: 12px;

    display: flex;
    justify-content: center;
    gap: 10px;

    @media #{$sp} {
      margin-top: 0;
      gap: 7px;
    }
  }

  &__product-img {
    @media #{$sp} {
      width: 58px;
    }
  }

  &__link-wrap {
    margin-top: 19px;
    text-align: center;

    @media #{$sp} {
      margin-top: 13px;
    }
  }

  &__link {
    @include btnLink(brown);

    @media #{$sp} {
      max-width: 268px;
    }
  }

}
