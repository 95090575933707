/*
  ----------------
  Gnav product
  Break point 追加: 800px
  ----------------
*/
.gnav-product {
  background-color: rgba(43, 43, 43, 0.7);
  display: flex;
  padding: 28px 32px 24px;
  width: 100%;

  position: absolute;
  top: 49px;
  left: 0;

  opacity: 0;
  transition: opacity .2s ease-in-out;
  visibility: hidden;

  @media all and (max-width : 800px) and (min-width : 600px) {
    flex-wrap: wrap;
  }

  &__item {
    max-width: 153px;

    &+.gnav-product__item {
      margin-left: 22px;

      @media all and (max-width : 800px) and (min-width : 600px) {
        margin: 10px;
      }
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      margin: 10px;
    }
  }

  &__item figure {
    background-color: $white-color;
    position: relative;

    &::before {
      border: 3px solid $white-color;
      display: block;
      content: "";
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      transform: translate(-50%, -50%);
    }
  }

  &__link {}

  &__img {
    width: 153px;
    height: 80px;
    object-fit: cover;
  }

  @media (hover: hover) and (pointer: fine) {
    &__link:hover {
      opacity: 1;

      figure::before {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }

      .gnav-product__name {
        color: #9f9f9f;
      }
    }
  }

  &__name {
    color: $white-color;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.538;
    margin-top: 9px;
  }
}

// Menu open
.gnav__item--product:hover .gnav-product {
  opacity: 1;
  transition: opacity .2s ease-in-out;
  visibility: visible;
}