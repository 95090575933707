/*
  ----------------
  #updatepass-main | パスワードの更新
  ----------------
*/

#update_password {
  .form-update-password-by-token {
    @include container;
  }

  div.btn-submit {
    margin-top: 1rem;
    text-align: center;
  }
}