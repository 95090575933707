/*
  ---------------
  accountページ共通スタイル
  ---------------
*/
.form-auth,
.account,
.order-journal-history,
.order-process-main-payment-status,
.rp-subscriptions,
.rp-future-entries,
.rp-entry,
.form-rps-consignee,
.form-regular-purchase-payment,
.form-edit-account,
.form-edit-address,
.form-update-password-by-token,
.signin-form {
  padding-top: 110px;
  padding-bottom: 100px;

  @media #{$sp} {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  #page-title {

    h1 {
      text-align: center;
      margin-top: 60px;

      @media #{$sp} {
        font-weight: 500;
      }
    }
  }

  h2 {
    border-bottom: 2px solid $border-gray-color;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0;
    padding-bottom: 5px;
    margin-top: 120px;

    @media #{$sp} {
      font-weight: 500;
      margin-top: 80px;
    }
  }

  h4,
  h5,
  h6 {
    background-color: $title-bg-color;
    border-right: 1px solid $form-border-color;
    flex: 0 0 260px;
    text-align: center;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (min-width: 600px) and (max-width: 850px) {
      border-right: 0;
    }

    @media #{$sp} {
      border-right: 0;
    }
  }

  // 標準機能のボタンを非表示
  .extra-controls {
    display: none;
  }

  // 標準機能のボタンを非表示
  div.add-all-ordered-items.panel-footer {
    display: none;
  }

  .message,
  .alert {
    border: 1px solid #171717;
    border-radius: 8px;
    margin: 40px auto 0;
    max-width: 650px;
    padding: 15px;
    text-align: center;
    width: 100%;
  }
}
