/*
  ---------------
  #news .news-archive-news | お知らせ 記事詳細 標準機能用スタイル
  #news .news-archive-pressrelease | お知らせ 記事詳細 標準機能用スタイル
  ---------------
*/
#news .news-archive-news,
#news .news-archive-pressrelease {

  /*==========================================
  #page-title | FV
  ==========================================*/
  #page-title {
    display: none;
  }

  /*==========================================
  .news-entry-header | 投稿記事タイトル
  ==========================================*/
  .news-entry-header {
    border-bottom: 1px solid $black-color;
    padding-bottom: 9px;

    position: relative;

    @media #{$sp} {
      padding-bottom: 16px;
    }
  }

  .news-entry-subject {
    font-size: 25px;
    font-weight: normal;
    line-height: 1.8;
    letter-spacing: 0;

    @media #{$sp} {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.08em;
    }
  }

  .release-date {
    display: inline-block;
    font-size: 13px;
    line-height: 1.84615385;
    letter-spacing: 0;

    @media #{$sp} {
      font-weight: 500;
    }
  }

  /*==========================================
  .news-entry-body | 投稿記事内容
  ==========================================*/
  .news-entry-body {
    margin-top: 49px;

    @media #{$sp} {
      margin-top: 39px;
    }

    p {
      font-size: 14px;
      line-height: 2.14285714;
      letter-spacing: 0;
    }

    img {
      margin: 39px 0 45px;

      @media #{$sp} {
        margin: 34px 0 40px;
      }
    }

    table {
      margin: 15px auto 20px auto;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      width: 100%;
      border-collapse: collapse;
      text-align: left;

      th {
        padding: 8px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        color: #333;
        background-color: #f0f0f0;
        font-weight: normal;
      }

      td {
        padding: 8px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }

      &.cal {

        th,
        td {
          width: 14.285714286%;
          height: 38px;
          text-align: center;
        }

        td {
          background-color: #fff;
        }

        .holiday {
          background-color: #ffebeb;
        }
      }
    }
  }

  /*==========================================
  .trailer-link | その他のNewsリンク
  ==========================================*/
  .trailer-link {
    display: none;
  }

  /*==========================================
  .appendix | PDFダウンロードリンク包括要素
  .pdf-download | PDFダウンロードリンク
  ==========================================*/
  .appendix {
    position: absolute;
    bottom: 9px;
    right: 0;

    @media #{$sp} {
      bottom: 16px;
    }
  }

  .pdf-download {
    border-radius: 20px;
    background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
    color: $white-color;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
    letter-spacing: 0;
    padding: 3px 19px 3px 36px;

    position: relative;

    &::before {
      content: "";
      background: url(/assets/img/newslist/news/icon_pdf_download.svg) center / contain no-repeat;
      display: block;
      width: 11.74px;
      height: 14.05px;

      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }
  }
}

#news .news-archive-pressrelease {

  .news-entry-body {

    .text_wrap {
      margin-bottom: 4em;
      clear: both;

      &:last-of-type {
        margin-bottom: 0;
      }

      h4 {
        border-bottom: 2px solid #900;
        color: #c00;
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 1em;
        padding-bottom: 0.25em;
        text-align: center;
      }

      h5 {
        margin-bottom: 0.5em;
      }

      p {
        line-height: 1.7;
        margin-bottom: 1em;
      }

      p:last-child {
        margin-bottom: 0;
      }

      p .img_right {
        float: right;
        max-width: 45%;
        height: auto;
        margin-left: 1em;
        margin-bottom: 1em;
      }

      p .img_center {
        display: block;
        margin: 1em auto 1em auto;
      }

      a {
        color: #c00;
      }

      strong {
        color: #c00;
      }

      .strong {
        font-weight: bold;
      }

      ul li {
        margin-left: 1em;
        list-style: initial;
      }

      .img_wrap {
        overflow: hidden;
        display: flex;
        gap: 20px;

        @media #{$sp} {
          flex-direction: column;
        }
      }

      img {
        margin: 0;
        width: auto;
      }

      .fr {
        float: right;
      }
      .fl {
        float: left;
      }
    }

    .profile_wrap {
      border: 1px solid #ccc;
      padding: 1em;

      .profile {
        color: #000;
        font-weight: bold;
      }
    }

    // p {
    //   font-size: 14px;
    //   line-height: 2.14285714;
    //   letter-spacing: 0;
    // }

    // img {
    //   margin: 39px 0 45px;

    //   @media #{$sp} {
    //     margin: 34px 0 40px;
    //   }
    // }
  }
}