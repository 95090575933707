/*
  ----------------
  .form-edit-account | ID、パスワード編集
  ----------------
*/
.form-edit-account {

  #page-title {
    @include container;
  }
}
