/*
  ----------------
  .item-list-fv | 商品一覧FV
  ----------------
*/
.item-list-fv {

  @media all and (min-width: 600px) and (max-width: 1015px) {
    padding-top: 109px;
  }

  &__wrap {
    position: relative;
  }

  &__img {
    position: relative;

    @media all and (min-width: 787px) and (max-width: 1015px) {
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
    }

    @media #{$sp} {
      height: calc(100vw * 1.34871795);
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: $black-color;
      opacity: 0.2;

      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__box {
    width: 100%;

    position: absolute;
    top: calc(50% + 33px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @media all and (min-width: 600px) and (max-width: 1015px) {
      top: 50%;
    }

    @media #{$sp} {
      top: calc(50% + 11px);
    }
  }

  &__title {
    color: $white-color;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.08em;
    text-align: center;
    width: 100%;

    @media all and (min-width: 787px) and (max-width: 1015px) {
      font-size: 36px;
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
      font-size: 25px;
    }

    @media #{$sp} {
      font-weight: 500;
      line-height: 2;
    }
  }
}
