
/*
  ----------------
  Account pages | Common
  ----------------
*/

.order-inquiry,
.review-list,
.write-review,
.account-overview,
.edit-account-properties,
.entry-reminder-update,
.form-close-account,
.form-form-CLOSE-ACCOUNT,
.form-consignee,
.form-deliveryservice,
// .form-edit-account,
.form-edit-address,
.form-normal-signin,
.form-orderer,
.form-order-checkout-forms,
.form-update-password-by-token,
.form-payment,
.form-points,
.form-sender,
.fovorites-items,
.manage-addressbook,
.manage-paymentmethod,
.form-register-exauth,
.order-journal-history,
.order-process-main-complete,
.order-process-main-payment-status,
.points-history,
.view-order,
.view-orders,
.view-inquiries,
.your-reviews,
.yourcoupons,
.rp-subscriptions,
.rp-future-entries,
.rp-entry,
.form-rps-consignee,
.form-regular-purchase-payment,
.signin-form {
  @include container;
}
