/*
  ---------------
  .item-list-main | 商品一覧 ラメリアプレミアム・スキンケア3点セット部分
  ---------------
*/
.item-list-main {

  /*---------- 共通スタイル (inner & outer) ----------*/
  .item-list-main-each {

    &__ruby {
      @include h2TitleRuby;
    }

    &__title {
      @include h2Title;
      margin-top: 20px;

      @media all and (min-width: 600px) and (max-width: 1015px) {
        font-size: 25px;
      }
    }

    &__block {

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }
    }

    &__header {

      @media #{$pc} {
        flex: 0 1 56.7993367%;
      }
    }

    &__img {

      @media #{$pc} {
        position: relative;
      }
    }

    &__message {

      @media #{$pc} {
        position: absolute;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        position: static;
        padding: 0 35px;
      }

      @media #{$sp} {
        padding: 0 28px 0 35px;
      }
    }

    &__message-title {
      font-size: 30px;
      font-weight: 400;
      line-height: 1.56666667;

      @media all and (min-width: 600px) and (max-width: 1015px) {
        font-size: 25px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.03em;
      }

      @media #{$sp} {
        font-size: 25px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.03em;
      }
    }

    &__message-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.84615385;
      letter-spacing: 0;
      margin-top: 3px;

      @media all and (min-width: 600px) and (max-width: 850px) {
        font-weight: 400;
        line-height: 2.69230769;
        letter-spacing: 0.03em;
      }

      @media #{$sp} {
        font-weight: 400;
        line-height: 2.69230769;
        letter-spacing: 0.03em;
      }
    }

    &__body {

      @media #{$pc} {
        flex: 0 0 380px;
        margin-left: 6.55058043%;
      }

      @media all and (min-width: 851px) and (max-width: 1015px) {
        flex: 0 0 330px;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        padding: 0 35px;
        margin-left: 0;
      }
    }

    &__description {}

    &__description-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 2.85714286;

      @media all and (min-width: 851px) and (max-width: 1015px) {
        font-size: 12px;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        font-size: 14px;
      }

      @media #{$sp} {
        letter-spacing: 0;
      }
    }

    &__content {
      font-size: 20px;
      font-weight: 400;
      line-height: 2;
      letter-spacing: 0;

      @media all and (min-width: 600px) and (max-width: 1015px) {
        font-size: 16px;
      }

      @media #{$sp} {
        font-size: 14px;
        line-height: 2.14285714;
        letter-spacing: 0.08em;
      }
    }

    &__price {
      font-size: 20px;
      font-weight: 400;
      line-height: 2;
      letter-spacing: 0;
      padding-left: 5.25em;
      text-indent: -5.25em;

      @media all and (min-width: 600px) and (max-width: 1015px) {
        font-size: 16px;
      }

      @media #{$sp} {
        font-size: 14px;
        line-height: 2.14285714;
        letter-spacing: 0.08em;
        padding-left: 5.375em;
        text-indent: -5.375em;
      }
    }

    &__btn {

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
      }

      @media #{$sp} {
        text-align: center;
      }
    }

    &__btn-link {
      @include btnLink(brown);

      font-size: 14px;
      font-weight: 400;
      line-height: 2.14285714;
      padding: 8px 10px 10px 12px;
    }
  }

  /*---------- 個別スタイル (inner care商品) ----------*/
  .inner-care .item-list-main-each {

    &__ruby {
      img {
        height: 23.52px;

        @media #{$sp} {
          height: 15.68px;
        }
      }
    }

    &__title {

      @media #{$pc} {
        line-height: 1.35;
        margin-top: 22px;
      }

      @media #{$sp} {
        margin-top: 7px;
      }
    }

    &__block {
      margin-top: 4.312%;

      @media #{$sp} {
        margin-top: 37px;
      }
    }

    &__message {

      @media #{$pc} {
        bottom: 6.0475162%;
        left: 6.56934307%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 45.7px;
      }

      @media #{$sp} {
        margin-top: 45.7px;
      }

    }

    &__message-title {
      letter-spacing: 0.145em;

      @media #{$sp} {
        letter-spacing: 0.03em;
      }
    }

    &__message-text {
      margin-top: 3px;

      @media #{$sp} {
        margin-top: 8px;
      }
    }

    &__body {
      @media #{$sp} {
        padding: 0 34px 0 35px;
      }
    }

    &__description {
      margin-top: 5px;

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 38px;
      }

      @media #{$sp} {
        margin-top: 38px;
      }
    }

    &__description-text {
      @media #{$sp} {
        line-height: 2.5;
      }
    }

    &__info {
      margin-top: 5%;

      @media #{$sp} {
        margin-top: 29px;
      }
    }

    &__btn {
      margin-top: 15.7894737%;

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 58px;
      }

      @media #{$sp} {
        margin-top: 58px;
      }
    }
  }

  /*---------- 個別スタイル (outer care 商品) ----------*/
  .outer-care {
    margin-top: 9.5356550%;

    @media all and (min-width: 600px) and (max-width: 850px) {
      margin-top: 86px;
    }

    @media #{$sp} {
      margin-top: 86px;
    }
  }

  .outer-care .item-list-main-each {

    &__ruby {
      img {
        height: 19.65px;

        @media #{$sp} {
          height: 13.1px;
        }
      }
    }

    &__title {

      @media #{$pc} {
        line-height: 1.3;
        margin-top: 23px;
      }

      @media #{$sp} {
        margin-top: 8px;
      }
    }

    &__block {
      margin-top: 6.05306799%;

      @media #{$sp} {
        margin-top: 35px;
      }
    }

    &__message {

      @media #{$pc} {
        top: 6.69546436%;
        left: 6.86131387%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 45.7px;
      }
    }

    &__message-title {
      letter-spacing: 0.15em;

      @media all and (min-width: 600px) and (max-width: 1100px) {
        font-size: 22px;
        font-weight: 400;
        line-height: 1.56666667;
        letter-spacing: 0.03em;
      }
    }

    &__message-text {
      margin-top: 2px;
    }

    &__body {

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 34px;
      }

      @media #{$sp} {
        padding: 0 28px 0 35px;
        margin-top: 34px;
      }
    }

    &__description {

      @media #{$pc} {
        margin-top: -13px;
      }
    }

    &__description-text {
      @media #{$sp} {
        line-height: 2.14285714;
      }
    }

    &__info {
      margin-top: 4.47368421%;

      @media #{$sp} {
        margin-top: 17px;
      }
    }

    &__deals {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.84615385;
      letter-spacing: 0;
      margin-top: 6.84210526%;

      @media #{$sp} {
        font-weight: 500;
        line-height: 2.69230769;
        letter-spacing: 0.08em;
        margin-top: 14px;
      }
    }

    &__btn {
      margin-top: 11.315789%;

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 59px;
      }

      @media #{$sp} {
        margin-top: 59px;
      }
    }
  }
}
