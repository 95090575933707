/*
  ---------------
  .news-sidebar | お知らせ・プレスリリース サイドバー
  ---------------
*/
// Logica 組み込み用スタイル
#news #page_aside2,
#newsentries #page_aside2 {
  flex-basis: 25.199645%;

  @media all and (min-width: 600px) and (max-width: 899px) {
    margin-top: 80px;
  }

  @media #{$sp} {
    margin-top: 80px;
  }
}

.news-sidebar {
  &__wrap {}
}

.aside-widget {

  &.latest {
    border: 1px solid $light-gray-color;
  }

  &.past {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.875;
    text-align: center;
    letter-spacing: 0;

    @media #{$sp} {
      font-weight: 500;
    }

    .latest & {
      border-bottom: 1px solid $light-gray-color;
      padding: 10px 0 8px;
    }

    .past & {
      background-color: $light-gray-color;
      padding: 15px 0;

      @media #{$sp} {
        padding: 14px 0 16px;
      }
    }
  }

  &__list {

    .latest & {
      padding: 22px 9.929078% 27px;

      @media all and (min-width: 600px) and (max-width: 899px) {
        padding: 22px 40px 27px;
      }

      @media #{$sp} {
        padding: 22px 28px 27px;
      }
    }
  }

  &__item {

    .latest & {
      border-bottom: 1px dashed $border-gray-color;

      & + .aside-widget__item {
        padding-top: 14px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .past & {
      background-color: $main-bg-color;
      border-bottom: 1px solid $light-gray-color;

      &.is-active {
        background-color: $black-color;
        color: $white-color;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__link {
    display: block;
    padding-bottom: 8px;

    .past & {
      padding: 12px 21px 14px;
    }

    .past & {
      font-size: 14px;
      line-height: 1.71428571;
      letter-spacing: 0;
    }
  }

  &__meta {
    line-height: 1.71428571;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__published {
    display: inline-block;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0;
    padding-right: 16px;
    margin-top: 5px;

    @media #{$sp} {
      font-weight: bold;
      padding-right: 10px;
    }
  }

  &__label {
    border: 1px solid $black-color;
    font-size: 13px;
    line-height: 1.69230769;
    letter-spacing: 0;
    padding: 0 20px 0 21px;
    margin-top: 5px;
  }

  &__item-title {
    font-size: 13px;
    line-height: 1.84615385;
    letter-spacing: 0;
    margin-top: 9px;
  }
}
