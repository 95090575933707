/*
  ----------------
  Modal | モーダル
  ----------------
*/

#modal {
  display: none;
  
  &.is-open {
    display: block;
  }

  .modal {
  
    &__wrap {
      background-color: rgba(0, 0, 0, .7);
      height: 100vh;
      padding-top: 4rem;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 999;
    }

    &__lists {
      margin: 0 auto;
      width: 80%;
    }

    &__list {
      border-bottom: 1px solid $white-color;
      display: block;
      margin-top: 2rem;
    }
    
    &__link {
      @include hoverLink;
      text-decoration: none;
      color: $white-color;
    }

    &__close {
      color: $white-color;
      margin-top: 4rem;
      text-align: center;
      text-decoration: underline;
    }
  }
}

.modal__btn {
  display: none;
  border: 1px solid $black-color;

  @media #{$sp} {
    display: block;
  }
}