.item-purchase {
  background-color: $main-bg-color;
  padding: 10.102489% 0 8.34553441%;
  margin-top: 89px;

  @media #{$sp} {
    padding: 77px 0 58px;
    margin-top: 44px;
  }

  .item-wrapper-02 & {
    padding: 122px 0 100px;

    @media #{$sp} {
      padding: 64px 0 52px;
    }
  }

  &__wrap {
    padding: 0 80px;

    @media all and (min-width: 600px) and (max-width: 1366px) {
      padding: 0 5.85651537%;
    }

    @media #{$sp} {
      padding: 0 30px;
    }
  }

  &__ruby {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.08em;
    text-align: center;

    @media all and (min-width: 600px) and (max-width: 756px) {
      font-size: 18px;
      line-height: 2.14285714;
    }

    @media #{$sp} {
      font-size: 14px;
      line-height: 2.14285714;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    margin-top: 21px;

    @media #{$pc} {
      letter-spacing: 0.08em;
    }

    @media all and (min-width: 600px) and (max-width: 756px) {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.6;
      margin-top: -1px;
      letter-spacing: 0;
    }

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      line-height: 1.6;
      margin-top: -1px;
      letter-spacing: 0;
    }
  }
}
