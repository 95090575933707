/*
  ----------------
  コラム詳細 | common-detail
  ----------------
*/
.column-detail-heading {
  margin-bottom: 90px;

  @media #{$sp} {
    margin-bottom: 40px;
  }

  &__wrap {
    position: relative;
  }

  &__img {}

  &__box {
    width: 100%;

    position: absolute;
    top: calc(50% + 53px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @media #{$sp} {
      top: calc(50% + 30px);
    }
  }

  &__title {
    color: $white-color;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.08em;
    text-align: center;
    width: 100%;

    @media all and (min-width: 787px) and (max-width: 1015px) {
      font-size: 36px;
    }

    @media all and (min-width: 600px) and (max-width: 786px) {
      font-size: 29px;
    }

    @media #{$sp} {
      font-weight: 500;
      line-height: 2;
    }
  }
}

.column-detail-main{
  @include container;

  // padding-top: 98px;
  padding-bottom: 199px;

  @media all and (min-width: 600px) and (max-width: 1015px) {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 152px;
  }

  @media #{$sp} {
    // padding-top: 39px;
    padding-bottom: 152px;
  }

  & > section {

    //----- grid class START -----//
    &.grid {

      @media #{$pc} {
        display: grid;
        // display: flex;
        grid-template-columns: 1fr 1fr;
        gap: 66px;
      }

      @media all and (min-width: 600px) and (max-width: 1015px) {
        // grid-template-columns: 1fr 29.5567%;
        gap: 33px;
      }

      @media #{$sp} {
        display: flex;
        flex-direction: column;
      }

      h3 {

        @media #{$pc} {
          margin-top: 0;
        }
      }

      img {
        margin-top: 0;
      }

      &:not(:first-of-type) {
        margin-top: 60px;

        @media #{$sp} {
          margin-top: 40px;
        }
      }

      &:nth-of-type(1) {
        @media all and (min-width: 600px) and (max-width: 1015px) {
          display: flex;
          flex-direction: column;
        }

        h2 {
          padding: 17px;

          @media #{$sp} {
            padding: 53px 10px 40px;
          }
        }

        p {
          @media #{$pc} {
            margin-top: -9px;
            margin-left: 8px;
          }
        }
      }

      &:nth-of-type(2) {

        @media #{$pc} {
          // grid-template-columns: 1fr 1fr;
        }

        @media all and (min-width: 600px) and (max-width: 1015px) {
          // grid-template-columns: 29.5567% 1fr;
        }

        & > div {
          // @media #{$pc} {
          //   margin-top: -163px;
          // }

          @media all and (min-width: 600px) and (max-width: 1015px) {
            margin-top: 0;
          }
        }

        h3 {
          @media #{$pc} {
            margin-bottom: 14px;
          }
        }

        img {
          grid-row: 1;
        }
      }
    }
    //----- grid class END -----//

    div {

      @media #{$sp} {
        order: 2;
      }
    }

    h2 {
      border: 1px solid #171717;
      font-size: 35px;
      font-weight: normal;
      height: 330px;
      letter-spacing: 0.08em;
      line-height: 1.7143;
      max-width: 574px;
      padding-top: 17px;
      text-align: center;
      width: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1183px) {
        font-size: 28px;
      }

      @media all and (min-width: 600px) and (max-width: 1015px) {
        max-width: 100%;
      }

      @media #{$sp} {
        font-size: 20px;
        font-weight: 500;
        height: auto;
        line-height: 1.5;
        margin-bottom: 24px;
        padding: 53px 0 40px;

        display: flex;
        flex-direction: column-reverse;
      }

      & > img {
        height: 21.93px;
        margin-bottom: 20px;
        width: auto;

        @media #{$sp} {
          height: 14.62px;
          margin-bottom: 10px;
          order: 1;
        }
      }

      & > span {
        display: block;
        font-family: adorn-garland, sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.25;
        margin-bottom: 10px;

        @media #{$sp} {
          font-size: 20px;
          margin-bottom: 5px;
          order: 1;
        }
      }
    }

    h3 {
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 1.6667;
      // margin-bottom: 41px;
      margin-top: 60px;
      padding-left: 2px;

      @media all and (min-width: 600px) and (max-width: 1015px) {
        font-size: 25px;
      }

      @media #{$sp} {
        font-size: 20px;
        line-height: 2;
        margin: 55px 0 0;
        padding-left: 0;
      }

      &:not(:first-of-type) {
        margin-top: 41px;
      }
    }

    p {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 2.8571;
      margin-top: 41px;
      padding-left: 2px;

      @media #{$sp} {
        line-height: 2.5;
        padding-left: 0;
        margin-top: 14px;
      }
    }

    &:first-of-type img {
      margin-top: 0;
    }

    img {
      // max-width: 572px;
      max-width: 100%;
      margin-top: 30px;

      @media #{$sp} {
        display: block;
        margin: 15px auto 0;
      }

      & + img {
        @media #{$sp} {
          margin-top: 20px;
        }
      }

      &.img-s {
        max-width: 350px;
      }

      &.img-m {
        max-width: 600px;
      }

      &.img-l {
        max-width: 900px;
      }
    }
  }

  .column__more {
    margin-top: 144px;

    @media all and (min-width: 600px) and (max-width: 1015px) {
      margin-top: 116px;
    }

    @media #{$sp} {
      margin-top: 116px;
    }

    & > a::before {
      top: 42%;
      right: auto;
      left: 11px;
      transform: rotate(-135deg);
    }
  }

  // コラム詳細（パターン２）
  &.pattern2 {
    padding-bottom: 124px;

    @media #{$sp} {
      padding-bottom: 121px;
    }

    & > section {
      @media #{$pc} {
        margin-right: -80px;

        grid-template-columns: 1fr 650px;
        gap: 72px;
      }

      @media #{$sp} {
        flex-direction: column-reverse;
      }

      &:nth-of-type(odd) {
        @media #{$pc} {
          margin-right: 0;
          margin-left: -80px;

          grid-template-columns: 650px 1fr;
        }

        img {
          grid-row: 1;
        }
      }

      &:nth-of-type(1) {
        display: block;
        margin-left: 0;

        & > div {
          img {
            margin: 96px calc(50% - 50vw) 0;
            max-width: 100vw;
            width: 100vw;

            @media #{$sp} {
              margin-top: 0;
            }
          }
        }

        p {
          @media #{$pc} {
            letter-spacing: 0;
            margin: 77px 0 0;
          }

          @media #{$sp} {
            margin-top: 44px;
          }
        }

        img {
          @media #{$pc} {
            margin-top: 13px;
            max-width: 100%;
          }
        }
      }

      &:nth-of-type(2) {
        margin-top: 77px;

        @media #{$sp} {
          margin-top: 28px;
        }

        & > div {
          @media #{$pc} {
            margin-top: 0;
            padding-right: 44px;
          }
        }

        h3 {
          @media #{$pc} {
            margin-bottom: 26px;
          }
        }

        p {
          @media #{$pc} {
          }
        }

        img {
          grid-row: auto;

          @media #{$pc} {
            margin-top: 86px;
          }
        }
      }

      &:nth-of-type(3) {
        margin-top: 109px;

        @media #{$sp} {
          margin-top: 0;
        }

        h3 {
          @media #{$pc} {
            margin-bottom: 34px;
          }
        }
      }

      &:nth-of-type(4) {
        margin-top: 90px;

        @media #{$sp} {
          margin-top: 0;
        }

        & > div {
          @media #{$pc} {
            padding-top: 15px;
            padding-right: 44px;
          }
        }

        h3 {
          @media #{$pc} {
            margin-bottom: 36px;
          }
        }
      }
    }

    h2 {
      border: none;
      font-size: 40px;
      height: auto;
      line-height: 1.5;
      margin-top: 0;
      max-width: 100%;
      padding-top: 0;

      @media #{$sp} {
        font-size: 25px;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 0;
        padding: 14px 0 48px;

      }

      & > img {
        height: 44px;
        margin-bottom: 19px;
        width: auto;

        @media #{$sp} {
          height: 33.9px;
          margin-top: 0;
          margin-bottom: 14px;
          order: 1;
        }
      }
    }

    h3 {
      margin-bottom: 41px;
      padding-left: 0;

      @media #{$sp} {
        font-size: 20px;
        line-height: 2;
        margin: 44px 0 0;
      }
    }

    p {
      letter-spacing: 0.03em;
      padding-left: 0;

      @media #{$sp} {
        line-height: 2.5;
        letter-spacing: 0;
        margin-top: 15px;
      }
    }

    img {
      max-width: 650px;
      width: 100vw;

      @media #{$sp} {
        margin-top: 55px;
        width: 100%;
      }
    }

    .column__more {
      margin-top: 78px;

      @media #{$sp} {
        margin-top: 90px;
      }
    }
  }
}
