/*
  ---------------
  .js-item-list-fv-slide | 商品一覧 FVスライド
  ---------------
*/
.item-list-fv {

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 14px;

    @media #{$sp} {
      bottom: 11px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 0 0 8px;

    @media #{$sp} {
      margin: 0 0 0 7px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .swiper-pagination-bullet {
    background: $white-color;
    border-radius: 0;
    opacity: 1;
    width: 80px;
    height: 5px;

    @media #{$sp} {
      width: 53px;
      height: 5px;
    }
  }

  .swiper-pagination-bullet-active {
    background: $black-color;
  }

}
