/*
  ----------------
  お客様の声集
  ----------------
*/
.voice-main {
  input[type="checkbox"] {
    display: none;
  }

  .is-hide {
    display: none;
  }

  &__wrap {
    @include container;
    padding-top: 57px;
    padding-bottom: 180px;

    @media all and (max-width : 1050px) {
      padding-right: 40px;
      padding-left: 40px;
    }

    @media #{$sp} {
      padding: 0 0 107px;
    }
  }

  &__title {
    background-color: $secondar-color;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.777;
    padding: 9px 17px;
    position: relative;

    @media #{$sp} {
      font-size: 14px;
      letter-spacing: 0.08em;
      line-height: 2.142;
      padding: 11px 30px 8px;
    }
  }

  &__reset {
    border: 1px solid $black-color;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.846;

    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);

    @media #{$sp} {
      right: 29px;
    }


    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }

  &__reset::before {
    display: block;
    content: "";
    background: url(/assets/img/voice/icon_closs.svg) 50% / contain no-repeat;

    position: absolute;
    top: 50%;
    right: 10px;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
  }

  &__reset input {
    appearance: none;
    padding: 5px 35px 5px 10px;

    @media #{$sp} {
      padding-top: 4px;
    }
  }

  &__product {
    padding: 20px 0 48px;

    display: flex;
    flex-wrap: nowrap;
    gap: 20px;

    @media #{$sp} {
      padding: 20px 30px 33px;

      flex-wrap: wrap;
      gap: 9px;
    }
  }

  &__product label {
    @include hoverLink;
    position: relative;

    @media #{$sp} {
      flex-basis: calc((100% - 18px) / 3);
    }

  }

  &__product label::before {
    display: block;
    content: "";
    background: rgba(0, 0, 0, 0.5) url(/assets/img/voice/icon_check.svg) 50% / 34px 24px no-repeat;
    opacity: 0;
    transition: opacity .2s ease-in-out;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    @media #{$sp} {
      background: rgba(0, 0, 0, 0.5) url(/assets/img/voice/icon_check.svg) 50% / 16px 11px no-repeat;
    }
  }

  &__product input:checked+label {

    &::before {
      opacity: 1;
      transition: opacity .2s ease-in-out;
    }

  }

  &__age {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.777;
    padding-top: 22px;

    display: flex;
    flex-wrap: nowrap;
    gap: 20px;

    @media #{$sp} {
      font-size: 16px;
      padding: 20px 30px 0;

      flex-wrap: wrap;
      gap: 9px;
    }
  }

  &__age-type {
    width: 100%;

    @media #{$sp} {
      flex-basis: calc((100% - 18px) / 3);
    }
  }

  &__age-type label {
    border: 1px solid $black-color;
    cursor: pointer;
    display: inline-block;
    padding: 13px;
    text-align: center;
    transition: background-color .2s ease-in-out;
    width: 100%;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $black-color;
        color: $white-color;
        opacity: .7;
        transition: background-color .2s ease-in-out;
      }
    }

    @media #{$sp} {
      padding: 10px;
    }
  }

  &__age-type input:checked+label {
    background-color: $black-color;
    color: $white-color;
    transition: background-color .2s ease-in-out;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $black-color;
        color: $white-color;
        opacity: 1;
        transition: background-color .2s ease-in-out;
      }
    }
  }

  &__list {
    margin-top: 90px;
    max-height: 730px;
    overflow: hidden;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px 63px;

    @media all and (max-width : 1050px) {
      gap: 30px;
      max-height: 690px;
    }

    @media all and (max-width : 900px) {
      max-height: 1070px;
    }

    @media #{$sp} {
      display: block;
      margin-top: 50px;
      max-height: 990px;
      padding: 0 30px;
    }
  }

  &__item {
    background: url(/assets/img/voice/bg_gradient_red.jpg) 50% / cover no-repeat;
    border-radius: 20px;
    flex-basis: calc((100% - 126px) / 3);
    overflow: hidden;
    padding: 10px 30px 28px;

    @media all and (max-width : 1050px) {
      flex-basis: calc((100% - 60px) / 3);
    }

    @media all and (max-width : 900px) {
      flex-basis: calc((100% - 30px) / 2);
    }

    @media #{$sp} {
      padding: 25px 30px 36px;

    }
  }

  &__item:nth-child(even) {
    background: url(/assets/img/voice/bg_gradient_blue.jpg) 50% / cover no-repeat;

    span {
      background: linear-gradient(to right, #4879ff 0%, #72aed9 100%);
    }
  }

  &__item:not(:first-child) {
    @media #{$sp} {
      margin-top: 15px;
    }
  }

  &__copy-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$pc} {
      min-height: 112px;
    }

    @media #{$sp} {
      margin-right: 0;

      flex-direction: row-reverse;
      gap: 20px;
      justify-content: flex-end;
    }
  }

  &__copy {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media #{$sp} {
      letter-spacing: 0.08em;
      line-height: 1.5;
    }
  }

  &__copy-icon {
    @media #{$pc} {
      filter: drop-shadow(0px 10px 10px rgba(0,0,0,.16));
      max-width: 70px;
      padding-left: 15px;
    }

    @media #{$sp} {
      min-width: 70px;
    }
  }

  &__tag {
    margin-top: 5px;

    @media #{$pc} {
      overflow: hidden;
      white-space: nowrap;
    }

    @media #{$sp} {
      line-height: 1;
      margin-top: 14px;
      padding-left: 0;
    }
  }

  &__tag span {
    background: linear-gradient(to right, #ff6562 0%, #ffc362 100%);
    border-radius: 20px;
    color: $white-color;
    display: inline-block;
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 1.8;
    padding: 2px 12px;
    max-width: 100%;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {
      @media all and (max-width : 1230px) {
        max-width: 68%;
      }
    }

    &+span {
      margin-left: 5px;
    }
  }

  &__text {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.923;
    margin-top: 15px;
    position: relative;

    @media #{$pc} {
      // max-height: 200px;
    }

    @media #{$sp} {
      font-size: 13px;
      line-height: 1.923;
    }

    &.is-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__text-more {
    color: #ba0000;
    display: none;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__info {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.923;
    margin-top: 3px;

    @media #{$sp} {
      font-size: 13px;
      line-height: 1.923;
      margin-top: 6px;
    }
  }

  &__more {
    margin-top: 88px;
    position: relative;
    text-align: center;

    @media #{$sp} {
      margin-top: 50px;
    }

    &::before {
      background: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
      content: "";
      display: block;
      height: 50px;
      position: absolute;
      top: -138px;
      left: 0;
      width: 100%;

      @media #{$sp} {
        top: -100px;
      }
    }
  }

  &__button {
    @include btnLink(brown);
    padding-top: 14px;
    padding-bottom: 14px;
  }

}