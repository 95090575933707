body.product {
  .go-top {
    display: none;
  }
}

.item-productclass-ITEMS_DRINKS,
.item-productclass-ITEMS_COSMETICS {
  .item-fv__box {
    display: none;
  }
}

.item-description {
  margin-top: 68.5px;

  @media #{$sp} {
    margin-top: 30.5px;
  }

  .item-wrapper-02 & {
    @media #{$sp} {
      margin-top: 31.5px;
    }
  }

  &__wrap {
    padding: 0 5.85651537%;

    @media #{$sp} {
      padding: 0 30px;
    }
  }
  &__img {}
}

.item-detail-heading {
  margin: 68.5px auto 52px;
  position: relative;

  @media #{$sp} {
    margin: 30.5px auto 40px;
    padding: 0;
  }

  & > h1 {
    background-color: rgba(255,255,255,0.6);
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 25px;
    // height: 189px;
    letter-spacing: 0;
    line-height: 2.4;
    padding: 4px 20px 4px 18px;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
    width: auto;

    @media #{$sp} {
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 2.1429;
    }
  }
}

.item-detail-body {
  margin-bottom: 190px;

  @media #{$sp} {
    margin-bottom: 77px;
    padding: 0;
  }

  h2 {
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 0.15em;
    line-height: 1.5667;

    @media #{$sp} {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 2;
      margin-top: 40px;
      text-align: center;
    }

    & > span {
      font-size: 25px;
      line-height: 1.6;

      @media #{$sp} {
        font-size: 20px;
      }
    }
  }

  p {
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 2.5;
    margin-top: 64px;

    @media #{$sp} {
      font-size: 14px;
      line-height: 2.5;
      margin-top: 40px;
    }

    & > span {
      font-weight: 500;
    }
  }

  & > div {
    margin-top: 120px;

    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;

    @media #{$sp} {
      margin-top: 110px;
      flex-direction: column-reverse;
    }

    &:nth-of-type(1) {
      @media #{$pc} {
        margin-top: 52px;
      }
    }

    &:nth-of-type(even) {
      @media #{$pc} {
        justify-content: flex-end;
      }
    }

    &:nth-of-type(odd) {
      @media #{$pc} {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .img-left {
    margin-right: 65px;

    @media #{$sp} {
      margin-right: 0;
      width: 100%;
    }
  }

  .img-right {
    margin-left: 65px;

    @media #{$sp} {
      margin-left: 0;
      width: 100%;
    }
  }

  .item-list-sub {
    margin-top: 0;

    @media #{$sp} {
      padding: 0;
    }
  }

  .item-list-sub-each__wrap {
    @media #{$sp} {
      display: flex;
    }
  }

  .item-list-sub-each__img {
    @media #{$sp} {
      margin: 24px 16px 0 0;
      width: 120px;
    }
  }

  .item-list-sub-each__box {
    @media #{$sp} {
      flex: 1;
    }
  }

  .item-list-sub-each__description {
    @media #{$sp} {
      font-size: 13px;
      line-height: 2.6923;
      margin: 24px 0 0;
    }
  }

  .item-list-sub-each__btn {
    @media #{$sp} {
      margin-top: 19px;
      text-align: right;
    }
  }

  .item-list-sub-each__link {
    border: 1px solid #171717;
    color: #171717;
    display: inline-block;
    letter-spacing: .08em;
    padding: 9px 10px 9px 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
    max-width: 220px;
    width: 100%;

    @media #{$pc} {
      &::before {
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        right: 14px;
        width: 7px;
        height: 7px;
        -webkit-transform: rotate(45deg) translateY(-50%);
        -ms-transform: rotate(45deg) translateY(-50%);
        transform: rotate(45deg) translateY(-50%);
      }
    }

    @media #{$sp} {
      border: none;
      padding: 0;
      max-width: auto;
      width: auto;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      &::after {
        background: url(/assets/img/item/skincare/icon_arrow.png) no-repeat center center;
        content: "";
        display: inline-block;
        height: 24px;
        margin-left: 12px;
        position: relative;
        width: 24px;
      }
    }
  }

  //ラメリアプレミアム
  &.premium {

    & > div {
      @media #{$sp} {
        margin-top: 63px;
        flex-direction: column;
      }
    }
  }
}

.item-voice {
  margin: 0 auto;
  max-width: 800px;
  position: relative;

  @media #{$sp} {
    margin: -50px 30px 0;
  }

  &__wrap {

  }

  &__box {
    background-color: rgba(255,255,255,0.8);
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 50%;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__box-title {
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 0.15em;
    line-height: 1.5667;

    @media #{$sp} {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.08em;
    }
  }

  &__box-btn {
    margin-top: 32px;
    max-width: 220px;
    width: 100%;

    @media #{$sp} {
      margin-top: 16px;
      width: 66.6667%;
    }
  }

  &__box-btn-link {

    @media #{$sp} {
      font-size: 10px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// 医学博士
.item-medical-doctor {

  &__wrap {
    background-color: #F2F2F2;
    margin: 97px auto 0;
    max-width: 800px;
    padding: 24px 39px 20px 24px;

    @media #{$sp} {
      margin-top: 120px;
      padding: 8px 8px 16px;
    }
  }

  &__wrap > div {
    display: flex;
  }

  &__img {
    margin-right: 37px;
    width: 200px;

    @media #{$sp} {
      margin-right: 9px;
      width: 120px;
    }
  }

  &__box {
    font-family: "Source Han Sans JP";
    font-weight: normal;
    flex: 1;
  }

  &__title {
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.08em;
    line-height: 2;
    margin-bottom: 16px;

    @media #{$sp} {
      font-size: 13px;
      margin-bottom: 8px;
    }
  }

  &__description {
    border-bottom: 1px solid #171717;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.8462;
    margin-bottom: 16px;
    padding-bottom: 8px;

    @media #{$sp} {
      font-size: 11px;
      line-height: 1.6364;
      margin-bottom: 0;
      padding-bottom: 30px;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.7143;

    @media #{$sp} {
      font-size: 13px;
      line-height: 1.8462;
      margin-top: 21px;
    }
  }
}


// よくあるご質問
.item-faq-contents {
  margin-top: 180px;
  margin-left: auto;

  @media #{$sp} {
    margin: 80px auto 0;
    padding: 0;
  }

  .faq-contents__title {
    background: none;
    border-top: none;
    border-bottom: 1px solid #171717;
    color: #171717;
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 40px;
    letter-spacing: 0.08em;
    line-height: 1.6667;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      line-height: 1.6;
    }
  }
}


// 全成分表示
.item-all-ingredients {
  margin-top: 113px;

  @media #{$sp} {
    margin-top: 81px;
  }

  &__wrap {
    @media #{$sp} {
      padding: 0;
    }
  }

  &__title {
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 25px;
    letter-spacing: 0;
    line-height: 1.6;

    @media #{$sp} {
      font-weight: 500;
      font-size: 20px;
    }
  }

  &__box {
    background: #fafafa;
    margin-top: 16px;
    padding: 25px 23px 20px;

    @media #{$sp} {
      margin-top: 13.5px;
      padding: 16.5px 15px 14.5px;
    }
  }

  &__box-text {
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 2.1429;

    @media #{$sp} {
      font-size: 14px;
    }

    &.is-open {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

      @media #{$sp} {
        -webkit-line-clamp: 4;
      }
    }
  }

  &__box-btn {
    margin-top: 16px;
    text-align: center;

    @media #{$sp} {
      margin-top: 13px;
    }
  }

  &__box-btn-link {

    @media #{$sp} {
      font-size: 11px;
      max-width: 151px;
      padding: 4px 11px;
    }
  }
}

// 全成分表示モーダル
.modal-all-ingredients {

  &__bg {
    background: rgba(000,000,000,0.6);
    display: none;
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    @media #{$sp} {
    }
  }

  &__wrap {
    background-color: #fff;
    margin: 0 auto;
    padding: 49px 40px;
    width: 650px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{$sp} {
      padding: 19.6px 25.85px 21.4px;
      width: 85.5769%;
    }
  }

  &__title {
    font-family: "DNP ShueiMGoStd";
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 0;
    text-align: center;

    @media #{$sp} {
      font-size: 20px;
    }
  }

  &__text {
    border-top: 1px solid #171717;
    font-family: "Source Han Sans JP";
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.08em;
    line-height: 2.1429;
    margin-top: 29.6px;
    padding-top: 19.7px;

    @media #{$sp} {
      margin-top: 10.3px;
    }
  }

  &__btn {
    background: url(/assets/img/item/icon_close.svg) no-repeat center center;
    background-size: cover;
    display: inline-block;
    height: 40px;
    width: 40px;

    position: absolute;
    top: -60px;
    right: 0;

    @media #{$sp} {
      height: 23.46px;
      width: 23.46px;

      top: -35.16px;
    }
  }
}