/*
  ----------------
  Order pages | Common
  ----------------
*/

.order-process-main-complete,
#order-entry,
.form-consignee,
.form-orderer,
.form-sender,
.form-deliveryservice,
.form-itemoptions,
.form-points,
.form-payment,
.form-rps-consignee {
  @include container;
  font-size: 15px;
  padding-bottom: 10rem;

  h1 {
    @include lowerTitle;
  }

  h2 {
    @include lowerTitleSub;
  }

  .addressbook {
    text-align: center;

    .ab-list {
      margin-top: 30px;

      @media #{$sp} {
        margin-top: 15px;
      }

      & > ul {
        @media #{$pc} {
          margin: 0 auto;
          text-align: center;
          width: 90%;

          display: grid;
          gap: 30px;
          grid-template-columns: repeat(auto-fit, 300px);
          justify-content: center;
        }
      }

      .ab-item {
        border: 1px solid #171717;
        color: #171717;
        display: inline-block;
        letter-spacing: .08em;
        padding: 10px;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: opacity 0.2s ease-in-out;
        z-index: 0;
        width: 100%;

        @media #{$sp} {
          & + li {
            margin-top: 15px;
          }
        }

        &:hover {
          color: $white-color;
          background-color: #171717;
          cursor: pointer;
        }
      }

      .name,
      .address {
        display: block;
        line-height: 1.5;
      }

      // .name {
      //   font-size: 16px;
      //   font-weight: 500;
      // }

      // .address {
      //   font-size: 12px;
      //   font-weight: 400;
      // }
    }
  }

  .input-group-address {
    margin-top: 1rem;
  }

  .order-cancel-edit {
    margin-top: 1rem;
    text-align: center;

    & > a {
      @include btn();
    }
  }
}