/*
  ----------------
  .view-order | 未発送の注文
  ----------------
*/
.view-orders {

  .order-list {
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }

    li {
      border: 1px solid $form-border-color;
      border-bottom: none;

      & + li {
        margin-top: 40px;
      }

      .request-paymentmethod-reset {
        border-bottom: 1px solid lightgray;
        text-align: center;

        & > h3 {
          background-color: #f2f2f2;
          border-bottom: 1px solid lightgray;
          color: #171717;
          display: block;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0.08em;
          line-height: 2.142;
          padding: 10px;
          text-align: center;
          width: 100%;
        }

        .order-reset-paymentmethod-form {
          padding: 30px;

          @media #{$sp} {
            padding: 30px 15px;
          }

          .btn {
            max-width: 300px;
          }

          .description {
            margin-top: 20px;
          }
        }
      }
    }

    li:not(:first-child) {
      border-top: 1px solid $form-border-color;
    }

    li:last-child {
      .itemdetail-row {

        @media all and (min-width: 600px) and (max-width: 850px) {
          padding-bottom: 20px;
        }

        @media #{$sp} {
          padding-bottom: 20px;
        }
      }
    }
  }


  /*================================================
    .panel-heading | 受注番号〜注文ステータス
  ================================================*/
  .panel-heading {

    .order-code {

      > div {

        display: flex;

        @media all and (min-width: 600px) and (max-width: 850px) {
          justify-content: center;
        }

        @media #{$sp} {
          justify-content: center;
        }
      }

      .order-code {
        border-bottom: none;
      }

      .detail {
        margin-left: 10px;

        a {
          text-decoration: underline;
        }
      }
    }
  }


  /*================================================
    .panel-body | 出荷受付状況〜商品詳細(画像、金額など)
  ================================================*/
  .panel-body {

    .panel-heading {

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {}
    }

    .shipment-code {
      background-color: $title-bg-color;
      border-right: 1px solid $form-border-color;
      text-align: center;
      padding: 10px;

      @media #{$pc} {
        flex: 0 0 260px;

        display: flex;
        justify-content: center;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        border-right: 0;
      }

      @media #{$sp} {
        border-right: 0;
      }
    }

    .shipment-status {
      padding: 10px;

      @media #{$pc} {
        flex: 0 1 100%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
      }

      @media #{$sp} {
        text-align: center;
      }
    }

    // .itemdetail-row {

    //   &:last-child {
    //     border-bottom: 0;
    //   }
    // }

    .goods {
      flex-basis: 100%;
    }
  }
}
