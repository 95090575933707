/*
  ----------------
  ラメリアストーリー | story-detail
  ----------------
*/
.news-archive-story,
.news-archive-concept {

  // Default part
  #page-title,
  .news-entry-icon,
  .news-entry-header,
  .news-images,
  .trailer-link {
    display: none;
  }
}

.story-detail {

  &-main {
    @include container;
    overflow: hidden;
    padding: 80px 80px 100px;

    @media all and (min-width: 600px) and (max-width: 850px) {
      padding: 80px 40px 100px;
    }

    @media #{$sp} {
      padding: 50px 30px 106px;
    }

    h2 {
      font-size: 40px;
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 1.5;

      display: flex;
      align-items: center;

      @media #{$sp} {
        font-size: 25px;
        letter-spacing: 0;
        line-height: 2;
        margin-right: -15px;
        margin-left: -15px;

        flex-direction: column;
        text-align: center;
      }

      & > span {
        border: 1px solid #171717;
        display: block;
        font-size: 50px;
        font-weight: normal;
        margin-right: 67px;
        height: 172px;
        width: 172px;

        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$sp} {
          font-size: 30px;
          letter-spacing: 0.08em;
          margin-right: 0;
          margin-bottom: 28px;
          height: 80px;
          width: 80px;
        }
      }
    }

    img {
      margin-top: 65px;
      max-width: 100%;
      width: auto;

      @media #{$sp} {
        margin-top: 44px;
      }

      &.left,
      &.right {

        @media #{$pc} {
          float: left;
          margin-top: 27px;
          margin-right: 75px;
        }

        & + p {
          margin-top: 18px;

          @media #{$sp} {
            margin-top: 28px;
          }
        }
      }

      &.right {

        @media #{$pc} {
          float: right;
          margin-right: auto;
          margin-left: 75px;
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 2.8571;
      margin-top: 48px;

      @media #{$sp} {
        margin-top: 48px;
      }
    }
  }

  &-pager {

    &__list {
      display: flex;

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {
        display: block;
      }
    }

    &__prev,
    &__next {
      overflow: hidden;
      position: relative;

      @media #{$pc} {
        width: 50%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        width: 100%;
      }

      &:before {
        background: url(/assets/img/newslist/story-detail/bg_pager_prev.jpg) no-repeat center center;
        background-size: cover;
        content: "";
        display: block;
        height: 100%;
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
        transition: all .3s ease-out;
      }

      &:hover:before {
        transform: scale(1.2);
      }
    }

    &__next {
      &:before {
        background: url(/assets/img/newslist/story-detail/bg_pager_next.jpg) no-repeat center center;
        background-size: cover;
      }

      .story-detail-pager__link {
        padding-left: 80px;

        @media all and (min-width: 600px) and (max-width: 850px) {
          padding-left: 46px;
        }

        @media #{$sp} {
          padding-left: 46px;
        }

        &::before {
          content: "Next Story";

          position: absolute;
          top: 72px;
          right: 19px;
          left: auto;
        }

        &::after {
          background: url(/assets/img/newslist/story-detail/icon_pager_next.svg) no-repeat center center;
          background-size: cover;

          position: absolute;
          top: 111px;
          right: 40px;
          left: auto;

          @media #{$sp} {
            top: 63px;
            right: 23px;
          }
        }
      }
    }

    &__disabled {
      pointer-events: none;

      &::after {
        background-color: rgba(0,0,0,0.5);
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &__link {
      font-size: 30px;
      font-weight: normal;
      height: 250px;
      letter-spacing: 0.08em;
      line-height: 1.6667;
      padding-left: 125px;
      position: relative;

      display: flex;
      align-items: center;

      @media #{$sp} {
        font-size: 17px;
        height: 143px;
        line-height: 1.6471;
        padding-left: 71px;
      }

      &::before {
        content: "Prev Story";
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.08em;

        position: absolute;
        top: 72px;
        left: 27px;

        @media #{$sp} {
          display: none;
        }
      }

      &::after {
        background: url(/assets/img/newslist/story-detail/icon_pager_prev.svg) no-repeat center center;
        background-size: cover;
        content: "";
        display: block;
        height: 29px;
        width: 29px;

        position: absolute;
        top: 111px;
        left: 44px;

        @media #{$sp} {
          height: 17px;
          width: 17px;

          top: 63px;
          left: 25px;
        }
      }
    }
  }

  &-links {
    @include container;
    padding-top: 74px;
    padding-bottom: 142px;

    display: flex;

    @media all and (min-width: 600px) and (max-width: 850px) {
      display: block;
    }

    @media #{$sp} {
      padding-top: 46px;
      padding-bottom: 78px;
      display: block;
    }

    &__title {
      font-size: 25px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 1.6;
      margin-right: 107px;
      margin-right: 91px;

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-right: 0;
        text-align: center;
      }

      @media #{$sp} {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.08em;
        line-height: 2;
        margin-right: 0;
        text-align: center;
      }
    }

    &__list {
      margin-top: 8px;

      &:nth-of-type(1) {
        margin-right: auto;

        @media all and (min-width: 600px) and (max-width: 850px) {
          margin-top: 25px;
          margin-right: 0;
        }

        @media #{$sp} {
          margin-top: 25px;
          margin-right: 0;
        }
      }

      // column-count: 2;
      // flex: 1;
    }

    &__item {
      list-style: none;
      margin-bottom: 16px;
      position: relative;

      @media #{$sp} {
        margin-bottom: 10px;
      }

      // &:nth-child(1),
      // &:nth-child(2),
      // &:nth-child(3),
      // &:nth-child(4) {
      //   margin-right: 10px;
      // }
    }

    &__link {
      border: 1px solid transparent;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 2;
      padding: 0px 22px 0 13px;

      @media #{$sp} {
        font-size: 13px;
        padding: 0px 22px 0 13px;
      }

      &:hover {
        border: 1px solid #707070;
        box-sizing: border-box;
      }
    }
  }
}