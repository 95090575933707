/*
  ----------------
  お客さまインタビュー詳細 | 記事ページ
  ----------------
*/
.news-archive-voice {

  // Default part
  #page-title,
  .news-entry-icon,
  .news-entry-header,
  .news-images,
  .trailer-link {
    display: none;
  }

  @include container;
  padding: 0;

  @media #{$sp} {
    padding: 0;
  }


  .article-block+.article-block {
    margin-top: 80px;

    @media #{$sp} {
      margin-top: 40px;
    }
  }

  .voice-interview-lead {
    position: relative;

    img {
      @media #{$sp} {
        aspect-ratio: 39 / 20;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    div {
      position: absolute;
      top: 48%;
      left: 17.7%;
      transform: translateY(-50%);

      @media #{$sp} {
        top: 31%;
        left: 10%;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.777;
      letter-spacing: 0;
    }

    h2 {
      font-size: 30px;
      font-weight: 400;
      line-height: 1.333;
      margin-top: 5px;

      @media #{$sp} {
        font-size: 20px;
        font-family: YuMincho;
        line-height: 1.5;
        letter-spacing: 0.08em;
        margin: 0;
      }
    }
  }

  .interviwe-body {
    padding: 74px 80px 90px;

    @media all and (max-width : 900px) {
      padding: 74px 40px 90px;
    }

    @media #{$sp} {
      padding: 0 30px 40px;
    }

    h2 {
      font-size: 30px;
      font-weight: 400;
      line-height: 1.666;
      letter-spacing: 0;
    }

    h3 {
      font-size: 30px;
      font-weight: 400;
      line-height: 0.8;
      letter-spacing: 0;
      text-align: center;

      @media #{$sp} {
        font-size: 18px;
        font-weight: 500;
        line-height: 2.222;
        letter-spacing: 0.08em;
      }
    }

    h4 {
      font-size: 25px;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0;

      @media all and (max-width : 900px) {
        font-size: 20px;
      }

      @media #{$sp} {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.571;
        letter-spacing: 0;
      }
    }

    p {
      font-weight: 400;
      line-height: 2.9;
      letter-spacing: 0;

      @media #{$sp} {
        line-height: 2.142;
      }
    }

    h2+p {
      margin-top: 40px;
    }

    p+p {
      margin-top: 40px;

      @media #{$sp} {
        margin-top: 30px;
      }
    }

  }

  .article-block {
    display: flex;
    align-items: flex-start;
    gap: 0 9%;

    @media all and (max-width : 900px) {
      gap: 0 6%;
    }

    @media #{$sp} {
      flex-direction: column;
    }

    &.message,
    &.favorite {
      display: block;
    }

    img {
      width: 32%;

      @media #{$pc} {
        max-width: 380px;
      }

      @media #{$sp} {
        width: 100%;
      }
    }
  }

  .interviwe-body .lead {
    margin-top: -21%;
    min-height: 360px;

    display: flex;
    align-items: flex-end;

    @media #{$pc} {
      gap: 0;
    }

    @media all and (max-width : 750px) {
      margin-top: -15%;
    }

    @media #{$sp} {
      align-items: flex-start;
      margin-top: -28%;
    }

    span {
      letter-spacing: 0;
      margin-top: 15px;
    }

    h3 {
      font-size: 20px;
      line-height: 1.15;
      letter-spacing: 0.08em;
    }

    img {
      filter: drop-shadow(0px 33px 76px rgba(0, 0, 0, 0.18));
      margin-left: -10px;
      width: 18%;

      @media #{$pc} {
        max-width: 216px;
      }

      @media all and (max-width : 900px) {
        min-width: 140px;
      }

      @media #{$sp} {
        min-width: 96px;
        margin-left: -15px;
      }
    }

    p {
      line-height: 2.9;
      letter-spacing: 0;
      padding-left: 70px;

      @media all and (max-width : 900px) {
        padding-left: 35px;
      }

      @media #{$sp} {
        line-height: 2.142;
        margin-top: 37px;
        padding-left: 0;
      }
    }
  }

  .profile {
    border: 1px solid #ddd;
    display: block;
    padding: 40px;

    @media #{$sp} {
      margin-top: 34px;
      padding: 20px;
    }

    span {
      display: block;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;

      @media #{$sp} {
        font-size: 16px;
      }
    }

    h3 {
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 0.08em;
      line-height: 1.333;
      margin-top: 5px;
      text-align: left;

      @media #{$sp} {
        font-size: 20px;
      }
    }

    p {
      line-height: 2.9;
      letter-spacing: 0;
      margin-top: 34px;

      @media #{$sp} {
        line-height: 2.142;
      }
    }
  }

  .interviwe-body .title,
  .interviwe-body .left-box,
  .interviwe-body .right-box,
  .interviwe-body .center-box {
    display: flex;
    align-items: flex-start;
    gap: 0 8%;

    @media all and (max-width : 900px) {
      gap: 0 6%;
    }

    @media #{$sp} {
      margin-top: 34px;
    }

    h2 {
      @media #{$sp} {
        // display: none;
        font-size: 20px;
        margin-top: 34px;
      }
    }

    p {
      margin-top: 40px;

      @media #{$sp} {
        margin-top: 34px;
      }
    }

    img {
      max-width: 556px;
      width: 47%;

      @media #{$sp} {
        width: 100%;
      }
    }
  }

  .interviwe-body .right-box {
    @media #{$pc} {
      flex-direction: row-reverse;
    }
  }

  .interviwe-body .center-box {
    display: block;

    h2 {
      margin-top: 40px;
      text-align: center;

      @media #{$sp} {
        margin-top: 34px;
      }
    }

    img {
      max-width: none;
      width: 100%;
    }
  }

  .interviwe-body .right-set {
    flex-direction: row-reverse;

    @media #{$sp} {
      flex-direction: column;
      margin-top: 47px;

      img+div {
        margin-top: 40px;
      }
    }
  }

  .interviwe-body .message {
    background-color: $main-color;
    margin-top: 90px;
    padding: 75px 84px 65px;

    @media all and (max-width : 900px) {
      padding: 35px 40px 35px;
    }

    @media #{$sp} {
      margin-top: 70px;
      padding: 42px 30px 52px;
    }

    h3 {
      @media #{$sp} {
        letter-spacing: 0;
      }
    }

    p {
      margin-top: 45px;

      @media all and (max-width : 900px) {
        margin-top: 20px;
      }

      @media #{$sp} {
        margin-top: 18px;
      }
    }
  }

  .interviwe-body .favorite {
    @media #{$sp} {
      margin-top: 70px;
    }

    ul {
      margin-top: 55px;

      display: flex;
      flex-wrap: wrap;
      gap: 20px 26px;

      @media #{$sp} {
        display: block;
        margin: 20px auto 0;
        max-width: 400px;
      }
    }

    li {
      background-size: cover;
      background-position: right 50%;
      height: 220px;
      padding: 30px 47px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: calc((100% - 26px) / 2);

      @media all and (max-width : 900px) {
        height: 185px;
        padding: 15px 25px;
      }

      @media #{$sp} {
        height: 125px;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }

    a {
      @include btn;
      margin-top: 15px;

      @media #{$sp} {
        font-size: 12px;
        margin-top: 10px;
        padding: 3px 10px;

        max-width: 150px;
        width: 44%;
      }
    }
  }
}