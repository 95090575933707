/*
  ----------------
  Button hover styles
  ----------------
*/
@mixin hoverBtn($type: none) {
  @media (hover: hover) and (pointer: fine) {

    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease-in-out;
    }
  }

  @if $type==brown {

    &::after {
      background: linear-gradient(to left, #d35900 0%, #c10000 100%);
      content: "";
      display: block;
      transition: width 0.2s ease-in-out;
      z-index: -1;

      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
    }

    &:hover {
      border-image: linear-gradient(to left, #d35900 0%, #c10000 100%);
      border-image-slice: 1;
      color: $white-color;
      opacity: 1;
    }

    &:hover::before {
      border-top: 1px solid $white-color;
      border-right: 1px solid $white-color;
    }

    &:hover::after {
      transition: width 0.2s ease-in-out;
      width: 100%;
    }
  }

  @if $type==white {

    &::after {
      background-color: $white-color;
      content: "";
      display: block;
      transition: width 0.2s ease-in-out;
      z-index: -1;

      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
    }

    &:hover {
      color: $black-color;
      opacity: 1;
    }

    &:hover::before {
      border-top: 1px solid $black-color;
      border-right: 1px solid $black-color;
    }

    &:hover::after {
      transition: width 0.2s ease-in-out;
      width: 100%;
    }
  }

  @else if $type==brownBg {
    transition: background-color 0.2s ease-in-out;

    &::after {
      background: linear-gradient(to left, #c10000 0%, #d35900 100%);
      z-index: -2;
    }


    &:hover {
      background-image: none;
      opacity: 1;
      transition: background-color 0.2s ease-in-out;
    }

    &:hover::before {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }
  }

  @else if $type == blackBg {
    &:hover {
      background-color: $black-color;
      border-radius: 5px;
      color: $white-color;
      opacity: 1;
      transition: background-color 0.2s ease-in-out;
    }
  }
}
