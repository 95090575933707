/*
  ----------------
  tokusho | 特定商取引に関する法律に基づく表記
  ----------------
*/
.order-info {

  &__wrap {
    @include container;
    padding-top: 110px;
    padding-bottom: 160px;

    @media #{$sp} {
      padding-top: 61px;
      padding-bottom: 80px;
    }
  }

  &__title {
    border-bottom: 2px solid $black-color;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.08em;
    margin-top: 80px;
    padding-left: 5px;

    @media #{$sp} {
      font-size: 18px;
      letter-spacing: 0;
      margin-top: 40px;
      padding-left: 0;
      text-align: center;
    }
  }

  &__content {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;

    @media #{$sp} {
      padding: 30px 0 0;
    }
  }

  table {
    border-collapse: collapse;
    border-top: 1px solid $light-gray-color;
    border-left: 1px solid $light-gray-color;
    margin: 0 auto;
    width: 100%;
  }

  th,
  td {
    padding: 8px;
    border-bottom: 1px solid $light-gray-color;
    border-right: 1px solid $light-gray-color;

    @media #{$sp} {
      display: inline-block;
      width: 100%;
    }
  }

  th {
    background-color: $secondar-color;

    @media #{$pc} {
      min-width: 155px;
      width: 20%;
    }

    @media #{$sp} {
      padding: 4px;
    }
  }

  td {
    @media #{$pc} {
      width: 80%;
    }
  }
}