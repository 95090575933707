/*
  ----------------
  nav menu | Common
  ----------------
*/
.common-menu {
  background-color: $black-color;
  color: #fff;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  transition: right .2s ease-in-out;

  .header-main & {
    position: fixed;
    top: 60px;
    right: -105vw;
    height: calc(100vh - 60px);
    width: calc(100vw + 4px);
  }

  .sidebar-is-open & {
    right: -2px;
  }

  &__wrap {
    padding: 40px 36px;

    .footer & {
      padding: 50px 24px 44px;

      @media #{$sp} {
        padding-bottom: 73px;
      }
    }

    @media #{$pc} {
      padding: 50px 40px 44px;
    }

    @media all and (max-width : 930px) and (min-width : 600px) {
      padding: 50px 24px 44px;
    }
  }

  &__main {
    @media #{$pc} {
      max-width: 878px;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;
    }
  }

  &__list {
    @media #{$pc} {
      width: calc((100% - 132px) / 3);

      &:not(:first-child) {
        margin-left: 24px;
      }
    }

    @media all and (max-width : 930px) and (min-width : 600px) {
      width: calc((100% - 48px) / 3);
    }
  }

  .footer &__list--last {
    @media #{$sp} {
      border-bottom: 1px solid #707070;
    }
  }

  &__item {
    @media #{$pc} {
      &:first-child .common-menu__link,
      &:first-child .common-menu__title {
        border-top: none;
      }
    }
  }

  &__link {
    font-size: 16px;
    line-height: 1.875;
    letter-spacing: 0;
    padding: 12px 0 10px;
    width: 100%;


    @media #{$sp} {
      border-top: 1px solid #707070;

      .footer & {
        padding: 12px 0 13px;
      }
    }

    @media #{$pc} {
      border-bottom: 1px solid #707070;
      font-size: 14px;
      font-weight: 400;
      line-height: 2.142;
      padding: 7px 0 10px;

      .common-menu__list--last & {
        padding: 10px 0;
      }
    }
  }

  &__title {
    font-size: 16px;
    line-height: 1.875;
    letter-spacing: 0;
    padding: 12px 30px 10px 0;
    position: relative;

    @media #{$sp} {
      border-top: 1px solid #707070;
      cursor: pointer;

      &::before,
      &::after {
        background-color: $white-color;
        content: "";
        display: block;
        position: absolute;
        transition: transform .2s ease-in-out;

        height: 2px;
        width: 20px;

        top: 53%;
        right: 15px;
        transform: translateY(-50%) rotate(0);
      }

      &::before {
        transform: translateY(-50%) rotate(90deg);
      }

      &.accordion-is-open {
        border-bottom: 1px solid #707070;

        &::before {
          transform: translateY(-50%) rotate(0);
        }
      }
    }

    @media #{$pc} {
      border-bottom: 1px solid #707070;
      font-size: 14px;
      font-weight: 400;
      line-height: 2.142;
      padding: 9px 30px 8px 0;
    }

  }

  &__inner-list {
    padding: 10px 0 15px;


    @media #{$sp} {
      display: none;

      .footer & {
        padding: 10px 0 27px;
      }
    }

    @media #{$pc} {
      &.common-menu__inner-list--column {
        padding: 10px 0 5px;
      }
    }
  }

  &__inner-item {
    position: relative;

    &::before {
      display: block;
      content: "・";
      font-size: 13px;

      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }

    .common-menu__item--product &::before {
      content: "-";
    }

    @media all and (max-width : 930px) and (min-width : 600px) {
      .common-menu__item--product & {
        margin-top: 10px;
      }

      .common-menu__item--product &::before {
        top: 13px;
      }
    }
  }

  &__inner-link {
    font-size: 13px;
    line-height: 2.615;
    letter-spacing: 0;
    padding-left: 14px;

    .footer & {
      line-height: 2.3;
    }

    @media #{$pc} {
      font-weight: 400;
      line-height: 2.3;
    }

    .common-menu__item--product & {
      padding-left: 8px;

      @media #{$pc} {
        line-height: 1.4;
      }
    }
  }
}