/*
  ----------------
  Button styles | 矢印なし
  ----------------
*/
// ---------------------------------------
// Gradient color
@mixin gradient($type: none) {
  border: 1px solid transparent;
  border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
  color: $brown-text-color;

  // Return button
  @if $type==return {
    border-image: linear-gradient(to right, #cc8600 0%, #ba0000 100%);
  }

  // With linear-gradient
  border-image-slice: 1;
}

@mixin btn($type: none) {
  // ---------------------------------------
  // Common Style

  border: 1px solid $black-color;
  color: $black-color;
  display: inline-block;
  letter-spacing: .08em;
  padding: 9px 10px 9px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 0;

  max-width: 220px;
  width: 100%;

  // ---------------------------------------
  // Hover | Default style

  @media (hover: hover) and (pointer: fine) {

    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease-in-out;
    }
  }

  // ---------------------------------------
  // Brown background

  @if $type==brownBg {
    @include hoverBtn(brownBg);

    border-image: none;
    border: none;
    color: $white-color;
    padding: 15px 10px 15px 12px;

    &::before,
    &::after {
      content: "";
      display: block;
      transition: opacity 0.2s ease-in-out;

      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    &::before {
      background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
      z-index: -1;
    }
  }

  // ---------------------------------------
  // Brown button | gradient border

  @else if $type==brown {
    // gradient color
    border: 1px solid transparent;
    border-image: linear-gradient(to left, #cc8600 0%, #ba0000 100%);
    border-image-slice: 1;
    color: $brown-text-color;
  }

  // ---------------------------------------
  // Delete button
  // 未定

  @else if $type == delete {

  }

  // ---------------------------------------
  // Large button | gradient border

  @else if $type==large {
    @include gradient;

    max-width: 100%;
  }

  // ---------------------------------------
  // Small button
  // 未定
  @else if $type == sm {
    padding: 14px 5px;
  }

  // ---------------------------------------
  // Brown button | with cart icon | used on item page
  //

  @else if $type == brownBgCart {
    @include btn(brownBg);

    .icon-cart {
      padding-left: 23.83px;

      position: relative;

      @media #{$sp} {
        padding-left: 25.03px;
      }

      &::before {
        content: "";
        display: block;
        background-image: url(/assets/img/item/icon_cart.svg);
        width: 17.13px;
        height: 18.15px;

        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);

        @media #{$sp} {
          top: 51%;
          left: -1px;
        }
      }
    }
  }

  // ---------------------------------------
  // animation with a gradient background | used inquiry on item page
  //

  @else if $type == gradientBg {
    @include gradient;
    @include hoverBtn(brown);
  }
}
