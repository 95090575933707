/*
  ----------------
  .cart-view | ショッピングカート
  ----------------
*/

.cart-view {
  padding: 110px 80px 100px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;

  @media #{$sp} {
    padding: 60px 30px 80px;
  }

  // 商品コード&メーカー非表示
  .goods-text-heading .item-name .code,
  .goods-text-heading .manufacturer .makers {
    display: none;
  }

  .goods-text-heading .item-tag span {
    background-color: #9f9f9f;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
    letter-spacing: 0;
    padding: 0px 14px 1px 15px;

    @media #{$sp} {
      font-size: 12px;
      line-height: 1;
      padding: 4px 8px;
    }
  }

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  .cart-contents-wrapper {
    padding-top: 60px;

    @media all and (max-width : 850px) {
      padding-top: 25px;
    }
  }

  h3 {
    font-weight: 400;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 18px;
    }
  }

  h3.itemdetails-caption {
    border-bottom: 1px solid $black-color;
    padding-bottom: 10px;
    text-align: left;

    @media all and (max-width : 850px) {
      margin-top: 25px;
      padding-bottom: 5px;
    }
  }

  .alert-cart-empty {
    border: 1px solid #171717;
    border-radius: 8px;
    margin: 40px auto 0;
    max-width: 650px;
    padding: 15px;
    text-align: center;
    width: 100%;
  }

  .itemdetails-header {
    margin-top: 20px;
  }

  .itemdetails-cart {
    margin-top: 50px;

    @media all and (max-width : 850px) {
      margin-top: 0;
    }
  }

  .cart-msgs {
    color: #ba0000;
  }

  .remove-cartitem,
  .save-cartitem {
    text-decoration: underline;

    @media #{$sp} {
      margin-top: 20px;
    }
  }

  .cart-checkout {
    text-align: center;
    margin-top: 10px;
  }

  // 注文に進む
  .cart-checkout .btn {
    @include btn(brownBg);
    margin-top: 60px;
  }

  // 注文に進む disabled
  .cart-checkout .btn:disabled {
    cursor: default;
    opacity: .5;
    animation: none;

    &:hover {
      transition: none;
    }

    &::before {
      transition: none;
    }

    &::after {
      background: linear-gradient(to right, #9b0000 0%, #ad4900 100%);
      transition: none;
    }

    &::after:hover {
      opacity: none;
      transition: none;
    }
  }

  // 注文に進む下 エラーメッセージ
  .error {
    margin-top: 10px;

    .message {
      color: #ba0000;
    }
  }

  // カートを空にする
  .cart-clear-all {
    margin-top: 30px;
    text-align: center;
  }

  .rp-conf {
    @media #{$pc} {
      margin-left: 130px;
    }
  }

  // 発送日プルダウン
  .rp-opts {
    background: #f2f2f2;
    margin: 10px 0;
    padding: 10px 15px;

    display: flex;
    align-items: center;

    @media #{$sp} {
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .rp-shipping-day {
    margin-left: 20px;
    flex: 1;

    // max-width: 150px;
    // width: 100%;
  }

  .rp-shipping-day select {
    @include hoverLink;
    color: $black-color;
  }

  .rp-batch {
    h4 {
      background-color: #f2f2f2;
      margin-bottom: 10px;
      padding: 10px;
      text-align: center;
    }

    .rp-conf-wrapper {
      @media #{$pc} {
        width: 50%;
      }

      .rp-conf {
        margin-left: 0;
      }
    }
  }
}
