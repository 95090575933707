/*
  ----------------
  Header common
  Break point 追加: 1125px
  ----------------
*/

.header-main {
  background-color: rgba(255, 255, 255, .6);
  transition: background-color .2s ease-in-out;
  width: 100%;
  z-index: 110;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;

  // Default reset
  .contents-wrapper {
    width: 100%;
  }

  .sidebar-is-open & {
    background-color: $black-color;
    transition: background-color .2s ease-in-out;
  }

  &__main {
    height: 60px;
    padding-left: 21px;

    display: flex;
    justify-content: space-between;

    @media all and (max-width : 1125px) and (min-width : 600px) {
      padding-left: 10px;
    }

    @media #{$sp} {
      border-bottom: 1px solid $white-color;
    }
  }

  &__logo-link {
    display: flex;
    align-items: center;

    @media all and (max-width : 1125px) and (min-width : 600px) {
      max-width: 100px;
    }

    @media #{$sp} {
      max-width: 151px;
    }
  }

  &__logo {
    @media all and (max-width : 1125px) and (min-width : 600px) {
      display: flex;
    }
  }

  &__logo-parts {
    .sidebar-is-open & {
      fill: #fff;
    }
  }

  &__box {
    display: flex;
  }
}