/*
  ----------------
  Itemdetails & price-total | カート内アイテム & 合計金額
  ----------------
*/

.itemdetails {

  .itemdetails-header {
    background-color: $title-bg-color;
    font-weight: 500;

    @media all and (max-width : 850px) {
      display: none;
    }
  }

  .itemdetails-body {
    display: flex;
    flex-wrap: wrap;

    .view-order &,
    .view-orders &,
    .order-journal-history &,
    .rp-entry & {
      display: block;
    }
  }

  .itemdetail-row {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-basis: 100%;
    justify-content: space-between;

    padding: 10px 0;

    &:not(:first-child) {
      border-top: 1px solid $light-gray-color;
    }

    @media all and (max-width : 850px) {
      display: block;
      padding: 20px 0;
    }

    .view-order &,
    .view-orders &,
    .order-journal-history & {
      border-bottom: 1px solid $form-border-color;
      padding: 30px 0;

      @media #{$pc} {
        display: flex;
        align-items: center;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
        padding: 30px 20px;
      }

      @media #{$sp} {
        padding: 30px 20px;
      }

      &:not(:first-child) {
        border-top: 0;
      }
    }
  }

  .item {
    display: inline;
    text-align: center;
    width: 50%;

    @media all and (max-width : 850px) {
      display: block;
      text-align: left;
      width: 100%;
    }

    .view-order &,
    .view-orders &,
    .order-journal-history &,
    .rp-entry & {

      flex-basis: 100%;
    }

    .control {
      margin-top: 10px;
    }

    .remove-item,
    .save-item {
      text-align: right;
    }
  }

  .goods {
    display: flex;
    align-items: center;

    .view-order &,
    .view-orders &,
    .order-journal-history &,
    .rp-entry & {
      text-align: center;

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {
        display: block;
      }
    }
  }

  .entry-item {
    display: flex;
    align-items: center;
  }

  .purchase-contents {
    display: flex;
    align-items: center;
    flex-basis: 50%;

    @media all and (max-width : 850px) {
      display: block;
      margin-top: 20px;
    }

    .view-order &,
    .view-orders &,
    .order-journal-history & {
      color: $bronw-color;
      flex: 0 0 150px;

      display: flex;
      justify-content: flex-end;

      @media #{$pc} {
        padding: 0 20px 0 10px;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        margin-top: 20px;

        justify-content: flex-end;
      }

      @media #{$sp} {
        margin-top: 20px;

        justify-content: flex-end;
      }
    }
  }

  .quantity,
  .subtotal {
    flex-basis: 50%;
    text-align: center;

    @media all and (max-width : 850px) {
      text-align: right;
    }

    .view-order &,
    .view-orders &,
    .order-journal-history &,
    .rp-entry &,
    .rp-future-entries & {
      flex-basis: auto;
    }
  }

  .subtotal {

    .view-order &,
    .view-orders &,
    .order-journal-history &,
    .rp-entry & {
      margin-left: 20px;

      @media #{$sp} {
        margin-left: 15px;
      }
    }
  }

  .item-thumbnail-img-wrapper {
    max-width: 100px;
    width: 100%;
  }

  .item-thumbnail-img {
    text-align: center;
  }

  .goods-text {
    margin-left: 30px;
    text-align: left;

    @media all and (max-width : 850px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.7;
    }

    .view-order &,
    .view-orders &,
    .order-journal-history &,
    .rp-entry &,
    .rp-future-entries & {
      display: inline-block;
      margin-left: 10px;

      @media #{$pc} {
        flex: 0 1 100%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        font-size: 14px;
        margin-top: 10px;
        max-width: 400px;
      }

      @media #{$sp} {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }

  .goods-text-heading {

    // 商品コード&メーカー非表示
    .item-name .code,
    .manufacturer .makers {
      display: none;
    }

    .item-tag span {
      background-color: #9f9f9f;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      color: #fff;
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.84615385;
      letter-spacing: 0;
      padding: 0px 14px 1px 15px;

      @media #{$sp} {
        font-size: 12px;
        line-height: 1;
        padding: 4px 8px;
      }
    }

    .view-order &,
    .view-orders &,
    .order-journal-history &,
    .rp-entry & {

      //商品コード非表示
      .code {
        display: none;
      }
    }
  }

  .stock-message {
    @media all and (max-width : 850px) {
      font-size: 14px;
    }
  }

  .item-name {

    a {
      @include hoverLink();
      text-decoration: underline;

      @media all and (max-width : 850px) {
        display: block;
      }
    }

    //商品コード非表示
    .code {
      display: none!important;
    }
  }

  .item-options {
    padding-left: 20px;
  }

  .item-option,
  .itemoptions {
    .item-option-name {
      display: inline-block;
      padding-right: 10px;
      width: 110px;

      @media all and (max-width : 850px) {
        width: auto;
      }
    }
  }

  .update-itemoptions {
    @include hoverLink;
    text-decoration: underline;
  }

  .unit-price {
    margin-top: 10px;

    .price:before {
      @media all and (max-width : 850px) {
        content: "金額：";
      }
    }
  }

  .clickable {
    cursor: pointer;
    font-size: 15px;
    text-decoration: underline;
    transition: all 0.2s;

    &:hover {
      opacity: 0.7;
    }

    @media all and (max-width : 850px) {
      font-size: 14px;
    }
  }

  .show-update-qty {
    margin-top: 10px;
    text-align: center;

    @media all and (max-width : 850px) {
      display: inline;
      text-align: left;
    }
  }
}

.rp-batch {
  padding: 10px 0;

  @media #{$sp} {
    padding: 20px 0;
  }
}

.price-total {
  .row {
    background-color: $title-bg-color;
    border-top: 1px solid $form-border-color;
    border-bottom: 1px solid $form-border-color;
    font-weight: 500;
    padding: 20px 0;

    align-items: center;
    display: flex;
    justify-content: flex-end;

    @media all and (max-width : 850px) {
      justify-content: center;
      padding: 20px 5px;
    }

    .rp-entry &,
    .rp-future-entries & {
      background-color: $white-color;
      border-top: 0;
      border-bottom: 0;
    }

    .label {
      @media all and (max-width : 850px) {
        flex-basis: 30%;
      }

      .rp-entry &,
      .rp-future-entries & {
        flex-basis: auto;
      }
    }

    .qty {
      @media all and (max-width : 850px) {
        flex-basis: 30%;
      }

      .rp-entry &,
      .rp-future-entries & {
        flex-basis: auto;
      }
    }
  }

  .subtotal {
    margin-left: 40px;

    @media all and (max-width : 850px) {
      margin: 0;
    }
  }
}
