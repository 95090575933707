/*
  ----------------
  Breadcrumb | パンくず
  ----------------
*/

.breadcrumbs,
.category-breadcrumbs,
.page-breadcrumbs {
  max-width: 1150px;
  margin: 20px auto 0;

  @media #{$sp} {
    margin: 0;
    padding-top: 10px;

    display: flex;
    flex-wrap: wrap;
  }

  ul {
    padding-left: 0;
    position: relative;

    display: flex;
    flex-wrap: wrap;

    &::before {
      position: absolute;
      display: block;
      content: "";

      top: 45%;
      left: 0px;
      width: 18px;
      height: 13px;
      transform: translateY(-50%);

      @media #{$sp} {
        top: 42%;
        left: 2px;
      }
    }
  }

  li {
    padding-right: 1rem;
    position: relative;

    &:not(:first-child) {
      margin-left: 0px;
      padding-left: 10px;

      @media #{$sp} {
        margin-left: 0;
        padding-left: 14px;
      }

      &::before {
        content: '>';
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  li,
  li a {
    @media #{$sp} {
      font-size: 11px;
    }
  }
}
.breadcrumbs + .breadcrumbs {
  display: none;
}
