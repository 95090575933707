/*
  ---------------
  .order-process-main-payment-status | お支払いの状態
  ---------------
*/
.order-process-main-payment-status {

  .wrapper {
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }
  }

  .order {
    border: 1px solid $form-border-color;
    padding-bottom: 60px;

    @media all and (min-width: 600px) and (max-width: 850px) {
      padding-bottom: 30px;
    }

    .request-paymentmethod-reset {
      border-bottom: 1px solid lightgray;
      text-align: center;

      & > h3 {
        background-color: #f2f2f2;
        border-bottom: 1px solid lightgray;
        color: #171717;
        display: block;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.08em;
        line-height: 2.142;
        padding: 10px;
        text-align: center;
        width: 100%;
      }

      .order-reset-paymentmethod-form {
        padding: 30px;

        @media #{$sp} {
          padding: 30px 15px;
        }

        .btn {
          max-width: 300px;
        }

        .description {
          margin-top: 20px;
        }
      }
    }
  }

  .panel-heading {
    border-top: 1px solid lightgray;

    > div {
      border-right: 1px solid $form-border-color;
      border-left: 1px solid $form-border-color;

      @media #{$sp} {
        > div {
          border-top: 1px solid $form-border-color;
        }
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .panel-body {
    div.status {
      border-bottom: 1px solid $form-border-color;

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {}

      > div {
        display: block;
        padding: 10px;

        @media #{$pc} {
          flex: 0 1 100%;
        }

        @media all and (min-width: 600px) and (max-width: 850px) {
          text-align: center;
        }

        @media #{$sp} {
          text-align: center;
        }
      }

      .label {
        background-color: $title-bg-color;
        border-left: 1px solid $form-border-color;
        flex: 0 0 260px;
        text-align: center;
        padding: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        @media all and (min-width: 600px) and (max-width: 850px) {
          border-right: 0;
        }

        @media #{$sp} {
          border-right: 1px solid $form-border-color;
          border-bottom: 1px solid $form-border-color;
        }
      }

      .status-text {
        border-left: 1px solid $form-border-color;
        border-right: 1px solid $form-border-color;
        display: block;
        padding: 10px;

        @media #{$pc} {
          flex: 0 1 100%;
        }

        @media all and (min-width: 600px) and (max-width: 850px) {
          text-align: center;
        }

        @media #{$sp} {
          text-align: center;
        }
      }
    }
  }

  /*================================================
    .shipment-header | 出荷番号〜お支払い方法
  ================================================*/
  .shipment {
    h4 {
      border: 1px solid $form-border-color;
      border-top: none;
      font-weight: normal;
    }
  }

  .shipment-header {

    .shipment-code,
    .shipment-status,
    .payments {
      border-bottom: 1px solid $form-border-color;

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {}

      > h5 {
        border-left: 1px solid lightgray;

        @media #{$sp} {
          border-right: 1px solid lightgray;
          border-bottom: 1px solid lightgray;
        }
      }

      > div {
        border-right: 1px solid lightgray;
        display: block;
        padding: 10px;

        @media #{$pc} {
          flex: 0 1 100%;
        }

        @media all and (min-width: 600px) and (max-width: 850px) {
          text-align: center;
        }

        @media #{$sp} {
          border-left: 1px solid lightgray;
          text-align: center;
        }

        .link-detail {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    > div.code.shipment-code {
      @media all and (min-width: 600px) and (max-width: 850px) {
        border-bottom: 0;
      }

      @media #{$sp} {
      }
    }
  }

  /*==========================================
    .shipment-delivery-address | 購入者情報〜お届け先
  ==========================================*/
  .shipment-delivery-address {

    .person {
      border-bottom: 1px solid $form-border-color;

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {}
    }

    .person-label {
      background-color: $title-bg-color;
      border-right: 1px solid $form-border-color;
      border-left: 1px solid $form-border-color;
      flex: 0 0 260px;
      text-align: center;
      padding: 10px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      @media all and (min-width: 600px) and (max-width: 850px) {
        border-right: 0;
      }

      @media #{$sp} {
        border-bottom: 1px solid $form-border-color;
      }
    }

    .person-contents {
      border-right: 1px solid $form-border-color;
      padding: 10px;

      @media #{$pc} {
        flex: 0 1 100%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
      }

      @media #{$sp} {
        border-left: 1px solid $form-border-color;
        text-align: center;
      }
    }
  }

  /*==========================================
    .shipment-contents | お届けに関する情報〜出荷合計金額
  ==========================================*/
  .shipment-contents {

    .delivery {

      table {
        border-collapse: collapse;
        width: 100%;

        caption {
          background-color: $title-bg-color;
          border-right: 1px solid $form-border-color;
          border-bottom: 1px solid $form-border-color;
          border-left: 1px solid $form-border-color;
          display: block;
          text-align: center;
          padding: 10px;
          width: 100%;
        }

        tr {
          border-bottom: 1px solid $form-border-color;

          display: flex;
        }

        th,
        td {
          display: block;
        }

        th {
          background-color: $title-bg-color;
          border-right: 1px solid $form-border-color;
          border-left: 1px solid $form-border-color;
          font-weight: 400;
          flex: 0 0 260px;
          text-align: center;
          padding: 10px;

          display: flex;
          flex-direction: column;
          justify-content: center;

          @media all and (min-width: 600px) and (max-width: 850px) {
            flex: 0 0 160px;
          }

          @media #{$sp} {
            flex: 0 0 145px;
          }
        }

        td {
          border-right: 1px solid $form-border-color;
          padding: 10px;

          flex: 0 1 100%;
        }
      }
    }

    .order-items {

      h4 {
        background-color: $title-bg-color;
        border-bottom: 1px solid $form-border-color;
        border-left: 1px solid $form-border-color;
        font-weight: normal;
        text-align: center;
        padding: 10px;
      }
    }


    .itemdetails-header {
      display: none;
    }

    .item {}

    .rpoption {

      @media all and (min-width: 600px) and (max-width: 850px) {
        max-width: 400px;
        margin: 0 auto;
        text-align: left;
      }

      @media #{$sp} {
        max-width: 400px;
        margin: 0 auto;
        text-align: left;
      }
    }
  }

  .applied-coupons {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    li {
      display: inline-block;
      background: -webkit-gradient(linear, right top, left top, from(#c10000), to(#d35900));
      background: -o-linear-gradient(right, #c10000 0%, #d35900 100%);
      background: linear-gradient(to left, #c10000 0%, #d35900 100%);
      color: #fff;
      padding: 2px 10px;
      border-radius: 4px;
      font-weight: bold;
    }
  }

  .order-buttons {
    text-align: center;
    margin-top: 60px;

    .btn {
      @include btn(brownBg);
    }

    .note {
      margin-top: 15px;
    }
  }

  .panel-footer {
    margin-top: 30px;
  }
}
