/*
  ----------------
  よくある質問 | faq-contents
  ----------------
*/
.faq-contents {
  @media #{$pc} {
    margin-left: 80px;
  }

  @media all and (max-width : 960px) and (min-width : 801px) {
    margin-left: 40px;

  }

  @media all and (max-width : 800px) {
    margin-left: 0;
  }

  &__wrap {
    width: 100%;

    @media #{$pc} {
      max-width: 850px;
    }

    @media all and (max-width : 800px) {
      padding-top: 53px;
      max-width: 100%;
    }
  }

  &__title {
    background-color: $secondar-color;
    border-top: 1px solid $black-color;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2;
    padding: 11px 17px 8px;

    @media #{$sp} {
      font-weight: 500;
      padding: 5px 21px 4px;
    }
  }

  &__title-sub {
    font-size: 18px;
    padding: 11px 17px 0;

    @media #{$sp} {
      font-size: 16px;
      font-weight: 500;
    }
  }

  details {
    border-bottom: 1px dashed $border-gray-color;
    position: relative;
  }

  details[open] {
    .faq-contents__answer {
      animation: fadeIn 0.5s ease-in-out;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }

  summary,
  &__answer-title {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.777;
    position: relative;

    &::before {
      content: "Q";
      display: block;
      font-size: 20px;
      font-weight: 400;
      line-height: 2;

      position: absolute;
      left: 35px;
      transform: translateY(-50%);
    }

    @media #{$sp} {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.714;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    summary:hover &__icon {
      background-color: $black-color;

      &::before {
        color: $white-color;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  summary {
    @include hoverLink;
    padding: 38px 65px 32px;

    &::before {
      top: 50%;
    }

    @media #{$sp} {
      padding: 24px 50px 14px 44px;

      &::before {
        top: 14px;
        left: 20px;
        transform: none;
      }
    }
  }

  &__icon {
    border: 1px solid $black-color;
    position: absolute;
    top: 50%;
    right: 20px;
    height: 30px;
    width: 30px;
    transform: translateY(-50%);

    &::before {
      content: "▼";
      display: block;
      font-size: 10px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }

    @media #{$sp} {
      top: 54%;
    }
  }

  details[open] &__icon {
    background-color: $black-color;

    &::before {
      content: "▲";
      color: $white-color;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__answer {
    background-color: $main-color;
    margin-bottom: 20px;
    padding: 30px 36px 40px;

    @media #{$sp} {
      margin: 8px 0 12px;
      padding: 18px 20px 14px;
    }
  }

  &__answer-title {
    padding-left: 30px;

    @media #{$sp} {
      display: flex;
      padding-left: 26px;
    }

    &::before {
      content: "A";
      top: 50%;
      left: 0;

      @media #{$sp} {
        top: -5px;
        transform: none;
      }
    }
  }

  &__answer-title a {
    display: inline-block;
  }

  &__answer-title+&__answer-text {
    margin-top: 29px;

    @media #{$sp} {
      margin-top: 14px;
    }
  }

  &__answer-text {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 2.307;

    a {
      @include hoverLink;
      text-decoration: underline;
    }
  }

  &__section {
    &+.faq-contents__section {
      margin-top: 80px;

      @media #{$sp} {
        margin-top: 53px;
      }
    }
  }
}