/*
  ----------------
  .order-journal-history | 注文履歴
  ----------------
*/

.order-journal-history {

  .order {
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }
  }

  .panel {
    border: 1px solid $form-border-color;
  }


  /*================================================
    .panel-heading | 注文日時〜出荷番号
  ================================================*/
  .panel-heading {

    .status {
      display: block;
      padding: 10px;

      @media #{$pc} {
        flex: 0 1 100%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
      }

      @media #{$sp} {
        text-align: center;
      }
    }

    .control a {
      text-decoration: underline;
    }
  }

  /*================================================
    .panel-body | お届け先〜請求金額合計
  ================================================*/
  .panel-body {

    .panels {

      li {
        border-bottom: 1px solid $form-border-color;

        @media #{$pc} {
          display: flex;
        }

        @media all and (min-width: 600px) and (max-width: 850px) {
          display: block;
        }

        @media #{$sp} {}

        > div {
          padding: 10px;

          @media #{$pc} {
            flex: 0 1 100%;
          }

          @media all and (min-width: 600px) and (max-width: 850px) {
            text-align: center;
          }

          @media #{$sp} {
            text-align: center;
          }
        }
      }

      h6 {
        background-color: $title-bg-color;
        border-right: 1px solid $form-border-color;
        flex: 0 0 260px;
        text-align: center;
        padding: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @media all and (min-width: 600px) and (max-width: 850px) {
          border-right: 0;
        }

        @media #{$sp} {
          border-right: 0;
        }
      }
    }

    .item {

      @media #{$pc} {
        flex: 0 1 85%;
      }
    }
  }
}
