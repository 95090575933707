/*
  ----------------
  Top | Product
  ----------------
*/
.top-product {
  background-color: $main-color;

  &__wrap {
    padding-top: 177px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding-top: 144px;
    }

    @media #{$sp} {
      padding-top: 81px;
    }
  }

  &__header {
    padding: 0 50px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding: 0 30px;
    }

    @media #{$sp} {
      padding: 0 34px;
    }
  }

  &__body {
    @media #{$pc} {
      margin: 70px auto 0;
      max-width: 1366px;
    }

    @media #{$sp} {
      margin-top: 50px;
    }
  }

  &__footer {
    margin-top: 119px;
    text-align: center;

    @media #{$sp} {
      margin-top: 60px;
    }
  }

  &__title-sub {
    @include h2TitleRuby();

    img {
      height: 23.46px;

      @media #{$sp} {
        height: 15.64px;
      }
    }
  }

  &__title {
    @include h2Title();
    line-height: 1.45;
    margin-top: 21px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      font-size: 28px;
    }

    @media #{$sp} {
      line-height: 1.6;
      margin-top: 14px;
    }
  }

  &__text {
    letter-spacing: 0.1em;
    line-height: 2.5;
    margin-top: 48px;
    text-align: center;

    @media all and (max-width : 800px) and (min-width : 600px) {
      margin-top: 30px;
    }

    @media #{$sp} {
      line-height: 2.2;
      margin-top: 27px;
      text-align: left;
    }
  }

  &__block {
    display: flex;

    @media #{$sp} {
      flex-wrap: wrap;
    }
  }

  // ラメリアプレミアム
  &__block--premium {
    padding-right: 50px;

    @media #{$pc} {
      height: 463px;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding-right: 30px;
    }

    @media #{$sp} {
      padding-right: 35px;
    }
  }

  // ディープグロースエッセンス
  &__block--deep-growth {
    margin-top: -130px;
    padding-right: 80px;
    padding-left: 50px;

    @media #{$pc} {
      flex-direction: row-reverse;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding-right: 40px;
      padding-left: 25px;
    }

    @media #{$sp} {
      margin-top: 52px;
      padding: 0 0 0 34px;
    }
  }

  // デイプロテクトBB
  &__block--day-protecct {
    margin-top: 85px;

    @media #{$pc} {
      height: 320px;
      padding-right: 18.5%;

      display: flex;
      justify-content: center;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding-right: 30px;
    }

    @media #{$sp} {
      margin-top: 80px;
      padding-right: 34px;
      padding-left: 34px;
    }
  }

  // ブリリアントソープ
  &__block--briliant-soap {
    margin-top: 20px;

    @media #{$pc} {
      flex-direction: row-reverse;
      height: 375px;
      padding-left: 50px;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      margin-top: 0;
      padding-left: 25px;
    }

    @media #{$sp} {
      margin-top: 82px;
      padding-left: 34px;
    }
  }

  // ラメリアスキンケア シリーズ3点セット
  &__block--skincare {
    margin-top: 65px;

    @media #{$pc} {
      height: 374px;
      padding-right: 50px;
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      height: 44vw;
      margin-top: 0;
      padding-right: 25px;
    }

    @media #{$sp} {
      margin-top: 57px;
      padding-right: 34px;
    }
  }

  &__view {
    // AOSアニメーション用スタイル
    overflow: hidden;

    @media #{$sp} {
      width: 100%;
    }
    .top-product__block--deep-growth & {
      margin-left: 50px;
      padding-bottom: 8px;

      display: flex;
      align-items: flex-end;

      @media all and (max-width : 800px) and (min-width : 600px) {
        margin-left: 20px;
      }

      @media #{$sp} {
        margin-left: 0;
        padding-bottom: 0;
      }
    }

    .top-product__block--day-protecct & {
      @media all and (max-width : 800px) and (min-width : 600px) {
        display: flex;
        align-items: flex-end;
      }
    }

    .top-product__block--briliant-soap & {
      margin-left: 32px;
      padding-bottom: 7px;

      @media all and (max-width : 800px) and (min-width : 600px) {
        display: flex;
        align-items: flex-end;
      }

      @media #{$sp} {
        margin-left: 0;
        padding-bottom: 0;
      }
    }

    .top-product__block--skincare & {
      padding-bottom: 6px;

      @media all and (max-width : 800px) and (min-width : 600px) {
      }

      @media #{$pc} {
        display: flex;
        align-items: flex-end;
      }

      @media #{$sp} {
        padding-bottom: 0;
      }
    }
  }

  &__img {}

  &__info {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .top-product__block--premium & {
      justify-content: center;
      max-width: 308px;
      margin-left: 84px;
      padding-top: 10px;
      width: 100%;

      @media all and (max-width : 800px) and (min-width : 600px) {
        margin-left: 30px;
      }

      @media #{$sp} {
        margin: 0;
        padding-top: 34px;
        padding-left: 34px;
      }
    }

    .top-product__block--deep-growth & {
      @media #{$pc} {
        min-width: 275px;
      }

      @media all and (max-width : 800px) and (min-width : 600px) {
        min-width: 245px;
      }

      @media #{$sp} {
        margin-top: 42px;
      }
    }

    .top-product__block--day-protecct & {
      margin-left: 55px;

      @media all and (max-width : 800px) and (min-width : 600px) {
        margin-left: 30px;
        min-width: 255px;
      }

      @media #{$sp} {
        margin-top: 34px;
        margin-left: 0;
      }
    }

    .top-product__block--briliant-soap & {
      @media all and (max-width : 800px) and (min-width : 600px) {
        min-width: 270px;
      }

      @media #{$sp} {
        margin-top: 30px;
      }
    }

    .top-product__block--skincare & {
      margin-left: 74px;

      @media #{$pc} {
        min-width: 310px;
      }

      @media all and (max-width : 800px) and (min-width : 600px) {
        margin-left: 30px;
        min-width: 270px;
      }

      @media #{$sp} {
        margin-left: 0;
        padding-top: 34px;
        padding-left: 34px;
      }
    }
  }

  &__name {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.15em;
    line-height: 1.47;

    @media all and (max-width : 800px) and (min-width : 600px) {
      font-size: 26px;
    }

    @media #{$sp} {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.08em;
      line-height: 1.75;
    }
  }

  &__description {
    margin-top: 15px;

    @media #{$sp} {
      letter-spacing: 0.08em;
      margin-top: 9px;
    }

    .top-product__block--deep-growth &,
    .top-product__block--skincare & {
      @media #{$pc} {
        margin-top: 22px;
      }
    }

    .top-product__block--day-protecct & {
      @media #{$sp} {
        margin-top: 16px;
      }
    }

    .top-product__block--skincare & {
      @media #{$sp} {
        margin-top: 12px;
      }
    }
  }

  &__link {
    @include btnLink(brown);
  }
}
