/*
  ----------------
  お客様インタビュー詳細 | その他のインタビュー記事
  ----------------
*/
.interview-article-slide {

  &__wrap {
    padding: 48px 80px 130px;

    @media #{$pc} {
      max-width: 1366px;
      margin: 0 auto;
    }

    @media all and (max-width : 800px) {
      padding: 44px 40px 50px;
    }

    @media #{$sp} {
      margin: 0 auto;
      max-width: 463px;
      padding: 43px 30px 130px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
    line-height: 0.8;
    letter-spacing: 0;
    text-align: center;

    @media #{$sp} {
      font-size: 18px;
      font-weight: 500;
      line-height: 2.222;
      letter-spacing: 0.08em;
    }
  }

  &__main {
    position: relative;
  }

  &__content {
    margin-top: 95px;
    padding-bottom: 115px;
    position: relative;
    overflow: hidden;

    @media #{$sp} {
      margin-top: 30px;
      padding-bottom: 82px;
    }
  }

  &__list {

  }

  &__item {

  }

  &__link {
    display: inline-block;
  }

  &__view {
    position: relative;

    @media #{$sp} {
      max-width: 180px;
      width: 54%;
    }
  }

  &__img {
    aspect-ratio: 18 / 11;
    object-fit: cover;
    object-position: top right;

    @media #{$sp} {
      aspect-ratio: 75 / 67;
      object-position: 80% 50%;
    }
  }

  &__copy {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6;
    letter-spacing: 0;

    @media #{$pc} {
      text-align: right;
      width: 145px;

      position: absolute;
      bottom: 15px;
      right: 13px;

      display: none;
    }

    @media all and (max-width : 900px) {
      font-size: 18px;
    }

    @media all and (max-width : 750px) {
      font-size: 16px;
    }

    @media #{$sp} {
      font-size: 13px;
      letter-spacing: 0.03em;
      margin-top: 15px;
    }
  }

  &__box {
  }

  &__header {
    @media #{$sp} {
      display: flex;
    }
  }

  &__body {
    padding: 0 20px 25px;
  }

  &__info {
    margin-top: 26px;
    padding: 0 20px;

    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media #{$sp} {
      line-height: 0;
      margin-top: 0;
      padding: 13px 15px 0;
    }
  }

  // 動的DOM
  .interview-info-work {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.923;
    letter-spacing: 0.03em;
    text-align: center;

    @media #{$pc} {
      flex-basis: 100%;
    }

    @media #{$sp} {
      font-size: 12px;
      text-align: left;
    }
  }

  .interview-info-name,
  .interview-info-age {
    font-size: 18px;
    font-weight: 400;
    display: inline-block;
    line-height: 1.18;
    letter-spacing: 0.03em;
    text-align: center;

    @media all and (max-width : 900px) {
      font-size: 16px;
    }

    @media #{$sp} {
      font-size: 14px;
    }
  }

  .interview-info-age {
    @media #{$pc} {
      margin-left: 15px;
    }
  }
  // 動的DOM | END

  &__read-wrap {
    margin-top: 28px;
    text-align: center;

    @media #{$sp} {
      margin-top: 13px;
    }
  }

  &__read {
    @include btnLink(brown);

    @media #{$sp} {
      max-width: 268px;
    }
  }

  // Swiper setting
  .swiper-pagination-bullet {
    background: $light-gray-color;
    color: #000;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    opacity: 1;

    height: 30px;
    width: 30px;
  }

  .swiper-pagination-bullet-active {
    background: $black-color;
    color: #fff;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include hoverLink;
    cursor: pointer;
    height: 57px;
    top: 36.5%;
    width: 22px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      height: 45px;
      width: 15px;
    }

    @media #{$sp} {
      height: 26px;
      width: 10px;
    }

    &::after {
      background: url(/assets/img/top/icon_arrow_pickup_prev.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 57px;
      width: 22px;

      position: absolute;
      top: 0;
      left: 0;

      @media all and (max-width : 800px) and (min-width : 600px) {
        height: 45px;
        width: 15px;
      }

      @media #{$sp} {
        height: 26px;
        width: 10px;
      }
    }
  }

  .swiper-button-prev {
    left: -45px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      left: -30px;
    }

    @media #{$sp} {
      left: -20px;
    }
  }

  .swiper-button-next {
    right: -45px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      right: -30px;
    }

    @media #{$sp} {
      right: -20px;
    }

    &::after {
      background-image: url(/assets/img/top/icon_arrow_pickup_next.svg);
    }
  }

}
