/*
  ---------------
  page title | 下層ページタイトル
  ---------------
*/

@mixin pageTitle($color, $directory) {
  @if $color==white {
    color: $white-color;
  } @else {
    color: $black-color;
  }

  background: url(/assets/img/#{$directory}/bg_page_title.jpg) no-repeat center center;
  background-size: cover;
  font-size: 40px;
  height: 448px;
  letter-spacing: 0.08em;
  line-height: 1.75;
  padding-top: 105px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$sp} {
    background: url(/assets/img/#{$directory}/bg_page_title_sp.jpg) no-repeat center center;
    background-size: cover;
    font-size: 25px;
    height: 400px;
    letter-spacing: 0.08em;
    line-height: 1.6;
    padding-top: 58px;
  }
}

/*
  ---------------
  common title for h2 and ruby | h2タイトル上ルビ h2タイトル
  ---------------
*/

@mixin h2TitleRuby {
  text-align: center;

  img {
    height: 59px;
    width: auto;

    @media #{$sp} {
      height: 39px;
    }
  }
}

@mixin h2Title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.15em;
  line-height: 1.75;

  @media #{$sp} {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 1.6;
  }
}
