/*
  ---------------
  .news-tab | タブ切り替え
  ---------------
*/
.news-tab {
  padding: 0 80px;
  margin-top: 78px;
  margin-bottom: -1px;

  @media #{$pc} {
    margin-right: auto;
    margin-left: auto;
    max-width: 1366px;
    padding-right: 5.8565%;
    padding-left: 5.8565%;
  }

  @media #{$sp} {
    padding: 0 15px;
    margin-top: 50px;
    margin-bottom: -1px;
  }

  // #newsentries &,
  // #news & {
  //   margin-top: 78px;

  //   @media #{$sp} {
  //     margin-top: 50px;
  //   }
  // }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    width: 50%;
    max-width: 600px;

    & + & {
      margin-left: 7px;

      @media #{$sp} {
        margin-left: 11px;
      }
    }
  }

  &__link {
    background-color: $black-color;
    color: $white-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: center;
    padding: 12px 0 14px;
    height: 50px;
    width: 100%;

    @media #{$sp} {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71428571;
    }

    .is-active & {
      background-color: $white-color;
      border-top: 2px solid $black-color;
      border-left: 1px solid $light-gray-color;
      border-right: 1px solid $light-gray-color;
      color: $black-color;
      margin-bottom: 0;
      height: 62px;
      pointer-events: none;

      &:hover {
        opacity: 1;
      }
    }
  }
}
