/*
  ----------------
  Error page
  ----------------
*/
.error-page {

  #container {
    @include container;
    padding-top: 200px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;

    @media #{$sp} {
      padding-top: 100px;
    }
  }

  h1 {
    font-size: 40px;
    text-align: center;

    @media #{$sp} {
      font-size: 36px;
    }
  }

  .error-description {
    font-size: 20px;
    text-align: center;

    @media #{$sp} {
      font-size: 18px;
    }
  }

  .error-note {
    display: inline-block;
    line-height: 1.5;
    margin: 40px auto 0;
    max-width: 700px;
    text-align: center;

    @media #{$sp} {
      text-align: left;
    }
  }

  .error-note p {
    margin-top: 10px;
  }
}