/*
  ----------------
  .entry-reminder-update | 仮パスワードの発行
  ----------------
*/

.entry-reminder-update {

  @media all and (min-width: 600px) and (max-width: 850px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  .form-body {

    .signout {
      width: 100%;

      & > a {
        margin-top: 20px;

        @include btn();
        background-color: $white-color;
        display: inline-block;
        max-width: none;
        width: auto;
      }
    }
  }
}

.entry_password_reminder {

  #container {
    padding-top: 110px;

    @media #{$sp} {
      padding-top: 80px;
    }
  }

  .msg {
    text-align: center;

    .msg-head {
      font-size: 20px;
      text-align: center;
    }

    .msg-body {
      display: inline-block;
      line-height: 1.5;
      margin: 40px auto 0;
      max-width: 700px;
      text-align: left;
    }
  }

  .btn-submit {
    .link-updatepass {
      margin-top: 40px;

      @media #{$sp} {
        margin-top: 20px;
      }

      @include btn();
    }
  }
}