/*
  ----------------
  お客様インタビュー
  ----------------
*/

.interview-lead {

  &__wrap {
    padding: 80px 0 105px;

    @media #{$sp} {
      padding: 39px 0 55px;
    }
  }

  &__title-sub {
    @include h2TitleRuby();

    img {
      height: 14.64px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.08em;
    line-height: 0.8;
    margin-top: 24px;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      line-height: 1.6;
      margin-top: 10px;
    }
  }

  &__title-img {}

}