.view-order,
.view-orders,
.order-journal-history,
.rp-subscriptions,
.rp-future-entries,
.rp-entry {
  .item-thumbnail-img-wrapper {

    @media #{$pc} {
      margin: 0 auto;
      max-width: 260px;
      flex: 0 0 260px;
    }

    @media #{$sp} {
      margin: 0 auto;
      max-width: 220px;
    }

    img {
      max-width: 150px;
    }
  }
}
