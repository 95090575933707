/*
  ----------------
  Error page
  ----------------
*/
.order__order_error {

  #container {
    @include container;
    padding-top: 200px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;

    @media #{$sp} {
      padding-top: 100px;
    }
  }

  h1 {
    font-size: 40px;
    text-align: center;

    @media #{$sp} {
      font-size: 36px;
    }
  }

  .signin-form {
    padding: 0;

    h3 {
      font-size: 40px;
      font-weight: normal;
      line-height: 1.75;
      text-align: center;

      @media #{$sp} {
        font-size: 28px;
        line-height: 1.6;
        letter-spacing: 0.08em;
      }
    }

    .form-note {
      margin: 43px 0;

      @media #{$sp} {
        margin: 30px 0;
      }
    }
  }

  .error-heading {
    font-size: 20px;
    text-align: center;

    @media #{$sp} {
      font-size: 18px;
    }
  }

  .error-message {
    line-height: 1.5;
    margin: 40px auto 0;
    max-width: 700px;
    text-align: center;

    @media #{$sp} {
      text-align: left;
    }
  }
}