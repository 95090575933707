/*
  ----------------
  Button | ボタンのスタイル
  ----------------
*/

/********** Common | 全ボタン共通 **********/
.btn {
  @include btn;
}

/********** Scroll button | TOPへスクロールボタン **********/
.go-top {
  @include btn;
  background-color: #2d2d2d;
  display: none;
  padding: 0 15px 10px;

  height: 60px;
  width: 60px;

  position: fixed;
  bottom: 95px;
  right: 0;
  z-index: 100;

  @media #{$sp} {
    bottom: 61px;
    transition: none;
  }
}

.go-cart {
  position: fixed;
  bottom: 95px;
  right: 0;
  z-index: 101;

  @media #{$sp} {
    bottom: 61px;
    transition: none;
  }
}

/********** Submit btn | 確定ボタン **********/
button.btn-confirm,
button.btn-back,
button.btn-submit {
  @include btn(sm);
  margin: 0 8px;

  @media #{$sp} {
    margin: 0;
    max-width: 100%;
  }
}

button.btn-submit {
    background: linear-gradient(to right, #9B0000 0%, #AD4900 100%);
    border: none;
    color: $white-color;

    @media #{$pc} {
      padding: 15px 5px;
    }

    @media #{$sp} {
      padding: 15px 5px;
    }
}

/********** Small btn | 小ボタン **********/
.btn-sm {
  @include btn(sm);
}

/********** Edit btn | 編集ボタン **********/
.btn-edit {
  @include btn(sm);
}

/********** Delete btn | 削除ボタン **********/
.btn-delete {
  @include btn(delete);
}

/********** Inline btn | インラインボタン **********/
.btn-inline {

}

/********** Favorite add btn | お気に入り追加ボタン **********/
.item-add-favorite-add {

}

/********** Favorite remove btn | お気に入り削除ボタン **********/
.btn-add-favorite-remove {

}

/********** Group btn | ボタングループ **********/
.btn-group {
  text-align: left;

  @media #{$sp} {
    text-align: center;
  }

  > div {
    margin-top: 1rem;
  }
}