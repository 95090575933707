/*
  ----------------
  Footer common
  ----------------
*/
.footer {

  &__wrap {
    background-color: $black-color;
    padding: 77px 0 0;

    @media #{$sp} {
      padding: 93px 0 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__body {

    .common-menu {
      overflow-y: hidden;
    }
  }

  &__logo {}

  &__img {}

  &__lead {
    color: $white-color;
    font-size: 18px;
    letter-spacing: 0.09em;
    line-height: 1.333;
    margin-top: 32px;

    @media #{$sp} {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 1.8;
      text-align: center;
    }
  }

  &__info {
    background-color: #272727;
    padding: 24px 0 32px;

    display: flex;
    flex-direction: column;

    @media #{$sp} {
      padding-top: 55px;
    }
  }

  &__info-list {
    display: flex;
    justify-content: center;

    @media #{$sp} {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info-item {
    margin: 0 16px;
    position: relative;

    @media #{$pc} {
      &:not(:first-child)::before {
        display: block;
        color:$white-color;
        content: "|";
        font-size: 14px;

        position: absolute;
        top: 50%;
        left: -18px;
        transform: translateY(-50%);
      }
    }
  }

  &__info-link {
    color: $white-color;
    letter-spacing: 0;

    @media #{$sp} {
      font-size: 16px;
      line-height: 2.5;
    }
  }

  &__copy {
    color: $white-color;
    font-size: 12px;
    line-height: 2.5;
    letter-spacing: 0;
    text-align: center;

    @media #{$sp} {
      margin-top: 20px;
    }
  }
}