/*
  ---------------
  .entries | お知らせ・プレスリリース 記事一覧
  ---------------
*/
.entries {
  // flex-basis: 74.8003549%;

  // @media #{$pc} {
  //   margin-left: 6.55058043%;
  // }

  // @media all and (min-width: 600px) and (max-width: 899px) {
  //   margin-left: 0;
  // }

  &__wrap {}

  &__title {
    background-color: $title-bg-color;
    border-top: 1px solid $black-color;
    font-size: 20px;
    font-weight: normal;
    line-height: 2;
    letter-spacing: 0;
    padding: 11px 22px 8px;

    @media #{$sp} {
      font-weight: 500;
      padding: 4px 22px 5px;
    }
  }

  &__list {
    margin-top: 20px;

    @media #{$sp} {
      margin-top: 29px;
    }
  }

  &__item {
    background-color: $main-bg-color;
    padding: 49px 53px 51px;

    @media #{$sp} {
      padding: 31px 31px 41px;
    }

    & + & {
      margin-top: 15px;

      @media #{$sp} {
        margin-top: 20px;
      }
    }
  }

  &__header {
    border-bottom: 1px solid $black-color;
    padding-bottom: 9px;

    @media #{$sp} {
      padding-bottom: 14px;
    }
  }

  &__header-title {
    font-size: 25px;
    font-weight: normal;
    line-height: 1.44;

    @media #{$sp} {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.45;
      letter-spacing: 0.08em;
    }
  }

  // &__datetime {
  //   line-height: 1;

  //   @media #{$pc} {
  //     margin-top: 2px;
  //   }
  // }

  &__published {
    display: block;
    font-size: 13px;
    line-height: 1.84615385;
    letter-spacing: 0;
    margin-top: 4px;

    @media #{$sp} {
      font-weight: 500;
      margin-top: 6px;
    }
  }

  &__body {
    font-size: 14px;
    line-height: 2.14285714;
    letter-spacing: 0;
    margin-top: 30px;
    max-height: 210px;
    overflow: hidden;

    @media #{$sp} {
      margin-top: 25px;
      height: 330px;
    }
  }

  &__footer {
    text-align: center;
    margin-top: 39px;

    @media #{$sp} {
      margin-top: 22px;
    }
  }

  &__footer-link {
    @include btnLink;
  }
}
