/*
  ----------------
  ユーティリティー
  ----------------
*/

.container {
  position: relative;

  @media #{$pc} {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 80px;
  }

  @media #{$sp} {
    padding: 0 35px;
  }
}

/*
  ----------------
  レスポンシブ用のユティリティー
  ----------------
*/

.sp-only {
  @media #{$pc} {
    display: none !important;
  }
}


.pc-only {
  @media #{$sp} {
    display: none !important;
  }
}
