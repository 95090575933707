.item-single {
  margin-top: 129px;

  @media #{$sp} {
    margin-top: 68.5px;
  }

  .item-wrapper-02 & {
    margin-top: 87px;

    @media #{$sp} {
      margin-top: 47px;
    }
  }

  &__wrap {}

  &__title {
    border-bottom: 2px solid $black-color;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 13px;

    @media #{$sp} {
      border-bottom: 1px solid $black-color;
      font-weight: 500;
      letter-spacing: 0.08em;
      padding-bottom: 9.5px;
    }
  }

  &__block {
    margin-top: 64px;

    @media #{$sp} {
      margin-top: 30.5px;
    }

    .item-wrapper-02 & {
      margin-top: 74px;

      @media #{$sp} {
        margin-top: 30px;
      }
    }
  }

  &__box {

    @media #{$pc} {
      display: flex;
    }

    @media all and (min-width: 600px) and (max-width: 1000px) {
      display: block;
    }
  }

  &__img {

    @media #{$pc} {
      flex: 0 1 100%;
    }
  }

  &__contents {
    @media #{$pc} {
      flex: 0 0 442px;
      margin-left: 6.59204%;
    }

    @media all and (min-width: 600px) and (max-width: 1000px) {
      margin-top: 26px;
      margin-left: 0;
    }

    @media #{$sp} {
      margin-top: 26px;
    }

    .item-wrapper-02 & {
      @media all and (min-width: 600px) and (max-width: 1000px) {
        margin-top: 26px;
      }
    }
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.85714286;
    letter-spacing: 0;

    @media #{$pc} {
      margin-top: -7px;
    }

    @media #{$sp} {
      font-weight: 500;
      line-height: 2.14285714;
    }

    // @media #{$pc} {
    //   flex: 0 0 442px;
    //   margin-left: 6.59204%;
    // }

    // @media all and (min-width: 600px) and (max-width: 1000px) {
    //   margin-top: 26px;
    //   margin-left: 0;
    // }

    // @media #{$sp} {
    //   margin-top: 26px;
    // }

    .item-wrapper-02 & {
      margin-left: 0;

      @media #{$pc} {
        margin-right: -5px;
      }
    }
  }

  // &__description-text {
  //   font-size: 14px;
  //   font-weight: 400;
  //   line-height: 2.85714286;
  //   letter-spacing: 0;

  //   @media #{$sp} {
  //     font-weight: 500;
  //     line-height: 2.14285714;
  //   }

  //   .item-wrapper-02 & {
  //     @media #{$pc} {
  //       margin-right: -5px;
  //     }
  //   }
  // }

  &__contents-item {
    border-top: 1px dashed $border-gray-color;
    margin-top: 26.5px;

    @media #{$sp} {
      margin-top: 18.5px;
    }

    &.is-hidden {
      display: none;
    }
  }

  &__info {
    margin-top: 33.5px;

    @media #{$sp} {
      margin-top: 17.5px;
    }
  }

  &__info-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0;

    @media #{$sp} {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.66666667;
    }
  }

  &__list {
    margin-top: 20px;

    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
    }

    @media all and (min-width: 757px) and (max-width: 1191px) {
      margin-top: 20px;
    }

    @media all and (min-width: 600px) and (max-width: 756px) {
      display: block;
      margin-top: 20px;
    }

    @media #{$sp} {
      margin-top: 20px;
    }
  }

  &__item {
    padding: 5px 40px 26px 45px;

    @media #{$pc} {
      margin-top: 45px;
      width: calc(100% / 3);

      display: flex;
      flex-direction: column;
    }

    @media all and (min-width: 757px) and (max-width: 1209px) {
      padding: 5px 3.31674959% 26px 3.73134328%;
    }

    @media all and (min-width: 757px) and (max-width: 1191px) {
      width: calc(100% / 2);
    }

    @media all and (min-width: 600px) and (max-width: 756px) {
      border-top: 1px dashed $border-gray-color;
      display: block;
      width: 100%;
      padding: 24.5px 0 23.5px 0;
      margin-top: 0;
    }

    @media #{$sp} {
      border-top: 1px dashed $border-gray-color;
      padding: 27.5px 0 29.5px 3px;
    }

    @media all and (min-width: 1192px) {
      &:nth-child(3n + 1) {
        border-left: 1px dashed $border-gray-color;
        border-right: 1px dashed $border-gray-color;
      }

      &:not(:nth-child(3n + 1)) {
        border-right: 1px dashed $border-gray-color;
      }
    }

    @media all and (min-width: 757px) and (max-width: 1191px) {
      &:nth-child(2n + 1) {
        border-left: 1px dashed $border-gray-color;
        border-right: 1px dashed $border-gray-color;
      }

      &:not(:nth-child(2n + 1)) {
        border-right: 1px dashed $border-gray-color;
      }
    }

    &:last-child {

      @media all and (min-width: 600px) and (max-width: 756px) {
        border-bottom: 1px dashed $border-gray-color;
      }

      @media #{$sp} {
        border-bottom: 1px dashed $border-gray-color;
        // padding-bottom: 12.5px;
      }
    }
  }

  &__item-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0;

    @media all and (min-width: 757px) and (max-width: 1191px) {
      font-size: 19px;
    }

    @media #{$sp} {
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &__label {
    font-size: 0;
    margin-left: -9px;
  }

  &__tag {
    background-color: #9f9f9f;
    border-radius: 50px;
    color: $white-color;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
    letter-spacing: 0;
    padding: 0px 14px 1px 15px;
    margin-top: 5px;
    margin-left: 9px;

    @media #{$sp} {
      font-size: 12px;
      line-height: 1;
      padding: 4px 8px;
      margin-top: 7px;
    }

    & + & {
      padding: 0px 13px 1px 14px;

      @media #{$sp} {
        padding: 4px 10px;
      }
    }

    .item-wrapper-02 & {
      @media #{$sp} {
        margin-top: 5px;
      }
    }

    &.primary {
      background-color: transparent;
      border: 1px solid $bronw-color;
      color: $bronw-color;
      padding: 0px 13px 0px 14px;
      margin-top: 7px;

      @media #{$sp} {
        padding: 3px 9px 3px 7px;
        margin-top: 10px;
      }
    }
  }

  &__price {
    font-size: 0;
    flex-grow: 1;
    line-height: 1.31818182;
    margin-top: 23px;

    @media #{$sp} {
      margin-top: 11px;
    }

    .item-wrapper-02 & {

      @media #{$sp} {
        margin-top: 15px;
      }
    }
  }

  &__price-number {
    display: inline-block;
    font-size: 39px;
    font-weight: 400;
    letter-spacing: 0.05em;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0.08em;
    }

    .item-wrapper-02 & {
      letter-spacing: 0.09em;

      @media #{$sp} {
        letter-spacing: 0.08em;
      }
    }
  }

  &__price-yen {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.28571429;
    letter-spacing: 0;
    margin-left: 10px;

    @media #{$sp} {
      font-size: 13px;
      line-height: 2.46153846;
      margin-left: 6px;
    }

    .item-wrapper-02 & {
      margin-left: 11px;

      @media #{$sp} {
        margin-left: -1px;
      }
    }
  }

  &__price-guide {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    font-family: "游ゴシック体";
    line-height: 1.84615385;
    letter-spacing: 0;
    margin-left: -5px;

    @media #{$sp} {
      font-size: 12px;
      line-height: 2;
      margin-left: 0;
    }
  }

  &__form {
    margin-top: 23px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media #{$sp} {
      margin-top: 10.5px;
    }

    .item-wrapper-02 & {
      margin-top: 25px;

      @media #{$sp} {
        margin-top: 11px;
      }
    }
  }

  &__qty {
    font-size: 0;
    margin-left: -2px;
    height: 50px;

    @media #{$pc} {
      flex: 0 0 92.13px;
    }

    .item-wrapper-02 & {
      flex: none;
    }
  }

  &__qty-label {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
  }

  &__qty-select {
    appearance: none;
    border: 1px solid $border-gray-color;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Source Han Sans JP";
    line-height: 1.71428571;
    letter-spacing: 0;
    text-align: right;
    padding: 9px 14px 11px;
    margin-left: 7px;
    width: 60px;
    height: 50px;

    @media #{$sp} {
      margin-left: 7px;
    }

    .item-wrapper-02 & {
      margin-left: 12px;

      @media #{$sp} {
        margin-left: 9px;
      }
    }
  }

  &__btn {
    @include btn(brownBgCart);

    line-height: 2.14285714;
    padding: 9px 10px 11px;
    margin-left: 8.253968%;
    max-width: 200px;

    @media all and (min-width: 600px) and (max-width: 756px) {
      margin-left: 26px;
    }

    @media #{$sp} {
      margin-left: 14px;
      max-width: 220px;
    }

    .item-wrapper-02 & {
      margin-left: 20px;
    }
  }

  /*================================================
    .item-wrapper-02 banner用クラス
  ================================================*/

  .outer-care {
    margin-top: 126px;

    @media #{$sp} {
      margin-top: 84px;
    }

    &__wrap {
      max-width: 900px;
      margin: 0 auto;
    }

    &__img {
      position: relative;

      img {
        height: 195px;
        object-fit: cover;

        @media #{$sp} {
          height: 121px;
        }
      }
    }

    &__box {
      width: 100%;

      position: absolute;
      top: 28px;
      left: 47px;

      @media #{$sp} {
        top: 16px;
        left: 23px;
      }
    }

    &__box-title {
      font-size: 25px;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0;

      @media #{$sp} {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.66666667;
      }
    }

    &__box-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57142857;
      letter-spacing: 0;

      @media #{$sp} {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.66666667;
      }
    }

    &__box-btn {
      margin-top: 15px;

      @media #{$sp} {
        margin-top: 11px;
      }
    }

    &__box-btn-link {
      @include btn;

      @media #{$sp} {
        font-size: 9px;
        font-weight: 500;
        line-height: 1.66666667;
        letter-spacing: 0;

        padding: 7px 10px 8px 12px;
        max-width: 151px;
      }
    }
  }
}
