/*
  ----------------
  #order-entry | 注文内容の確認
  ----------------
*/

#order-entry,
.order-process-main-payment-status {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;

  @media all and (max-width : 850px) {
    padding: 110px 30px 120px;
  }

  @media #{$sp} {
    padding-top: 61px;
  }

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  // クーポンの利用
  #order-entry-content {

    #publish-coupon>div.body {
      @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  // 所有しているクーポン
  #apply-coupons {

    .coupon {
      @media #{$sp} {
        width: calc(50% - 7.5px);
      }

      .btn {
        @media #{$sp} {
          max-width: 100%;
        }
      }
    }
  }

  .form-note {
    margin: 0;
    padding: 0;
  }

  // 注文内容修正ボタン
  #page-title+.order-confirm-upselling {
    .cartMessage_order_edit {
      display: block;
    }
  }
  // 友の会入会ボタン
  .order-confirm-upselling {
    margin-top: 20px;
    text-align: center;

    .order-upselling-contents {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @media #{$sp} {
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }

    .anchorlink {
      max-width: 390px;
      margin: 0 auto;
      width: 100%;

      @media #{$sp} {
        max-width: 500px;
      }
    }

    .cartMessage_order_edit {
      display: none;
      margin-right: 50px;

      @media #{$sp} {
        margin-top: 20px;
        margin-right: 0;
      }

      a {
        max-width: 370px;
        margin: 0 auto;
        width: 100%;

        @media #{$sp} {
          max-width: 500px;
        }
      }
    }

    .order-upselling-form .checkbox {
      display: none;
    }
  }

  .checkout {
    text-align: center;
  }

  .checkout button {
    max-width: 250px;
    padding: 10px 0;
  }

  h2 {
    margin-top: 3rem;

    @media (max-width: 768px) {
      margin-top: 60px;
    }
  }

  h3 {
    border-bottom: 1px solid #171717;
    font-weight: 400;
    margin-top: 50px;
    padding-bottom: 10px;
    text-align: left;

    @media #{$sp} {
      margin-top: 60px;
    }
  }

  h4 {
    font-weight: bold;
  }

  .coupons {
    margin-top: 15px;

    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    .coupon {

      .btn-apply-coupon {
        text-decoration: none;
      }
    }
  }

  // クーポンの利用
  #publish-coupon {

    .body {
      margin-top: 3rem;
      padding-right: 2rem;

      .input-group {
        flex-wrap: nowrap;

        .form-control {
          border-color: #171717;
          margin-right: 10px;
        }

        .btn-publish-coupon {
          display: flex;
          align-items: center;
          justify-content: center;

          @media #{$sp} {
            font-size: 12px;
            max-width: 120px;
            padding: 10px;
          }
        }
      }
    }
  }

  #order-entry-order {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      display: block;
    }

    .panel {
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }

  .btn-apply-coupon {
    text-decoration: underline;
  }

  .panel {
    flex: 1;
    margin-top: 3rem;
    position: relative;

    @media #{$sp} {
      margin-top: 4rem;
    }

    &.payment {
      margin-left: 3rem;
      padding-left: 0;
      padding-right: 0;

      @media #{$sp} {
        margin-left: 0;
      }
    }

    .header {
      background-color: #f2f2f2;
      padding: 1em;
      width: 100%;
    }

    .body {
      position: relative;
      margin-top: 1rem;
      padding-left: 2rem;

      @media (max-width: 768px) {
        padding-left: 0;
      }
    }

    .person-contents {
      padding: 0.5em;

      @media #{$sp} {
        padding: 0;
      }
    }

    .delivery-service-conf {
      li {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        &:first-of-type {
          display: none;
        }
      }
      .dlv-flags {
        li {
          display: block;
        }
      }

      li h4 {
        flex-basis: 20%;
      }

      li:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }

  .itemdetails-header {
    margin-top: 8rem;
  }

  .itemdetails-body {
    @media #{$sp} {
      margin-top: 4rem;
    }

    .remove-one .btn {
      padding: 0 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .itemdetails-caption {
    padding: 0 2rem;

    @media #{$sp} {
      padding: 0;
    }

    .active .label {
      color: #9b0000;
      font-weight: bold;

      &:after {
        border-left-color: #9b0000;
      }
    }

    .move-item-dst {
      li[data-dst-param="copy"] {
        display: none;
      }
    }
  }

  div.move-item-wrapper ul.move-item-dst > li.inactive {
    background-color: transparent;
  }

  .control {
    position: absolute;
    top: 0.75em;
    right: 1em;
    text-align: right;

    @media #{$pc} {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    @media #{$sp} {
      position: static;
      text-align: center;
    }

    a:not(.btn-use-points) {
      background-color: #fff;
      border: 1px solid #000;
      padding: 0 1rem;

      @media #{$sp} {
        margin-top: 3rem;
        // margin-left: 2rem;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.allow-update-deliveryrequest-control {
      @media #{$sp} {
        text-align: center;
      }
    }

    a.btn-edit-deliveryservice {
      @media #{$sp} {
        margin-top: 2rem;
        margin-left: 0;
      }
    }
  }

  .header .header{
    // 編集ボタン
    .btn {
      @media #{$pc} {
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #000;
        display: inline-block;
        font-size: 1.3rem;
        padding: 0 10px;
        text-align: center;
      }

      @media #{$sp} {
        padding: 10px 20px;
        margin-top: 1rem;
      }
    }
  }

  // お支払い方法
  .body .control .btn {
    @media #{$sp} {
      margin-top: 10px;
    }

    img {
      max-width: 150px;

      @media #{$sp} {
        max-width: 170px;
      }
    }
  }

  .addressbook {
    text-align: center;

    .btn-addressbook {

      @media #{$pc} {
        max-width: 220px;
      }
    }
  }

  .payment-total-invoice {
    @media #{$sp} {
      margin-top: 2rem;
    }
  }

  .panel-view {
    @media #{$sp} {
      margin-top: 1rem;
    }
  }

  .move-item-toggle {
    margin-top: 3rem;
    padding-left: 2rem;

    @media (max-width: 768px) {
      padding-left: 0;
    }

    .btn-moveitem.disable-moveitem {
      position: relative;
      margin-top: 1rem;

      @media (max-width: 768px) {
        padding: 0 1.5rem;
      }

      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translateY(-50%);
        margin: auto;
        box-sizing: border-box;
        border: 5px solid transparent;
        border-left: 8px solid #555;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          left: 0;
        }
      }

      .btn {
        background: none;
        border: 0;
        color: inherit;
        font: inherit;
        line-height: normal;
        overflow: visible;
        padding: 0;

        text-align: left;
        text-decoration: underline;
      }

    }

    .btn-moveitem.enable-moveitem {
      text-align: center;

      .btn {
        max-width: 410px;

        @media #{$sp} {
          // margin-left: -20px;
        }
      }
    }
  }

  .move-item-wrapper-main {
    @media (max-width: 768px) {
      padding: 0 1.5rem;
    }
  }

  .new-destination-consignee {
    margin-top: 8rem;

    @media #{$sp} {
      margin-top: 4rem;
    }
  }

  .form {
    margin-top: 1rem;
  }

  .form-body {
    margin: 4rem -4rem 0;
    padding: 50px 8.5406%;

    @media #{$sp} {
      padding: 25px 30px;
    }
  }

  .move-item-dst {
    li {
      margin-top: 1rem;
      padding: 0 2rem;

      @media (max-width: 768px) {
        padding: 0;
      }

      .label {
        cursor: pointer;
        position: relative;
        text-decoration: underline;
      }

      .label::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 10px;
        left: -15px;
        margin: auto;
        box-sizing: border-box;
        border: 5px solid transparent;
        border-left: 8px solid #555;
        margin-bottom: 20px;
      }

      &:nth-of-type(n + 3) {
        display: none;
      }
    }
  }

  .shipment-items-total {
    margin-top: 8rem;

    @media #{$sp} {
      margin-top: 4rem;
    }

    .row {
      justify-content: center;
    }
  }

  .update-present-option {
    display: none;
  }
  .present-option-add {
    background-color: #ceefff;
    font-weight: 700;
    padding: 2rem 0;
    align-items: center;
    display: flex;
    margin-top: 3rem;
    text-align: center;
    justify-content: center;
    font-size: 2rem;

    &__note {
      text-align: right;
    }

  }
  .present-applied .caption {
    height: 0;
    overflow: hidden;
  }

  .present-option-add__note {
    display: none;
  }

  .present-list {
    background-color: #ceefff;
    font-weight: 700;
    padding: 2rem 0;
    align-items: center;
    display: flex;
    margin-top: 50px;
    text-align: center;
    justify-content: center;
    font-size: 2rem;

    li+li {
      margin-top: 10px;
    }

    .present-option-code {
      display: none;
    }

    // 友人紹介プレゼント
    .present-3380 {

      @media #{$sp} {
        font-size: 12px;
      }

      .property {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
      }

      .description:after {
        content: '：';
      }
    }

    // 友の会入会プレゼント
    .present-P001 {

      @media #{$sp} {
        font-size: 12px;
      }

      .property {
        display: inline;

        &:after {
          content: '：';
        }
      }

      .present-option {
        display: inline;
      }

      .description {
        display: inline;
      }
    }
  }

  // お届け先の分割・商品の移動を決定する
  .move-item-submit .submit-moveitem {
    margin-top: 40px;
    text-align: center;

    .btn {
      max-width: 310px;
      max-width: fit-content;
      padding: 10px 20px;
      text-decoration: none;

      @media #{$sp} {
        width: 100%;
      }
    }
  }

  // オプション変更ボタン
  .goods-text .update-itemoptions {

    a {
      margin-top: 10px;
      text-decoration: none;
    }

  }

  .goods-text .unit-price {
    margin-top: 10px;
  }

  .goods-text .applied-promotions {
    display: none;
  }

  .amount-table {
    margin-top: 3rem;
    width: 100%;

    table {
      border-collapse: collapse;
      margin-bottom: 10px;
      width: 100%;
    }

    th,
    td {
      font-size: 16px;
      font-weight: 400;
      border: 1px solid lightgray;
      padding: 10px 20px;

      @media #{$sp} {
        font-size: 14px;
        font-weight: 500;
      }
    }

    th {
      background: #f2f2f2;
      border-right: none;
    }
  }

  // 適用中のクーポン
  #detach-coupons {

    ul {
      padding-top: 15px;
    }

    .detach-enabled {
      margin-top: 5px;
    }

    .cpn-exp {
      display: inline-block;
      margin-left: 10px;
    }

    .date {
      margin-left: 4px;
    }

    .btn-detack-coupon {
      margin-top: 15px;
    }

    @media (max-width:469px) {
      ul {
        padding-top: 20px;
      }

      .detach-enabled:nth-child(2) {
        margin-top: 15px;
      }

      label {
        display: inline-block;
        line-height: 1.75;
      }

      .btn-detack-coupon {
        margin-top: 20px;
      }
    }
  }
}
