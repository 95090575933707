/*
  ----------------
  Online Counseling | Flow
  ----------------
*/
.counseling-flow {
  &__wrap {
    margin: 0 auto;
    max-width: 1206px;
    padding: 110px 0 101px;

    @media all and (max-width : 900px) {
      padding: 81px 30px 35px;
    }
  }

  &__header {
    // padding: 0 50px;
  }

  &__title {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.08em;
    text-align: center;

    @media all and (max-width : 900px) {
      font-size: 25px;
      letter-spacing: 0;
      line-height: 1.6;
      text-align: center;
    }
  }

  &__list {
    counter-reset: number 0;
    margin-top: 74px;

    display: flex;
    justify-content: space-between;

    @media all and (max-width : 900px) {
      display: block;
      margin-top: 63px;
    }
  }

  &__item {
    box-sizing: content-box;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 3.0769;
    padding: 0 16.5px;
    position: relative;
    width: 25%;

    display: flex;
    flex-direction: column;

    @media all and (max-width : 900px) {
      display: block;
      padding: 0 0 46px 72px;
      width: auto;
    }

    &:before {
      background: $white-color;
      border: 1px solid $black-color;
      border-radius: 50%;
      counter-increment: number;
      content: counter(number,decimal-leading-zero);
      font-size: 25px;
      height: 60px;
      letter-spacing: 0;
      margin: 0 auto;
      width: 60px;

      display: flex;
      align-items: center;
      justify-content: center;

      @media all and (max-width : 900px) {
        box-sizing: border-box;
        margin: 0;

        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &::after {
      background-color: $black-color;
      content: "";
      display: block;
      height: 1px;
      width: 100%;

      position: absolute;
      top: 31px;
      right: 0;
      z-index: -1;

      @media all and (max-width : 900px) {
        height: 100%;
        width: 1px!important;

        right: auto;
        left: 30px;
      }
    }

    &:first-child {
      @media #{$pc} {
        padding-left: 0;

        &::after {
          width: 50%;
        }
      }

      @media all and (max-width : 900px) {
        padding-left: 72px;

        .counseling-flow__text {
          margin-top: 15px;
        }
      }
    }

    &:nth-child(2) {
      @media all and (max-width : 900px) {
        .counseling-flow__title-sub {
          &::after {
            background: url(/assets/img/counseling/icon_mail.svg) no-repeat center center;
            background-size: contain;
            height: 34.78px;
            margin-left: 22px;
            width: 54px;
          }
        }
      }
    }

    &:nth-child(3) {
      @media all and (max-width : 900px) {
        .counseling-flow__title-sub {
          &::after {
            background: url(/assets/img/counseling/icon_zoom.svg) no-repeat center center;
            background-size: contain;
            height: 48.2px;
            margin: 0 0 0 61px;
            width: 64.48px;
          }
        }
      }
    }

    &:last-child {
      @media #{$pc} {
        padding-right: 0;
      }

      @media all and (max-width : 900px) {
        .counseling-flow__title-sub {
          padding-top: 16px;

          &::after {
            background: url(/assets/img/counseling/icon_counseling.svg) no-repeat center center;
            background-size: contain;
            height: 49.03px;
            margin: -9px 0 0 31px;
            width: 43.98px;
          }
        }

        .counseling-flow__text {
          margin-top: 12px;
        }
      }

      &::after {
        right: auto;
        left: 0;
        width: 50%;

        @media all and (max-width : 900px) {
          display: none;
        }
      }
    }
  }

  &__title-sub {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6;
    margin-top: 25px;
    text-align: center;

    @media #{$pc} {
      min-height: 64px;
    }

    @media all and (max-width : 900px) {
      margin-top: 0;
      text-align: left;

      display: flex;
      transform: translateY(-2px);

      &::after {
        background: url(/assets/img/counseling/icon_form.svg) no-repeat center center;
        background-size: contain;
        content: "";
        display: block;
        height: 48.07px;
        margin: auto 0 auto 31px;
        width: 45.41px;
      }
    }
  }

  &__img {
    background-color: $main-bg-color;
    height: 160px;
    margin-top: 26px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width : 900px) {
      display: none;
    }
  }

  &__icon {
    width: auto;
  }

  &__text {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 2.3077;
    margin-top: 15px;

    @media all and (max-width : 900px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 2.1429;
      margin-top: 6px;
    }
  }

  &__zoom {
    background-color: $white-color;
    border: 1px solid $black-color;
    height: 82px;
    margin-top: 37px;
    padding: 16px 0 0 8px;
    position: relative;

    display: flex;
    align-items: inherit;

    @media all and (max-width : 900px) {
      display: block;
      height: auto;
      margin: 30px 0 -6px;
      padding: 12px 0 19px;
      text-align: center;
    }
  }

  &__zoom-title {
    background-color: $white-color;
    font-size: 13px;
    font-weight: 500;
    line-height: 2.3077;
    margin: 0 auto;
    padding: 0 17px;

    position: absolute;
    top: -17px;
    left: 50%;
    transform: translateX(-50%);

    @media all and (max-width : 900px) {
      font-size: 15px;
      line-height: 2.3333;

      top: -20px;
    }
  }

  &__zoom-btn {

    &:nth-of-type(2) {
      @media #{$pc} {
        padding-top: 3px;
      }

      @media all and (max-width : 900px) {
        margin-top: 2px;

        img {
          width: 209.56px;
        }
      }
    }

    img {
      width: auto;

      @media all and (max-width : 900px) {
        width: 237.49px;
      }
    }
  }
}