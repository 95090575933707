/*
  ----------------
  Form & Table | フォーム & テーブル
  ----------------
*/

body.signup {
  h1 {
    @include pageTitle($white-color, signup);
    margin-bottom: 98px;

    @media #{$sp} {
      margin-bottom: 39px;
    }
  }
}

.form-note,
.submit-note {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.8571;
  margin: 43px 0;
  text-align: center;

  @media #{$sp} {
    margin: 30px 0;
    padding: 0 30px;
  }
}

.form-body {
  @include container();
  background: $main-color;
  padding: 70px 8.5406% 130px;

  @media #{$pc} {
    max-width: 1206px;
  }

  @media all and (min-width: 600px) and (max-width: 850px) {
    padding: 50px 30px 70px;
  }

  @media #{$sp} {
    padding: 50px 30px 70px;
  }

  .form-rps-consignee &,
  .form-edit-account &,
  .form-edit-address & {
    @media all and(min-width: 600px) and (max-width: 794px) {
      padding: 50px 30px 70px;
    }
  }

  // ご利用規約
  .signup-form {
    &__title {
      font-size: 25px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 1.6;
      text-align: center;
    }

    &__note,
    .note-signu {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 1.8462;
      margin-top: 22px;
      text-align: center;

      @media #{$sp} {
        font-size: 14px;
        line-height: 2.1429;
        padding: 0;
        text-align-last: left;
      }
    }

    &__terms {
      background: $white-color;
      border: 1px solid $light-gray-color;
      border-radius: 2px;
      box-sizing: border-box;
      color: $black-color;
      display: block;
      font-size: 13px;
      font-weight: 400;
      height: 100vh;
      line-height: 1.8462;
      margin: 45px auto 0;
      max-height: 553px;
      max-width: 1000px;
      overflow-y: scroll;
      padding: 50px;
      width: 100%;

      @media #{$sp} {
        font-size: 14px;
        line-height: 2.1429;
        margin: 45px 0 0;
        max-height: 814px;
        padding: 10px;
      }
    }

    .btn-submit {
      margin-top: 60px;

      @media #{$sp} {
        margin-top: 47px;
      }
    }
  }
}

fieldset {
  border: none;
  margin: 60px 0 0;
  padding: 60px 0 0;
  position: relative;

  &::before {
    background-color: $light-gray-color;
    content: "";
    display: block;
    height: 1px;
    margin: 0 0 0 -103px;
    width: calc(100% + 206px);

    position: absolute;
    top: 0;
    left: 0;
  }

  // &:nth-of-type(1) {
  //   border-top: none;
  // }

  @media #{$sp} {
    margin: 55px 0 0;
    padding: 55px 0 0;
  }

  //ID・パスワードの変更
  &.new-password {
    &::before {
      top: -40px;
    }

    legend {
      font-size: 25px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 1.6;
      text-align: center;
      transform: translateY(50px);
    }
  }
}

.formrow {

  @media #{$pc} {
    display: flex!important;
    margin-top: 30px;
  }

  @media #{$sp} {
    display: block;
    font-size: 15px;
    // flex-direction: column;
  }

  &:not(:first-of-type),
  &.formrow-checkbox {
    border-top: none;
  }

  .form-rps-consignee &,
  .form-edit-account &,
  .form-edit-address &,
  .manage-paymentmethod & {
    @media all and(min-width: 600px) and (max-width: 794px) {
      display: block;
    }
  }
}

.input-label {
  display: flex;
  align-items: baseline;

  @media #{$pc} {
    flex-basis: 33.665%;
  }

  @media #{$sp} {
    margin-top: 12px;
    align-items: center;
  }

  .form-edit-account &,
  .form-edit-address & {

    .input-may-required {
      border: 1px solid $bronw-color;
      border-radius: 10px;
      color: $bronw-color;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.08em;
      margin-left: 1rem;

      height: 21px;
      width: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      @media #{$sp} {
        height: 20px;
      }
    }
  }
}

.input-divider-heading {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 40px;

  @media #{$sp} {
    font-weight: 500;
    margin-top: 36px;
  }
}

.input-name {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;

  @media #{$sp} {
    font-size: 14px;
    font-weight: 500;
  }
}

.input-required {
  border: 1px solid $bronw-color;
  border-radius: 10px;
  color: $bronw-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08em;
  margin-left: 1rem;

  height: 21px;
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$sp} {
    height: 20px;
  }
}

.input-control {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  @media #{$sp} {
    margin-top: 13px;
  }

  .form-rps-consignee &,
  .form-edit-account &,
  .form-edit-address &,
  .manage-paymentmethod & {
    @media all and(min-width: 600px) and (max-width: 794px) {
      margin-top: 13px;
    }
  }
}

.input-control-group {
  .input-group {
    width: auto;

    @media #{$sp} {
      // margin-top: 1rem;
    }
  }
}

.input-group {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  & + .input-group {
    @media #{$pc} {
      margin-left: 28px;
    }

    @media #{$sp} {
      margin-left: 12px;
    }
  }

  .manage-paymentmethod & {
    flex-wrap: nowrap;
  }

  // デフォルトスタイルリセット
  input,
  select {
    -webkit-appearance: none;
    appearance: none;
  }

  // 共通スタイル
  input[type=text],
  input[type=email],
  input[type=tel],
  input[type=number],
  input[type=password],
  input[type=date],
  select,
  textarea{
    border: 1px solid $light-gray-color;
    border-radius: 2px;
    font-size: 16px;
    letter-spacing: 0;
    padding: 1.5rem 2rem;
    resize: vertical;
    width: 100%;

    @media #{$sp} {
      font-size: 14px;
      font-weight: 500;
    }

    &::placeholder {
      color: $gray-color;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;

      @media #{$sp} {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .name,
  .kana {
    // max-width: 321px;
  }

  // 郵便番号 都道府県 職業
  .postalCode,
  .pref,
  .job {
    max-width: 227px;
  }

  // セレクトボックス共通
  select {
    background-image: url(/assets/img/signup/icon_arrow_select.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 29px 29px;

    -webkit-appearance: none;
    appearance: none;
  }

  // 生年月日
  .date {
    position: relative;
    width: 31.8182%;

    -webkit-appearance: none;
    appearance: none;

    .manage-paymentmethod & {
      max-width: 100%;

      @media #{$sp} {
        width: 100%;
      }
    }

    &.date-yyyy,
    &.date-mm,
    &.date-dd {
      background-image: url(/assets/img/signup/text_year_select.svg);
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: 16px 24px;
    }

    &.date-yyyy {

      .manage-paymentmethod & {
        margin-left: 15px;
      }
    }

    &.date-mm {
      background-image: url(/assets/img/signup/text_month_select.svg);
      margin-left: auto;

      @media #{$sp} {
        margin-left: 2.1%;
      }

      .manage-paymentmethod & {
        margin-left: 0;
      }
    }

    &.date-dd {
      background-image: url(/assets/img/signup/text_day_select.svg);
      margin-left: auto;

      @media #{$sp} {
        margin-left: 2.4242%;
      }
    }
  }

  .date-delim {
    display: none;
  }

  textarea {
    height: 196px;

    @media #{$sp} {
      max-width: none;
    }
  }

  // チェックボックス
  label {
    font-size: 16px;
    letter-spacing: 0;
    padding-left: 45px;
    position: relative;
    width: 100%;

    display: flex;
    align-items: center;

    @media #{$sp} {
      font-size: 14px;
      font-weight: 400;
    }

    & + label {
      margin-top: 15px;
    }

    input[type=checkbox] {
      cursor: pointer;
      position: absolute;
      left: 0;
      width: 0;

      &::before,
      &::after {
        border-radius: 4px;
        box-sizing: border-box;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &::before {
        background-color: $white-color;
        border: 1px solid #DBDBDB;
        height: 30px;
        width: 30px;
      }

      &:checked::after {
        background: #171717;
        height: 18px;
        left: 6px;
        width: 18px;
      }
    }
  }

  // ラジオボタン
  .radio-item {
    display: inline-block;
    float: left;

    @media #{$sp} {
      margin-left: 0;
      margin-bottom: 0.5rem;
    }

    &:not(:first-of-type) {
      margin-left: 53px;

      @media #{$sp} {
        margin-left: 20px;
      }
    }

    label {
      display: inline-block;
      font-size: 16px;
      letter-spacing: 0;
      padding-left: 45px;
      position: relative;

      display: flex;
      align-items: center;
    }
  }

  input[type=radio] {
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 0;

    &::before,
    &::after {
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::before {
      background-color: $white-color;
      border: 1px solid #DBDBDB;
      height: 30px;
      width: 30px;
    }

    &:checked::after {
      background: #171717;
      height: 18px;
      left: 6px;
      width: 18px;
    }
  }
}

.formrow-radio {
  label {
    display: inline-block;
    font-size: 16px;
    letter-spacing: 0;
    padding-left: 45px;
    position: relative;
    width: auto;

    display: flex;
    align-items: center;

    & + label {
      margin-top: 0;
      margin-left: 53px;

      @media #{$sp} {
        margin-left: 20px;
      }
    }
  }
}

.input-note {
  font-size: 13px;
  flex-basis: 100%;
  margin-top: 0.5rem;
  @media #{$sp} {
    font-size: 10px;
  }
}

.btn-submit,
.formrow-submit {
  text-align: center;

  @media #{$sp} {
    margin-top: 20px;
  }
}

.signup-note,
button.btn-submit {
  margin-top: 60px;
  text-align: center;

  @media #{$sp} {
    margin-top: 47px;
  }
}

.nmessage-default {
  color: $alert-text-color;
}
