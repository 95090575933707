/*
  ---------------
  .item-list-sub | 商品一覧 3点セット単品販売部分
  ---------------
*/
.item-list-sub {
  @media #{$pc} {
    margin: 5.18242123% -31px 0;

    display: flex;
  }

  @media all and (min-width: 600px) and (max-width: 1100px) {
    flex-wrap: wrap;
  }

  @media all and (min-width: 600px) and (max-width: 709px) {
    margin: 69px 0 0;
  }

  @media #{$sp} {
    padding: 0 34px 0 36px;
    margin-top: 69px;
  }


  .item-list-sub-each {

    @media #{$pc} {
      width: calc((100% - 186px) / 3);
      margin: 31px;

      display: flex;
      flex-direction: column;
    }

    @media all and (min-width: 710px) and (max-width: 1100px) {
      width: calc((100% - 124px) / 2);
    }

    @media all and (min-width: 600px) and (max-width: 709px) {
      display: block;
      width: 100%;
    }

    & + .item-list-sub-each {

      @media all and (min-width: 600px) and (max-width: 709px) {
        margin-top: 72px;
      }

      @media #{$sp} {
        margin-top: 72px;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.03em;
      text-align: center;

      @media #{$sp} {
        font-weight: 500;
        line-height: 1.15;
      }
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.78571429;
      letter-spacing: 0.03em;
      text-align: center;
      margin-top: 4px;

      @media #{$sp} {
        line-height: 1.625;
        margin-top: 13px;
      }
    }

    &__img {
      margin-top: 9.9815157%;

      @media all and (min-width: 600px) and (max-width: 709px) {
        margin-top: 43px;
      }

      @media #{$sp} {
        margin-top: 43px;
      }
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 2.14285714;
      letter-spacing: 0.03em;
      margin-top: 5.68391866%;

      @media #{$sp} {
        line-height: 2.5;
        margin-top: 23px;
      }
    }

    &__info {
      flex-grow: 1;
      margin-top: 5.40665434%;

      @media #{$sp} {
        margin-top: 25px;
      }
    }

    &__content {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.78571429;
      letter-spacing: 0.03em;

      @media #{$sp} {
        line-height: 2.14285714;
      }
    }

    &__price {
      font: 14px;
      font-weight: 400;
      line-height: 1.78571429;
      letter-spacing: 0.03em;

      @media #{$sp} {
        line-height: 2.14285714;
      }
    }

    &__btn {
      text-align: center;
      margin-top: 12.1996303%;

      @media #{$sp} {
        margin-top: 46px;
      }
    }

    &__btn-link {
      @include btnLink(brown);

      font-size: 14px;
      font-weight: 400;
      line-height: 2.14285714;
      padding: 8px 10px 10px 12px;
    }
  }

}
