/*
  ----------------
  .form-sender | 送り主の編集
  ----------------
*/
.form-sender {
  padding: 110px 80px 120px;
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;

  @media all and (max-width : 850px) {
    padding: 0 30px 120px;
  }

  @media #{$sp} {
    padding-top: 61px;
  }

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  .form-note {
    @media #{$sp} {
      padding: 0;
      text-align-last: center;
    }
  }

  .form-body {
    @media #{$pc} {
      padding-bottom: 70px;
    }

    @media #{$sp} {
      margin-right: -30px;
      margin-left: -30px;
    }

    .formrow-submit {
      margin-top: 40px;
    }
  }

  .order-cancel-edit {
    margin-top: 40px;
  }
}