/*
  ----------------
  会社について | Philosophy
  ----------------
*/

.about-philosophy {

  &__wrap {
    @include container;
    padding-top: 95px;

    @media all and (max-width : 800px) and(min-width: 600px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media #{$sp} {
      padding-top: 115px;
    }
  }

  &__title-sub {
    @include h2TitleRuby();

    @media #{$sp} {
      img {
        height: 59px;
      }
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.08em;
    line-height: 0.8;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      line-height: 1.6;
    }
  }

  &__title-img {}

  &__list {
    padding-top: 40px;

    display: flex;
    gap: 40px;
    justify-content: center;

    @media all and (max-width : 800px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    @media #{$sp} {
      gap: 50px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: calc((100% - 80px) / 3);

    @media all and (max-width : 800px) {
      width: 330px;
    }
  }

  figure {
    width: 55%;

    @media #{$sp} {
      max-width: 207px;
      width: 100%;
    }
  }

  &__img figure {
  }

  &__meaning {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.15em;
    line-height: 1;
    margin-top: 10px;
    text-align: center;

    @media #{$sp} {
      margin-top: 15px;
    }
  }

  &__text {
    font-size: 15px;
    letter-spacing: 0.08em;
    line-height: 1.6;
    margin-top: 20px;
    text-align: center;
  }
}