/*
  ----------------
  SP menu
  ----------------
*/
.sp-menu {
  border-left: 1px solid $white-color;

  &__button {
    padding: 15px 13px 0 16px;
    position: relative;

    height: 60px;
    width: 60px;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }


  &__icon {
    height: 16px;
    width: 30px;
    position: relative;
  }

  &__bar {
    background-color: #000;
    display: block;
    transition: transform .2s ease-in-out,
    background-color .2s ease-in-out;

    height: 1px;
    width: 30px;

    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  &__bar--top,
  &__bar--bottom {
    transform: translate(-50%, -50%) rotate(0);
  }


  &__bar--top {
    top: 1px;
    transform-origin: top left;
  }

  &__bar--middle {
    top: 50%;
  }

  &__bar--bottom {
    bottom: 0;
    transform-origin: bottom left;
  }

  &__icon-text {
    width: 100%;
  }

  // Sidebar menu open
  .sidebar-is-open & {
    background-color: transparent;
    transition: background-color .2s ease-in-out;

    &__bar--top,
    &__bar--bottom {
      background-color: $white-color;
      transition: transform .2s ease-in-out,
      background-color .2s ease-in-out;
    }

    &__bar--top {
      transform: translate(-50%, -50%) rotate(28deg);
      transform-origin: top left;
    }

    &__bar--middle {
      display: none;
    }

    &__bar--bottom {
      transform: translate(-50%, -50%) rotate(-28deg);
      transform-origin: bottom left;
    }

    .sp-menu__icon-text {
      fill: #fff;
    }
  }
}