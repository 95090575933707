/*
  ----------------
  会社について | 会社情報
  Add break point 900px
  ----------------
*/
.about-info {

  &__wrap {
    padding: 140px 0 107px;

    @media #{$sp} {
      padding: 145px 0 60px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.08em;
    line-height: 1.2;
    text-align: center;
  }

  &__contents {
    @include container;
    display: flex;
    margin-top: 53px;

    @media all and (max-width : 900px) {
      flex-direction: column-reverse;
    }

    @media all and (max-width : 800px) and (min-width: 600px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &__main {
    width: 100%;

    @media all and (max-width : 900px) {
      margin-top: 48px;
    }
  }

  &__view {
    display: flex;

    @media #{$pc} {
      margin-left: 54px;
      max-width: 258px;
      width: 100%;

      flex-direction: column;
      justify-content: space-between;
    }

    @media all and (max-width : 900px) {
      flex-direction: row-reverse;
      margin-left: 0;
      max-width: 100%;
    }

    @media #{$sp} {
      margin: auto calc((50vw - 50%) * -1);
      max-width: none;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead {

  }

  tbody {

  }

  tr {
    border-top: 1px solid $light-gray-color;
    text-align: left;

    display: flex;
    align-items: flex-start;

    &:last-child {
      border-bottom: 1px solid $light-gray-color;
    }

    @media #{$sp} {
      padding: 12px 0 16px 30px;

      align-items: flex-start;
      flex-direction: column;
    }
  }

  th,
  td {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15em;
    line-height: 2;
    padding: 18px 0 17px;

    @media #{$sp} {
      letter-spacing: 0.15em;
      padding: 0;
    }
  }

  th {

    @media #{$pc} {
      min-width: 217px;
      padding-left: 28px;
    }

    @media #{$sp} {
      font-size: 16px;
      font-weight: 500;
    }
  }

  td {
    @media #{$sp} {
      font-size: 13px;
    }
  }

  &__name {
    margin-left: 17px;
  }

  &__view-img {
    @media all and (max-width : 900px) {
      // width: 50%;
      flex-basis: 50%;
    }
  }


}