/*
  ---------------
  .rp-subscription | 定期アイテム一覧(定期基本情報)
  ---------------
*/
ol.rp-subscriptions-nav-list {
  margin-top: 30px;
  text-align: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);

  @media all and (min-width: 600px) and (max-width: 850px) {
    margin-top: 15px;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  @media #{$sp} {
    margin-top: 15px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }

  a,
  span {
    border: 1px solid #171717;
    color: #171717;
    display: inline-block;
    letter-spacing: .08em;
    padding: 9px 10px 9px 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
    width: 100%;
    padding: 14px 5px;
  }

  li.active {
    span {
      background-color: #171717;
      border: 1px solid #171717;
      color: #fff;
    }
  }
}

.rp-subscriptions {

  /*================================================
    .panel | お届け先〜お支払い方法
  ================================================*/
  .panel {
    border: 1px solid $form-border-color;
    margin-top: 40px;

    .consignee {
      border-bottom: 1px solid $form-border-color;

      @media #{$pc} {

        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }
    }

    .payment-method {

      @media #{$pc} {

        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }
    }

    .person-contents,
    .payment-method-name {
      display: block;
      padding: 10px;

      @media #{$pc} {
        flex: 0 1 100%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
      }

      @media #{$sp} {
        text-align: center;
      }
    }
  }

  /*================================================
    .regular-purchase | 出荷予定日〜単価
  ================================================*/
  .regular-purchase {
    margin-top: 40px;

    .panel {
      margin-top: 0;
    }
  }


  /*================================================
  .panel-heading | 出荷予定日〜利用状態
  ================================================*/
  .panel-heading {

    > div {

      > span {
        display: block;
        padding: 10px;

        @media #{$pc} {
          flex: 0 1 100%;
        }

        @media all and (min-width: 600px) and (max-width: 850px) {
          text-align: center;
        }

        @media #{$sp} {
          text-align: center;
        }
      }
    }

    .next-shipping-date {
      div {
        color: #ba0000;
        font-weight: bold;
      }
    }

    .next-shipping-date-prospect {
      div {
        color: #ba0000;
        font-weight: bold;
      }
    }
  }

  /*================================================
    .panel-body | 商品画像〜単価(金額)
  ================================================*/
  .panel-body {

    .message-wrapper {
      // エラーメッセージ
      .error {
        background-color: #ba0000;
        color: white;
        text-align: center;
        padding: 4px 8px 0;
      }
    }

    .item {
      text-align: center;
      padding: 30px 0;

      @media #{$pc} {
        display: flex;
        align-items: center;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {
        padding: 30px 20px;
      }
    }

    .item-text {
      text-align: left;

      @media #{$pc} {
        flex: 0 1 100%;
        margin-left: 10px;
      }

      @media all and (min-width: 600px) and (max-width: 1047px) {
        margin-left: 0;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: inline-block;
        margin-top: 10px;
        margin-left: 0;
      }

      @media #{$sp} {
        display: inline-block;
        margin-top: 10px;
        margin-left: 0;
      }
    }

    .unit-price {
      display: flex;

      > h6 {
        background: none;
        border: none;
        display: inline;
        flex: inherit;
        padding: 0;
      }

      > div {
        margin-left: 5px;
      }
    }
  }

  .panel-footer {
    .note {
      border-top: 1px solid lightgray;
      display: block;
      padding: 10px;
    }

    .btn-group {
      padding: 0 15px 15px;

      display: grid;
      gap: 15px;
      grid-template-columns: repeat(3, 1fr);

      @media (min-width: 600px) and (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{$sp} {
        padding: 0 10px 10px;
        grid-template-columns: repeat(1, 1fr);
      }

      .edit-rp-coupon,
      .edit-rp-cons,
      .edit-rp-pay,
      .edit-rp-skip-next,
      .edit-rp-stop-all {
        margin-top: 0;
      }

      .btn {
        line-height: 2.142;
        max-width: 100%;
      }
    }

    .update-shipping-schedule,
    .control {
      border-top: 1px solid lightgray;
      display: flex;

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }

      @media #{$sp} {
        display: block;
      }

      .link {
        background-color: #f2f2f2;
        border-right: 1px solid lightgray;
        flex: 0 0 260px;
        text-align: center;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        & + div {
          display: block!important;
          padding: 10px;
          flex: 0 1 100%;

          @media all and (min-width: 600px) and (max-width: 850px) {
            text-align: center;
          }

          @media #{$sp} {
            text-align: center;
          }

          .btn {
            display: block;
            margin-top: 10px;
            max-width: 250px;
          }
        }
      }

    }
  }
}
