/*
  ---------------
  .form-rps-consignee | 定期お届け先の編集
  ---------------
*/
.form-rps-consignee {

  #page-title,
  .form-note {
    @media #{$sp} {
      line-height: 2;
    }
  }

  .input-group-address {
    padding-top: 40px;
  }
}
