/*
  ----------------
  Stiky side link | FAQ | ご注文ガイド
  ----------------
*/
.stiky-side-link {
  width: 100%;

  @media #{$pc} {
    max-width: 284px;
  }

  @media all and (max-width : 960px) and (min-width : 801px) {
    max-width: 220px;
  }

  @media all and (max-width : 800px) {
    max-width: 100%;
  }

  &__list {
    background-color: $main-color;

    @media #{$pc} {

      position: sticky;
      top: 190px;
    }
  }

  &__item {
    border-top: 1px solid $light-gray-color;

    &:last-child {
      border-bottom: 1px solid $light-gray-color;
    }
  }

  &__link {
    letter-spacing: 0;
    line-height: 1.714;
    padding: 12px 0 14px 21px;
    text-align: left;
    transition: background-color .2s ease-in-out;
    width: 100%;

    &.is-active {
      background-color: $black-color;
      color: $white-color;
    }

    @media #{$sp} {
      padding: 12px 0 13px 21px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $black-color;
        color: $white-color;
        opacity: 1;
        transition: background-color .2s ease-in-out;
      }
    }
  }
}