/*
  ----------------
  TOP | Column
  ----------------
*/
.top-column {
  &__wrap {
    margin: 0 -31px;
    padding: 95px 80px 155px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding: 55px 40px 46px;
    }

    @media #{$sp} {
      margin: 0;
      padding: 80px 35px 85px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.15em;
    line-height: 1.566;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 0.96;
    }
  }

  &__list {
    margin: 25px auto 0;
    max-width: 1268px;

    display: flex;
    flex-wrap: wrap;

    @media #{$sp} {
      display: block;
      margin-top: 58px;
      max-width: 100%;
    }
  }

  &__item {
    flex-basis: calc((100% - 186px) / 3);
    margin: 40px 31px 0;


    @media #{$pc} {
      &:nth-child(n + 7) {
        display: none;
      }
    }

    @media all and (max-width : 800px) and (min-width : 600px) {
      flex-basis: calc((100% - 120px) / 3);
      margin: 20px 20px 0;
    }

    @media #{$sp} {
      display: block;
      margin: 0;

      &:nth-child(n + 4) {
        display: none;
      }

      &:not(:first-child) {
        margin: 36px 0 0;
      }
    }
  }

  &__link {
    display: inline-block;
    width: 100%;
  }

  &__img {}

  &__title-column {
    font-size: 20px;
    font-weight: normal;
    margin-top: 28px;
    letter-spacing: 0;
    line-height: 1.6;
    text-align: center;

    @media all and (max-width : 800px) and (min-width : 600px) {
      font-size: 18px;
      margin-top: 15px;
    }

    @media #{$sp} {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.333;
    }
  }

  &__text {
    letter-spacing: 0.08em;
    margin-top: 17px;
    text-align: center;

    @media all and (max-width : 800px) and (min-width : 600px) {
      margin-top: 12px;
    }

    @media #{$sp} {
      letter-spacing: 0.03em;
      line-height: 2.142;
      margin-top: 14px;
    }
  }

  &__more {
    margin-top: 65px;
    text-align: center;

    @media #{$sp} {
      margin-top: 37px;
    }
  }

  &__btn {
    @include btnLink(brown);
  }
}