/*
  ----------------
  コラム一覧 | common
  ----------------
*/
.column-main {
  @include container;
  background-color: $white-color;
  // padding-top: 98px;
  padding-bottom: 140px;

  // @media all and (max-width : 960px) {
  //   padding: 40px 40px 90px;
  // }

  // @media all and (max-width : 800px) {
  //   display: block;
  // }

  @media #{$sp} {
    // padding-top: 39px;
    padding-bottom: 144px;
  }
}

//コラム一覧
.newslist_column_index_html {

  .newslist {
    @include container;
    padding-right: 80px;
    padding-left: 80px;

    .newslist-column_less_is_more {
      margin-top: 82px;

      @media #{$sp} {
        margin-top: 64px;
      }
    }

    .newslist-column_main {
      margin-top: 98px;

      @media #{$sp} {
        margin-top: 87px;
      }
    }
  }

  .action-links-box {
    margin-top: 140px;

    @media #{$sp} {
      margin-top: 144px;
    }

    &__wrap {
      padding: 57px 71px;

      @media #{$sp} {
        padding: 83px 35px 70px;
      }
    }
  }
}

//コラム詳細
.news-archive-column_pick_up,
.news-archive-column_less_is_more,
.news-archive-column_main {

  .news-entry-icon,
  .news-entry-header,
  .trailer-link {
    display: none;
  }
}

// コラム別一覧
.column-main-less_is_more,
.column-main-main {

  .column__img {
    position: relative;
    overflow: hidden;
    padding-top: 61.1111%;

    @media #{$sp} {
      padding-top: 61.0895%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}
