.item-regular {
  margin-top: 102px;

  @media #{$sp} {
    margin-top: 83px;
  }

  .item-wrapper-02 & {
    margin-top: 114px;

    @media #{$sp} {
      margin-top: 59px;
    }
  }

  &__wrap {}

  &__title {
    border-bottom: 2px solid $black-color;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 13px;

    @media #{$sp} {
      border-bottom: 1px solid $black-color;
      font-weight: 500;
      letter-spacing: 0.08em;
      padding-bottom: 9.5px;
    }
  }

  &__block {
    margin-top: 64px;

    @media #{$sp} {
      margin-top: 30.5px;
    }

    .item-wrapper-02 & {
      @media #{$sp} {
        margin-top: 29.5px;
      }
    }
  }

  &__box {

    @media #{$pc} {
      display: flex;
    }

    @media all and (min-width: 600px) and (max-width: 1000px) {
      display: block;
    }
  }

  &__img {

    @media #{$pc} {
      flex: 0 1 100%;
    }
  }

  &__description {
    margin-top: -7px;

    @media #{$pc} {
      flex: 0 0 442px;
      margin-left: 6.59204%;
    }

    @media all and (min-width: 600px) and (max-width: 1000px) {
      margin-top: 24.6px;
      margin-left: 0;
    }

    @media #{$sp} {
      margin-top: 24.6px;
    }

    .item-wrapper-02 & {
      @media #{$sp} {
        margin-top: 25.6px;
      }
    }
  }

  &__description-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.85714286;
    letter-spacing: 0;

    @media #{$pc} {
      margin-right: -6px;
    }

    @media #{$sp} {
      line-height: 2.14285714;
    }

    .item-wrapper-02 & {
      @media #{$pc} {
        letter-spacing: -0.01em;
        margin-right: -5px;
      }
    }
  }

  &__list {
    margin-top: 24px;

    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
    }

    @media all and (min-width: 757px) and (max-width: 1191px) {
      margin-top: 20px;
    }

    @media all and (min-width: 600px) and (max-width: 756px) {
      display: block;
      margin-top: 32px;
    }

    @media #{$sp} {
      margin-top: 32px;
    }

    .item-wrapper-02 & {
      margin-top: 79px;

      @media all and (min-width: 757px) and (max-width: 1191px) {
        margin-top: 20px;
      }

      @media #{$sp} {
        margin-top: 22px;
      }
    }
  }

  &__item {
    padding:  0 40px 0 50px;

    @media #{$pc} {
      margin-top: 45px;
      width: calc(100% / 3);

      display: flex;
      flex-direction: column;
    }

    @media all and (min-width: 757px) and (max-width: 1191px) {
      width: calc(100% / 2);
    }

    @media all and (min-width: 600px) and (max-width: 756px) {
      border-top: 1px dashed $border-gray-color;
      display: block;
      width: 100%;
      padding: 24.5px 0 23.5px 0;
      margin-top: 0;
    }

    @media #{$sp} {
      border-top: 1px dashed $border-gray-color;
      padding: 24.5px 0 23.5px 0;
    }

    @media all and (min-width: 1192px) {
      &:nth-child(3n + 1) {
        border-left: 1px dashed $border-gray-color;
        border-right: 1px dashed $border-gray-color;
      }

      &:not(:nth-child(3n + 1)) {
        border-right: 1px dashed $border-gray-color;
      }
    }

    @media all and (min-width: 757px) and (max-width: 1191px) {
      &:nth-child(2n + 1) {
        border-left: 1px dashed $border-gray-color;
        border-right: 1px dashed $border-gray-color;
      }

      &:not(:nth-child(2n + 1)) {
        border-right: 1px dashed $border-gray-color;
      }
    }

    &:last-child {

      @media all and (min-width: 600px) and (max-width: 756px) {
        border-bottom: 1px dashed $border-gray-color;
      }

      @media #{$sp} {
        border-bottom: 1px dashed $border-gray-color;
        padding-bottom: 33.5px;
      }
    }

    .item-wrapper-02 & {
      @media #{$sp} {
        padding: 17.5px 0 29.5px;
      }
    }
  }

  &__item-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71428571;
    letter-spacing: 0;

    @media all and (min-width: 757px) and (max-width: 1191px) {
      font-size: 13px;
    }

    @media #{$sp} {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.84615385;
    }

    .item-wrapper-02 & {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: 0;
      margin-top: -2px;

      @media all and (min-width: 757px) and (max-width: 1191px) {
        font-size: 18px;
      }

      @media #{$sp} {
        font-weight: 500;
      }
    }
  }

  &__item-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0;
    margin-top: 2px;

    @media all and (min-width: 757px) and (max-width: 1191px) {
      font-size: 19px;
    }

    @media #{$sp} {
      font-weight: 500;
      line-height: 1.5;
      margin-top: 4px;
    }

    .item-wrapper-02 & {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: 0;
      margin-top: 0;

      @media all and (min-width: 757px) and (max-width: 1191px) {
        font-size: 18px;
      }

      @media #{$sp} {
        font-weight: 500;
      }
    }
  }

  &__label {
    font-size: 0;
    margin-left: -9px;
  }

  &__tag {
    background-color: #9f9f9f;
    border-radius: 50px;
    color: $white-color;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.84615385;
    letter-spacing: 0;
    padding: 0px 14px 1px 15px;
    margin-top: 6px;
    margin-left: 9px;

    @media #{$sp} {
      font-size: 12px;
      line-height: 1;
      padding: 4px 8px;
      margin-top: 5px;
    }

    & + & {
      padding: 0px 13px 1px 14px;

      @media #{$sp} {
        padding: 4px 10px;
      }
    }

    .item-wrapper-02 & {
      margin-top: 9px;

      @media #{$sp} {
        margin-top: 7px;
      }
    }
  }

  &__price {
    font-size: 0;
    flex-grow: 1;
    line-height: 1.31818182;
    margin-top: 23px;

    @media #{$sp} {
      margin-top: 11px;
    }

    .item-wrapper-02 & {
      margin-top: 18px;

      @media #{$sp} {
        margin-top: 10px;
      }
    }
  }

  &__price-number {
    display: inline-block;
    font-size: 39px;
    font-weight: 400;

    @media #{$pc} {
      letter-spacing: 0;
    }

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0.08em;
    }

    .item-wrapper-02 & {
      font-size: 40px;
      letter-spacing: 0.08em;

      @media #{$sp} {
        font-size: 25px;
      }
    }
  }

  &__price-yen {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.28571429;
    letter-spacing: 0;
    margin-left: 12px;

    @media #{$sp} {
      font-size: 13px;
      line-height: 2.46153846;
      margin-left: 6px;
    }

    .item-wrapper-02 & {
      margin-left: 6px;
    }
  }

  &__price-guide {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 2.28571429;
    letter-spacing: 0;
    margin-left: 3px;

    @media #{$sp} {
      font-size: 12px;
      line-height: 2;
      margin-left: 6px;
    }
  }

  &__form {
    text-align: right;
    margin-top: 27px;

    @media #{$sp} {
      margin-top: 6px;
    }

    .item-wrapper-02 & {
      margin-top: 25px;

      @media #{$sp} {
        margin-top: 11px;
      }
    }
  }

  &__btn {
    @include btn(brownBgCart);

    line-height: 2.14285714;
    padding: 9px 10px 11px;
    max-width: 200px;

    @media #{$sp} {
      max-width: 220px;
    }
  }
}
