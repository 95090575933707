/*
  ---------------
  .pagination | お知らせ・プレスリリース ページネーション
  ---------------
*/
.pagination {
  margin-top: 35px;
  overflow: hidden;

  @media #{$sp} {
    margin-top: 27px;
  }

  &__wrap {
    display: flex;
  }

  &__link {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71428571;
    letter-spacing: 0;
  }

  .prev {
    padding-left: 31px;
    margin-right: auto;

    position: relative;

    &::before {
      content: "";
      background: url(/assets/img/newslist/news/icon_pagination_left.svg) center / contain no-repeat;
      display: block;
      width: 10.45px;
      height: 27.15px;

      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .next {
    float: right;
    padding-right: 31px;
    margin-left: auto;

    position: relative;

    &::before {
      content: "";
      background: url(/assets/img/newslist/news/icon_pagination_right.svg) center / contain no-repeat;
      display: block;
      width: 10.45px;
      height: 27.15px;

      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
