/*
  ----------------
  Top | hero
  ----------------
*/
.top-hero {
  overflow: hidden;
  
  &__wrap {
    position: relative;

    @media all and (max-width : 800px) and (min-width : 600px) {
      padding-top: 109px;
    }
  }

  &__container {
    position: relative;
  }

  &__box {
    width: 482px;
    z-index: 10;

    display: flex;
    align-items: center;
    flex-direction: column;

    position: absolute;
    top: 55.5%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{$sp} {
      top: 51.1%;
      width: 100%;
    }
  }

  &__title {
    color: $white-color;
    font-size: 50px;
    line-height: 1.4;

    @media all and (max-width : 800px) and (min-width : 600px) {
      font-size: 40px;
    }

    @media #{$sp} {
      font-size: 25px;
      line-height: 0.96;
      padding-left: 10px;
    }
  }

  &__link-wrap {
    margin-top: 34px;
    width: 100%;

    display: flex;
    justify-content: center;

    @media #{$sp} {
      margin-top: 46px;
    }
  }

  &__link {
    @include btn();
    font-size: 16px;
    line-height: 1.5;
    max-width: 200px;
    padding: 12px 10px;
    transition: background-color 0.2s ease-in-out;


    &:hover {
      background-color: $black-color;
      color: $white-color;
      opacity: 1;
      transition: background-color 0.2s ease-in-out;
    }

    @media #{$sp} {
      background-color: rgba(255, 255, 255, 0.2);
      border: 1px solid $white-color;
      color: $white-color;
    }
  }

  &__scroll {
    width: 57px;
    z-index: 10;

    display: flex;
    align-items: center;
    flex-direction: column;

    position: absolute;
    bottom: 3.4%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{$sp} {
      bottom: 3%;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: bold;

    @media #{$sp} {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 1.714;
    }
  }

  &__icon {

  }

  &__img {

  }

  .swiper-pagination-bullet {
    background-color: #f2f2f2;
    border-radius: 0;
    opacity: 1;

    height: 5px;
    width: 80px;

    @media all and (max-width : 800px) and (min-width : 600px) {
      width: 50px;
    }

    @media #{$sp} {
      max-width: 53px;
      width: 100%;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $black-color;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 15px;

    @media #{$sp} {
      bottom: 8px;
    }
  }
}
