/*
  ----------------
  Import files
  ----------------
*/
@import "setting/vars";

/*--------------------------------------------------
Mixin
--------------------------------------------------*/
@import "mixin/container";
@import "mixin/max-width";
@import "mixin/hover-link";
@import "mixin/hover-btn";
@import "mixin/btn";
@import "mixin/btn-link";
@import "mixin/lower-title";
@import "mixin/lower-title-sub";
@import "mixin/common-title";

/*--------------------------------------------------
 Base
--------------------------------------------------*/
@import "base/normalize";
@import "base/base";

/*--------------------------------------------------
 Setting
--------------------------------------------------*/
@import "setting/utility";

/*--------------------------------------------------
 Modules
--------------------------------------------------*/
@import "modules/breadcrumb";
@import "modules/button";
@import "modules/common";

// header ---------------
@import "modules/header/header-user";
@import "modules/header/header-links";
@import "modules/header/gnav";
@import "modules/header/gnav-product";
@import "modules/header/sp-menu";
@import "modules/header/style";

// footer ----------------
@import "modules/footer/style"; //Footer common

// Common menu ----------------
@import "modules/common-menu";

// slide ---------------
@import "modules/slide/item-list-fv-slide";

@import "modules/action-links-box";
@import "modules/form"; //Form common
@import "modules/itemdetails"; //itemdetails common
@import "modules/modal"; //Modal common
// @import "modules/panel"; //Panel common
@import "modules/stiky-side-link";
@import "modules/user-voice-slide";

// account common style---------------
@import "modules/account/common";
@import "modules/account/panel-heading";
@import "modules/account/amount-table";
@import "modules/account/item-thumbnail";

/*--------------------------------------------------
 Pages
--------------------------------------------------*/
@import "pages/common";

/********** Error page  **********/
@import "pages/error/error-page";

/********** Top  **********/
@import "pages/top/style";
@import "pages/top/top-hero";
@import "pages/top/pick-up";
@import "pages/top/top-product";
@import "pages/top/top-concept";
@import "pages/top/top-column";
@import "pages/top/top-news";
@import "pages/top/top-text-animation";

/********** About | 会社について  **********/
@import "pages/abouts/about-heading";
@import "pages/abouts/about-mission";
@import "pages/abouts/about-philosophy";
@import "pages/abouts/about-info";

/********** FAQ | よくあるご質問  **********/
@import "pages/faq/faq-heading";
@import "pages/faq/faq-contents";
@import "pages/faq/style";

/********** Guide | ご注文ガイド  **********/
@import "pages/guide/guide-heading";
@import "pages/guide/guide-contents";
@import "pages/guide/style";

/********** Privacy policy | 個人情報保護方針 **********/
@import "pages/privacy/privacy-policy";

/********** tokusho | 特定商取引に関する法律に基づく表記 **********/
@import "pages/tokusho/order-info";

/********** User voice | お客様の声集 **********/
@import "pages/voice/voice-lead";
@import "pages/voice/voice-main";

/********** User interview | お客様インタビュー | CMS **********/
@import "pages/interview/interview-heading";
@import "pages/interview/interview-lead";
@import "pages/interview/interview-new";
@import "pages/interview/interview-main";
// 記事
@import "pages/interview/news-archive-voice";
@import "pages/interview/interview-article-slide";

/********** Signin | サインイン **********/
@import "pages/signin/form-normal-signin";

/********** Signup | サインアップ **********/
@import "pages/signup/signup-form";

/********** Cart | カート **********/
@import "pages/cart/cart-main";

/********** Account | アカウント **********/
@import "pages/account/common";
@import "pages/account/account/account-overview";
@import "pages/account/myorders/view-orders";
@import "pages/account/myorder/view-order";
@import "pages/account/orderhistory/order-journal-history";
@import "pages/account/rpitems/rp-subscriptions";
@import "pages/account/rpschedule/rp-future-entries";
@import "pages/account/rporderentry/rp-entry";
@import "pages/account/rpconsignee/form-rps-consignee";
@import "pages/account/rppayment/form-regular-purchase-payment";
@import "pages/account/editprop/edit-account-properties";
@import "pages/account/edit/form-edit-account";
@import "pages/account/addressbook/manage-addressbook";
@import "pages/account/paymentmethod/manage-paymentmethod";
@import "pages/account/coupons/yourcoupons";
@import "pages/account/inquiries/view-inquiries";


/********** Order | 注文 **********/
@import "pages/order/style";

/********** Updatepass | パスワード更新 **********/
@import "pages/updatepass/updatepass-main";

/********** Riminder | 仮パスワードの発行 **********/
@import "pages/reminder/entry-reminder-update";

/********** List | 商品一覧 **********/
@import "pages/list/item-list-fv";
@import "pages/list/item-list-lead";
@import "pages/list/item-list-main";
@import "pages/list/item-list-sub";
@import "pages/list/item-list";
@import "pages/list/item-list-howto";

/********** Item | 商品詳細 **********/
// @import "pages/item/item-contents-main";
@import "pages/item/item-description";
@import "pages/item/item-fv";
@import "pages/item/item-purchase";
@import "pages/item/item-regular";
@import "pages/item/item-shop-guide";
@import "pages/item/item-single";
@import "pages/item/item-switch";
@import "pages/item/item-trial";
@import "pages/item/item-inquiry";

/********** Contact | お問い合わせ・資料請求 **********/
@import "pages/contact/contact-catalog";
@import "pages/contact/contact-form";

/********** Online Counseling | オンラインカウンセリング **********/
@import "pages/counseling/counseling-fv";
@import "pages/counseling/counseling-guidance";
@import "pages/counseling/counseling-flow";
@import "pages/counseling/counseling-precautions";
@import "pages/counseling/counseling-form";

/********** Column | コラム一覧  **********/
@import "pages/newslist/column/column-heading";
@import "pages/newslist/column/column-contents";
@import "pages/newslist/column/style";

/********** Column Detail | コラム詳細  **********/
@import "pages/newslist/column/column-detail";

/********** Story Detail | ラメリアストーリー  **********/
@import "pages/newslist/story/story-heading";
@import "pages/newslist/story/story-detail";
@import "pages/newslist/column/_column-detail";

/********** News | お知らせ一覧  CMS & Template **********/
@import "pages/newslist/news/news/news-archive"; // for standard feature
// @import "pages/newslist/news/newsentries/news-archive"; // for standard feature ←必要なければ削除
@import "pages/newslist/news/entries";
// @import "pages/newslist/news/entry"; ←必要なければ削除
@import "pages/newslist/news/news-heading";
@import "pages/newslist/news/news-sidebar";
@import "pages/newslist/news/news-tab";
@import "pages/newslist/news/pagination";
// @import "pages/newslist/news/style"; ←必要なければ削除
