/*
  ----------------
  コラム一覧 | column-contents
  ----------------
*/
.column,
.newslist_column_index_html,
.news-archive-column_pick_up,
.news-archive-column_less_is_more,
.news-archive-column_main {

  .newslist-column_less_is_more {
    margin-top: 82px;

    @media #{$sp} {
      margin-top: 64px;
    }
  }

  .newslist-column_main {
    margin-top: 98px;

    @media #{$sp} {
      margin-top: 87px;
    }
  }

  .newslist-column_less_is_more,
  .newslist-column_main {

    .entry-icon {
      position: relative;
      overflow: hidden;
      padding-top: 61.1111%;

      @media #{$sp} {
        padding-top: 61.0895%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__wrap {
  }

  &__title,
  .panel-heading-text .news-name {
    border-bottom: 1px solid $black-color;
    font-size: 25px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.6;
    padding-bottom: 14px;
    text-align: left;

    @media #{$sp} {
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 5px;
    }
  }

  &__list,
  .news-entries {
    margin: 50px auto 0;
    max-width: 1206px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 63px;

    @media all and (min-width: 600px) and (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 17px 26px;
    }

    @media #{$sp} {
      margin-top: 25px;
      max-width: 100%;

      grid-template-columns: repeat(2, 1fr);
      gap: 17px 26px;
    }

    &.upper {
      grid-template-columns: repeat(2, 1fr);
      gap: 66px;

      @media all and (min-width: 600px) and (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
      }

      @media #{$sp} {
        margin-top: 39px;

        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
      }

      .is-new-item::before {
        top: 12px;
        right: 13px;
      }

      .column__box {
        padding: 26px 30px 25px;

        @media #{$sp} {
          padding: 19px 12px 23px;
        }
      }

      .column__title-column {
        font-size: 16px;
        line-height: 1.6;

        @media #{$sp} {
          font-size: 16px;
          line-height: 1.25;
        }
      }

      .column__text {
        font-size: 25px;
        line-height: 1.6;
        margin-top: 5px;

        @media #{$sp} {
          font-size: 20px;
          line-height: 1.5;
          margin-top: 3px;
        }
      }
    }

    .news-entry {
      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  &__item {

    @media #{$sp} {
      display: block;
      margin: 0;
    }
  }

  &__link {
    background-color: $main-color;
    display: inline-block;
    width: 100%;
  }

  &__img {
    display: block;
    position: relative;

    &.is-new-item::before {
      content: "";
      display: block;
      background: url(/assets/img/column/icon_new_badge.svg) 50%/contain no-repeat;
      position: absolute;
      top: 6px;
      right: 6px;
      height: 70px;
      width: 70px;

      @media #{$sp} {
        height: 40px;
        width: 40px;
      }
    }
  }

  &__box,
  .entry-text {
    background-color: $main-color;
    padding: 26px 30px 25px;

    @media #{$sp} {
      padding: 19px 12px 23px;
    }
  }

  .meta {
    display: none;
  }

  &__title-column,
  .headline {
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: center;

    @media #{$sp} {
      padding-right: 3px;
      padding-left: 3px;
    }
  }

  &__text,
  .body {
    font-size: 20px;
    letter-spacing: 0.03em;
    line-height: 1.5;
    margin-top: 8px;
    text-align: center;

    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media #{$sp} {
      font-size: 18px;
      line-height: 1.1111;
    }
  }

  &__more,
  .news-archives-link {
    margin-top: 65px;
    text-align: center;

    @media #{$sp} {
      margin-top: 53px;
    }

    & > a {
      @include btnLink(brown);
      max-width: 300px;
    }
  }

  &__btn {
    @include btnLink(brown);
  }

  // コラム一覧（Pick Up）
  .newslist-column_pick_up .news-entries {
    // grid-template-columns: repeat(auto-fit,minmax(570px,1fr));
    grid-template-columns: repeat(auto-fit,minmax(330px,1fr));
    gap: 66px;

    @media #{$sp} {
      margin-top: 39px;

      grid-template-columns: repeat(auto-fit,minmax(330px,1fr));
      gap: 15px;
    }

    .news-entry {
      &:not(:nth-child(-n+2)) {
        display: none;
      }
    }

    .is-new-item::before {
      top: 12px;
      right: 13px;
    }

    .column__box {
      padding: 26px 30px 25px;

      @media #{$sp} {
        padding: 19px 12px 23px;
      }
    }

    .column__title-column {
      font-size: 16px;
      line-height: 1.6;

      @media #{$sp} {
        font-size: 16px;
        line-height: 1.25;
      }
    }

    .column__text {
      font-size: 25px;
      line-height: 1.6;
      margin-top: 5px;

      @media #{$sp} {
        font-size: 20px;
        line-height: 1.5;
        margin-top: 3px;
      }
    }
  }


  // コラム一覧（別パターン）
  &__list.pattern2 {
    @media #{$pc} {
      display: block;

      .column__item {
        &:not(:first-child) {
          margin: 20px 0 0;
        }
      }

      .column__link {
        display: flex;
        align-items: center;
      }

      .column__img {
        width: 55.3068%;
      }

      .column__box {
        padding: 11px 51px 25px;
        flex: 1;
      }

      .column__title-column {
        text-align: left;
      }

      .column__text {
        text-align: left;
      }
    }

    @media all and (min-width: 600px) and (max-width: 1000px) {
      .column__link {
        display: block;
      }

      .column__img {
        width: 100%;
      }

      .column__box {
        padding: 29px 51px 28px;
      }
    }
  }
}
