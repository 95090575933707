/*
  ----------------
  TOP | Common
  ----------------
*/
body.top {
  overflow-x: hidden;
}

.online-counseling-sp {
  background-color: rgba(23, 23, 23, 0.8);
  color: $white-color;
  display: none;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.7;
  padding: 14px 0 12px 47px;
  text-align: center;
  transition: none;
  width: 100vw;
  z-index: 100;


  position: fixed;
  bottom: 0;
  left: 0;

  &__inner {
    position: relative;

    &::before {
      display: block;
      content: "";
      background: url(/assets/img/nav/icon_gnav_online.svg) 50% / contain no-repeat;

      position: absolute;
      top: 50%;
      left: -32px;
      width: 25px;
      height: 28px;
      transform: translateY(-50%);
    }
  }
}