/*
  ---------------
  .rp-entry | 定期出荷予定(詳細)
  ---------------
*/
.rp-entry {

  .shipments {

    > ul {
      margin-top: 40px;
    }
  }


  /*==========================================
    #order-entry-order | 購入者情報〜お支払い方法
  ==========================================*/
  #order-entry-order {
    border: 1px solid $form-border-color;
    border-bottom: 0;
    margin-top: 100px;

    @media #{$sp} {
      margin-top: 60px;
    }

    > div {
      border-bottom: 1px solid $form-border-color;

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }
    }

    .person-label,
    .header {
      background-color: $title-bg-color;
      border-right: 1px solid $form-border-color;
      flex: 0 0 260px;
      text-align: center;
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      @media all and (min-width: 600px) and (max-width: 850px) {
        border-right: 0;
      }

      @media #{$sp} {
        border-right: 0;
      }
    }

    .person > .person-contents,
    .body {
      display: block;
      padding: 10px;

      @media #{$pc} {
        flex: 0 1 100%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
      }

      @media #{$sp} {
        text-align: center;
      }
    }
  }


  .shipment {
    border: 1px solid $form-border-color;
  }

  /*==========================================
    .shipment-entry-adr | お届け先〜送り主
  ==========================================*/
  .shipment-entry-adr {

    ul {}

    .consignee,
    .sender {
      border-bottom: 1px solid $form-border-color;

      @media #{$pc} {
        display: flex;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        display: block;
      }
    }

    .header {
      background-color: $title-bg-color;
      border-right: 1px solid $form-border-color;
      flex: 0 0 260px;
      text-align: center;
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      @media all and (min-width: 600px) and (max-width: 850px) {
        border-right: 0;
      }

      @media #{$sp} {
        border-right: 0;
      }
    }

    .body {
      display: block;
      padding: 10px;

      @media #{$pc} {
        flex: 0 1 100%;
      }

      @media all and (min-width: 600px) and (max-width: 850px) {
        text-align: center;
      }

      @media #{$sp} {
        text-align: center;
      }
    }
  }

  /*==========================================
    .shipment-content | 配達予定日〜ご購入商品明細
  ==========================================*/
  .shipment-content {

    .header {
      background-color: $title-bg-color;
      border-bottom: 1px solid $form-border-color;
      display: block;
      text-align: center;
      padding: 10px;
      width: 100%;
    }

    .body {

      li {
        border-bottom: 1px solid $form-border-color;

        display: flex;

        > div {
          padding: 10px;

          flex: 0 1 100%;
        }

        h4 {

          @media all and (min-width: 600px) and (max-width: 850px) {
            border-right: 0;
            flex: 0 0 160px;
          }

          @media #{$sp} {
            border-right: 0;
            flex: 0 0 125px;
          }
        }
      }
    }

    .itemdetails {

      .itemdetails-caption {
        background-color: $title-bg-color;
        border-bottom: 1px solid $form-border-color;
        text-align: center;
        padding: 10px;

        h3 {
          font-size: 14px;
          font-weight: 400;
          line-height: 2.142;
        }
      }

      .itemdetails-header {
        display: none;
      }

      .itemdetails-body {

        .itemdetail-row  {
          border-bottom: 1px solid $form-border-color;
          padding: 30px 0 10px;

          @media #{$pc} {
            display: block;
          }

          @media all and (min-width: 600px) and (max-width: 850px) {
            display: block;
          }

          @media #{$sp} {
            padding: 30px 20px;
          }

          &:not(:first-child) {
            border-top: 0;
          }
        }

        .item {}

        .goods {
          @media #{$pc} {

            display: flex;
            align-items: center;
          }

          @media all and (min-width: 600px) and (max-width: 850px) {
            display: block;
          }

          @media #{$sp} {}
        }

        .purchase-contents {
          color: $bronw-color;

          display: flex;
          justify-content: flex-end;

          @media #{$pc} {
            // flex: 0 0 215px;
            // padding: 0 20px;
          }

          @media all and (min-width: 600px) and (max-width: 850px) {
            margin-top: 20px;

            justify-content: flex-end;
          }

          @media #{$sp} {
            margin-top: 20px;

            justify-content: flex-end;
          }

          @media all and (max-width: 448px) {
            justify-content: flex-start;
          }

          .subtotal {
            margin-left: 20px;

            @media #{$sp} {
              margin-left: 15px;
            }
          }
        }
      }
    }

    .price-total {

      @media #{$sp} {
        text-align: right;
      }

      .row {
        padding: 10px 0;

        display: flex;
        justify-content: flex-end;

        @media #{$sp} {
          padding: 20px;
        }

        @media all and (max-width: 448px) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }

      .qty {
        margin-left: 20px;

        @media #{$sp} {
          margin-left: 10px;
        }

        @media all and (max-width: 448px) {
          margin-right: 10px;
        }
      }

      .subtotal {
        margin-left: 20px;

        @media #{$sp} {
          margin-left: 10px;
        }

        @media all and (max-width: 448px) {
          margin-left: 0;
        }
      }
    }
  }
}
