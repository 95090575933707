/*
  ----------------
  会社について | Mission
  ----------------
*/
.about-mission {

  &__wrap {
    @include container;
    padding-top: 77px;

    @media all and (max-width : 800px) and (min-width: 600px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media #{$sp} {
      padding: 48px 10px 0;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.15em;
    line-height: 1.175;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0.08em;
      line-height: 1.6;
    }
  }

  &__lead {
    font-size: 30px;
    font-weight: 300;
    letter-spacing: -0.3px;
    line-height: 2;
    margin-top: 48px;
    text-align: center;

    @media #{$sp} {
      font-size: 25px;
      letter-spacing: 0;
      margin-top: 36px;
    }
  }

  &__text {
    letter-spacing: 0;
    line-height: 2.857;
    margin-top: 41px;
    text-align: center;

    @media #{$sp} {
      letter-spacing: -0.1px;
      padding: 0 10px;
      margin-top: 64px;
      text-align: left;
    }
  }


}