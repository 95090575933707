/*
  ----------------
  .form-points | ポイント利用設定
  ----------------
*/
.form-points {
  padding: 110px 80px 120px;

  @media all and (max-width : 850px) {
    padding: 110px 30px 120px;
  }

  @media #{$sp} {
    padding-top: 60px;
  }

  h1 {
    margin-top: 40px;
    text-align: center;

    @media all and (max-width : 850px) {
      font-size: 28px;
      margin-top: 30px;
    }
  }

  .form-body {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;

    .btn-submit {
      margin-top: 40px;
    }
  }
}